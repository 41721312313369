export const validateSKUs = (sku) => {
  const skus = sku
    .replace(/[\r|\n]$/, '')
    .replace(/\r/g, '')
    .replace(/\n/g, ',')
    .replace(/\s/g, '')
    .split(',');
  let passRate = 0;
  skus.map((s) => {
    if (/^(\d+)$/.test(s) || /^(-\d+)|(\d+)$/.test(s)) passRate++;
  });
  return passRate === skus.length;
};

export const formatAsCSV = (sku) => {
  return sku
    .replace(/[\r|\n]$/, '')
    .replace(/\r/g, '')
    .replace(/\n/g, ',')
    .replace(/\s/g, '');
};

export const formatAsArray = (sku) => {
  if (sku) {
    return formatAsCSV(sku)
      .split(',')
      .map((sku) => {
        if (parseInt(sku)) return parseInt(sku);
      });
  }
  return [];
};

export const validateStoreNumbers = (s) => {
  const stores = s
    .replace(/[\r|\n]$/, '')
    .replace(/\r/g, '')
    .replace(/\n/g, ',')
    .replace(/\s/g, '')
    .split(',');
  let passRate = 0;
  stores.map((s) => {
    if (/^(\d+)$/.test(s) || /^(-\d+)|(\d+)$/.test(s)) passRate++;
  });
  return passRate === stores.length;
};

export const padNumber = (n, w, z) => {
  z = z || '0';
  n = `${n}`;
  return n.length >= w ? n : new Array(w - n.length + 1).join(z) + n;
};
