import { findIndex, reduce } from 'lodash';
import ActionTypes from '../constants/ActionTypes/index';

const { queue } = ActionTypes;

const queueItems = (state) => state.queue.data;
export const queueByIds = (state) => {
  return reduce(
    queueItems(state),
    (array, row) => {
      array[row.id] = row;
      return array;
    },
    {}
  );
};

const updateQueueJobs = (updates, data) => {
  updates.forEach((u) => {
    const { id, ...rest } = u;

    const i = findIndex(data, (o) => {
      return o.id === id;
    });

    if (i > -1) {
      data[i] = { ...data[i], ...rest };
    }
  });
  return data;
};

const running = (
  state = {
    show: false,
    data: [],
    checkQueue: [], // any component or event which requires the queue check to be running will add a request to this; while it is not empty, queue check runs
  },
  action
) => {
  switch (action.type) {
    // add a request to check the queue
    case queue.REQUEST_CHECK: {
      const { checkQueue } = state;
      if (checkQueue.filter((r) => r == action.codeName).length == 0) checkQueue.push(action.codeName);
      return { ...state, checkQueue };
    }

    // remove a request to check the queue
    case queue.REMOVE_CHECK: {
      return { ...state, checkQueue: state.checkQueue.filter((r) => r !== action.codeName) };
    }

    // remove all requests to check queue
    case queue.CLEAR_REQUESTS: {
      return { ...state, checkQueue: [] };
    }

    case queue.UPDATE_MULTIPLE: {
      const { data } = state;
      const newData = updateQueueJobs(action.data, data);
      return { ...state, data: newData };
    }

    case queue.SYNC:
      return { ...state, data: action.data };

    case queue.FLOATING:
      return {
        ...state,
        show: action.show,
      };

    default:
      return state;
  }
};

export default running;
