import React, { Component } from 'react';
import { Table, TableBody, TableHead, TableCell, TableRow } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { filter } from 'lodash';
import { Content } from '../../lib/Content';
import { returnJSON } from '../../../utils/async';

class MagazineDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filter: '',
    };
  }

  componentDidMount() {
    returnJSON(
      '/api/lookup/magazines',

      (error, result) => {
        if (error) return error.log(error);
        this.setState({
          data: result,
        });
      }
    );
  }

  handleFilter = (e) => {
    const v = e.target.value;
    this.setState({ filter: v });
  };

  render() {
    const data =
      this.state.filter === ''
        ? this.state.data
        : filter(this.state.data, (f) => f.sku_no.toString().indexOf(this.state.filter) > -1);
    return (
      <Content title="Magazines" fullBleed>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SKU No</TableCell>
                <TableCell>SKU Name</TableCell>
                <TableCell>Publication Year</TableCell>
                <TableCell>Sale Start</TableCell>
                <TableCell>Sale End</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <TextField
                    value={this.state.filter}
                    style={{ width: '100%' }}
                    placeholder="Filter SKUs..."
                    onChange={this.handleFilter}
                  />
                </TableCell>
                <TableCell colSpan={4} />
              </TableRow>
              {data.map((row) => (
                <TableRow key={row.sku_no}>
                  <TableCell>{row.sku_no}</TableCell>
                  <TableCell style={{ whiteSpace: 'pre-wrap' }}>{row.sku_name}</TableCell>
                  <TableCell>{row.mag_year}</TableCell>
                  <TableCell>{moment(row.mag_start).format('Do MMMM YYYY')}</TableCell>
                  <TableCell>{moment(row.mag_end).format('Do MMMM YYYY')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Content>
    );
  }
}

export default MagazineDisplay;
