import React from 'react';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import store from './js/store';
import muiTheme from './js/constants/muiTheme';
import App from './js/components/Window/App';
import { useAuth } from './js/hooks/useAuth';
import AppLoading from './js/components/lib/AppLoading';
import ErrorPage from './js/components/lib/ErrorPage/ErrorPage';
import { ErrorBoundary } from './js/components/lib/ErrorBoundary';

export default ({ skipAD }) => {
  const { isLoading, authenticationError, apiError } = useAuth(skipAD);

  const content = () => {
    if (isLoading) return <AppLoading includeFurniture />;
    if (authenticationError) return <ErrorPage errorInfo={{ code: 401 }} includeFurniture />;
    if (apiError) return <ErrorPage errorInfo={{ code: 503 }} includeFurniture />;

    return <App />;
  };

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={muiTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ErrorBoundary>{content()}</ErrorBoundary>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Provider>
  );
};
