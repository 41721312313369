import React from 'react';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { Table, TableBody, TableHead, TableCell, TableRow, FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { MdIndeterminateCheckBox } from 'react-icons/md';
import Checkbox from '@material-ui/core/Checkbox';
import { reduce, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { postJSON } from '../../../../utils/async';
import { Content } from '../../../lib/Content';
import jss from '../../../../utils/jss';
import GenericChips from './GenericChips';
import { colorNames } from '../../../../constants/colors';
import Actions from '../../../../actions';

const styles = {
  flexWrap: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  flexWrap2: {
    display: 'flex',
  },
  flexItem: {
    flex: 1,
    paddingRight: 5,
    minWidth: 350,
  },

  flexItem2: {
    marginBottom: 15,
    paddingRight: 15,
    borderBottom: 1,
    color: '#4286f4',
  },

  chipAreaTitle: {
    paddingLeft: 5,
    fontSize: '10pt',
  },

  chipArea: {
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: colorNames.lightGrey,
    paddingLeft: 10,
  },

  tableWrapper: {
    paddingRight: 10,
    width: '95%',
  },
  directionRow: {
    flexDirection: 'row',
  },

  rightAlign: {
    textAlign: 'right',
  },
  storeInput: {
    paddingLeft: 10,
  },
  secondFlexItem: {
    textAlign: 'right',
  },
  checkbox: {
    marginBottom: 0,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

class StoreChecker extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'StoreChecker',
      classNamePrefix: 'StoreChecker-',
    });
    this.state = {
      data: [],
      storeInput: [],
      storeOutput: 'Output',
      copied: false,
      finalStores: [],
    };
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  handleChange = (event) => {
    this.setState({ height: event.target.value });
  };

  handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  handleRowClick = (store_no) => {
    this.setState((prevState) => {
      let newStores = prevState.finalStores;
      if (prevState.finalStores.includes(store_no)) {
        newStores = newStores.filter((finalStore) => finalStore !== store_no);
      } else {
        newStores.push(store_no);
      }
      return {
        finalStores: newStores,
      };
    });
  };

  handleSelectAll = (event, checked) => {
    let finalStores;
    if (checked) {
      finalStores = reduce(
        this.state.data,
        (array, value) => {
          if (value.exists) {
            array.push(value.store_no);
          }
          return array;
        },
        []
      );
    } else {
      finalStores = [];
    }
    this.setState({ finalStores });
  };

  updateCheck = () => {
    this.setState((oldState) => {
      return {
        checked: !oldState.checked,
      };
    });
  };

  handleDataRequest = () => {
    const { storeInput } = this.state;

    this.props.loading.toggle({
      show: true,
      text: 'Searching for a stores...',
    });

    postJSON(
      '/api/lookup/store/check',
      {
        store_no: storeInput.split(','),
      },
      (error, result) => {
        if (error) return error.log(error);

        const sortedData = sortBy(result, (o) => o.exists);

        this.setState(
          {
            data: sortedData,
            finalStores: result.filter((o) => o.exists).map((o) => o.store_no),
          },
          () =>
            this.props.loading.toggle({
              show: false,
            })
        );
      }
    );
  };

  handleRemoveChip = (index) => {
    this.setState((prevState) => {
      const { finalStores } = prevState;
      finalStores.splice(index, 1);
      return { finalStores };
    });
  };

  render() {
    const { classes } = this.stylesheet;

    return (
      <Content title="Store Checker" fullBleed>
        <Paper>
          <div className={classes.flexWrap}>
            <div className={jss.combine(classes.flexItem, classes.storeInput)}>
              <TextField
                label="Store No"
                underlineshow="true"
                value={this.state.storeInput}
                onChange={(event) => this.setState({ storeInput: event.target.value })}
              />
            </div>

            <div className={classes.flexItem}>
              <div className={classes.flexWrap}>
                <div className={jss.combine(classes.flexItem, classes.leftAlign)}>
                  <Button
                    style={{ marginRight: '15rem' }}
                    variant="contained"
                    primary="true"
                    onClick={this.handleDataRequest}
                    value={this.state.value}
                  >
                    SUBMIT
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.flexWrap2}>
            <div className={classes.tableWrapper}>
              <Table multiselectable="false" enableselectall="false">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        labelPlacement="start"
                        control={<Checkbox color="primary" />}
                        uncheckedicon={this.state.finalStores.length > 0 ? <MdIndeterminateCheckBox size={24} /> : null}
                        checked={
                          this.state.finalStores.length > 0 &&
                          this.state.finalStores.length === this.state.data.filter((o) => o.exists).length
                        }
                        onChange={this.handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>Store No</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Store Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody deselectonclickaway="false">
                  {this.state.data.map((row, index) => (
                    <TableRow
                      style={{ color: row.exists ? 'inherit' : 'lightgrey' }}
                      selected={this.state.finalStores.includes(row.store_no)}
                      key={row.store_no}
                    >
                      <TableCell>
                        <FormControlLabel
                          labelPlacement="start"
                          control={<Checkbox color="primary" />}
                          style={styles.checkbox}
                          disabled={!row.exists}
                          onChange={() => this.handleRowClick(row.store_no)}
                          checked={this.state.finalStores.includes(row.store_no)}
                        />
                      </TableCell>
                      <TableCell>{row.store_no}</TableCell>
                      <TableCell>{row.location_name}</TableCell>
                      <TableCell>{row.store_type}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>

            {this.state.finalStores.length > 0 && (
              <div className={jss.combine(classes.flexItem, classes.chipArea)}>
                <div className={classes.chipAreaTitle}> Final Stores </div>
                <GenericChips data={this.state.finalStores} onDelete={this.handleRemoveChip} />
                <Button
                  variant="contained"
                  secondary="true"
                  onClick={() => this.handleCopy(this.state.finalStores.join(','))}
                  value={this.state.value}
                  style={{ margin: '0.5rem', display: 'inline-block' }}
                >
                  COPY
                </Button>
              </div>
            )}
          </div>
        </Paper>
      </Content>
    );
  }
}

const { loading } = Actions;

const mapDispatchToProps = (dispatch) => ({
  loading: {
    toggle: (options) => dispatch(loading.toggle(options)),
  },
});

export default connect(null, mapDispatchToProps)(StoreChecker);
