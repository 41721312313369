import React from 'react';
import { TextField } from '@material-ui/core';

export default class StagedTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ value: nextProps.value });
  }

  handleChange = (e) => {
    const v = e.target.value;
    this.setState({ value: v });
  };

  handleCommit = (e) => {
    let { value } = this.state;
    if (this.props.onCommit) {
      value = this.props.onCommit(e, value);
    }
    this.props.onChange(e, value);
    this.props.onBlur && this.props.onBlur(e, this.state.value);
  };

  render() {
    const { value, onChange, onBlur, onCommit, ...rest } = this.props;
    return <TextField onChange={this.handleChange} onBlur={this.handleCommit} value={this.state.value} {...rest} />;
  }
}
