import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ButtonWidget from './Button';
import './style.css';

const ButtonCards = (props) => {
  const isRow = !props.col;
  const classes = classnames('button--group', { row: isRow }, { col: !isRow });
  return (
    <div className={classes}>
      {props.data.map((name, i) => (
        <ButtonWidget
          key={i}
          selectedRadio={props.selectedRadio}
          selectRadio={props.selectRadio}
          radioName={name}
          ignoreFormat={props.ignoreFormat}
          func={props.func}
        />
      ))}
    </div>
  );
};

ButtonCards.propTypes = {
  data: PropTypes.array.isRequired,
  selectedRadio: PropTypes.string.isRequired,
  selectRadio: PropTypes.func.isRequired,
  col: PropTypes.bool,
  ignoreFormat: PropTypes.array,
  func: PropTypes.func,
};

export default ButtonCards;
