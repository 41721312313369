import React from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import moment from 'moment';
import { Paper, Button, TextField } from '@material-ui/core';
import { MdDateRange, MdGpsFixed, MdLocalPlay, MdMoneyOff, MdRepeat, MdShoppingBasket } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Actions from '../../../../../actions';
import ContentHeader from '../../../../lib/ContentHeader';
import jss from '../../../../../utils/jss';
import { colorNames } from '../../../../../constants/colors';
import Card from '../../../../lib/Card';
import ApprovalComment from './CB_Comment';
import getCampaignObject, { getProvisionalBase } from './selectors/getCampaignObject';
import getCount from './selectors/getCount';
import { getRequiredCellsGrouped } from './selectors/getCells';
import getSupplier from './selectors/getSupplier';
import { getCycleDetails, getTimePeriods } from './selectors/getTimePeriods';
import { saveDownload } from '../../../../../utils/async';
import { smartcounts_download } from '../../../../../utils/Analytics';
import { modules } from '../../../../../constants/moduleInfo';

const MdLocalPrintShop = MdLocalPlay;

const {
  targetedComms: {
    smartcounts: { campaign },
  },
  modal,
  loading,
} = Actions;

const styles = {
  boxWrap: {
    display: 'flex',
    width: '90%',
    margin: '0 auto',
    minWidth: 960,
  },
  box: {
    flex: 2,
    maxHeight: 250,

    fontSize: '10pt',
  },
  boxHeader: {
    fontSize: '11pt',
    padding: 10,
    backgroundColor: colorNames.lightGrey,
    fontWeight: 'bold',
    userSelect: 'none',
    cursor: 'pointer',
  },
  boxContent: {
    padding: 10,
    overflowY: 'auto',
    maxHeight: 190,
  },
  between: {
    flex: 1,
    position: 'relative',
  },
  between__line: {
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: colorNames.green,
  },
  innerRow: {
    display: 'flex',
    marginBottom: 3,
  },
  innerCol: {
    flex: 1,
    whiteSpace: 'nowrap',
    '&:first-child': {
      paddingRight: 5,
    },
  },
  alignRight: {
    textAlign: 'right',
  },
  totalRow: {
    fontWeight: 'bold',
    borderTopStyle: 'double',
    borderTopColor: colorNames.lightGrey,
    marginTop: 5,
    paddingTop: 5,
  },
  targetingRow: {
    paddingBottom: 5,
    '&:not(:first-child)': {
      paddingTop: 5,
    },
    '&:last-child': {
      borderBottom: 'none',
      paddingBottom: 0,
    },
  },
  targetingCell: {
    marginBottom: 3,
    fontWeight: 'bold',
  },
  targetingVolumes: {
    display: 'flex',
    alignContent: 'center',
  },
  targetingVolumeItem: {
    marginRight: 5,
    flex: 4,
  },
  iconStyle: {
    float: 'right',
    color: colorNames.grey,
  },
  supplierLine: {
    marginTop: 40,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  supplierDetails: {
    flex: 2,
  },
  downloadProof: {
    flex: 1,
    textAlign: 'center',
  },
};

class CB_Summary extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'CampaignCreator6',
      classNamePrefix: 'CampaignCreator6-',
    });

    this.state = {
      approval: {
        show: false,
        title: '', // unnecessary?!
        message: '',
      },
      downloading: false,
    };
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  checkComplete = () => {
    const {
      campaign: { supplier, name },
    } = this.props;
    if (
      supplier.contact !== '' &&
      supplier.email !== '' &&
      supplier.email &&
      supplier.email.validEmail() &&
      name.short !== ''
    ) {
      this.props.actions.campaign.addCompleted(this.props.stepIndex);
    } else {
      this.props.actions.campaign.removeCompleted(this.props.stepIndex);
    }
  };

  handleDownloadProof = () => {
    this.setState({ downloading: true });

    smartcounts_download('proof', this.props.campaign.campaign_id);

    saveDownload(
      `/api/targeted-comms/smartcounts/campaign/proof/${this.props.campaign.campaign_id}`,
      {
        save: true,
        mimeType: 'application/pdf',
        path: `Proof_${this.props.campaign.name.short}_${this.props.campaign.campaign_id}.pdf`,
      },
      (err) => {
        this.setState({ downloading: false });
        if (err) {
          console.error(err);
        }
      }
    );
  };

  handleSend = () => {
    this.props.actions.modal.toggle((modal) => ({
      show: true,
      title: 'Complete Campaign',
      body: <ApprovalComment modal={modal} />,
      actions: [
        { text: 'Cancel', action: () => modal.close() },
        {
          text: 'Send for Approval',
          position: 'right',
          color: colorNames.green,
          disabled: !this.props.campaign.base_id,
          action: () => {
            this.props.loading.toggle({ show: true, text: 'Sending Campaign...' });
            this.props.actions.campaign
              .updateBaseID(this.props.campaign.provisionalBase)
              .then(() => {
                this.props.actions.campaign.updateStage(1);
              })

              .then(() => {
                this.props.loading.toggle({
                  complete: true,
                  doneText: 'Campaign Sent',
                  onDismiss: () => this.props.navigate(modules.smartCounts.path),
                });
              })
              .catch((err) => console.error(err));
          },
        },
      ],
    }));
  };

  handleUpdateSupplierName = (e) => {
    const contact = e.target.value;
    this.props.actions.campaign.updateSupplier({ contact }).then(() => this.checkComplete());
  };

  handleUpdateSupplierEmail = (e) => {
    const email = e.target.value;
    this.props.actions.campaign.updateSupplier({ email }).then(() => this.checkComplete());
  };

  handleUpdateName = (e) => {
    const name = e.target.value;
    this.props.actions.campaign.updateName(name).then(() => this.checkComplete());
  };

  render() {
    const { classes } = this.stylesheet;
    const { campaign } = this.props;
    const costs = {
      print: 0,
      redemption: 0,
      pcr: campaign.pcr === 'standard' ? 975 : campaign.pcr === 'extended' ? 1175 : 0,
      total: 0,
    };

    return (
      <div>
        {Object.keys(campaign.count.data).length > 0 && (
          <div>
            <ContentHeader header="Campaign Summary" style={{ wrap: { marginBottom: 10 } }} />
            <div className={classes.boxWrap}>
              <Card header="Products" icon={<MdShoppingBasket />} className={classes.box}>
                <div className={classes.innerRow}>
                  <div className={classes.innerCol}>Aisle</div>
                  <div className={classes.innerCol}>{campaign.count.data.aisle.toSentenceCase()}</div>
                </div>
                <div className={classes.innerRow}>
                  <div className={classes.innerCol}>Brand</div>
                  <div className={classes.innerCol}>{campaign.count.data.brand}</div>
                </div>
                <div className={classes.innerRow}>
                  <div className={classes.innerCol}>Redemption</div>
                  <div className={classes.innerCol}>{campaign.count.data.skus.redemption.length}</div>
                </div>
                <div className={classes.innerRow}>
                  <div className={classes.innerCol}>Brand</div>
                  <div className={classes.innerCol}>{campaign.count.data.skus.brand.length}</div>
                </div>
                <div className={classes.innerRow}>
                  <div className={classes.innerCol}>Competitor</div>
                  <div className={classes.innerCol}>{campaign.count.data.skus.competitor.length}</div>
                </div>
                <div className={classes.innerRow}>
                  <div className={classes.innerCol}>Overlay</div>
                  <div className={classes.innerCol}>{campaign.count.data.skus.overlay.length}</div>
                </div>
                <div className={classes.innerRow}>
                  <div className={classes.innerCol}>Add. Overlay</div>
                  <div className={classes.innerCol}>{campaign.count.data.skus.additionalOverlay.length}</div>
                </div>
              </Card>
              <div className={classes.between}>
                <div className={classes.between__line} />
              </div>
              <Paper className={classes.box}>
                <div className={classes.boxHeader}>
                  Timings <MdDateRange style={styles.iconStyle} size={24} />
                </div>
                <div className={classes.boxContent}>
                  <div className={classes.innerRow}>
                    <div className={classes.innerCol}>Cycle</div>
                    <div className={classes.innerCol}>{campaign.cycle && campaign.cycle.cycle}</div>
                  </div>
                  <div className={classes.innerRow}>
                    <div className={classes.innerCol}>Print Start</div>
                    <div className={classes.innerCol}>
                      {campaign.cycle && moment(campaign.cycle.start_date).format('DD/MM/YYYY')}
                    </div>
                  </div>
                  <div className={classes.innerRow}>
                    <div className={classes.innerCol}>Print End</div>
                    <div className={classes.innerCol}>
                      {campaign.cycle &&
                        moment(campaign.cycle.start_date)
                          .add(campaign.time_periods.print, 'weeks')
                          .subtract(1, 'days')
                          .format('DD/MM/YYYY')}
                    </div>
                  </div>
                  <div className={classes.innerRow}>
                    <div className={classes.innerCol}>Red. End</div>
                    <div className={classes.innerCol}>
                      {campaign.cycle &&
                        (campaign.time_periods.date
                          ? moment(campaign.time_periods.date).format('DD/MM/YYYY')
                          : moment(campaign.cycle.start_date)
                              .add(campaign.time_periods.print, 'weeks')
                              .add(campaign.time_periods.redemption, 'weeks')
                              .subtract(2, 'days')
                              .format('DD/MM/YYYY'))}
                    </div>
                  </div>
                </div>
              </Paper>
              <div className={classes.between}>
                <div className={classes.between__line} />
              </div>
              <Paper className={classes.box} style={{ flex: 3 }}>
                <div className={classes.boxHeader}>
                  Targeting <MdGpsFixed style={styles.iconStyle} size={24} />
                </div>
                <div className={classes.boxContent}>
                  {map(campaign.cellsGrouped, (cells, group) => {
                    return (
                      <div className={classes.targetingRow} key={group}>
                        <div className={classes.targetingCell}>{group.toSentenceCase()}</div>
                        {map(cells, (cell) => {
                          switch (cell.template) {
                            case 1:
                              costs.print += cell.printVolume * 0.07;
                              break;
                            case 2:
                              costs.print += cell.printVolume * 0.14;
                              break;
                            case 3:
                              costs.print += cell.printVolume * 0.14;
                              break;
                            case 4:
                              costs.print += cell.printVolume * 0.15;
                              break;
                          }
                          costs.redemption +=
                            cell.printVolume *
                            (cell.redemptionRate / 100) *
                            (cell.offer_type === 'money' ? cell.offer_value : cell.offer_value / 200);
                          const AB =
                            cell.display.match(/A$|B$/) ||
                            (cell.display.match(/Continuity$/) && (
                              <span title="Continuity">
                                <MdRepeat />
                              </span>
                            ));
                          return (
                            <div className={classes.targetingVolumes} key={cell.code}>
                              <div
                                style={{
                                  marginRight: 5,
                                  flex: 1,
                                }}
                              >
                                {AB}
                              </div>
                              <div className={classes.targetingVolumeItem} title="Print Volume">
                                <MdLocalPrintShop /> {cell.printVolume && cell.printVolume.withComma()}
                              </div>
                              <div className={classes.targetingVolumeItem} title="Redemption Rate">
                                <MdLocalPlay /> {cell.redemptionRate}%
                              </div>
                              <div className={classes.targetingVolumeItem} title="Offer">
                                <MdMoneyOff />{' '}
                                {cell.offer_type === 'money'
                                  ? cell.offer_value < 1
                                    ? `${(cell.offer_value * 100).toFixed(0)}p off`
                                    : `£${cell.offer_value} off`
                                  : `${cell.offer_value} points`}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </Paper>
              <div className={classes.between}>
                <div className={classes.between__line} />
              </div>
              <Paper className={classes.box}>
                <div className={classes.boxHeader} style={{ cursor: 'default' }}>
                  Estimated Costs
                </div>
                <div className={classes.boxContent}>
                  <div className={classes.innerRow}>
                    <div className={classes.innerCol}>Print</div>
                    <div className={jss.combine(classes.innerCol, classes.alignRight)}>
                      {(costs.print < 10000 ? 10000 : costs.print).formatCurrency()}
                    </div>
                  </div>
                  <div className={classes.innerRow}>
                    <div className={classes.innerCol}>Redemption</div>
                    <div className={jss.combine(classes.innerCol, classes.alignRight)}>
                      {costs.redemption.formatCurrency()}
                    </div>
                  </div>
                  {campaign.pcr !== 'none' && (
                    <div className={classes.innerRow}>
                      <div className={classes.innerCol}>PCR</div>
                      <div className={jss.combine(classes.innerCol, classes.alignRight)}>
                        {costs.pcr.formatCurrency()}
                      </div>
                    </div>
                  )}
                  <div className={jss.combine(classes.innerRow, classes.totalRow)}>
                    <div className={classes.innerCol}>Total</div>
                    <div className={jss.combine(classes.innerCol, classes.alignRight)}>
                      {(costs.redemption + costs.pcr + (costs.print < 10000 ? 10000 : costs.print)).formatCurrency()}
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
            <div className={jss.combine(classes.boxWrap, classes.supplierLine)}>
              <Paper className={classes.supplierDetails}>
                <div className={classes.innerRow}>
                  <div className={classes.innerCol} style={{ textAlign: 'center' }}>
                    <TextField
                      label="Contact Name"
                      value={campaign.supplier.contact || ''}
                      onChange={this.handleUpdateSupplierName}
                      disabled={campaign.stage > 0}
                    />
                  </div>
                  <div
                    className={classes.innerCol}
                    style={{
                      textAlign: 'center',
                      ...(campaign.supplier.email && !campaign.supplier.email.validEmail() && { paddingBottom: 15 }),
                    }}
                  >
                    <TextField
                      label="Contact E-mail"
                      value={campaign.supplier.email || ''}
                      onChange={this.handleUpdateSupplierEmail}
                      error={campaign.supplier.email && !campaign.supplier.email.validEmail()}
                      helperText={
                        campaign.supplier.email && !campaign.supplier.email.validEmail() && 'Please enter a valid email'
                      }
                      disabled={campaign.stage > 0}
                    />
                  </div>
                </div>
                <div className={classes.innerRow}>
                  <div className={classes.innerCol} style={{ textAlign: 'center' }}>
                    <TextField
                      label="Campaign Name"
                      value={campaign.name.short || ''}
                      onChange={this.handleUpdateName}
                      disabled={campaign.stage > 0}
                    />
                  </div>
                </div>
              </Paper>
              <div className={classes.downloadProof}>
                <Button
                  style={{ color: '#FFFFFF', backgroundColor: colorNames.blue }}
                  onClick={this.handleDownloadProof}
                  disabled={this.state.downloading || !campaign.completed.includes(this.props.stepIndex)}
                  variant="contained"
                >
                  {this.state.downloading ? 'Downloading...' : 'Download Proof'}
                </Button>
              </div>
              {campaign.stage === 0 && (
                <div className={classes.downloadProof}>
                  <Button
                    style={{ backgroundColor: colorNames.green, labelColor: '#FFFFFF' }}
                    onClick={this.handleSend}
                    disabled={!campaign.completed.includes(this.props.stepIndex)}
                    variant="contained"
                  >
                    Send for Approval
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { modal, user } = state;
  return {
    user,
    campaign: {
      completed: getCampaignObject(state).completed,
      campaign_id: getCampaignObject(state).campaign_id,
      base_id: getCampaignObject(state).base_id,
      name: { short: getCampaignObject(state).name, long: getCampaignObject(state).full_name },
      count: getCount(state),
      stage: getCampaignObject(state).stage,
      cellsGrouped: getRequiredCellsGrouped(state),
      supplier: getSupplier(state),
      rejectionComment: getCampaignObject(state).rejectionComment,
      pcr: getCampaignObject(state).cells.pcr,
      cycle: getCycleDetails(state),
      time_periods: getTimePeriods(state),
      provisionalBase: getProvisionalBase(state),
    },
    modal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loading: {
    toggle: (opts) => dispatch(loading.toggle(opts)),
  },
  actions: {
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
    },
    campaign: {
      addCompleted: (index) => dispatch(campaign.addCompleted(index)),
      removeCompleted: (index) => dispatch(campaign.removeCompleted(index)),
      updateStage: (stage) => dispatch(campaign.updateStage(stage)),
      updateSupplier: (update) => dispatch(campaign.updateSupplier(update)),
      updateName: (name) => dispatch(campaign.updateCampaignName(name)),
      updateBaseID: (base_id) => dispatch(campaign.updateBaseID(base_id)),
    },
  },
});

const SummaryWrapper = ({ ...props }) => {
  const navigate = useNavigate();
  return <CB_Summary {...props} navigate={navigate} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryWrapper);
