import React, { useState } from 'react';
import { Table, TableBody, TableHead, TableCell, TableRow, TextField } from '@material-ui/core';
import { map } from 'lodash';
import { MdError, MdLock } from 'react-icons/md';
import { colorNames } from '../../../../../../constants/colors';

const ExecutionTable = (props) => {
  const [data, setData] = useState(props.data);

  const { errors } = props;
  let total = 0;

  const handleChange = (e, cell, i) => {
    const { value } = e.target;
    data.campaign.execution[i].selected_volumes = value;
    setData(data);
    props.handleExecutionStatus(data.base.id, {
      [cell.cell_description]: value,
    });
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Cell</TableCell>
          <TableCell>Briefed Volumes</TableCell>
          <TableCell>Available Volumes</TableCell>
          <TableCell>Selected Volumes</TableCell>
          <TableCell>% of Briefed</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {map(data.campaign.execution, (cell, i) => {
          const volume = cell.cell_description == 'Total' ? total : cell.selected_volumes;
          if (cell.cell_description !== 'Total') total += parseInt(volume);
          return (
            <TableRow key={`${data.id}_${cell.cell_description}`}>
              <TableCell>{cell.cell_description}</TableCell>
              <TableCell>{cell.briefed_volumes}</TableCell>
              <TableCell>{cell.available_volumes}</TableCell>

              <TableCell>
                {cell.cell_description !== 'Total' ? (
                  !/^X|ABT - B/g.test(cell.cell_description) ? (
                    <TextField
                      name={`${cell.cell_description}_update`}
                      value={cell.selected_volumes}
                      inputstyle={{
                        fontSize: 13,
                      }}
                      style={{
                        ...(errors[data.base.id] &&
                          errors[data.base.id][cell.cell_description] && {
                            marginBottom: 15,
                          }),
                        transition: 'all 0.2s linear',
                      }}
                      onChange={(e) => {
                        handleChange(e, cell, i);
                      }}
                      error={errors[data.base.id] && errors[data.base.id][cell.cell_description]}
                      helperText={errors[data.base.id] && errors[data.base.id][cell.cell_description]}
                    />
                  ) : (
                    <span>
                      {volume}
                      <MdLock
                        style={{
                          marginLeft: 10,
                        }}
                      />
                    </span>
                  )
                ) : (
                  total
                )}
              </TableCell>
              <TableCell>
                {((Math.round((volume / cell.briefed_volumes) * 10000) / 10000) * 100).toFixed(2)}%{' '}
                {volume / cell.briefed_volumes > 1 && (
                  <span title="Selected Volumes is greater than briefed volumes">
                    <MdError color={colorNames.orange} />
                  </span>
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ExecutionTable;
