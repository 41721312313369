import jwtDecode from 'jwt-decode';
import env from './env';
import 'regenerator-runtime/runtime';

export const handleLoginApi = async (access_token) => {
  const uri = `${env.api[env.name]}/login`;

  return fetch(uri, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      access_token,
    }),
  })
    .then((response) => response.json())
    .then((token) => {
      sessionStorage.setItem('token', token.token);
      sessionStorage.setItem('refreshToken', token.refreshToken);
    });
};

export const getUser = () => {
  const token = sessionStorage.getItem('token');
  return token && jwtDecode(token);
};
