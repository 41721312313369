import { createSelectorCreator } from 'reselect';
import { memoize, forEach } from 'lodash';
import { getAisle, getCellOffers, getRequiredFlags, getRSP } from './getCells';

const createDeepEqualSelector = createSelectorCreator(memoize, (...args) =>
  args.reduce((acc, val) => `${acc}-${JSON.stringify(val)}`, '')
);

const offerValue = createDeepEqualSelector(
  [getCellOffers, getRequiredFlags, getRSP, getAisle],
  (offers, required, rsp, aisle) => {
    const warnings = {};
    forEach(offers, (offer, code) => {
      if (required[code].required) {
        if (
          (offer.type === 'points' && parseInt(offer.value) === rsp * 200) ||
          (offer.type === 'money' && parseFloat(offer.value) === rsp)
        ) {
          warnings[code] = {
            ...warnings[code],
            value: 'Offer value makes product free.',
          };
        }
      }
    });
    return warnings;
  }
);

export default createDeepEqualSelector([offerValue], (offers) => {
  const total = Object.keys(offers).length;
  return {
    offers,
    total,
  };
});
