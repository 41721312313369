import { cloneDeep } from 'lodash';
import moment from 'moment';
import ActionTypes from '../../constants/ActionTypes/index';

const {
  targetedComms: { planning },
} = ActionTypes;

const defaultState = {
  completed: [],
  aisles: { redemption: [], targeted: [] },
  name: '',
  productGroups: [],
  definitions: [],
  rules: null,
  stores: [],
  periodType: null,
  periodDates: { from: null, to: null },
  coupons: [],
  variables: {},
  segmentations: [],
  rerun: false,
  maxValue: null,
};

const planningTool = (state = cloneDeep(defaultState), action) => {
  switch (action.type) {
    case planning.RESTORE_COUNT: {
      const {
        completed,
        aisles,
        name,
        productGroups,
        definitions,
        rules,
        stores,
        periodDates,
        coupons,
        periodType,
        variables,
        segmentations,
        maxValue,
      } = action.payload;

      if (periodType == 'daterange') {
        periodDates.from = moment(periodDates.from).toDate();
        periodDates.to = moment(periodDates.to).toDate();
      }

      const generateName = () => {
        const num = moment().format('YYYYMMDDHHmm');
        return name.replace(/(_Clone_(\d*))?$/, `_Clone_${num}`);
      };
      return {
        ...state,
        completed: [0],
        aisles: { redemption: [], targeted: [] },
        name: generateName(),
        productGroups,
        definitions,
        rules,
        stores,
        periodDates,
        coupons,
        periodType,
        variables,
        segmentations,
        rerun: true,
        maxValue,
      };
    }

    case planning.ADD_COMPLETED: {
      const { completed } = state;
      if (!completed.includes(action.index)) completed.push(action.index);
      return {
        ...state,
        completed,
      };
    }
    case planning.REMOVE_COMPLETED: {
      const { completed } = state;
      return {
        ...state,
        completed: completed.filter((item) => item !== action.index),
      };
    }
    case planning.RESET:
      return {
        ...cloneDeep(defaultState),
      };
    case planning.RECEIVED_AISLES:
      return {
        ...state,
        aisles: action.aisles,
      };
    case planning.CHANGE_NAME:
      return {
        ...state,
        name: action.data,
      };
    case planning.CHANGE_GROUPS: {
      return {
        ...state,
        productGroups: action.productGroups,
      };
    }

    case planning.CHANGE_BANDING_VISIBILITY: {
      const pg = JSON.parse(JSON.stringify(state.productGroups));
      if (pg[action.index].bandings.show.includes(action.field)) {
        pg[action.index].bandings.show = pg[action.index].bandings.show.filter((item) => item !== action.field);
      } else {
        pg[action.index].bandings.show.push(action.field);
      }
      return {
        ...state,
        productGroups: pg,
      };
    }

    case planning.CHANGE_AISLES:
      return {
        ...state,
        definitions: action.definitions,
        rules: action.rules,
      };
    case planning.CHANGE_STORES:
      return {
        ...state,
        stores: action.stores,
      };
    case planning.CHANGE_PERIOD:
      var maxValue = action.periodDates.from
        ? moment(action.periodDates.to).startOf('week').diff(moment(action.periodDates.from), 'weeks')
        : state.rules;
      return {
        ...state,
        periodType: action.periodType,
        periodDates: action.periodDates,
        maxValue: parseInt(maxValue),
      };
    case planning.CHANGE_COUPONS:
      return {
        ...state,
        coupons: action.coupons,
      };
    case planning.CHANGE_PROMOS:
      return {
        ...state,
        promos: action.promos,
      };
    case planning.CHANGE_VARS:
      return {
        ...state,
        variables: action.variables,
      };
    case planning.CHANGE_SEGMENTATIONS:
      return {
        ...state,
        segmentations: action.segmentations,
      };
    default:
      return state;
  }
};

export default planningTool;
