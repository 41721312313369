import React from 'react';
import nectar360 from '../../../../images/nectar360.svg';
import packageJson from '../../../../../package.json';
import env from '../../../utils/env';

const runEnv = env.name;

const { version } = packageJson;

const SUPPORT_LINK =
  'https://sainsburys.service-now.com/assist?id=sc_cat_item&table=sc_cat_item&sys_id=79e8de141b3b38d03f00a6c7b04bcb3a';

const styles = {
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '16px',
    boxSizing: 'border-box',
    overflowY: 'auto',
  },
  pageContainer: {
    flexGrow: 1,
  },
  loadingText: {
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'center',
  },
  footerContainer: {
    minHeight: '60px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end',
  },
  footerText: {
    margin: '0',
    marginLeft: '24px',
    color: '#2E2E2E',
    fontSize: '14px',
  },
  nectar360: {
    maxWidth: '60px',
  },
};

const FullPageContent = ({ children, includeFurniture }) => (
  <div style={styles.container}>
    {includeFurniture && (
      <div>
        <img src={nectar360} alt="" style={styles.nectar360} />
      </div>
    )}
    <div style={styles.pageContainer}>{children}</div>
    {includeFurniture && (
      <div style={styles.footerContainer}>
        <p style={styles.footerText}>
          {runEnv.toCapitalized()} v{version}
        </p>
        <a href={SUPPORT_LINK} style={styles.footerText} target="_blank" rel="noreferrer">
          Report an issue
        </a>
        <p style={styles.footerText}>&copy; Nectar 360 Limited {new Date().getFullYear()}</p>
      </div>
    )}
  </div>
);

export default FullPageContent;
