import _ from 'lodash';
import { formatProper } from '../../../../../utils/utils';

/**
 * @param {Array} data
 * */

const calcUplifts = function (data) {
  /**
   * @param {Object} value
   * @param {string} value.MASTER_CAMPAIGN_ID
   * @param {string} value.super_category
   * @param {string} value.metric
   * @param {string} value.manufacturer
   * @param {string} value.cell
   * @param {number} value.offer_exp
   * @param {number} value.offer_ctrl
   * @param {number} value.aisle_exp
   * @param {number} value.aisle_ctrl
   * */
  return {
    campaigns: _.reduce(
      data,
      (result, value) => {
        result.All || (result.All = []);
        if (!result.All.includes(value.master_campaign_id)) {
          result.All.push(value.master_campaign_id);
        }
        result.Channel || (result.Channel = {});
        result.Channel[value.cell] || (result.Channel[value.cell] = []);
        if (!result.Channel[value.cell].includes(value.master_campaign_id)) {
          result.Channel[value.cell].push(value.master_campaign_id);
        }
        result.Category || (result.Category = {});
        result.Category[value.category] || (result.Category[value.category] = []);
        if (!result.Category[value.category].includes(value.master_campaign_id)) {
          result.Category[value.category].push(value.master_campaign_id);
        }
        result.Super_Category || (result.Super_Category = {});
        result.Super_Category[value.super_category] || (result.Super_Category[value.super_category] = []);
        if (!result.Super_Category[value.super_category].includes(value.master_campaign_id)) {
          result.Super_Category[value.super_category].push(value.master_campaign_id);
        }
        result.Brand || (result.Brand = {});
        result.Brand[value.brand] || (result.Brand[value.brand] = []);
        if (!result.Brand[value.brand].includes(value.master_campaign_id)) {
          result.Brand[value.brand].push(value.master_campaign_id);
        }
        result.Manufacturer || (result.Manufacturer = {});
        result.Manufacturer[value.manufacturer] || (result.Manufacturer[value.manufacturer] = []);
        if (!result.Manufacturer[value.manufacturer].includes(value.master_campaign_id)) {
          result.Manufacturer[value.manufacturer].push(value.master_campaign_id);
        }
        return result;
      },
      {}
    ),
    uplifts: _.reduce(
      data,
      (result, value) => {
        result.summary_results || (result.summary_results = {});
        result.summary_results[value.metric] || (result.summary_results[value.metric] = {});
        if ('exp_offer' in result.summary_results[value.metric]) {
          result.summary_results[value.metric].exp_offer += value.offer_exp;
          result.summary_results[value.metric].ctrl_offer += value.offer_ctrl;
          result.summary_results[value.metric].exp_aisle += value.offer_exp;
          result.summary_results[value.metric].ctrl_aisle += value.offer_ctrl;
        } else {
          result.summary_results[value.metric].exp_offer = value.offer_exp;
          result.summary_results[value.metric].ctrl_offer = value.offer_ctrl;
          result.summary_results[value.metric].exp_aisle = value.aisle_exp;
          result.summary_results[value.metric].ctrl_aisle = value.aisle_ctrl;
        }
        result.Channel || (result.Channel = {});
        result.Channel[value.metric] || (result.Channel[value.metric] = {});
        result.Channel[value.metric][value.cell] || (result.Channel[value.metric][value.cell] = {});
        if ('exp_offer' in result.Channel[value.metric][value.cell]) {
          result.Channel[value.metric][value.cell].exp_offer += value.offer_exp;
          result.Channel[value.metric][value.cell].ctrl_offer += value.offer_ctrl;
          result.Channel[value.metric][value.cell].exp_ctrl += value.aisle_exp;
          result.Channel[value.metric][value.cell].ctrl_ctrl += value.aisle_ctrl;
        } else {
          result.Channel[value.metric][value.cell].exp_offer = value.offer_exp;
          result.Channel[value.metric][value.cell].ctrl_offer = value.offer_ctrl;
          result.Channel[value.metric][value.cell].exp_aisle = value.aisle_exp;
          result.Channel[value.metric][value.cell].ctrl_aisle = value.aisle_ctrl;
        }
        result.Category || (result.Category = {});
        result.Category[value.metric] || (result.Category[value.metric] = {});
        result.Category[value.metric][value.category] || (result.Category[value.metric][value.category] = {});
        if ('exp_offer' in result.Category[value.metric][value.category]) {
          result.Category[value.metric][value.category].exp_offer += value.offer_exp;
          result.Category[value.metric][value.category].ctrl_offer += value.offer_ctrl;
          result.Category[value.metric][value.category].exp_aisle += value.aisle_exp;
          result.Category[value.metric][value.category].ctrl_aisle += value.aisle_ctrl;
        } else {
          result.Category[value.metric][value.category].exp_offer = value.offer_exp;
          result.Category[value.metric][value.category].ctrl_offer = value.offer_ctrl;
          result.Category[value.metric][value.category].exp_aisle = value.aisle_exp;
          result.Category[value.metric][value.category].ctrl_aisle = value.aisle_ctrl;
        }
        result.Super_Category || (result.Super_Category = {});
        result.Super_Category[value.metric] || (result.Super_Category[value.metric] = {});
        result.Super_Category[value.metric][value.super_category] ||
          (result.Super_Category[value.metric][value.super_category] = {});
        if ('exp_offer' in result.Super_Category[value.metric][value.super_category]) {
          result.Super_Category[value.metric][value.super_category].exp_offer += value.offer_exp;
          result.Super_Category[value.metric][value.super_category].ctrl_offer += value.offer_ctrl;
          result.Super_Category[value.metric][value.super_category].exp_aisle += value.aisle_exp;
          result.Super_Category[value.metric][value.super_category].ctrl_aisle += value.aisle_ctrl;
        } else {
          result.Super_Category[value.metric][value.super_category].exp_offer = value.offer_exp;
          result.Super_Category[value.metric][value.super_category].ctrl_offer = value.offer_ctrl;
          result.Super_Category[value.metric][value.super_category].exp_aisle = value.aisle_exp;
          result.Super_Category[value.metric][value.super_category].ctrl_aisle = value.aisle_ctrl;
        }
        result.Brand || (result.Brand = {});
        result.Brand[value.metric] || (result.Brand[value.metric] = {});
        result.Brand[value.metric][value.brand] || (result.Brand[value.metric][value.brand] = {});
        if ('exp_offer' in result.Brand[value.metric][value.brand]) {
          result.Brand[value.metric][value.brand].exp_offer += value.offer_exp;
          result.Brand[value.metric][value.brand].ctrl_offer += value.offer_ctrl;
          result.Brand[value.metric][value.brand].exp_aisle += value.aisle_exp;
          result.Brand[value.metric][value.brand].ctrl_aisle += value.aisle_ctrl;
        } else {
          result.Brand[value.metric][value.brand].exp_offer = value.offer_exp;
          result.Brand[value.metric][value.brand].ctrl_offer = value.offer_ctrl;
          result.Brand[value.metric][value.brand].exp_aisle = value.aisle_exp;
          result.Brand[value.metric][value.brand].ctrl_aisle = value.aisle_ctrl;
        }
        result.Manufacturer || (result.Manufacturer = {});
        result.Manufacturer[value.metric] || (result.Manufacturer[value.metric] = {});
        result.Manufacturer[value.metric][value.manufacturer] ||
          (result.Manufacturer[value.metric][value.manufacturer] = {});
        if ('exp_offer' in result.Manufacturer[value.metric][value.manufacturer]) {
          result.Manufacturer[value.metric][value.manufacturer].exp_offer += value.offer_exp;
          result.Manufacturer[value.metric][value.manufacturer].ctrl_offer += value.offer_ctrl;
          result.Manufacturer[value.metric][value.manufacturer].exp_aisle += value.aisle_exp;
          result.Manufacturer[value.metric][value.manufacturer].ctrl_aisle += value.aisle_ctrl;
        } else {
          result.Manufacturer[value.metric][value.manufacturer].exp_offer = value.offer_exp;
          result.Manufacturer[value.metric][value.manufacturer].ctrl_offer = value.offer_ctrl;
          result.Manufacturer[value.metric][value.manufacturer].exp_aisle = value.aisle_exp;
          result.Manufacturer[value.metric][value.manufacturer].ctrl_aisle = value.aisle_ctrl;
        }
        return result;
      },
      {}
    ),
  };
};

const calcSummaries = function (data) {
  /**
   * @param {Object} v.EXPOSED
   * * */
  const uplifts = calcUplifts(data);
  const { campaigns } = uplifts;
  return {
    uplifts: _.reduce(
      uplifts.uplifts,
      (r, v, k) => {
        if (k === 'summary_results') {
          r[k] = _.reduce(
            v,
            (result, value, key) => {
              if (key === 'EXPOSED') {
                return result;
              }
              if (key === 'KNOWN_SPEND' || key === 'UNITS') {
                const uplift = (value.exp_offer - value.ctrl_offer) / value.ctrl_offer;
                const custs = ((value.exp_offer - value.ctrl_offer) / v.EXPOSED.exp_offer) * 1000;
                result[key] = {
                  perc: isFinite(uplift) ? uplift : 0,
                  custs: isFinite(custs) ? custs : 0,
                };
              } else {
                const uplift = (value.exp_offer - value.ctrl_offer) / value.ctrl_offer;
                result[key] = {
                  perc: isFinite(uplift) ? uplift : 0,
                };
              }
              return result;
            },
            {}
          );
          r[k].FOP = {
            perc:
              (v.VISITS.exp_offer / v.TOTAL_CUSTS.exp_offer - v.VISITS.ctrl_offer / v.TOTAL_CUSTS.ctrl_offer) /
              (v.VISITS.ctrl_offer / v.TOTAL_CUSTS.ctrl_offer),
          };
          r[k].SPC = {
            perc:
              (v.KNOWN_SPEND.exp_offer / v.TOTAL_CUSTS.exp_offer -
                v.KNOWN_SPEND.ctrl_offer / v.TOTAL_CUSTS.ctrl_offer) /
              (v.KNOWN_SPEND.ctrl_offer / v.TOTAL_CUSTS.ctrl_offer),
          };
          r[k].UPV = {
            perc:
              (v.UNITS.exp_offer / v.VISITS.exp_offer - v.UNITS.ctrl_offer / v.VISITS.ctrl_offer) /
              (v.UNITS.ctrl_offer / v.VISITS.ctrl_offer),
          };
          r[k].SPV = {
            perc:
              (v.KNOWN_SPEND.exp_offer / v.VISITS.exp_offer - v.KNOWN_SPEND.ctrl_offer / v.VISITS.ctrl_offer) /
              (v.KNOWN_SPEND.ctrl_offer / v.VISITS.ctrl_offer),
          };
          r[k].PEN = {
            perc:
              (v.TOTAL_CUSTS.exp_offer / v.TOTAL_CUSTS.exp_aisle -
                v.TOTAL_CUSTS.ctrl_offer / v.TOTAL_CUSTS.ctrl_aisle) /
              (v.TOTAL_CUSTS.ctrl_offer / v.TOTAL_CUSTS.ctrl_aisle),
          };
          r[k].SOW = {
            perc:
              (v.KNOWN_SPEND.exp_offer / v.KNOWN_SPEND.exp_aisle -
                v.KNOWN_SPEND.ctrl_offer / v.KNOWN_SPEND.ctrl_aisle) /
              (v.KNOWN_SPEND.ctrl_offer / v.KNOWN_SPEND.ctrl_aisle),
          };
        } else {
          r[k] = _.reduce(
            v,
            (result, value, key) => {
              if (key === 'EXPOSED') {
                return result;
              }
              if (key === 'KNOWN_SPEND' || key === 'UNITS') {
                result[key] = {
                  perc: _.map(value, (i, index) => {
                    return {
                      value: isFinite((i.exp_offer - i.ctrl_offer) / i.ctrl_offer)
                        ? (i.exp_offer - i.ctrl_offer) / i.ctrl_offer
                        : 0,
                      type: formatProper(index, ['TV', 'JS']),
                      metric: key,
                      selection: k,
                      campaigns: campaigns[k][index],
                      result_type: 'perc',
                    };
                  }),
                  custs: _.map(value, (i, index) => {
                    const exposedCusts = uplifts.uplifts[k].EXPOSED[index].exp_offer;
                    return {
                      value: isFinite(((i.exp_offer - i.ctrl_offer) / exposedCusts) * 1000)
                        ? ((i.exp_offer - i.ctrl_offer) / exposedCusts) * 1000
                        : 0,
                      type: formatProper(index, ['TV', 'JS']),
                      metric: key,
                      selection: k,
                      campaigns: campaigns[k][index],
                      result_type: 'custs',
                    };
                  }),
                };
              } else {
                result[key] = {
                  perc: _.map(value, (i, index) => {
                    return {
                      value: isFinite((i.exp_offer - i.ctrl_offer) / i.ctrl_offer)
                        ? (i.exp_offer - i.ctrl_offer) / i.ctrl_offer
                        : 0,
                      type: formatProper(index, ['TV', 'JS']),
                      metric: key,
                      selection: k,
                      campaigns: campaigns[k][index],
                      result_type: 'perc',
                    };
                  }),
                };
              }
              return result;
            },
            {}
          );
          r[k].FOP = {
            perc: _.map(v.VISITS, (value, key) => {
              const custs = v.TOTAL_CUSTS[key];
              return {
                value:
                  (value.exp_offer / custs.exp_offer - value.ctrl_offer / custs.ctrl_offer) /
                  (value.ctrl_offer / custs.ctrl_offer),
                type: formatProper(key, ['TV', 'JS']),
                metric: 'FOP',
                selection: k,
                campaigns: campaigns[k][key],
                result_type: 'perc',
              };
            }),
          };
          r[k].SPC = {
            perc: _.map(v.KNOWN_SPEND, (value, key) => {
              const custs = v.TOTAL_CUSTS[key];
              return {
                value:
                  (value.exp_offer / custs.exp_offer - value.ctrl_offer / custs.ctrl_offer) /
                  (value.ctrl_offer / custs.ctrl_offer),
                type: formatProper(key, ['TV', 'JS']),
                metric: 'SPC',
                selection: k,
                campaigns: campaigns[k][key],
                result_type: 'perc',
              };
            }),
          };
          r[k].UPV = {
            perc: _.map(v.UNITS, (value, key) => {
              const custs = v.VISITS[key];
              return {
                value:
                  (value.exp_offer / custs.exp_offer - value.ctrl_offer / custs.ctrl_offer) /
                  (value.ctrl_offer / custs.ctrl_offer),
                type: formatProper(key, ['TV', 'JS']),
                metric: 'UPV',
                selection: k,
                campaigns: campaigns[k][key],
                result_type: 'perc',
              };
            }),
          };
          r[k].SPV = {
            perc: _.map(v.KNOWN_SPEND, (value, key) => {
              const custs = v.VISITS[key];
              return {
                value:
                  (value.exp_offer / custs.exp_offer - value.ctrl_offer / custs.ctrl_offer) /
                  (value.ctrl_offer / custs.ctrl_offer),
                type: formatProper(key, ['TV', 'JS']),
                metric: 'SPV',
                selection: k,
                campaigns: campaigns[k][key],
                result_type: 'perc',
              };
            }),
          };
          r[k].PEN = {
            perc: _.map(v.TOTAL_CUSTS, (value, key) => {
              return {
                value:
                  (value.exp_offer / value.exp_aisle - value.ctrl_offer / value.ctrl_aisle) /
                  (value.ctrl_offer / value.ctrl_aisle),
                type: formatProper(key, ['TV', 'JS']),
                metric: 'PEN',
                selection: k,
                campaigns: campaigns[k][key],
                result_type: 'perc',
              };
            }),
          };
          r[k].SOW = {
            perc: _.map(v.KNOWN_SPEND, (value, key) => {
              return {
                value:
                  (value.exp_offer / value.exp_aisle - value.ctrl_offer / value.ctrl_aisle) /
                  (value.ctrl_offer / value.ctrl_aisle),
                type: formatProper(key, ['TV', 'JS']),
                metric: 'SOW',
                selection: k,
                campaigns: campaigns[k][key],
                result_type: 'perc',
              };
            }),
          };
        }
        return r;
      },
      {}
    ),
    campaigns: uplifts.campaigns,
  };
};

const csvSummaries = function (data) {
  const summaries = calcSummaries(data);
  const { uplifts } = summaries;
  const { campaigns } = summaries;
  return _.flatten(
    _.reduce(
      uplifts,
      (result, value, key) => {
        if (key !== 'summary_results') {
          result.push(
            _.flatten(
              _.map(value, (metric) => {
                return _.flatten(
                  _.map(metric, (item, index) => {
                    return _.map(item, (i) => {
                      i.result_type = index === 'custs' ? 'per 1000 customers' : 'percentage uplift';
                      i.campaigns = i.campaigns.join();
                      return i;
                    });
                  })
                );
              })
            )
          );
        } else {
          result.push(
            _.flatten(
              _.reduce(
                value,
                (result, metric, key) => {
                  if (key !== 'campaignCount') {
                    result.push(
                      _.map(metric, (item, index) => {
                        return {
                          campaigns: campaigns.All.join(),
                          metric: key,
                          result_type: index === 'custs' ? 'per 1000 customers' : 'percentage uplift',
                          selection: 'Total',
                          type: 'Total',
                          value: item,
                        };
                      })
                    );
                  } else {
                    result.push([
                      {
                        campaigns: campaigns.All.join(),
                        metric: 'campaignCount',
                        result_type: 'count',
                        selection: 'Total',
                        type: 'Total',
                        value: metric,
                      },
                    ]);
                  }
                  return result;
                },
                []
              )
            )
          );
        }
        return result;
      },
      []
    )
  );
};

const isEmpty = function (filters, allData) {
  const dates = _.map(allData, 'start_date');
  const minDate = new Date(Math.min.apply(null, dates));
  const maxDate = new Date(Math.max.apply(null, dates));
  return _.map(filters, (value, key) => {
    if (key !== 'date') {
      return value.length === 0;
    }
    return value.start.getTime() === minDate.getTime() && value.end.getTime() === maxDate.getTime();
  }).every((element) => element);
};

const textEmpty = function (event) {
  try {
    return event.target.value === '';
  } catch (err) {
    return true;
  }
};

export { calcSummaries, csvSummaries, isEmpty, textEmpty };
