import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Snackbar } from '@material-ui/core';
import Sidebar from 'react-sidebar';
import { configureGA } from '../../utils/Analytics';
import Actions from '../../actions/index';
import Routes from './routes/index.js';
import Appbar from '../lib/Appbar/Appbar';
import Footer from '../lib/Appbar/Footer';
import SideBarContent from '../lib/Sidebar/sidebar.content';
import Modal2 from '../lib/Modal/Modal2';
import Loading from '../lib/Loading/Loading';
import config from '../../../config';
import env from '../../utils/env';
import { getUser } from '../../utils/authProvider';
import QueueCheck from '../lib/QueueCheck';
import ErrorPage from '../lib/ErrorPage/ErrorPage';
import '../../utils/PrototypeExtensions';
import './style.css';

const { appInfo } = config;
const runEnv = env.name;

console.dev = (...args) => {
  if (runEnv === 'development') {
    console.log(...args);
  }
};

const App = (props) => {
  const [open, setOpen] = useState(false);

  const actions = Actions;

  useEffect(() => {
    props.actions.queue.requestQueue();
    const user = getUser();

    if (user) {
      configureGA();
    } else {
      props.actions.error(401);
    }
  }, []);

  const handleSideBarChange = (open) => {
    setOpen(open);
  };

  if (props.errorInfo.code) return <ErrorPage errorInfo={props.errorInfo} />;

  return (
    <BrowserRouter>
      <Sidebar
        className="sidebar"
        sidebar={
          <SideBarContent
            actions={actions}
            handleSideBarChange={handleSideBarChange}
            user={props.user}
            appInfo={appInfo}
            className="no--select"
          />
        }
        touchHandleWidth={0}
        open={open}
        onSetOpen={handleSideBarChange}
        styles={{
          sidebar: {
            width: '225px',
            zIndex: 3,
            backgroundColor: '#FFFFFF',
          },
          content: {
            overflow: 'hidden',
          },
        }}
        overlayClassName="sidebar--overlay"
        appInfo={appInfo}
      >
        <div className="window">
          <QueueCheck />
          <Appbar handleSideBarChange={handleSideBarChange} appInfo={appInfo} />
          <div className="window--content" id="app_content">
            <Modal2
              open={props.loading.show}
              fadeDelay={props.loading.fadeDelay}
              full
              center
              style={{ zIndex: 999999 }}
            >
              <Loading
                text={props.loading.text}
                doneText={props.loading.doneText}
                style={{ color: '#FFFFFF' }}
                busy={props.loading.show}
                progress={props.loading.progress}
                complete={props.loading.complete}
              />
            </Modal2>
            <Modal2
              open={props.modal.style !== 'snackbar' && props.modal.show}
              title={props.modal.title}
              actions={props.modal.actions}
              modalStyle={props.modal.style}
              preventESC={props.modal.preventESC}
              onDismiss={() => {
                if (props.modal.dismissable) actions.modal.toggle({ show: false });
              }}
              dismissable={props.modal.dismissable}
              full
            >
              {props.modal.body}
            </Modal2>
            <Snackbar
              style={{ bottom: 35 }}
              open={(props.modal.style === 'snackbar' && props.modal.show) || false}
              message={(props.modal.style === 'snackbar' && props.modal.body) || ''}
              onRequestClose={() => props.actions.modal.toggle({ show: false })}
            />
            <Routes />
          </div>
          <Footer actions={actions} />
        </div>
      </Sidebar>
    </BrowserRouter>
  );
};

const { modal, queue, error } = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    modal: { toggle: (opts) => dispatch(modal.toggle(opts)) },
    queue: {
      toggle: () => dispatch(queue.toggle()),
      requestQueue: () => dispatch(queue.requestQueue()),
    },
    error: (code) => dispatch(error(code)),
  },
});

export default connect((state) => state, mapDispatchToProps)(App);
