import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

class CSVInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      csv: props.value,
      error: null,
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    if ('value' in props) {
      this.setState({ csv: props.value });
    }
  }

  processInput = (e) => {
    const input = e.target.value;
    let data = input
      .replace(/[\r|\n]$/, '')
      .replace(/\r/g, '')
      .replace(/\n/g, ',')
      .replace(/ /g, ''); // replace new lines with commas and remove spaces

    // Verify data
    if (input === '') {
      data = input;
    } else {
      data.split(',').forEach((dataPoint) => {
        const condition = this.props.disallowNegative ? /^(\d+)$/.test(dataPoint) : /^(-\d+)|(\d+)$/.test(dataPoint);
        if (!condition) {
          this.setState(
            {
              error: this.props.disallowNegative
                ? 'Only positive numbers can be provided and must be above 0.'
                : 'Only numbers can be provided.',
            },
            () => this.props.onChange(this.state.csv, true)
          );
        } else {
          this.setState({ error: null }, () => this.props.onChange(this.state.csv, false));
        }
      });
    }
    this.setState({ csv: input }, () => this.props.onChange(data, this.state.error));
  };

  render() {
    return (
      <TextField
        placeholder={this.props.placeholder || ''}
        label={this.props.floatingLabelText}
        multiline
        rows={this.props.rows || 2}
        rowsMax={this.props.rows || 4}
        value={this.state.csv || ''}
        disabled={this.props.disabled}
        error={this.state.error || null != null}
        helperText={this.state.error || null}
        onChange={this.processInput}
        onKeyPress={this.props.onKeyPress || null}
        ref={this.props.inputRef}
        style={{
          width: '100%',
          marginBottom: 10,
          ...this.props.style,
        }}
      />
    );
  }
}

CSVInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  floatingLabelText: PropTypes.string,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  inputRef: PropTypes.func,
  disallowNegative: PropTypes.bool,
  onError: PropTypes.func,
};

export default CSVInput;
