import store from '../store';
import Actions from '../actions';
import { getUser } from './authProvider';

export default (permission, redirect) => {
  const user = getUser();
  if (!user) return false;
  let hasPermission = false;
  if (!user) return hasPermission;
  if (typeof permission === 'string') {
    hasPermission = user.permissions.includes(permission);
    if (!hasPermission && redirect) {
      store.dispatch(Actions.error(403));
    }
  } else {
    hasPermission = permission.map((p) => user.permissions.includes(p)).filter((p) => p === true).length > 0;
  }
  return hasPermission;
};
