import React from 'react';
import PropTypes from 'prop-types';
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@material-ui/core';

class RadioButton extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    className: PropTypes.string,
    labelKey: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    radioName: PropTypes.string.isRequired,
    buttonStyle: PropTypes.object,
    groupStyle: PropTypes.object,
    labelstyle: PropTypes.object,
    disabled: PropTypes.array,
  };

  defaultStyle = {
    button: {
      paddingBottom: 10,
      paddingTop: 10,
      paddingLeft: 10,
    },
    group: {},
    label: {},
  };

  render() {
    const { data, labelKey, buttonStyle, className, value, handleChange, radioName, groupStyle, labelstyle } =
      this.props;
    return (
      <FormControl component="fieldset">
        <RadioGroup
          row
          name={radioName}
          onChange={handleChange}
          value={parseInt(value)}
          className={className}
          style={{ ...this.defaultStyle.group, ...groupStyle }}
        >
          {data.map((value, key) => {
            let disabled;
            if (this.props.disabled) {
              disabled = this.props.disabled.includes(key);
            }
            return (
              <FormControlLabel
                control={<Radio />}
                key={key}
                value={key}
                label={value[labelKey]}
                style={{ ...this.defaultStyle.button, ...buttonStyle }}
                labelstyle={{ ...this.defaultStyle.label, ...labelstyle }}
                disabled={disabled}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  }
}

export default RadioButton;
