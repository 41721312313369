import ActionTypes from '../../../../constants/ActionTypes';
import { addCompleted, resetFromStep, syncToServer } from './index';

const {
  targetedComms: {
    smartcounts: { campaign },
  },
} = ActionTypes;

const checkComplete = (dispatch, getState) => {
  const {
    time_periods: { print, redemption, date, cycle },
    completed,
  } = getState().TargetedComms.Smartcounts.campaign;

  if (cycle && print && redemption && (redemption !== 'fixed' || date)) {
    if (!completed.includes(1)) return Promise.resolve(dispatch(addCompleted(1)));
  } else if (completed.includes(1)) return Promise.resolve(dispatch(resetFromStep(1)));
};

export const updateCycle = (cycle) => (dispatch) =>
  Promise.resolve(dispatch({ type: campaign.UPDATE_CYCLE, cycle })).then(() => dispatch([checkComplete, syncToServer]));

export const updatePrintPeriod = (period) => (dispatch) =>
  Promise.resolve(dispatch({ type: campaign.UPDATE_PRINT, period })).then(() =>
    dispatch([checkComplete, syncToServer])
  );

export const updateRedemptionPeriod = (period) => (dispatch) =>
  Promise.resolve(dispatch({ type: campaign.UPDATE_REDEMPTION, period })).then(() =>
    dispatch([checkComplete, syncToServer])
  );

export const updateRedemptionDate = (date) => (dispatch) =>
  Promise.resolve(dispatch({ type: campaign.UPDATE_REDEMPTION_DATE, date })).then(() =>
    dispatch([checkComplete, syncToServer])
  );
