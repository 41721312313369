import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

class TextInput extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    errorText: PropTypes.string,
    onChange: PropTypes.func,
    handlePaste: PropTypes.func,
    floatingLabelText: PropTypes.string,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    className: PropTypes.string,
    inputstyle: PropTypes.object,
    textID: PropTypes.string,
  };

  componentDidMount() {
    if (this.props.handlePaste) {
      document.getElementById(this.props.textID).addEventListener('paste', this.handlePaste);
    }
  }

  handleChange = (event, newValue) => {
    const { onChange, errorText } = this.props;
    if (onChange) {
      if (errorText) {
        if (onChange(event, newValue)) {
          this.setState({ errorText });
        } else {
          this.setState({ errorText: null });
        }
      } else {
        onChange(event, newValue);
      }
    }
  };

  handlePaste = (event) => {
    if (this.props.handlePaste) {
      const pasteData = event.clipboardData.getData('text/plain');
      if (this.props.errorText) {
        if (this.props.handlePaste(pasteData)) {
          this.setState({ errorText: this.props.errorText });
        } else {
          this.setState({ errorText: null });
        }
      } else {
        this.props.handlePaste(pasteData);
      }
    }
  };

  render() {
    const {
      textID,
      className,
      placeholder,
      floatingLabelText,
      multiline,
      rows,
      fullWidth,
      inputstyle,
      onChange,
      errorText,
      ...remaining
    } = this.props;
    return (
      <TextField
        id={textID}
        className={className}
        placeholder={placeholder}
        error={errorText != null}
        helperText={errorText}
        label={floatingLabelText}
        multiline={multiline}
        rows={rows}
        onChange={this.handleChange}
        fullWidth={fullWidth}
        inputstyle={inputstyle}
        {...remaining}
      />
    );
  }
}

export default TextInput;
