import React from 'react';
import { connect } from 'react-redux';
import { MdLock } from 'react-icons/md';

const LockMessage = ({ locked, message }) => {
  return locked ? (
    <div style={{ display: 'inline-block' }}>
      <MdLock /> {message || 'Smartcounts is locked.'}
    </div>
  ) : null;
};
const mapStateToProps = ({
  TargetedComms: {
    Smartcounts: {
      list: { locked },
    },
  },
}) => ({ locked });
export default connect(mapStateToProps)(LockMessage);
