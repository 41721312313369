import { createTheme } from '@material-ui/core/styles';
import { theme, colorNames } from './colors';

const muiTheme = createTheme({
  ...theme,
  stepper: {
    iconColor: '#A2B682',
  },
  Button: {
    primaryColor: '#8B98B6',
    disabledColor: colorNames.lighterGrey,
  },
});

export default muiTheme;
