import React from 'react';
import PropTypes from 'prop-types';
import { MdHighlightOff, MdContentPaste } from 'react-icons/md';
import SearchBrand from './SeachBrand';
import Channels from './Channels';
import DatePicker from '../../../../../lib/DatePicker/DatePicker';
import { formatProper } from '../../../../../../utils/utils';
import Checkbox from '../../../../../lib/Checkbox/Checkbox';
import FileUpload from '../../../../../lib/FileUpload/FileUpload';

class ModalContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      views: {
        brand: this.RenderSearch,
        manufacturer: this.RenderSearch,
        master_campaign_id: this.RenderSearch,
        primary_channel: this.RenderChannels,
        channel: this.RenderChannels,
        category: this.RenderSearch,
        super_category: this.RenderSearch,
        date: this.RenderDate,
      },
    };
  }

  static propTypes = {
    state: PropTypes.object.isRequired,
    page: PropTypes.string.isRequired,
  };

  RenderSearch = () => {
    const { state } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <SearchBrand
          data={state.checked ? state.data : state.filteredData}
          searchFunction={state.func.addValues}
          dataValue={this.props.page}
          searchID={this.props.page}
          searchItems={[this.props.page]}
        />
        <Checkbox label="Show All" handleChange={state.func.handleChecked} checked={state.checked} />
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginTop: 10,
          }}
        >
          <div className="selected--content">
            <div>Selected:</div>
            <div>
              {state.placeholder.map((value, key) => {
                return (
                  <div key={key} className="selected--element">
                    <MdHighlightOff
                      onClick={() => {
                        state.func.removeValue(value);
                      }}
                    />
                    <span>{state.modal.title === 'Master Campaign ID' ? value : formatProper(value)}</span>
                  </div>
                );
              })}
            </div>
          </div>
          {this.props.page === 'master_campaign_id' ? (
            <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
              <div className="modal--paste--input" onClick={() => state.func.handleMasterOpen()}>
                <MdContentPaste height="25" width="25" />
                <span>Paste campaign ID's</span>
              </div>
              <FileUpload
                labelClass="modal--file--upload master--campaign"
                changeEventHandler={state.func.handleCSV}
                fileTypes=".csv"
              />
            </div>
          ) : (
            <FileUpload labelClass="modal--file--upload" changeEventHandler={state.func.handleCSV} fileTypes=".csv" />
          )}
        </div>
      </div>
    );
  };

  RenderChannels = () => {
    const { state } = this.props;
    return (
      <div
        style={{
          paddingTop: 10,
        }}
      >
        <Checkbox label="Show All" handleChange={state.func.handleChecked} checked={state.checked} />
        <Channels data={state.data} filtered={state.checked ? state.data : state.filteredData} state={state} />
      </div>
    );
  };

  RenderDate = () => {
    const { state } = this.props;
    const minDate = state.dateRange.start;
    const maxDate = state.dateRange.end;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <DatePicker
          label="Start Date"
          format="dd/MM/yyyy"
          minDate={minDate}
          maxDate={maxDate}
          value={state.placeholder.start}
          onChange={(date) => state.func.addValues(date, 'start')}
        />
        <DatePicker
          label="End Date"
          format="dd/MM/yyyy"
          minDate={minDate}
          maxDate={maxDate}
          value={state.placeholder.end}
          onChange={(date) => state.func.addValues(date, 'end')}
        />
      </div>
    );
  };

  render() {
    return this.state.views[this.props.page]();
  }
}

export default ModalContent;
