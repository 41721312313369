import config from '../../config';

const { app_env = 'development', skipAD } = config;

const getEnvironment = () => {
  if (app_env === 'development') {
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
  }
  return app_env;
};

const getRunEnvironment = () => {
  if (process.env.RUN_ENV === 'development') {
    return 'development';
  }
  return 'build';
};

export default {
  name: getEnvironment(),
  runEnv: getRunEnvironment(),
  skipAD,
  api: {
    development: 'http://localhost:3030',
    staging: 'https://api.igloo.staging.nectar360.co.uk',
    production: 'https://api.igloo.nectar360.co.uk',
  },
  legacyApi: {
    development: 'http://localhost:3030',
    staging: 'https://legacy-api.igloo.staging.nectar360.co.uk',
    production: 'https://legacy-api.igloo.nectar360.co.uk',
  },
  ga: {
    development: 'G-FY42Z2ZE5F',
    staging: 'G-FY42Z2ZE5F',
    production: 'G-WL4M91N2RD',
  },
};
