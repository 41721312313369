import { createSelectorCreator } from 'reselect';
import { filter, memoize, reduce } from 'lodash';
import getCampaignObject from './getCampaignObject';

const createDeepEqualSelector = createSelectorCreator(memoize, (...args) =>
  args.reduce((acc, val) => `${acc}-${JSON.stringify(val)}`, '')
);

export const getStores = (state) => getCampaignObject(state).count.payload.stores;
const getCatable = (state) => getCampaignObject(state).catable_stores;
export const getCatableStores = createDeepEqualSelector([getStores, getCatable], (stores, cstores) =>
  reduce(
    cstores,
    (array, store) => {
      if ((stores.length > 0 && stores.includes(store.store_no)) || stores.length === 0) {
        array.push(store.store_no);
      }
      return array;
    },
    []
  )
);
