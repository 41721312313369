import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { reduce } from 'lodash';

class ContentTabbed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
    this.styles = StyleSheet.create({
      tabBar: {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        userSelect: 'none',
        cursor: 'default',
        width: '100%',
        ':after': {
          content: "' '",
          display: 'block',
          clear: 'both',
        },
        borderBottom: '2px solid',
        borderBottomColor: '#c3c3c3',
      },
      tab: {
        float: 'left',
        padding: 10,
        borderBottom: '2px solid',
        borderColor: 'transparent',
      },
      tabActive: {
        position: 'relative',
        fontWeight: 'bold',
        ':after': {
          content: "' '",
          display: 'block',
          position: 'absolute',
          bottom: -4,
          left: 0,
          right: 0,
          borderBottom: '2px solid',
          borderBottomColor: this.props.tintColor,
        },
      },
      tabBody: {
        padding: 0,
      },
      tabBadge: {
        fontSize: 12,
        fontWeight: 'bold',
        color: this.props.tintColor,
        marginLeft: 5,
      },
    });
  }

  render() {
    return (
      <div>
        <ul className={css(this.styles.tabBar)}>
          {this.props.tabLabels.map((tab, i) => {
            return (
              <li
                key={i}
                className={css(this.styles.tab, i === this.state.activeTab && this.styles.tabActive)}
                onClick={() => this.setState({ activeTab: i })}
              >
                {tab}
                <span id={`tab_badge_${i}`} className={css(this.styles.tabBadge)}>
                  {(this.props.tabBadge && this.props.tabBadge[i]) || null}
                </span>
              </li>
            );
          })}
        </ul>
        {React.Children.toArray(this.props.children).map((child, i) => {
          return (
            <div
              key={i}
              className={css(this.styles.tabBody)}
              style={{ display: this.state.activeTab === i ? 'block' : 'none' }}
            >
              {child}
            </div>
          );
        })}
      </div>
    );
  }
}

ContentTabbed.propTypes = {
  tabLabels: PropTypes.array,
  tabBadge: PropTypes.array,
  tintColor: PropTypes.string,
};

export default ContentTabbed;
