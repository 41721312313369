import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Paper, TextField } from '@material-ui/core';
import { map, forEach, filter } from 'lodash';
import { MdLock } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Actions from '../../../../../actions';
import { getExecutionList } from './selector';
import jss from '../../../../../utils/jss';

import { colorNames } from '../../../../../constants/colors';
import {
  getExecutionListByBaseId,
  getSelectedCycle,
  getStart,
  getEnd,
} from '../../../../../reducers/TargetedComms/Smartcounts/list';
import { postJSON } from '../../../../../utils/async';
import SmartcountsShared from '../Constants/SmartcountsShared';

import CampaignName from './lib/CampaignName';
import ExecutionTable from './lib/ExecutionTable';
import { modules } from '../../../../../constants/moduleInfo';

const styles = {
  splitWrap: {
    display: 'flex',
    textAlign: 'center',
  },
  splitActions: {
    flex: 2,
  },
  splitCol: {
    flex: 1,
  },
  subtle: {
    fontSize: '10pt',
    color: colorNames.grey,
  },
  row: {
    position: 'relative',
    marginTop: 10,
    overflow: 'hidden',
    '&:before': {
      display: 'block',
      position: 'absolute',
      content: "' '",
      top: 0,
      left: -5,
      bottom: 0,
      width: 0,
      backgroundColor: 'transparent',
      transition: 'all 450ms ease-in-out',
    },
    '&.approved': {
      '&:before': {
        transform: 'translateX(5px)',
        backgroundColor: `${colorNames.green} !important`,
        width: '5px !important',
      },
    },
    '&.updated': {
      '&:before': {
        transform: 'translateX(5px)',
        backgroundColor: `${colorNames.blue} !important`,
        width: '5px !important',
      },
    },
    '&.rejected': {
      '&:before': {
        transform: 'translateX(5px)',
        backgroundColor: `${colorNames.red} !important`,
        width: '5px !important',
      },
    },
  },
  actions: {
    padding: 10,
    backgroundColor: colorNames.lighterGrey,
  },
  noneMessage: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 50,
    userSelect: 'none',
    cursor: 'default',
  },
  lockedMessage: {
    marginLeft: 10,
  },
  conflict: {
    padding: 10,
    paddingBottom: 15,
    color: colorNames.red,
  },
};

class ExecutionList extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'SCExecutionList',
      classNamePrefix: 'SCExecutionList-',
    });
    this.state = { actions: {}, errors: {}, updates: {}, statuses: {}, rejections: {}, warn: false };
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  handleConfirm = function (id, status) {
    const { statuses } = this.state;
    const { rejections } = this.state;
    if (statuses[id] == status) {
      delete statuses[id];
    } else {
      statuses[id] = status;
    }
    this.setState({ statuses });
  };

  handleUpdate = (id, data) => {
    if (!this.state.warning) {
      // TODO: Reintroduce warning before navigation
      // this.props.actions.page.warn(true)
    }
    const cell = this.props.listByBaseIds[id].campaign.execution.filter(
      (o) => o.cell_description === Object.keys(data)[0]
    )[0];
    if (data[Object.keys(data)[0]] > cell.available_volumes) {
      this.setState((prevState) => ({
        errors: {
          [id]: { ...prevState.errors[id], [Object.keys(data)[0]]: 'Volume cannot exceed available' },
        },
      }));
    } else {
      if (Object.keys(data)[0] === 'N_REP_New (ABT - A)') {
        data[Object.keys(data)[0].replace('ABT - A', 'ABT - B')] = data[Object.keys(data)[0]];
      }
      const updates = {};
      updates[id] = data;

      this.setState((prevState) => {
        if (prevState.errors[id]) {
          delete prevState.errors[id][Object.keys(data)[0]];
          if (Object.keys(prevState.errors[id]).length === 0) {
            delete prevState.errors[id];
          }
        }
        return { ...prevState };
      });
    }
    const newUpdates = this.state.updates[id] || {};
    Object.keys(data).forEach(function (key) {
      newUpdates[key] = data[key];
    });

    const allUpdates = this.state.updates;
    allUpdates[id] = newUpdates;

    this.setState({ updates: allUpdates, warning: true });
  };

  handleAddComment = (id, comment) => {
    this.setState((prevState) => {
      const { rejections } = prevState;
      rejections[id] = comment;

      return {
        rejections,
      };
    });
  };

  consolidate = () => {
    const actions = {};
    const data = this.state.statuses;
    Object.keys(data).forEach(function (key) {
      if (data[key] == 'approved') {
        if (this.state.updates.hasOwnProperty(key)) {
          actions[key] = { status: 'updated', updates: this.state.updates[key] };
        } else {
          actions[key] = { status: 'approved' };
        }
      } else if (data[key] == 'rejected') {
        actions[key] = { status: 'rejected', comment: this.state.rejections[key] };
      }
      this.setState({ actions }, () => {
        this.handleContinue();
      });
    }, this);
  };

  handleContinue = () => {
    const approvedOrUpdated = filter(this.state.actions, (o) => o.status !== 'rejected');
    if (approvedOrUpdated.length > 0) {
      this.props.actions.modal.toggle((modal) => ({
        show: true,
        title: 'Confirm Commit',
        style: 'warning',
        body: (
          <div>
            The following campaigns will be executed;
            <ul>
              {Object.keys(this.state.actions).map((k) => {
                if (this.state.actions[k].status !== 'rejected') {
                  return <li key={k}>{this.props.listByBaseIds[k].fullName}</li>;
                }
              })}
            </ul>
            You will not be able to stop this campaign once it is executed.
          </div>
        ),
        actions: [
          {
            text: 'Cancel',
            action: modal.close,
          },
          {
            text: 'Execute',
            color: colorNames.green,
            position: 'right',
            action: () => {
              this.sendToModel();
              modal.close();
            },
          },
        ],
      }));
    } else {
      this.sendToModel();
    }
  };

  sendToModel = () => {
    // TODO: Reintroduce warning before navigation
    // this.props.actions.page.warn(false)
    postJSON('/api/targeted-comms/smartcounts/execution/model', { campaigns: this.state.actions }, (err, res) => {
      if (err === 423) {
        this.props.actions.modal.toggle((modal) => ({
          show: true,
          style: 'warning',
          title: 'Smartcounts Locked',
          body: 'Unable to continue as Smartcounts is busy. Try again later.',
          actions: [
            {
              text: 'Ok',
              action: modal.close,
            },
          ],
        }));
      }
      setTimeout(() => {
        this.props.actions.smartcounts.toggleLock();
      }, 1000);
    });
  };

  handleOpen = (id) => {
    if (id) {
      this.props.actions.loading.toggle({ show: true, text: 'Loading...' });
      this.props.navigate(`${modules.smartCounts.path}/campaign/${id}`);
    } else {
      console.error(`Smartcounts: Invalid count`);
    }
  };

  render() {
    const { execute: canExecute } = this.props.SCAccess;
    const { classes } = this.stylesheet;

    return (
      <div>
        {this.props.list.length > 0 ? (
          <div>
            <div style={{ marginTop: 20 }}>
              {this.props.list.map((row) => {
                const status = this.state.statuses[row.base.id] || null;
                const updates = this.state.updates[row.base.id];

                return (
                  <Paper
                    style={{
                      marginBottom: 20,
                    }}
                    key={row.id}
                  >
                    <div className={jss.combine(classes.row, status)}>
                      <CampaignName row={row} />
                      {row.campaign.stage === 4 && (
                        <ExecutionTable
                          data={row}
                          handleExecutionStatus={this.handleUpdate}
                          updates={updates}
                          errors={this.state.errors}
                          status={status}
                          locked={this.props.locked}
                        />
                      )}
                      {row.campaign.stage === 7 && (
                        <div className={classes.conflict}>
                          Base ID already executed. Reject the campaign before you can fix this.
                        </div>
                      )}
                    </div>
                    {(row.campaign.stage === 4 || row.campaign.stage === 7) && (
                      <div className={classes.actions}>
                        <div className={classes.splitWrap}>
                          <div className={classes.splitActions} style={{ textAlign: 'left' }}>
                            <Button
                              style={{
                                marginRight: 5,
                                backgroundColor: status == 'approved' ? colorNames.green : null,
                              }}
                              onClick={() => this.handleConfirm(row.base.id, 'approved')}
                              labelstyle={{
                                ...(status === 'approved' && {
                                  color: colorNames.white,
                                }),
                              }}
                              disabled={this.props.locked || row.campaign.stage === 7}
                            >
                              Commit Campaign
                            </Button>

                            <Button
                              style={{ backgroundColor: status === 'rejected' ? colorNames.red : null }}
                              onClick={() => this.handleConfirm(row.base.id, 'rejected')}
                              disabled={this.props.locked}
                              labelstyle={{
                                ...(status === 'rejected' && {
                                  color: colorNames.white,
                                }),
                              }}
                            >
                              Reject Campaign
                            </Button>
                            {status === 'rejected' && (
                              <TextField
                                placeholder="Rejection Comments"
                                value={this.state.rejections[row.base.id] || ''}
                                onChange={(e) => this.handleAddComment(row.base.id, e.target.value)}
                                style={{ marginLeft: 10 }}
                              />
                            )}
                          </div>
                          <div className={jss.combine(classes.splitCol)} style={{ textAlign: 'right' }}>
                            <Button onClick={() => this.handleOpen(row.count.id)}>Open Campaign</Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Paper>
                );
              })}
              {canExecute && (
                <Button
                  variant="contained"
                  onClick={this.consolidate}
                  disabled={
                    this.props.locked ||
                    Object.keys(this.state.errors).length > 0 ||
                    Object.keys(this.state.statuses).length === 0
                  }
                >
                  Continue
                </Button>
              )}
              {this.props.locked && (
                <span className={classes.lockedMessage}>
                  <MdLock /> Smartcounts is locked.
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className={classes.noneMessage}>Nothing marked for execution</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  list: getExecutionList(state),
  listByBaseIds: getExecutionListByBaseId(state),
  selectedCycle: getSelectedCycle(state),
  locked: state.TargetedComms.Smartcounts.list.locked,
});

const {
  loading,
  modal,
  targetedComms: {
    smartcounts: { list },
  },
} = Actions;
const mapDispatchToProps = (dispatch) => ({
  actions: {
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
    },
    loading: {
      toggle: (opts) => dispatch(loading.toggle(opts)),
    },
    smartcounts: {
      toggleLock: () => dispatch(list.toggleLock()),
    },
  },
});

const ExecutionListWrapper = ({ ...props }) => {
  const navigate = useNavigate();
  const Component = SmartcountsShared(ExecutionList);
  return <Component {...props} navigate={navigate} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(ExecutionListWrapper);
