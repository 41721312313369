import ActionTypes from '../../../constants/ActionTypes/index';

const {
  targetedComms: {
    smartcounts: { campaign },
  },
} = ActionTypes;

export const defaultState = {
  completed: [0],
  id: null,
  campaign_id: null,
  base_id: null,
  name: null,
  full_name: null,
  stage: 0,
  rejected: false,
  rejectionComment: null,
  time_periods: {
    print: null,
    redemption: null,
    date: null,
    cycle: null,
  },
  clashes: [],
  coupons: {
    artwork: {
      l: 46,
      t: 35,
      s: 20,
    },
    text: {
      line1: '',
      line2: '',
      extended: '',
    },
    template: 1,
  },
  rsp: {
    original: 0,
    used: 0,
  },
  cells: {
    visible: ['new', 'lapsed', 'solus', 'repertoire', 'brand', 'acquisition'],
    required: ['new', 'lapsed', 'solus', 'repertoire', 'brand', 'acquisition'],
    continuity: [],
    ab: [],
    transactional: false,
    groupedExisting: false,
    pcr: 'none',
    prints: {},
    redemptionRates: {},
    offers: {},
    templates: {},
    barcodes: {},
  },
  supplier: {
    contact: null,
    email: null,
  },
  count: {
    id: null,
    payload: {},
    results: [],
  },
  sku_details: {},
  skus_missing: [],
  cycles: [],
  redemption_rates: {},
  existingBaseIds: [],
  provisionalBase: '', // base id typed in by user, not sent to backend until checks passed and 'send for approval' clicked
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case campaign.ADD_COMPLETED: {
      const { completed } = state;
      if (!completed.includes(action.index)) {
        completed.push(action.index);
      }

      return { ...state, completed };
    }

    case campaign.REMOVE_COMPLETED: {
      let { completed } = state;
      completed = completed.filter((o) => o !== action.index);
      return { ...state, completed };
    }

    case campaign.RESET: {
      if (!action.index) {
        return { ...defaultState };
      }
      return { ...state, completed: state.completed.filter((c) => c < action.index) };
    }

    case campaign.START_CAMPAIGN: {
      // add default offer_value:
      const defaultOffers = action.data.cells.hasOwnProperty('offers') ? action.data.cells.offers : {};
      if (Object.keys(action.data.cells).length == 0) {
        state.cells.visible.forEach((c) => {
          defaultOffers[c] = { value: Math.ceil(action.data.rsp.original * 200 * 0.2) };
        });
      }

      return {
        ...state,
        ...action.data,
        cells: {
          ...state.cells,
          ...action.data.cells,
          offers: defaultOffers,
        },
      };
    }

    case campaign.UPDATE_CYCLE:
      return { ...state, time_periods: { ...state.time_periods, cycle: action.cycle } };

    case campaign.UPDATE_PRINT:
      return { ...state, time_periods: { ...state.time_periods, print: action.period } };

    case campaign.UPDATE_REDEMPTION:
      return { ...state, time_periods: { ...state.time_periods, redemption: action.period } };

    case campaign.UPDATE_REDEMPTION_DATE:
      return { ...state, time_periods: { ...state.time_periods, date: action.date } };

    case campaign.UPDATE_GROUPED_EXISTING:
      return { ...state, cells: { ...state.cells, groupedExisting: action.existing } };

    case campaign.UPDATE_TRANSACTIONAL_TOGGLE:
      return { ...state, cells: { ...state.cells, transactional: action.transactional } };

    case campaign.UPDATE_RSP:
      return { ...state, rsp: { ...state.rsp, used: action.rsp } };

    case campaign.UPDATE_PCR:
      return { ...state, cells: { ...state.cells, pcr: action.pcr } };

    case campaign.UPDATE_VISIBLE:
      return { ...state, cells: { ...state.cells, visible: action.visible } };

    case campaign.UPDATE_REQUIRED:
      return { ...state, cells: { ...state.cells, required: action.required } };

    case campaign.UPDATE_CONTINUITY:
      return {
        ...state,
        cells: { ...state.cells, continuity: action.continuity },
      };

    case campaign.UPDATE_AB:
      return {
        ...state,
        cells: { ...state.cells, visible: action.ab },
      };

    case campaign.UPDATE_CELL_PRINTS:
      return {
        ...state,
        cells: {
          ...state.cells,
          prints: {
            ...state.cells.prints,
            [action.cell]: {
              method: action.method,
              value: action.value,
            },
          },
        },
      };

    case campaign.UPDATE_TEMPLATE:
      return {
        ...state,
        cells: {
          ...state.cells,
          templates: { ...state.cells.templates, ...action.template },
        },
      };

    case campaign.UPDATE_CELL_REDEMPTION:
      return {
        ...state,
        cells: {
          ...state.cells,
          redemptionRates: {
            ...state.cells.redemptionRates,
            ...action.redemption,
          },
        },
      };

    case campaign.UPDATE_OFFER_TYPE:
      return {
        ...state,
        cells: {
          ...state.cells,
          offers: {
            ...state.cells.offers,
            [action.cell]: {
              type: action.offer_type,
              value: (state.cells.offers[action.cell] || {}).value,
            },
          },
        },
      };

    case campaign.UPDATE_OFFER_VALUE:
      return {
        ...state,
        cells: {
          ...state.cells,
          offers: {
            ...state.cells.offers,
            [action.cell]: { value: action.value, type: (state.cells.offers[action.cell] || {}).type },
          },
        },
      };

    case campaign.ALL_TEMPLATES_TO_STANDARD: {
      return {
        ...state,
        cells: {
          ...state.cells,
          templates: {},
        },
      };
    }

    case campaign.UPDATE_COUPON_TEXT: {
      return {
        ...state,
        coupons: {
          ...state.coupons,
          text: {
            ...state.coupons.text,
            ...action.text,
          },
        },
      };
    }

    case campaign.UPDATE_ARTWORK: {
      return {
        ...state,
        coupons: {
          ...state.coupons,
          ...((action.path || action.path === null) && { artwork_path: action.path }),
          ...(action.position && { artwork: { ...state.coupons.artwork, ...action.position } }),
        },
      };
    }

    case campaign.UPDATE_STAGE: {
      return { ...state, stage: action.stage };
    }

    case campaign.UPDATE_SUPPLIER: {
      return { ...state, supplier: { ...state.supplier, ...action.update } };
    }

    case campaign.UPDATE_NAME: {
      return { ...state, name: action.name };
    }

    case campaign.UPDATE_BASE_ID: {
      return { ...state, base_id: action.id };
    }

    case campaign.UPDATE_BARCODE: {
      return { ...state, cells: { ...state.cells, barcodes: { ...state.cells.barcodes, ...action.update } } };
    }

    case campaign.UPDATE_REJECTION_COMMENT: {
      return {
        ...state,
        rejectionComment: action.comment,
      };
    }

    case campaign.UPDATE_CLASHES: {
      return {
        ...state,
        clashes: action.clashes,
      };
    }

    case campaign.PROVISIONAL_BASE: {
      return {
        ...state,
        provisionalBase: action.base,
      };
    }

    default:
      return state;
  }
};
