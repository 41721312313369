import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Actions from '../../../actions';
import { moduleGroups } from '../../../constants/moduleInfo';
import Icon from '../../lib/Icon';
import './style.css';
import jss from '../../../utils/jss';
import logo from '../../../../images/igloo-logo.svg';
import Modal2 from '../../lib/Modal/Modal2';
import { Tiles, IconTile } from '../../lib/Tile';
import { Content } from '../../lib/Content';

const styles = {
  homepageWrapper: {
    position: 'relative',
    height: 'calc(100% - 50px)',
    textAlign: 'center',
  },
  graphic: {
    maxWidth: 300,
    marginTop: 40,
    marginBottom: 80,
    textAlign: 'center',
  },
};

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showGroup: false,
      groupInfo: { name: null, modules: [] },
    };
    this.stylesheet = jss.createStyleSheet(styles);
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  _parseModules = (m, i) => {
    const { name, path, icon, color } = m;
    if (m.hasAccess()) {
      return (
        <Link to={path} key={i} style={{ textDecoration: 'none' }}>
          <IconTile color={color} text={name} icon={<Icon icon={icon} color="#fff" size={28} />} />
        </Link>
      );
    }
  };

  getIcons = () => {
    const modules = [];
    moduleGroups.map((g, i) => {
      if (g.type === 'module') {
        modules.push(this._parseModules(g.module, i));
      } else if (g.type === 'group') {
        const icons = [];
        g.modules.map((m) => {
          if (m.hasAccess()) {
            icons.push(m.icon);
          }
        });
        if (icons.length > 0) {
          modules.push(
            <IconTile
              key={i}
              style={{ backgroundColor: g.color }}
              onClick={() => this.setState({ showGroup: true, groupInfo: g })}
              text={g.name}
              icon={<Icon icon={g.icon} color="#fff" size={28} />}
            />
          );
        }
      }
    });
    return modules;
  };

  render() {
    return (
      <div style={{ userSelect: 'none' }}>
        <Modal2
          open={this.state.showGroup}
          onDismiss={() => this.setState({ showGroup: false })}
          dismissable
          title={this.state.groupInfo.name}
        >
          <Tiles>
            {this.state.groupInfo.modules.map((m, i) => {
              return this._parseModules(m, i);
            })}
          </Tiles>
        </Modal2>
        <Content
          style={{
            position: 'relative',
            height: 'calc(100% - 50px)',
            textAlign: 'center',
            paddingTop: 50,
          }}
        >
          <img src={logo} className={this.stylesheet.classes.graphic} alt="Nectar 360 - Igloo" draggable={false} />
          <Tiles>{this.getIcons()}</Tiles>
        </Content>
      </div>
    );
  }
}

Homepage.propTypes = {};

const { modal } = Actions;
const mapDispatchToProps = (dispatch) => ({
  actions: {
    modal: {
      toggle: (c) => dispatch(modal.toggle(c)),
    },
  },
});

export default connect(null, mapDispatchToProps)(Homepage);
