import React from 'react';
import { Route } from 'react-router';
import { modules } from '../../../constants/moduleInfo';
import SkuChecker from '../../views/Lookups/SkuChecker/SkuChecker';
import StoreChecker from '../../views/Lookups/StoreChecker/StoreChecker';
import Magazines from '../../views/Lookups/MagazineDisplay';
import UnitsSold from '../../views/Lookups/UnitsSold';
import { HasAccess } from './has-access';

const { skuLookup, storeLookup, magazineLookup, unitsSold } = modules;

export const Lookups = (
  <>
    <Route
      path={skuLookup.path}
      element={
        <HasAccess accessFn={skuLookup.hasAccess}>
          <SkuChecker />
        </HasAccess>
      }
    />
    <Route
      path={storeLookup.path}
      element={
        <HasAccess accessFn={storeLookup.hasAccess}>
          <StoreChecker />
        </HasAccess>
      }
    />
    <Route
      path={magazineLookup.path}
      element={
        <HasAccess accessFn={magazineLookup.hasAccess}>
          <Magazines />
        </HasAccess>
      }
    />
    <Route
      path={unitsSold.path}
      element={
        <HasAccess accessFn={unitsSold.hasAccess}>
          <UnitsSold />
        </HasAccess>
      }
    />
  </>
);
