import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

class Badge extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { color, ...props } = this.props;
    const styles = StyleSheet.create({
      badge: {
        display: 'inline-block',
        fontWeight: '100',
        fontSize: '9pt',
        border: '1px solid black',
        borderRadius: 3,
        padding: '2px 5px',
        marginRight: 5,
        borderColor: color,
        color,
        userSelect: 'none',
        cursor: 'default',
      },
    });
    return (
      <div className={css(styles.badge)} {...props}>
        {this.props.children}
      </div>
    );
  }
}

export default Badge;
