import ActionTypes from '../constants/ActionTypes/index';

export const toggle = (args) => {
  return (dispatch, getState) => {
    let timer;
    let opts;

    if (typeof args === 'object') {
      opts = args;
    } else {
      opts = args({
        close: () => dispatch(toggle({ show: false })),
        update: (o) => dispatch(toggle(o)),
        failed: () => {
          dispatch(toggle({ show: false }));
          dispatch({
            type: ActionTypes.general.TOGGLE_MODAL,
            show: true,
            style: 'error',
            title: 'Timeout',
            body: 'The request timed out',
            actions: [
              {
                text: 'Ok',
                action: () => dispatch({ type: ActionTypes.general.TOGGLE_MODAL, show: false }),
              },
            ],
          });
        },
      });
    }

    if (opts.complete) {
      if (typeof opts.onDone === 'function') opts.onDone();

      setTimeout(() => {
        if (typeof opts.onDismiss === 'function') {
          opts.onDismiss();
        }
        dispatch(toggle({ show: false, complete: false }));
      }, opts.dismissDelay || 3000);
    }

    if (typeof opts.timeout === 'object') {
      opts.timeout.map((timeout, i) => {
        setTimeout(timeout.action, timeout.time);
      });
    } else {
      if (timer) clearTimeout(timer);
      timer = setTimeout(opts.onTimeout, opts.timeout + 500);
    }

    dispatch({
      ...getState().loading,
      type: ActionTypes.general.TOGGLE_LOADING,
      options: opts,
    });
  };
};

const update = (opts) => {
  return (dispatch, getState) => {
    dispatch({
      ...getState().loading,
      type: ActionTypes.general.TOGGLE_LOADING,
      options: opts,
    });
  };
};
