import React from 'react';
import { connect } from 'react-redux';
import {
  MenuItem,
  Paper,
  TextField,
  Select,
  FormControl,
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  InputLabel,
  Switch,
} from '@material-ui/core';

import { map, isEqual } from 'lodash';
import { MdArrowForward, MdRepeat } from 'react-icons/md';
import jss from '../../../../../utils/jss';
import { colorNames } from '../../../../../constants/colors';
import Actions from '../../../../../actions';
import Card from '../../../../lib/Card';
import getCells, { getRedemptionRates, getRequired } from './selectors/getCells';
import getCosts from './selectors/getCosts';
import { getCycleDetails, getTimePeriods } from './selectors/getTimePeriods';
import StagedTextField from '../../../../lib/TextInput/StagedTextField';
import getErrors from './selectors/getErrors';
import getWarnings from './selectors/getWarnings';

import ab_icon from '../../../../../../images/ab-icon.png';
import Info from '../../../../lib/Info';
import { getCatableStores } from './selectors/getStores';
import CB_Costs from './CB_Costs';
import getCampaignObject from './selectors/getCampaignObject';

export const styles = {
  flexWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  flexCell: {
    flex: '1 auto',
    margin: '0 5',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  staticText: {
    display: 'block',
    position: 'relative',
    paddingTop: 30,
  },
  floatingText: {
    position: 'absolute',
    fontSize: '9pt',
    top: 10,
    color: 'rgba(96, 125, 139, 0.5)',
  },
  fixedText: {
    fontSize: '7pt',
    color: 'rgba(96, 125, 139, 0.5)',
  },

  bigName: {
    fontSize: '14pt',
    fontWeight: 'bold',
  },
  subtle: {
    fontSize: 10,
    color: colorNames.grey,
  },
};

class CB_Targeting extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'campaignCreator3',
      classNamePrefix: 'CampaignCreator-Step3-',
    });
    this.stylesheet.attach();

    this.state = {
      volumeWarning: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.cc, nextProps.cc);
  }

  componentWillReceiveProps(nextProps) {
    const {
      activeStep,
      cc: {
        completed,
        errors: { total },
      },
    } = nextProps;

    if (activeStep === 2 && total > 0 && completed.includes(2)) {
      this.props.actions.campaign.removeCompleted(2);
    }

    if (activeStep === 2 && total === 0 && !completed.includes(2)) {
      this.props.actions.campaign.addCompleted(2);
    }
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  handleGroupedSwitch = (e, checked) => {
    this.props.actions.campaign.toggleGroupedExisting(checked);
  };

  handleTransactionalSwitch = (e, checked) => {
    this.props.actions.campaign.toggleTransactional(checked);
  };

  handleRSP = (e) => {
    const v = e.target.value;
    this.props.actions.campaign.updateRSP(parseFloat(v).formatCurrency());
  };

  handlePCR = (e, i) => {
    const v = e.target.value;
    this.props.actions.campaign.updatePCR(v);
  };

  handleSetVolume = (c, v, t) => {
    // allow comma format for v, but convert it to non-comma
    if (v && v.indexOf(',') == 1) {
      if (v.split(',').length == 2) {
        v = parseInt(v.split(',').join(''));
      } else v = 0; // but if more than 1 comma, treat it as if NaN
    } else {
      v = isNaN(parseInt(v)) ? 0 : parseInt(v);
    }
    this.props.actions.campaign.updatePrintVolume(c, v, t);
  };

  handleUpdateCell = (code, field, value) => {
    switch (field) {
      case 'required':
        this.props.actions.campaign.updateRequired(code, value);
        break;
      case 'continuity':
        this.props.actions.campaign.updateContinuity(code, value);
        break;
      case 'ab':
        this.props.actions.campaign.updateAB(code, value);
        break;
      case 'prints':
        this.props.actions.campaign.updatePrintVolume(code, value.value, value.method);
        break;
      case 'template':
        this.props.actions.campaign.updateTemplate(code, value);
        break;
      case 'redemption':
        this.props.actions.campaign.updateCellRedemption(code, value);
        break;
      case 'offer_type':
        this.props.actions.campaign.updateOfferType(code, value);
        break;
      case 'offer_value':
        this.props.actions.campaign.updateOfferValue(code, value);
        break;
    }
  };

  render() {
    const { classes } = this.stylesheet;
    const disableScreen = this.props.cc.stage > 0;
    const { errors, warnings, catable_stores } = this.props.cc;
    return (
      <div style={{ userSelect: 'none', cursor: 'default' }}>
        <div className={classes.flexWrap} style={{ alignItems: 'flex-start', marginBottom: 20 }}>
          <div className={classes.flexCell}>
            <Card style={{ fontSize: '10pt' }} header="Campaign Settings">
              <div className={classes.flexWrap}>
                <div className={classes.flexCell}>
                  <div className={classes.flexWrap}>
                    <div className={classes.flexCell} style={{ width: '75%', padding: '5px 0' }}>
                      Grouped Existing
                    </div>
                    <div className={classes.flexCell} style={{ textAlign: 'center' }}>
                      <Switch
                        onChange={this.handleGroupedSwitch}
                        style={{ display: 'inline-table', width: 'auto' }}
                        checked={this.props.cc.groupedExisting}
                        disabled={disableScreen}
                      />
                    </div>
                  </div>
                  <div className={classes.flexWrap}>
                    <div className={classes.flexCell} style={{ width: '75%', padding: '5px 0' }}>
                      Transactional Cell
                    </div>
                    <div className={classes.flexCell} style={{ textAlign: 'center' }}>
                      <Switch
                        onChange={this.handleTransactionalSwitch}
                        style={{ display: 'inline-table', width: 'auto' }}
                        checked={this.props.cc.transactional}
                        disabled={disableScreen}
                      />
                    </div>
                  </div>
                </div>
                <div className={classes.flexCell} style={{ paddingLeft: 50 }}>
                  {disableScreen ? (
                    <span className={classes.staticText}>
                      <span className={classes.floatingText}>Retail Selling Price</span>
                      {this.props.cc.rsp.used}
                    </span>
                  ) : (
                    <StagedTextField
                      name="rsp"
                      label="Retail Selling Price (£)"
                      value={this.props.cc.rsp.used}
                      onChange={this.handleRSP}
                      onCommit={(e) => {
                        const v = e.target.value;
                        return v === '' || isNaN(v) ? this.props.cc.rsp.original : v;
                      }}
                      disabled={disableScreen}
                    />
                  )}
                  <br />
                  {disableScreen ? (
                    <span className={classes.staticText}>
                      <span className={classes.floatingText}>Post Campaign Report</span>
                      {this.props.cc.pcr.toCapitalized()}
                    </span>
                  ) : (
                    <FormControl style={{ minWidth: 120 }}>
                      <InputLabel>Post Campaign Report</InputLabel>
                      <Select
                        labelId="regexThing"
                        value={this.props.cc.pcr}
                        onChange={this.handlePCR}
                        disabled={disableScreen}
                      >
                        <MenuItem value="none">None</MenuItem>
                        <MenuItem value="standard">Standard</MenuItem>
                        <MenuItem value="extended">Extended</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </div>
              </div>
            </Card>
          </div>
          <div className={classes.flexCell}>
            <CB_Costs />
          </div>
        </div>
        {map(this.props.cc.groupedCells, (cells, group) => {
          if (Object.keys(cells).length > 0) {
            return (
              <Paper key={group} style={{ marginBottom: 10 }}>
                <Table>
                  <TableBody>
                    {map(cells, (cell, id) => {
                      return [
                        <TableRow key={`${id}_1`}>
                          {!disableScreen && (
                            <TableCell rowSpan={2} style={{ width: 50 }}>
                              <Switch
                                checked={cell.required}
                                disabled={disableScreen || cell.forceRequired || cell.continuity}
                                onChange={() => this.handleUpdateCell(id, 'required', !cell.required)}
                              />
                            </TableCell>
                          )}
                          <TableCell rowSpan={2} style={{ ...(!cell.required && { opacity: 0.5 }) }}>
                            {disableScreen ? <span className={classes.bigName}>{cell.display}</span> : cell.display}
                            <br />
                            {id.indexOf('cont') === 0 && <MdRepeat size={16} />}
                            {/_a$|_b$/g.test(id) && <img src={ab_icon} style={{ width: 15 }} draggable={false} />}
                          </TableCell>
                          <TableCell
                            style={{
                              ...(!cell.required && { opacity: 0.5 }),
                              paddingLeft: 0,
                              ...(disableScreen & { paddingBottom: 10 }),
                            }}
                          >
                            {cell.volumes && (
                              <div className={classes.flexWrap} style={{ paddingTop: 30 }}>
                                <div className={classes.flexCell} style={{ width: '30%', paddingRight: 3 }}>
                                  <div>All Available</div>
                                  {cell.volumes.overlay > 0 && <div>Overlay</div>}
                                </div>
                                <div className={classes.flexCell} style={{ width: '60%' }}>
                                  <div
                                    style={{
                                      color:
                                        cell.printVolumeType === 'all' && !cell.forcePrintVolume ? colorNames.blue : '',
                                    }}
                                  >
                                    {cell.volumes.standard.withComma()}
                                  </div>
                                  {cell.volumes.overlay > 0 && (
                                    <div
                                      style={{
                                        color:
                                          cell.printVolumeType === 'overlay' && !cell.forcePrintVolume
                                            ? colorNames.blue
                                            : '',
                                      }}
                                    >
                                      {cell.volumes.overlay.withComma()}
                                    </div>
                                  )}
                                </div>

                                <div className={classes.flexCell} style={{ width: '10%', textAlign: 'right' }}>
                                  <div style={{ marginBottom: 5 }}>
                                    <MdArrowForward
                                      onClick={() =>
                                        !disableScreen &&
                                        !cell.forcePrintVolume &&
                                        cell.required &&
                                        this.handleUpdateCell(id, 'prints', {
                                          value: cell.volumes.standard,
                                          method: 'all',
                                        })
                                      }
                                      style={{
                                        color: cell.printVolumeType === 'all' ? colorNames.blue : '',
                                        ...(disableScreen || cell.forcePrintVolume
                                          ? { opacity: 0 }
                                          : { cursor: 'pointer' }),
                                      }}
                                    />
                                  </div>
                                  {cell.volumes.overlay > 0 && (
                                    <div>
                                      <MdArrowForward
                                        onClick={() =>
                                          !disableScreen &&
                                          !cell.forcePrintVolume &&
                                          cell.required &&
                                          this.handleUpdateCell(id, 'prints', {
                                            value: cell.volumes.overlay,
                                            method: 'overlay',
                                          })
                                        }
                                        style={{
                                          color: cell.printVolumeType === 'overlay' ? colorNames.blue : '',
                                          ...(disableScreen || cell.forcePrintVolume
                                            ? { opacity: 0 }
                                            : { cursor: 'pointer' }),
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            {id === 'transactional' && (
                              <TextField
                                name={`print_${id}`}
                                value={cell.printVolume}
                                label="Prints Per Store"
                                style={{
                                  marginBottom: errors.prints.transactional ? 15 : 0,
                                  fontSize: 'inherit',
                                  width: '100%',
                                }}
                                onChange={(e) =>
                                  this.handleUpdateCell('transactional', 'prints', {
                                    value: isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value),
                                    method: 'manual',
                                  })
                                }
                                error={errors.prints.transactional != null}
                                helperText={errors.prints.transactional}
                                disabled={disableScreen}
                              />
                            )}
                          </TableCell>
                          {/** Print Volume * */}
                          <TableCell
                            style={{
                              ...(!cell.required && { opacity: 0.5 }),
                              ...(disableScreen & { paddingBottom: 10 }),
                            }}
                          >
                            {'printVolume' in cell &&
                              cell.volumes &&
                              ((!disableScreen && !cell.forcePrintVolume && (
                                <StagedTextField
                                  name={`print_${id}`}
                                  value={cell.printVolume}
                                  label="Print Volume"
                                  style={{
                                    marginBottom: errors.prints[id] ? 15 : 0,
                                    fontSize: 'inherit',
                                  }}
                                  onChange={(e) => this.handleSetVolume(id, e.target.value, 'manual')}
                                  error={errors.prints[id] != null}
                                  helperText={errors.prints[id]}
                                  disabled={disableScreen || !cell.required}
                                />
                              )) ||
                                ('printVolume' in cell && (
                                  <span className={classes.staticText}>
                                    <span className={classes.floatingText}>Print Volume</span>
                                    {cell.printVolume.withComma()}
                                  </span>
                                )))}
                            {id === 'transactional' && (
                              <div>
                                <div className={classes.fixedText}>Total Prints</div>
                                {(cell.printVolume * catable_stores.length).withComma()}
                              </div>
                            )}
                          </TableCell>
                          {/** Redemption Rate * */}
                          <TableCell
                            style={{
                              ...(!cell.required && { opacity: 0.5 }),
                              ...(disableScreen & { paddingBottom: 10 }),
                            }}
                          >
                            {'redemptionRate' in cell && (
                              <div className={classes.flexWrap}>
                                <div className={classes.flexCell} style={{ width: '60%' }}>
                                  {!disableScreen && !cell.forceRedemptionRate ? (
                                    <TextField
                                      name={`${id}_redemption`}
                                      value={cell.redemptionRate}
                                      label="Redemption Rate (%)"
                                      style={{
                                        width: '100%',
                                        fontSize: 'inherit',
                                        ...(errors.redemptionRates[id] && {
                                          marginBottom: 15,
                                        }),
                                      }}
                                      onChange={(e) => {
                                        const v = e.target.value;
                                        this.handleUpdateCell(id, 'redemption', v);
                                      }}
                                      disabled={disableScreen || !cell.required}
                                      error={errors.redemptionRates[id] != null}
                                      helperText={errors.redemptionRates[id]}
                                    />
                                  ) : (
                                    <span className={classes.staticText}>
                                      <span className={classes.floatingText}>Redemption Rate</span>
                                      {cell.redemptionRate}%
                                    </span>
                                  )}
                                </div>
                                <div className={classes.flexCell} style={{ width: '15%', paddingTop: 30 }}>
                                  Est.
                                </div>
                                <div
                                  className={classes.flexCell}
                                  style={{
                                    paddingLeft: 5,
                                    width: '25%',
                                    paddingTop: 30,
                                  }}
                                >
                                  {Math.floor(cell.printVolume * (cell.redemptionRate / 100)).withComma()}
                                </div>
                              </div>
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              ...(!cell.required && { opacity: 0.5 }),
                              ...(disableScreen & { paddingBottom: 10 }),
                              verticalAlign: 'top',
                              paddingTop: 10,
                            }}
                          >
                            {!disableScreen &&
                              id !== 'existing' &&
                              id !== 'transactional' &&
                              !/^cont_/.test(id) &&
                              !/_a$|_b$/.test(id) && (
                                <div>
                                  <FormControlLabel
                                    label="Continuity"
                                    control={
                                      <Switch
                                        checked={cell.continuity}
                                        onChange={() => this.handleUpdateCell(id, 'continuity', !cell.continuity)}
                                        disabled={disableScreen || !cell.required || cell.forceContinuity}
                                      />
                                    }
                                  />
                                </div>
                              )}

                            {id !== 'existing' && id !== 'transactional' && !/_b$/.test(id) && !cell.continuity && (
                              <div>
                                <FormControlLabel
                                  label="A/B Targeting"
                                  control={
                                    <Switch
                                      checked={/_a$/.test(id)}
                                      onChange={(e) => this.handleUpdateCell(id, 'ab', e.target.checked)}
                                      disabled={
                                        disableScreen ||
                                        !cell.required ||
                                        (!/_a$|_b$/.test(id) && cell.volumes.standard < 10000)
                                      }
                                    />
                                  }
                                />
                                {!/_a$|_b$/.test(id) && cell.volumes && cell.volumes.standard < 10000 && (
                                  <Info color="orange" style={{ marginTop: 5 }}>
                                    A/B Testing not possible when available volume is less than 10,000.
                                  </Info>
                                )}
                              </div>
                            )}
                          </TableCell>
                        </TableRow>,
                        <TableRow key={`${id}_2`} style={{ ...(disableScreen && { paddingBottom: 10 }) }}>
                          <TableCell
                            colSpan={2}
                            style={{
                              paddingLeft: 0,
                              paddingRight: 0,
                              verticalAlign: 'top',
                            }}
                          >
                            {disableScreen ? (
                              <span className={classes.staticText}>
                                <span className={classes.floatingText}>Coupon Template</span>
                                {cell.template === 1
                                  ? 'Standard'
                                  : cell.template === 2
                                  ? 'Extended'
                                  : cell.template === 3
                                  ? 'Freestyle'
                                  : 'Recipe'}
                              </span>
                            ) : (
                              <FormControl style={{ minWidth: 120 }}>
                                <InputLabel>Coupon Template</InputLabel>
                                <Select
                                  labelId="regexThing"
                                  value={cell.template}
                                  style={{
                                    width: '100%',
                                    fontSize: 'inherit',
                                    ...(cell.errors && cell.errors.ab && { marginBottom: 15 }),
                                  }}
                                  onChange={(e) => {
                                    const v = e.target.value;
                                    this.handleUpdateCell(id, 'template', v);
                                  }}
                                  disabled={disableScreen || !cell.required}
                                  error={cell.errors && cell.errors.ab != null}
                                  helperText={cell.errors && cell.errors.ab}
                                >
                                  <MenuItem value={1}>Standard</MenuItem>
                                  <MenuItem value={2}>Extended</MenuItem>
                                  <MenuItem value={3}>Freestyle</MenuItem>
                                  <MenuItem value={4}>Recipe</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                          </TableCell>
                          <TableCell
                            colSpan={2}
                            style={{
                              ...(disableScreen && { paddingBottom: 10 }),
                              verticalAlign: 'top',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                ...(errors.offers[id] && {
                                  paddingBottom: 15,
                                }),
                              }}
                            >
                              {disableScreen ? (
                                <span className={classes.staticText}>
                                  <span className={classes.floatingText}>Offer</span>
                                  {cell.offer_type === 'money'
                                    ? cell.offer_value < 1
                                      ? `${cell.offer_value}p off`
                                      : `£${cell.offer_value} off`
                                    : `${cell.offer_value} bonus points`}
                                </span>
                              ) : (
                                [
                                  <FormControl style={{ minWidth: 120 }}>
                                    <Select
                                      labelId="regexThing"
                                      value={cell.offer_type}
                                      autoWidth
                                      style={{ flex: 1, fontSize: 'inherit' }}
                                      floatingLabelText="Offer Type"
                                      disabled={disableScreen || !cell.required}
                                      key="offertype"
                                      onChange={(e, i) => {
                                        const v = e.target.value;
                                        this.handleUpdateCell(id, 'offer_type', v);
                                      }}
                                      error={(errors.offers[id] || {}).type != null}
                                      helperText={(errors.offers[id] || {}).type}
                                    >
                                      <MenuItem value="points">Points</MenuItem>
                                      <MenuItem value="money">£</MenuItem>
                                    </Select>
                                  </FormControl>,
                                  <TextField
                                    style={{
                                      flex: 2,
                                      fontSize: 'inherit',
                                    }}
                                    key="offervalue"
                                    value={cell.offer_value}
                                    label="Offer Value"
                                    disabled={disableScreen || !cell.required}
                                    onChange={(e) => {
                                      const v = e.target.value;
                                      this.handleUpdateCell(id, 'offer_value', v);
                                    }}
                                    error={(errors.offers[id] || {}).value != null}
                                    helperText={(errors.offers[id] || {}).value}
                                  />,
                                ]
                              )}
                            </div>
                            {warnings.offers[id] && <Info color="orange">{warnings.offers[id].value}</Info>}
                            {/_b$/.test(id) && errors.offers[id] && errors.offers[id].dupe && (
                              <Info style={{ marginBottom: 10 }} color="red">
                                {errors.offers[id].dupe}
                              </Info>
                            )}
                          </TableCell>
                        </TableRow>,
                      ];
                    })}
                  </TableBody>
                </Table>
              </Paper>
            );
          }
        })}
      </div>
    );
  }
}

const {
  targetedComms: {
    smartcounts: { campaign },
  },
  modal,
} = Actions;

const mapStateToProps = (state) => {
  const {
    completed,
    rsp,
    cells: { pcr, groupedExisting, transactional },
  } = state.TargetedComms.Smartcounts.campaign;

  return {
    cc: {
      completed,
      errors: getErrors(state),
      warnings: getWarnings(state),
      groupedCells: getCells(state),
      costs: getCosts(state),
      catable_stores: getCatableStores(state),
      time_periods: getTimePeriods(state),
      cycle_details: getCycleDetails(state),
      redemptionRates: getRedemptionRates(state),
      rsp,
      pcr,
      groupedExisting,
      transactional,
      required: getRequired(state),
      stage: getCampaignObject(state).stage,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
    },
    campaign: {
      addCompleted: (index) => dispatch(campaign.addCompleted(index)),
      removeCompleted: (index) => dispatch(campaign.removeCompleted(index)),
      toggleGroupedExisting: (v) => dispatch(campaign.toggleGroupedExisting(v)),
      toggleTransactional: (v) => dispatch(campaign.toggleTransactional(v)),
      updateRSP: (v) => dispatch(campaign.updateRSP(v)),
      updatePCR: (v) => dispatch(campaign.updatePCR(v)),
      updateRequired: (cell, value) => dispatch(campaign.updateRequired(cell, value)),
      updateContinuity: (cell, value) => dispatch(campaign.updateContinuity(cell, value)),
      updateAB: (cell, value) => dispatch(campaign.updateAB(cell, value)),
      updatePrintVolume: (cell, value, method) => dispatch(campaign.updatePrintVolume(cell, value, method)),
      updateTemplate: (cell, value) => dispatch(campaign.updateTemplate(cell, value)),
      updateCellRedemption: (cell, value) => dispatch(campaign.updateRedemptionRate(cell, value)),
      updateOfferType: (cell, type) => dispatch(campaign.updateOfferType(cell, type)),
      updateOfferValue: (cell, value) => dispatch(campaign.updateOfferValue(cell, value)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CB_Targeting);
