import React from 'react';
import PropTypes from 'prop-types';
import { formatProper } from '../../../../../../utils/utils';

const Details = (props) => {
  return (
    <div className="details">
      <img className="img--details" src={props.imageSource} />
      {props.names.map((items, i) => {
        let value =
          props.values[items.replace(/ /g, '_').toLowerCase()].length > 0
            ? props.values[items.replace(/ /g, '_').toLowerCase()]
            : ['All'];
        value = value.length > 1 ? 'Multiple' : value[0];
        if (items.replace(/ /g, '_').toLowerCase() === 'date') {
          const start = props.values[items.replace(/ /g, '_').toLowerCase()].start.toLocaleDateString('en-GB');
          const end = props.values[items.replace(/ /g, '_').toLowerCase()].end.toLocaleDateString('en-GB');
          value = `${start} - ${end}`;
        }
        return (
          <div className="details--items" key={i}>
            <span>{formatProper(value, ['LTD'])}</span>
            <span className="item--name">{items}</span>
          </div>
        );
      })}
    </div>
  );
};

Details.propTypes = {
  imageSource: PropTypes.string.isRequired,
  names: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
};

export default Details;
