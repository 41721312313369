import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import * as hexToRgba from 'color';
import Color from 'color';
import { ContentHeader } from '../ContentHeader/index';
import { colorNames } from '../../../constants/colors';
import jss from '../../../utils/jss';

const styles = {
  tiles: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textAlign: 'left',
    flexWrap: 'wrap',
  },
  tilesSplitWrap: {
    marginRight: 10,
    borderRadius: 5,
  },
  tile: {
    fontFamily: 'VennSemiEx, Helvetica, sans-serif',
    position: 'relative',
    width: 230,
    minWidth: 230,
    height: 130,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
    padding: 20,
    borderRadius: 5,
    fontSize: '15pt',
    fontWeight: 'bold',
    userSelect: 'none',
    cursor: 'default',
    color: '#FFF',
    marginBottom: 10,
    marginRight: 10,
    transition: 'all 0.1s ease-in-out',
    '&:hover': {
      transform: 'scale(1.02)',
      cursor: 'pointer',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
    },
  },
  tileSplit: {
    height: 65,
    boxShadow: 'none',
    margin: 0,
    flexDirection: 'unset',
    justifyContent: 'unset',
    '&:hover': {
      border: 'none !important',
      borderRadius: 'inherit !important',
      boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
    },
  },
  tileSplitTop: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  tileSplitBottom: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  tileDisabled: {
    opacity: 0.5,
    '&:hover': {
      transform: 'scale(1)',
      cursor: 'default',
    },
    pointerEvents: 'none',
  },
  compact: {
    width: 200,
    minWidth: 20,
    height: 100,
    padding: '10 15',
    fontWeight: 'normal',
  },
  banner: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    fontSize: '9pt',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    backgroundColor: 'red',
    borderRadius: 'inherit',
    padding: '2px 7px',
  },
};

const stylesheet = jss.createStyleSheet(styles);
stylesheet.attach();

class Tiles extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, ...rest } = this.props;
    return (
      <div className={stylesheet.classes.tiles} {...rest}>
        {children}
      </div>
    );
  }
}

Tiles.propTypes = {
  compact: PropTypes.bool,
};

const TileSplit = ({ children, ...rest }) => {
  return (
    <div className={stylesheet.classes.tilesSplitWrap} {...rest}>
      {React.Children.toArray(children).map((c, i) => {
        if (i < 2) return React.cloneElement(c, { split: i.toString() });
      })}
    </div>
  );
};

TileSplit.propTypes = {
  children: PropTypes.arrayOf((propValue, key, componentName, location, propFullName) => {
    if (propValue.length !== 2) {
      return new Error(
        `Invalid prop '${propFullName}' supplied to ${componentName}. There must be exactly two children.`
      );
    }
  }),
};

class IconTile extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { compact, disabled, color, style, icon, text, split, badge, ...rest } = this.props;
    const { classes } = stylesheet;

    return (
      <div
        className={jss.combine(
          classes.tile,
          compact && classes.compact,
          disabled && classes.tileDisabled,
          split && classes.tileSplit,
          split === '0' && classes.tileSplitTop,
          split === '1' && classes.tileSplitBottom
        )}
        style={{
          backgroundColor: color ? colorNames[color] || color : colorNames.blueGrey,
          ...(split === '0' && { borderBottom: `1px solid ${Color(hexToRgba(color)).darken(0.2)}` }),
          ...(split === '1' && { borderBottom: `1px solid ${Color(hexToRgba(color)).lighten(0.2)}` }),
          ...style,
        }}
        {...rest}
      >
        {badge && <div className={classes.banner}>{badge}</div>}
        {React.cloneElement(icon, {
          ...(split ? { size: 24 } : !compact && { size: 25 }),
          style: { paddingLeft: 0, ...(split ? { marginRight: 5 } : { marginBottom: 10 }) },
        })}
        {text}
      </div>
    );
  }
}

IconTile.propTypes = {
  color: PropTypes.string,
};

const ContentTile = ({ header, children, style, aside, ...rest }) => {
  const styles = {
    tile: {
      width: '100%',
      backgroundColor: '#ffffff',
      boxShadow: '0 0 5px rgba(25, 25, 25, 0.25)',
      flexShrink: 0,
      padding: 15,
      boxSizing: 'border-box',
      ...style,
    },
    title: {
      fontWeight: 'bold',
      paddingBottom: 10,
      userSelect: 'none',
      cursor: 'default',
    },
  };
  return (
    <div style={styles.tile} {...rest}>
      {header && (
        <div style={styles.title}>
          {header} <div style={{ float: 'right', textAlign: 'right' }}>{aside}</div>
        </div>
      )}
      {children}
    </div>
  );
};

export { Tiles, TileSplit, IconTile, ContentTile };
