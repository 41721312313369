import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { map, reduce, sortBy, find } from 'lodash';
import { css, StyleSheet } from 'aphrodite/no-important';

// Components
import { MdIndeterminateCheckBox } from 'react-icons/md';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import { MdCheckBox } from 'react-icons/md';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { formatAsCSV } from '../../../../../utils/NumberUtils';
import { colorNames } from '../../../../../constants/colors';
import Actions from '../../../../../actions';

const style_table = StyleSheet.create({
  table: {
    width: '100%',
    backgroundColor: 'white',
    borderCollapse: 'collapse',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
  },
  th: {
    textAlign: 'left',
    userSelect: 'none',
    cursor: 'default',
    padding: 5,
    whiteSpace: 'nowrap',
  },
  tr: {
    ':nth-child(2n)': {
      backgroundColor: colorNames.lighterGrey,
    },
    borderRadius: 'inherit',
  },
  td: {
    padding: 5,
    verticalAlign: 'middle',
    fontSize: '10pt',
    borderRadius: 'inherit',
  },
  filterRow: {
    paddingBottom: 5,
  },
  filter: {
    width: '100%',
    outline: 'none',
    font: 'inherit',
    fontSize: '90%',
    padding: '3 5',
    height: 25,
    border: '1px solid',
    borderColor: colorNames.grey,
    transition: 'border-color 0.2s ease-in-out',
    resize: 'none',
    ':focus': {
      borderColor: colorNames.blue,
    },
  },
  pageButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
});

class SCTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      sort: [null, 'asc'],
      page: 0,
      metaPage: 0,
    };

    this.visibleSkus = [];
    this.checked = {
      all: [],
      skus: [],
      brands: [],
      ownLabel: null,
    };
  }

  /**
   * Prevent Update when changing a checkbox in a different tab
   */
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      // how could it even try to update if one of these wasn't true?
      nextProps.newcount.products[this.props.tab] !== this.props.newcount.products[this.props.tab] ||
      nextProps.disableBrand !== this.props.disableBrand ||
      nextProps.newcount.checkedSKUs !== this.props.newcount.checkedSKUs ||
      nextState !== this.state
    );
  }

  componentDidMount() {
    this.updateBadges();
  }

  componentWillMount() {
    this.processChecked(this.props);
  }

  /**
   * Assign the checked values to a variable that does not trigger an update of the render so we reduce once
   */
  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    this.processChecked(nextProps);
    this.checkComplete();
  }

  processChecked = (props) => {
    this.checked = reduce(
      props.newcount.checkedSKUs[props.tab],
      (checked, data) => {
        if (!checked.skus.includes(data.sku_no.toString())) {
          checked.skus.push(data.sku_no.toString());
        }
        if (!checked.brands.includes(data.brand)) {
          checked.brands.push(data.brand);
        }
        if (data.brand.toUpperCase().indexOf('OWN LABEL') > -1 && !checked.own) {
          checked.ownLabel = data.brand;
        }
        return checked;
      },
      { all: [], skus: [], brands: [], ownLabel: null }
    );

    this.checked.all = reduce(
      props.newcount.checkedSKUs,
      (checked, group) => {
        group.map((data) => {
          if (!checked.includes(data.sku_no.toString())) {
            checked.push(data.sku_no.toString());
          }
        });
        return checked;
      },
      []
    );

    if ('handleUnknownBrand' in props && this.checked.brands.includes('UNKNOWN'))
      props.handleUnknownBrand(this.props.tab);
  };

  /**
   * Called when toggling the checkbox on each row
   * @param {event} e - the event
   * @param {bool} c - checked status
   */
  handleCheckRow = (e, index) => {
    const c = e.target.checked;

    const newIndex = parseInt(index) + parseInt(this.state.page * 100);
    const data = this.tableData()[newIndex];
    let checkedSKUs = [...this.props.newcount.checkedSKUs[this.props.tab]];
    if (c) {
      checkedSKUs.push(data);
    } else {
      checkedSKUs = checkedSKUs.filter((item) => item.sku_no !== data.sku_no);
    }

    this.props.actions.newcount.updateCheckedSKUs({
      [this.props.tab]: checkedSKUs,
    });
  };

  /**
   * Called when the main checkbox in the table header is called
   */
  handleCheckAll = () => {
    const table = this.tableData();
    const fullChecked = [...this.props.newcount.checkedSKUs[this.props.tab]];
    let newChecked = [];
    const num = this.disableCount + this.props.newcount.checkedSKUs[this.props.tab].length;
    if (this.disableCount + this.props.newcount.checkedSKUs[this.props.tab].length < table.length) {
      const diff = table.length - this.disableCount;
      table.map((row) => {
        if (
          !fullChecked.includes(row) &&
          row.brand.toUpperCase().indexOf('OWN LABEL') === -1 &&
          this.props.disableBrand !== row.brand
        ) {
          newChecked.push(row);
        }
      });
      this.props.actions.newcount.updateCheckedSKUs({
        [this.props.tab]: [...this.props.newcount.checkedSKUs[this.props.tab], ...newChecked],
      });
    } else {
      newChecked = fullChecked.filter((item) => !table.includes(item));
      this.props.actions.newcount.updateCheckedSKUs({
        [this.props.tab]: newChecked,
      });
    }
  };

  /**
   * Called when typing in a filter field
   * @param {event} e - the event
   */
  handleChangeFilter = (e) => {
    let {
      dataset: { field },
      value,
    } = e.target;
    if (field === 'sku_no') {
      value = formatAsCSV(value);
    }

    /**
     * Because React seems to be quite slow when rendering, this was causing filters to be slow when
     * typing. So, I've masked it in a timeout so that typing remain quick.
     */
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(
      () =>
        this.setState((prevState) => ({
          metaPage: 0,
          page: 0,
          filter: {
            ...prevState.filter,
            [field]: value !== '' ? value : undefined,
          },
        })),
      500
    );
  };

  /**
   * Called when sorting a column
   * @param {event} e - the event
   */
  handleChangeSort = (e) => {
    const {
      dataset: { field },
    } = e.currentTarget;

    let direction = 'asc';
    if (this.state.sort[0] === field) {
      direction = this.state.sort[1];
      if (direction === 'asc') {
        direction = 'desc';
      } else {
        direction = 'asc';
      }
    }
    this.setState({ sort: [field, direction] });
  };

  /**
   * Called when focus is set to the SKU filter field to expand the height
   * @param {event} e - the event
   */
  handleSkuFieldFocus = (e) => {
    const f = e.target;
    f.style.width = f.offsetWidth;
    f.parentElement.style.width = f.offsetWidth;
    f.style.position = 'absolute';
    f.style.height = 200;
  };

  /**
   * Called when focus is taken away from the SKU filter field
   * @param {event} e -the event
   */
  handleSkuFieldBlur = (e) => {
    e.target.style = null;
  };

  /**
   * Called to set the bage values to the tabs (appears next to the tab name)
   * @private
   */
  updateBadges() {
    let badge;
    switch (this.props.tab) {
      case 'brand':
        badge = document.getElementById('tab_badge_1');
        break;
      case 'competitor':
        badge = document.getElementById('tab_badge_2');
        break;
      case 'overlay':
        badge = document.getElementById('tab_badge_3');
        break;
    }
    if (badge)
      badge.innerText = `${this.props.newcount.checkedSKUs[this.props.tab].length} / ${
        this.props.newcount.products[this.props.tab].length
      }`;
  }

  /**
   * Returns the data in the order specified by the sort
   * @returns {Array}
   */
  _sortData = () => {
    if (this.state.sort[0]) {
      const data = sortBy(this.props.newcount.products[this.props.tab], [
        (o) => {
          return o[this.state.sort[0]];
        },
      ]);
      if (this.state.sort[1] === 'asc') {
        return data;
      }
      return data.reverse();
    }
    return this.props.newcount.products[this.props.tab];
  };

  /**
   * Displays rows n - n + 100
   */
  changePage = (n) => {
    this.setState({ page: n });
  };

  /**
   * Moves the list of visible page buttons by n
   */
  changeMetaPage = (n) => {
    const { metaPage } = this.state;
    this.setState({ metaPage: metaPage + n });
  };

  /**
   * Returns the data to display in the table after filtering
   * @returns {Array}
   */
  tableData = () => {
    this.disableCount = 0;
    const { sku_no, sku_name, segmentation, subcat, brand } = this.state.filter;
    return reduce(
      this._sortData(),
      (array, sku, index) => {
        if (sku_no) {
          const sku_array = sku_no.split(',');

          if (!sku_array.includes(sku.sku_no.toString())) {
            return array;
          }
        }
        if (sku_name && !sku.sku_name.contains(sku_name)) {
          return array;
        }
        if (segmentation && !sku.segment_name.contains(segmentation)) {
          return array;
        }
        if (subcat && !sku.sub_category_name.contains(subcat)) {
          return array;
        }
        if (brand && !sku.brand.contains(brand) && !sku.manufacturer.contains(brand)) {
          return array;
        }

        if (!this.visibleSkus.includes(sku.sku_no.toString())) {
          this.visibleSkus.push(sku.sku_no.toString());
        }

        if (
          this.props.tab !== 'redemption' &&
          (sku.brand.toUpperCase().indexOf('OWN LABEL') > -1 || this.props.disableBrand === sku.brand)
        ) {
          this.disableCount++;
        }

        array.push(sku);

        return array;
      },
      []
    );
  };

  /**
   * Returns the checkbox style to display depending on what has been checked
   * @returns {ReactElement}
   */
  allCheckType = () => {
    let disableCount = 0;

    map(this.checked.skus, (sku) => {
      if (this.visibleSkus.includes(sku)) disableCount++;
    });

    if (disableCount === this.visibleSkus.length) {
      return <MdCheckBox size={24} />;
    }
    if (this.props.newcount.checkedSKUs[this.props.tab].length > 0) {
      return <MdIndeterminateCheckBox size={24} />;
    }
    return <MdCheckBoxOutlineBlank size={24} />;
  };

  checkComplete = () => {
    if ('tabComplete' in this.props) {
      switch (this.props.tab) {
        case 'competitor':
          /**
           * Zero competitors selector or more than 1
           * At least 2 brands selected
           */
          if (this.checked.skus.length === 0 || (this.checked.skus.length > 1 && this.checked.brands.length > 1)) {
            this.props.tabComplete.add('competitor');
          } else {
            this.props.tabComplete.remove('competitor');
          }
      }
    }
  };

  render() {
    this.updateBadges();
    this.visibleSkus = [];
    this.disableCount = 0;
    const fullData = this.tableData();
    const startValue = this.state.page * 100;
    const tableData = fullData.slice(startValue, startValue + 100); // only show 100 at a time
    const { checked } = this;
    const qRunning = this.props.queueData && this.props.queueData.status === 'running';

    const metaPages = [];
    if (fullData.length > 100) {
      /*
       * create an array of buttons to take you to each 'page' (i.e. set of 100 rows), and subdivide them into groups of ten
       * to be visible at any given time
       */

      let pages = [];
      for (let i = 0; i < fullData.length / 100; i++) {
        pages.push(
          <Button
            style={{ display: 'flex', flexShrink: i, backgroundColor: this.state.page === i ? '#C8C8C8' : null }}
            key={i}
            onClick={() => this.changePage(i)}
          >
            {i + 1}
          </Button>
        );
        if ((i + 1) % 10 == 0) {
          metaPages.push(pages);
          pages = [];
        }
      }
      if (pages.length > 0) {
        metaPages.push(pages);
      }
    }
    return (
      <div>
        <table className={css(style_table.table)}>
          <thead>
            <tr>
              {this.props.tab !== 'redemption' && (
                <th className={css(style_table.th)} rowSpan="2" style={{ verticalAlign: 'bottom' }}>
                  <FormControlLabel
                    labelPlacement="start"
                    control={<Checkbox color="primary" />}
                    checkedIcon={this.allCheckType()}
                    checked={this.props.newcount.checkedSKUs[this.props.tab].length > 0}
                    onChange={this.handleCheckAll}
                    disabled={this.props.newcount.locked || qRunning}
                  />
                </th>
              )}
              <th className={css(style_table.th)} data-field="sku_no" onClick={this.handleChangeSort}>
                SKU Number
                <MdKeyboardArrowDown
                  style={{
                    marginLeft: 3,
                    display: this.state.sort[0] === 'sku_no' ? 'inline-block' : 'none',
                    transform: this.state.sort[1] === 'asc' ? 'rotate(180deg)' : 'none',
                  }}
                />
              </th>
              <th className={css(style_table.th)} data-field="sku_name" onClick={this.handleChangeSort}>
                SKU Name
                <MdKeyboardArrowDown
                  style={{
                    marginLeft: 3,
                    display: this.state.sort[0] === 'sku_name' ? 'inline-block' : 'none',
                    transform: this.state.sort[1] === 'asc' ? 'rotate(180deg)' : 'none',
                  }}
                />
              </th>
              <th className={css(style_table.th)} data-field="segmentation" onClick={this.handleChangeSort}>
                Segment Name
                <MdKeyboardArrowDown
                  style={{
                    marginLeft: 3,
                    display: this.state.sort[0] === 'segmentation' ? 'inline-block' : 'none',
                    transform: this.state.sort[1] === 'asc' ? 'rotate(180deg)' : 'none',
                  }}
                />
              </th>
              <th className={css(style_table.th)} data-field="subcat" onClick={this.handleChangeSort}>
                Sub-Category
                <MdKeyboardArrowDown
                  style={{
                    marginLeft: 3,
                    display: this.state.sort[0] === 'subcat' ? 'inline-block' : 'none',
                    transform: this.state.sort[1] === 'asc' ? 'rotate(180deg)' : 'none',
                  }}
                />
              </th>
              <th className={css(style_table.th)} data-field="brand" onClick={this.handleChangeSort}>
                Brand / Manufacturer
                <MdKeyboardArrowDown
                  style={{
                    marginLeft: 3,
                    display: this.state.sort[0] === 'brand' ? 'inline-block' : 'none',
                    transform: this.state.sort[1] === 'asc' ? 'rotate(180deg)' : 'none',
                  }}
                />
              </th>
            </tr>
            <tr className={css(style_table.filterRow)}>
              <td className={css(style_table.td)}>
                <textarea
                  className={css(style_table.filter)}
                  data-field="sku_no"
                  rows="1"
                  onChange={this.handleChangeFilter}
                  onFocus={this.handleSkuFieldFocus}
                  onBlur={this.handleSkuFieldBlur}
                />
              </td>
              <td className={css(style_table.td)}>
                <input
                  type="search"
                  className={css(style_table.filter)}
                  data-field="sku_name"
                  onChange={this.handleChangeFilter}
                />
              </td>
              <td className={css(style_table.td)}>
                <input
                  type="search"
                  className={css(style_table.filter)}
                  data-field="segmentation"
                  onChange={this.handleChangeFilter}
                />
              </td>
              <td className={css(style_table.td)}>
                <input
                  type="search"
                  className={css(style_table.filter)}
                  data-field="subcat"
                  onChange={this.handleChangeFilter}
                />
              </td>
              <td className={css(style_table.td)}>
                <input
                  type="search"
                  className={css(style_table.filter)}
                  data-field="brand"
                  onChange={this.handleChangeFilter}
                />
              </td>
            </tr>
          </thead>
          <tbody style={{ borderRadius: 'inherit' }}>
            <tr className={css(style_table.tr)}>
              {startValue + 100 < fullData.length ? (
                <td colSpan={6} className={css(style_table.td)}>
                  Showing {'SKU'.pluralise(tableData.length > 1)} {startValue} - {startValue + 100}
                </td>
              ) : (
                <td colSpan={6} className={css(style_table.td)}>
                  Showing {'SKU'.pluralise(tableData.length > 1)} {startValue} - {fullData.length}
                </td>
              )}
            </tr>
            {map(tableData, (sku, index) => {
              return (
                <tr key={sku.sku_no} className={css(style_table.tr)}>
                  {this.props.tab !== 'redemption' && (
                    <td>
                      <FormControlLabel
                        labelPlacement="start"
                        control={<Checkbox color="primary" />}
                        data-index={index}
                        checked={checked.skus.includes(sku.sku_no.toString())}
                        onChange={(e) => this.handleCheckRow(e, index)}
                        disabled={
                          this.props.newcount.locked ||
                          qRunning ||
                          (checked.all.includes(sku.sku_no.toString()) &&
                            !checked.skus.includes(sku.sku_no.toString())) ||
                          ('disableBrand' in this.props && this.props.disableBrand === sku.brand) ||
                          (checked.ownLabel &&
                            sku.brand.toUpperCase().indexOf('OWN LABEL') > -1 &&
                            sku.brand !== checked.ownLabel)
                        }
                      />
                    </td>
                  )}
                  <td className={css(style_table.td)}>{sku.sku_no}</td>
                  <td className={css(style_table.td)}>{sku.sku_name}</td>
                  <td className={css(style_table.td)}>{sku.segment_name}</td>
                  <td className={css(style_table.td)}>{sku.sub_category_name}</td>
                  <td className={css(style_table.td)}>
                    {sku.brand}
                    <br />
                    <small>{sku.manufacturer}</small>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {fullData.length > 100 ? (
          <div className={css(style_table.pageButtons)}>
            {this.state.metaPage > 0 ? (
              <Button
                onClick={() => {
                  this.changeMetaPage(-1);
                }}
              >
                {'< Previous pages'}
              </Button>
            ) : null}
            {metaPages[this.state.metaPage]}
            {this.state.metaPage === metaPages.length - 1 ? null : (
              <Button
                onClick={() => {
                  this.changeMetaPage(1);
                }}
              >
                {'Next pages >'}
              </Button>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

SCTable.propTypes = {
  disableWhere: PropTypes.object,
};

const mapStateToProps = (state) => {
  const {
    TargetedComms: {
      Smartcounts: { count },
    },
    queue,
  } = state;
  return {
    newcount: {
      locked: count.locked,
      skus: count.skus,
      products: count.productDetails,
      checkedSKUs: count.checkedSKUs,
    },
    queueData: find(queue.data, (o) => {
      return o.id === count.jobDetails.id;
    }),
  };
};

const {
  targetedComms: {
    smartcounts: { newcount },
  },
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    newcount: {
      addCompleted: (index) => dispatch(newcount.addCompleted(index)),
      removeCompleted: (index) => dispatch(newcount.deleteCompleted(index)),
      updateCheckedSKUs: (skus) => dispatch(newcount.updateCheckedSKUs(skus)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SCTable);
