import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Table, TableBody, TableHead, TableCell, TableRow, FormControlLabel, Checkbox } from '@material-ui/core';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { filter } from 'lodash';
import { MdOpenInNew } from 'react-icons/md';
import { returnJSON, postJSON } from '../../../utils/async';
import { Content, ContentTabbed } from '../../lib/Content';
import Actions from '../../../actions';
import jss from '../../../utils/jss';
import CampaignDialog from './CampaignDialog';
import PermissionCheck from '../../../utils/PermissionCheck';
import { colorNames } from '../../../constants/colors';

const styles = {
  flexWrap: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: 250,
  },

  flexItem: {
    paddingRight: 15,
    borderBottom: 1,
    paddingBottom: 20,
    paddingTop: 20,
  },
  flexItem2: {
    paddingRight: 15,
    borderBottom: 1,
    paddingBottom: 35,
    paddingTop: 20,
  },
  propContainer: {
    width: 200,
    margin: 20,
    textAlign: 'center',
  },
  headerSplit: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  headerItem: {
    flex: 1,
    boxSizing: 'border-box',
    '&:first-child': {
      paddingRight: 10,
    },
    '&:last-child': {
      paddingLeft: 10,
    },
  },
  headline: {
    paddingTop: 16,
    marginBottom: 12,
    fontWeight: 400,
  },
  myStyleClassName: {
    padding: '16px 0px 16px 0px',
    '& a': {
      textDecoration: 'none',
      color: '#0000ee',
    },
    '& a:hover': {
      textDecoration: 'underline',
    },
  },
  link: {
    color: 'white',
    ':hover': {
      textDecoration: 'none',
      color: 'red',
    },
  },
};

class CampaignChecker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        incorrect: [],
        incomplete: [],
        complete: [],
      },
      dialogOpen: false,
      dialogData: {},
      qcCheck: [],
      filter: null,
      slideIndex: 0,
    };
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'CampaignChecker',
      classNamePrefix: 'CampaignChecker-',
    });
  }

  componentDidMount() {
    this.handleDataRequest();
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  handleDataRequest = () => {
    this.props.actions.loading.toggle({
      show: true,
    });
    returnJSON(
      '/api/campaign-checker/list',

      (error, result) => {
        if (error) return console.error(error);
        this.processData(result);
        this.props.actions.loading.toggle({
          show: false,
        });
      }
    );
  };

  processData = (data) => {
    const incomplete = filter(data, (o) => o.status === 'INCOMPLETE');
    const incorrect = filter(data, (o) => o.status !== 'INCOMPLETE' && o.status !== 'COMPLETE');
    const complete = filter(data, (o) => o.status === 'COMPLETE');
    this.setState({
      data: { incomplete, incorrect, complete },
    });
  };

  handleFilter = (event) => {
    const { value } = event.target;
    this.setState({ filter: value === '' ? null : value });
  };

  handleOpen = (data) => {
    this.setState({ dialogOpen: true, dialogData: data });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false, dialogData: {} });
  };

  handleApproveComplete = () => {
    this.props.actions.loading.toggle({
      show: true,
    });
    postJSON(
      '/api/campaign-checker/completed',
      {
        campaigns: this.state.qcCheck,
      },
      (error, result) => {
        this.setState({
          qcCheck: [],
        });
        this.props.actions.loading.toggle({
          show: false,
        });
        if (error) {
          console.error(error);
          return;
        }
        this.handleDataRequest();
      }
    );
  };

  handleQCCheck = (id) => {
    this.setState((prevState) => {
      let { qcCheck } = prevState;
      if (qcCheck.includes(id)) {
        qcCheck = qcCheck.filter((o) => o !== id);
      } else {
        qcCheck.push(id);
      }
      return { qcCheck };
    });
  };

  applyFilter = (data) => {
    let { filter: filterEvals } = this.state;
    filterEvals = filterEvals ? filterEvals.toLowerCase() : null;
    return filter(data, (value) => {
      return filterEvals
        ? value.id.toLowerCase().indexOf(filterEvals) > -1 ||
            (value.campaign_name && value.campaign_name.toLowerCase().indexOf(filterEvals) > -1) ||
            (value.supplier_name && value.supplier_name.toLowerCase().indexOf(filterEvals) > -1)
        : true;
    });
  };

  render() {
    const isAnalyst = PermissionCheck('Campaign_Status_Analyst');
    const { classes } = this.stylesheet;
    const dataIncorrect = this.applyFilter(this.state.data.incorrect);
    const dataPending = this.applyFilter(this.state.data.incomplete);
    const dataCompleted = this.applyFilter(this.state.data.complete);

    return (
      <Content title="Evaluations Status" fullBleed>
        <div className={classes.headerSplit}>
          <div className={classes.headerItem}>
            <Paper style={{ padding: '0 10' }}>
              <TextField
                label="Filter Evaluations"
                placeholder="Filter by Master Campaign ID, Campaign Name or Supplier Name"
                underlineShow
                onChange={this.handleFilter}
                style={{ width: '100%', marginBottom: '1rem' }}
                value={this.state.filter || ''}
              />
            </Paper>
          </div>
        </div>
        <Paper>
          <ContentTabbed
            tabBadge={[`(${dataIncorrect.length})`, `(${dataPending.length})`, `(${dataCompleted.length})`]}
            tabLabels={['Incorrect Briefs', 'Pending Evaluations', 'Completed Evaluations']}
          >
            <div>
              {/* INCORRECT CAMPAIGNS */}
              <Table selectable style={{ userSelect: 'auto', cursor: 'text' }} fixedHeader>
                {dataIncorrect.length > 0 ? (
                  [
                    <TableHead key="incorrect_header">
                      <TableRow>
                        <TableCell>Master Campaign ID</TableCell>
                        <TableCell>Brief Status</TableCell>
                        <TableCell>Last Validation</TableCell>
                      </TableRow>
                    </TableHead>,
                    <TableBody key="incorrect_body">
                      {dataIncorrect.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <a
                              href="#"
                              onClick={() => this.handleOpen(row)}
                              style={{
                                textDecoration: 'none',
                                color: '#02132d',
                                userSelect: 'auto',
                              }}
                            >
                              {row.id}
                            </a>
                          </TableCell>
                          <TableCell>
                            <a
                              href="#"
                              onClick={() => this.handleOpen(row)}
                              style={{
                                textDecoration: 'none',
                                color: '#02132d',
                                userSelect: 'auto',
                              }}
                            >
                              {row.status}{' '}
                              <MdOpenInNew size={14} style={{ marginBottom: 3 }} color={colorNames.graphite} />
                            </a>
                          </TableCell>
                          <TableCell>
                            <a
                              href="#"
                              onClick={() => this.handleOpen(row)}
                              style={{
                                textDecoration: 'none',
                                color: '#02132d',
                                userSelect: 'auto',
                              }}
                            >
                              {moment(row.last_validation).format('DD-MM-YYYY, h:mm:ss a')}
                            </a>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>,
                  ]
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>No Incorrect Evaluations</TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </div>

            {/* INCOMPLETE CAMPAIGNS */}

            <div>
              <Table style={{ userSelect: 'auto', cursor: 'text' }} fixedHeader>
                {dataPending.length > 0 ? (
                  [
                    <TableHead key="incomplete_header">
                      <TableRow>
                        <TableCell>Master Campaign ID</TableCell>
                        <TableCell>Campaign Name</TableCell>
                        <TableCell>Supplier Name</TableCell>
                        <TableCell>Brief Status</TableCell>
                        <TableCell>Last Validation</TableCell>
                      </TableRow>
                    </TableHead>,
                    <TableBody key="incomplete_body">
                      {dataPending.map((row, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell style={{ userSelect: 'auto', whiteSpace: 'auto' }}>{row.id}</TableCell>
                            <TableCell style={{ userSelect: 'auto', whiteSpace: 'normal' }}>
                              {row.campaign_name}
                            </TableCell>
                            <TableCell style={{ userSelect: 'auto', whiteSpace: 'normal' }}>
                              {row.supplier_name}
                            </TableCell>
                            <TableCell
                              style={{
                                userSelect: 'auto',
                                whiteSpace: 'normal',
                                whiteSpace: 'auto',
                              }}
                            >
                              {(row.validations[0] || {}).message}
                            </TableCell>
                            <TableCell style={{ userSelect: 'auto' }}>
                              {moment(row.last_validation).format('DD-MM-YYYY, h:mm:ss a')}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>,
                  ]
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>No Pending Evaluations</TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </div>

            {/* COMPLETE CAMPAIGNS */}

            <div style={{ width: '100%' }}>
              <Table style={{ userSelect: 'auto', cursor: 'text' }} fixedHeader>
                {dataCompleted.length > 0 ? (
                  [
                    <TableHead key="complete_header">
                      <TableRow>
                        {isAnalyst && <TableCell>Analyst Approved</TableCell>}
                        <TableCell>Master Campaign ID</TableCell>
                        <TableCell>Campaign Name</TableCell>
                        <TableCell>Supplier</TableCell>
                        <TableCell>Last Validation</TableCell>
                      </TableRow>
                    </TableHead>,
                    <TableBody key="complete_body">
                      {dataCompleted.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <div className={classes.flexWrap}>
                              <div className={classes.flexItem}>
                                <FormControlLabel
                                  labelPlacement="start"
                                  control={<Checkbox color="primary" />}
                                  checked={row.qc_date !== null || this.state.qcCheck.includes(row.qc_id)}
                                  onChange={() => this.handleQCCheck(row.qc_id)}
                                  disabled={!isAnalyst || row.qc_date !== null}
                                />
                              </div>
                              <div className={classes.flexItem} style={{ userSelect: 'auto' }}>
                                {row.qc_date !== null && moment(row.qc_date).format('DD-MM-YYYY, h:mm:ss a')}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell style={{ userSelect: 'auto' }}>{row.id}</TableCell>
                          <TableCell
                            style={{
                              userSelect: 'auto',
                              whiteSpace: 'normal',
                            }}
                          >
                            {row.campaign_name}
                          </TableCell>
                          <TableCell style={{ userSelect: 'auto' }}>{row.supplier_name}</TableCell>
                          <TableCell style={{ userSelect: 'auto' }}>
                            {moment(row.last_validation).format('DD-MM-YYYY, h:mm:ss a')}
                          </TableCell>
                        </TableRow>
                      ))}
                      {isAnalyst ? (
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Button
                              style={{
                                marginTop: 15,
                                marginBottom: 15,
                                labelColor: '#ffffff',
                                backgroundColor: '#a4c639',
                              }}
                              onClick={this.handleApproveComplete}
                              disabled={this.state.qcCheck.length === 0}
                              variant="contained"
                            >
                              APPROVE
                            </Button>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>,
                  ]
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={4}>No Complete Evaluations</TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </div>
          </ContentTabbed>
        </Paper>

        <CampaignDialog
          dialogOpen={this.state.dialogOpen}
          handleClose={this.handleClose}
          dialogData={this.state.dialogData}
          isAnalyst={isAnalyst}
          actionLookup={this.actionLookup}
          handleApprove={this.handleApprove}
          processData={this.processData}
        />
      </Content>
    );
  }
}
const { loading } = Actions;

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loading: {
      toggle: (options) => dispatch(loading.toggle(options)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignChecker);
