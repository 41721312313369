import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    boxSizing: 'border-box',
  },
  box: {
    boxSizing: 'border-box',
  },
  left5050: {
    flex: '1 0 0',
  },
  right5050: {
    flex: '1 0 0',
  },
  left7525: {
    flex: '2 0 0',
  },
  right7525: {
    flex: '1 0 0',
  },
  left2575: {
    flex: '1 0 0',
  },
  right2575: {
    flex: '2 0 0',
  },
});

export class Left extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let paddingRight;
    if ('style' in this.props) {
      if ('paddingRight' in this.props.style) {
        paddingRight = this.props.style.paddingRight;
      }
    }
    return (
      <div className={css(styles.sides, styles[`left${this.props.layout}`])}>
        <div style={{ paddingRight: paddingRight || 5, boxSizing: 'border-box' }}>{this.props.children}</div>
      </div>
    );
  }
}

export class Right extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={css(styles.sides, styles[`right${this.props.layout}`])}>
        <div style={{ paddingLeft: 5, paddingRight: 2, boxSizing: 'border-box', ...this.props.style }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export class Split extends React.Component {
  constructor(props) {
    super(props);
  }

  processComponents(components) {
    const output = [];
    components.forEach((node, index) => {
      output.push(React.cloneElement(node, { key: index, layout: this.props.layout || '5050' }));
    });
    return output;
  }

  render() {
    return (
      <div className={css(styles.wrapper)} style={this.props.style}>
        {this.processComponents([...this.props.children])}
      </div>
    );
  }
}
