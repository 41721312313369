import React from 'react';
import { connect } from 'react-redux';

class TargetingView extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>Targeting</div>;
  }
}

export default connect(null, null)(TargetingView);
