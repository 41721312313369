import React from 'react';
import { connect } from 'react-redux';
import { MdChat, MdInfo, MdError, MdPriorityHigh } from 'react-icons/md';
import Actions from '../../../../../../actions';
import campaign_stages, { extended, icons } from '../../Constants/campaign_stages';
import { colorNames } from '../../../../../../constants/colors';
import ProgressBar from '../../../../../lib/ProgressBar';

class CampaignStatus extends React.Component {
  constructor(props) {
    super(props);
    this.formatStage(props.data.status);
  }

  componentWillReceiveProps(nextProps) {
    this.formatStage(nextProps.data.status);
  }

  formatStage = (s) => {
    this.stage = s.replace('stage:', '');
  };

  handleInfo = () => {
    this.props.modal.toggle((modal) => ({
      show: true,
      title: campaign_stages[this.stage],
      body: extended[this.stage],
      dismissable: true,
      style: 'info',
      actions: [
        {
          text: 'Ok',
          action: modal.close,
        },
      ],
    }));
  };

  handleRejectInfo = () => {
    const { audit } = this.props.data.campaign;
    const cmt = audit[audit.length - 1].comment;
    this.props.modal.toggle((modal) => ({
      show: true,
      title: 'Campaign Rejected',
      body: (
        <div>
          Your campaign was rejected with the follow comment: <p>{cmt}</p>
        </div>
      ),
      dismissable: true,
      style: 'error',
      actions: [
        {
          text: 'Ok',
          action: modal.close,
        },
      ],
    }));
  };

  render() {
    const {
      data: {
        status,
        count: { queue },
      },
    } = this.props;
    if (status.indexOf('stage:') === 0) {
      const Icon = icons[this.stage || 'count'] || MdError;
      return (
        <span>
          <Icon size={14} /> {campaign_stages[this.stage]} <MdInfo color={colorNames.grey} onClick={this.handleInfo} />
        </span>
      );
    }
    switch (status) {
      case 'counted':
        return (
          <span>
            <icons.count /> Count
          </span>
        );
      case 'counting':
        return <ProgressBar queue={queue} showText runningText="Waiting..." />;
      case 'rejected':
        return (
          <span style={{ color: colorNames.red }}>
            Rejected <MdChat onClick={this.handleRejectInfo} />
          </span>
        );
      case 'in progress': {
        const Icon = icons[0];
        return (
          <span>
            <Icon /> In Progress
          </span>
        );
      }
      case 'executed': {
        const Icon = icons[6];
        return (
          <span>
            <Icon /> Executed
          </span>
        );
      }
      case 'expired':
        return (
          <span>
            <MdPriorityHigh color={colorNames.orange} /> Expired
          </span>
        );
      default:
        return <span>{status.toSentenceCase()}</span>;
    }
  }
}

const { modal } = Actions;

const mapDispatchToProps = (dispatch) => ({
  modal: {
    toggle: (opts) => dispatch(modal.toggle(opts)),
  },
});

export default connect(null, mapDispatchToProps)(CampaignStatus);
