import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { map, reduce, filter, sortBy, memoize } from 'lodash';
import defaultCells from '../../../../../../reducers/TargetedComms/Smartcounts/default_cells';
import getCampaignObject from './getCampaignObject';

const createDeepEqualSelector = createSelectorCreator(memoize, (...args) =>
  args.reduce((acc, val) => `${acc}-${JSON.stringify(val)}`, '')
);

export const cells = (state) => state.TargetedComms.Smartcounts.campaign.cells;
export const getRequired = (state) => cells(state).required;
export const getVisible = (state) => cells(state).visible;
export const getTemplates = (state) => cells(state).templates;
const getOffers = (state) => cells(state).offers;
export const getRSP = (state) => {
  const { rsp } = getCampaignObject(state);
  return parseFloat(rsp.used !== rsp.original ? rsp.used : rsp.original);
};
const getBarcodes = (state) => cells(state).barcodes;
const getCountData = (state) => state.TargetedComms.Smartcounts.campaign.count;
export const getAisle = (state) => {
  const { payload } = getCountData(state);
  return { name: payload.aisle, rule: payload.aisle_rule, sensitivity: payload.aisle_sensitivity };
};
const getCountResults = (state) => getCountData(state).results;
const getPrints = (state) => cells(state).prints;
export const getAdjustedRedemptionRates = (state) => cells(state).redemptionRates;
export const getOriginalRedemptionRates = (state) => getCampaignObject(state).redemption_rates;

const getStage = (state) => state.TargetedComms.Smartcounts.campaign.stage;

const cellPriority = createSelector([getVisible], (cells) => {
  return map(
    sortBy(
      reduce(
        cells,
        (output, cell) => {
          output.push({ cell, order: (cell.match(/_/g) || []).length });
          return output;
        },
        []
      ),
      (o) => o.order
    ),
    (o) => o.cell
  );
});

export const getRedemptionRates = createDeepEqualSelector(
  [cellPriority, getAdjustedRedemptionRates, getOriginalRedemptionRates],
  (cells, adjusted, original) => {
    return reduce(
      cells,
      (output, cell) => {
        return {
          ...output,
          [cell]: {
            adjusted: adjusted[cell],
            original: original[cell] ? original[cell].rate : 0,
          },
        };
      },
      {}
    );
  }
);

const getVolumesFromCount = createDeepEqualSelector([getCountResults], (c) => {
  let output = {};
  const cellMap = ['new', 'lapsed', 'solus', 'repertoire', 'brand', 'acquisition'];
  c = c.filter((c) => c.CELL !== '7');
  map(c, (v) => {
    let standard = 0;
    map(
      Object.keys(v).filter((o) => !['OVERLAYS', 'CELL'].includes(o)),
      (k) => {
        if (k === 'COUPON') {
          standard += parseInt(v[k]);
        }
      }
    );
    output = {
      ...output,
      [cellMap[v.CELL - 1]]: {
        standard: 0,
        overlay: 0,
        ...output[cellMap[v.CELL - 1]],
        [v.OVERLAYS === '0' ? 'standard' : 'overlay']: standard,
      },
    };
  });
  return output;
});

export const getVisibleCells = createSelector([getVisible], (visible) => {
  return reduce(
    defaultCells,
    (output, cell, code) => {
      if (visible.includes(code)) {
        let parent = null;
        if (/^cont_/.test(code) && /_a$|_b$/.test(code)) {
          parent = code.replace(/_a$|_b$/, '');
        } else if (/^cont_|_a$|_b$/g.test(code)) {
          parent = code.replace(/^cont_|_a$|_b$/g, '');
        }

        output = {
          ...output,
          [code]: { ...cell, parent },
        };
      }
      return output;
    },
    {}
  );
});

const getContinuityFlags = createDeepEqualSelector([getVisibleCells, getVisible], (cells, visible) => {
  return reduce(
    cells,
    (output, cell, code) => {
      if (!/^cont_|transactional/.test(code)) {
        output = {
          ...output,
          [code]:
            visible.includes(`cont_${code}`) ||
            visible.includes(`cont_${code}_a`) ||
            visible.includes(`cont_${code}_b`),
        };
      }
      return output;
    },
    {}
  );
});

const getABFlags = createDeepEqualSelector([getVisibleCells], (cells) => {
  return reduce(
    cells,
    (output, cell, code) => {
      if (!/_a$|_b$|transactional/.test(code)) {
        return {
          ...output,
          [code]: Object.keys(cells).includes(`${code}_a`) || Object.keys(cells).includes(`${code}_b`),
        };
      }
      return output;
    },
    {}
  );
});

export const getCellOffers = createDeepEqualSelector([getVisibleCells, getOffers, getRSP], (cells, offers, rsp) => {
  return reduce(
    cells,
    (output, cell, code) => {
      let type = 'points';
      let value = 0;
      if (offers[code]) {
        type = offers[code].type || 'points';
        value = offers[code].value;
      }
      return {
        ...output,
        [code]: { type, value },
      };
    },
    {}
  );
});

export const getAvailableVolumes = createDeepEqualSelector(
  [getVisibleCells, cellPriority, getVolumesFromCount, getPrints, getRedemptionRates],
  (cells, priority, volumes, prints, redemption) => {
    const output = {};

    priority.forEach((code) => {
      if (code === 'existing') {
        output.existing = {
          standard:
            (volumes.new ? volumes.new.standard : 0) +
            (volumes.lapsed ? volumes.lapsed.standard : 0) +
            (volumes.solus ? volumes.solus.standard : 0) +
            (volumes.repertoire ? volumes.repertoire.standard : 0),
        };
      } else if (!cells[code].parent) {
        output[code] = volumes[code];
      } else if (/_a$|_b$/.test(code) && !/^cont_/.test(code)) {
        output[code] = {
          standard: Math.floor(volumes[cells[code].parent].standard / 2),
          overlay: Math.floor(volumes[cells[code].parent].overlay / 2),
        };
      } else if (/^cont_/.test(code) && !/_a$|_b/.test(code)) {
        output[code] = {
          standard:
            Math.floor(
              (prints[cells[code].parent] || {}).value *
                ((redemption[cells[code].parent].adjusted || redemption[cells[code].parent].original) / 100)
            ) || 0,
          overlay: 0,
        };
      } else if (/^cont_/.test(code) && /_a$|_b$/.test(code)) {
        output[code] = {
          standard:
            Math.floor(
              ((prints[cells[code].parent.replace(/^cont_/, '')] || {}).value *
                (redemption[cells[code].parent.replace(/^cont_/, '')].adjusted / 100)) /
                2
            ) || 0,
          overlay: 0,
        };
      } else {
        output[code] = { standard: 0, overlay: 0 };
      }
    });
    return output;
  }
);

export const getRequiredFlags = createDeepEqualSelector(
  [getVisibleCells, getRequired, getAvailableVolumes],
  (cells, required, vols) => {
    return reduce(
      cells,
      (output, cell, code) => {
        let forced = code === 'transactional' || false;
        if (/^cont_|_a$|_b$/g.test(code) || required.includes(`cont_${code}`)) {
          forced = true;
        }
        if (code === 'existing' || code === 'transactional') {
          output[code] = { required: true, forced: true };
        } else if (!/^cont_/.test(code)) {
          let req = required.includes(code) || forced;
          if (!vols[code]) {
            req = false;
            forced = true;
          }
          output[code] = { required: req, forced };
        } else {
          output[code] = { required: true, forced: true };
        }
        return output;
      },
      {}
    );
  }
);

export const getPrintVolumes = createDeepEqualSelector([getVisibleCells, getPrints], (cells, prints) => {
  return reduce(
    cells,
    (output, cell, code) => {
      // If it's B it should be the same as A's print volume
      if (/_b$/.test(code)) {
        return { ...output, [code]: prints[code.replace(/_b$/, '_a')] || { value: 0, method: null } };
      }
      return { ...output, [code]: prints[code] || { value: 0, method: null } };
    },
    {}
  );
});

export const cellTemplates = createDeepEqualSelector([getVisibleCells, getTemplates], (cells, templates) => {
  return reduce(
    cells,
    (output, cell, code) => {
      return { ...output, [code]: templates[code] || 1 };
    },
    {}
  );
});

export const getBuiltCells = createDeepEqualSelector(
  [
    getVisibleCells,
    getRequiredFlags,
    getContinuityFlags,
    getABFlags,
    cellTemplates,
    getCellOffers,
    getBarcodes,
    getRedemptionRates,
    getAvailableVolumes,
    getPrintVolumes,
  ],
  (
    cells,
    requiredFlags,
    continuityFlags,
    abFlags,
    templates,
    offers,
    barcodes,
    redemptionRates,
    cellVolumes,
    prints
  ) => {
    return reduce(
      cells,
      (output, cell, code) => {
        return {
          ...output,
          [code]: {
            ...cell,
            continuity: continuityFlags[code],
            required: requiredFlags[code].required,
            forceRequired: requiredFlags[code].forced,
            ab: abFlags[code],
            template: templates[code],
            offer_type: offers[code].type,
            offer_value: offers[code].value,
            barcode: barcodes[code] || '',
            redemptionRate: redemptionRates[code].adjusted || redemptionRates[code].original || '',
            volumes: cellVolumes[code],
            printVolume: (prints[code] || {}).value || 0,
            printVolumeType: (prints[code] || {}).method || null,
            forcePrintVolume: /_b$/.test(code),
          },
        };
      },
      {}
    );
  }
);

const cellsGrouped = createDeepEqualSelector([getBuiltCells, getStage], (cells, stage) => {
  return reduce(
    cells,
    (groups, cell, cellName) => {
      if ((cell.required && stage > 0) || stage === 0) {
        const group = cellName.replace(/cont_|_a$|_b$/g, '');
        groups[group] = {
          ...groups[group],
          [cellName]: cell,
        };
      }
      return groups;
    },
    {}
  );
});

export default cellsGrouped;

export const getRequiredCellsGrouped = createDeepEqualSelector([cellsGrouped], (groups) =>
  reduce(
    groups,
    (g, cells, group) => {
      const reduceGroup = reduce(cells, (c, cell, code) => ({ ...c, ...(cell.required && { [code]: cell }) }), {});
      return {
        ...g,
        ...(Object.keys(reduceGroup).length > 0 && { [group]: reduceGroup }),
      };
    },
    {}
  )
);
