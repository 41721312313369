/**
 * Makes the first letter of the string a captial letter
 * @returns {string}
 */
import { padNumber } from './NumberUtils';

String.prototype.toCapitalized = function () {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

String.prototype.validEmail = function () {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    this
  );
};

/**
 * Captialises each word within a string
 * @returns {string}
 */
String.prototype.toSentenceCase = function () {
  return this.split(' ')
    .map(function (word) {
      return word.toCapitalized();
    })
    .join(' ');
};

String.prototype.fromCamelCase = function () {
  const result = this.replace(/([a-z])([A-Z][a-z])/g, '$1 $2')
    .replace(/([A-Z][a-z])([A-Z])/g, '$1 $2')
    .replace(/([a-z])([A-Z]+[a-z])/g, '$1 $2')
    .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, '$1 $2')
    .replace(/([a-z]+)([A-Z0-9]+)/g, '$1 $2')
    .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, '$1 $2')
    .replace(/([0-9])([A-Z][a-z]+)/g, '$1 $2')
    .replace(/([A-Z]{2,})([0-9]{2,})/g, '$1 $2')
    .replace(/([0-9]{2,})([A-Z]{2,})/g, '$1 $2')
    .trim();

  // capitalize the first letter
  return result.charAt(0).toUpperCase() + result.slice(1);
};

String.prototype.toCamelCase = function () {
  return this.replace('-', ' ')
    .split(' ')
    .map(function (word, index) {
      // If it is the first word make sure to lowercase all the chars.
      if (index == 0) {
        return word.toLowerCase();
      }
      // If it is not the first word only upper case the first char and lowercase the rest.
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
};

/**
 * If the test is true, the letter s is appended.
 * @param {boolean} test
 * @param {boolean} [apostrophe=false] apostrophe
 * @returns {string}
 */
String.prototype.pluralise = function (test, apostrophe = false) {
  if (test) {
    return `${this + (apostrophe ? "'" : '')}s`;
  }
  return this;
};

/**
 * Return true if the string contains the specified string
 * @param {string} string - string to search for
 * @returns {boolean}
 */
String.prototype.contains = function (string) {
  const reg = new RegExp(string.toString().toLowerCase(), 'g');
  const subject = this.toLowerCase();
  return reg.test(subject);
};

/**
 * Changes each element of the array to an Int
 * @returns {array}
 */
Array.prototype.eachToInt = function () {
  return this.map((a) => {
    return parseInt(a);
  });
};

Number.prototype.decimalPlaces = function () {
  if (this.toString().indexOf('.') > -1) {
    return this.toString().split('.')[1].length;
  }
  return 0;
};

Number.prototype.formatCurrency = function () {
  return this.toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });
};

Number.prototype.pad = function (w, z) {
  return padNumber(this, w, z);
};

/**
 * Wrapper for adding a comma to numbers
 * @returns {string}
 */
Number.prototype.withComma = function () {
  return this.toLocaleString('en');
};

/**
 * Supress content editable warning
 */
console.error = (function () {
  const { error } = console;

  return function (exception) {
    if (`${exception}`.indexOf('Warning: A component is `contentEditable`') != 0) {
      error.apply(console, arguments);
    }
  };
})();

String.prototype.hashCode = function () {
  let hash = 0;
  let i;
  let chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
