import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import TextField from '@material-ui/core/TextField';
import {
  Radio,
  RadioGroup,
  Button,
  Chip,
  Switch,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Select,
  FormControl,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { MdFileDownload } from 'react-icons/md';
import moment from 'moment/moment';
import MenuItem from '@material-ui/core/MenuItem';
import { reduce, map, flattenDeep, cloneDeep } from 'lodash';
import CSVInput from '../../../../lib/CSVInput/CSVInput';
import Box from '../../../../lib/Box/Box.js';
import { Split, Left, Right } from '../../../../lib/Split/index';
import Badge from '../../../../lib/Badge/index';
import { postJSON } from '../../../../../utils/async';
import SaveXLS from '../../../../../utils/SaveXLS';

import jss from '../../../../../utils/jss';

import { Content } from '../../../../lib/Content';

import { colorNames } from '../../../../../constants/colors';

import Actions from '../../../../../actions';

const styles = {
  flexWrap: {
    display: 'flex',
    alignItems: 'inline-block',
    padding: '1rem',
  },
  flexItem: {
    flex: 1,
  },
  rightAlign: {
    textAlign: 'right',
  },
  skuInput: {
    marginRight: '3rem',
  },
};

class SKUFilter extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'SkuSaleVerifier',
      classNamePrefix: 'SkuSaleVerifier-',
    });
    this.state = {
      show: false,
      groupedData: {},
      filtered: false,
      ignore: false,
      unsold: false,
      filter: 0,
      filteredData: {},
    };
    this.stepIndex = this.props.stepIndex;
  }

  /*
        Warns user if filtering unsold skus will empty a targeting group, and gives option to remove that group or cancel;
        forbids emptying redemption group.
    */
  filterWarn = (names, redEmpty) => {
    if (redEmpty) {
      this.props.actions.modal.toggle({
        show: true,
        title: 'This will leave the Redemption group empty. Please return to Step 1 and amend',
        style: 'warning',
        body: '',
        actions: [
          {
            text: 'Cancel',
            action: () => this.props.actions.modal.toggle({ show: false }),
          },
        ],
      });
    } else {
      let nameString = '';
      names.forEach(function (name) {
        nameString = `${nameString},${name}`;
      });

      nameString = nameString.substring(1);
      this.props.actions.modal.toggle({
        show: true,
        title: `This will leave empty groups: ${nameString}; Continue with filter and remove empty groups?`,
        style: 'warning',
        body: '',
        actions: [
          {
            text: 'Cancel',
            action: () => this.props.actions.modal.toggle({ show: false }),
          },
          {
            text: 'Continue',
            position: 'right',
            action: () => {
              this.handleRemove(names);
            },
          },
        ],
      });
    }
  };

  filterSkus = () => {
    const data = cloneDeep(this.state.groupedData);

    const groups = this.props.planning.productGroups;

    const empties = [];

    let redEmpty = false;

    /*
        initially checks that filtering unsold skus will not leave a group empty; then, if not, filters them
        */
    const checkEmpties = (x) => {
      if (x == groups.length) {
        return true;
      }

      // an array of the skus in the PG
      const skuArray = groups[x].skus.split(',');
      let newSkus = '';

      // looping through the PG's skus, checks if that sku was in the data returned, else removes as not real sku
      // else adds it to a string of skus
      skuArray.forEach(function (sku) {
        if (data[sku]) {
          if (data[sku].total != this.state.filter) {
            newSkus = `${newSkus + sku},`;
          } else {
            delete data[sku];
          }
        }
      }, this);
      if (newSkus == '') {
        // i.e., if all the skus from this group are fake or unsold:
        if (groups[x].type == 'Redemption') {
          redEmpty = true;
        } else {
          empties.push(groups[x].name);
        }

        return false;
      }
      newSkus = newSkus.substring(0, newSkus.length - 1);

      if (checkEmpties(x + 1)) {
        groups[x].skus = newSkus;
        return true;
      }
      return false;
    };

    if (checkEmpties(0)) {
      this.props.actions.planning.updateGroups(groups);
      this.setState({ groupedData: data, filtered: true });
    } else {
      this.setState({ filteredData: data }, () => this.filterWarn(empties, redEmpty));
    }
  };

  checkUnsold = (data) => {
    let unsold = false;
    const keys = Object.keys(data);
    keys.forEach(function (key) {
      if (data[key].total == this.state.filter) {
        unsold = true;
      }
    }, this);
    this.setState({ unsold });
  };

  handleDataRequest = () => {
    this.props.actions.loading.toggle({
      show: true,
      text: 'Calculating Volumes...',
    });
    let skus = [];
    this.props.planning.productGroups.forEach(function (group) {
      skus = skus.concat(group.skus.split(','));
    }, this);

    let datePeriod = this.props.planning.rules * 7 - 1;
    if (this.props.planning.periodDates.from) {
      const from = moment(this.props.planning.periodDates.from);
      const to = moment(this.props.planning.periodDates.to);
      datePeriod = to.diff(from, 'days') - 1;
    }

    postJSON(
      '/api/lookup/units-sold',
      {
        sku_no: skus.map((s) => parseInt(s)),
        datePeriod,
      },
      (error, result) => {
        if (error) return;

        const groupedData = this.groupSKUs(result);
        this.props.planning.productGroups.forEach(function (group) {
          const skuArray = group.skus.split(',');
          for (const sku of skuArray) {
            if (groupedData[sku]) {
              groupedData[sku].group = group.name;
              groupedData[sku].type = group.type;
            }
          }
        }, this);

        this.setState(
          {
            groupedData,
            show: true,
            filtered: false,
          },
          () => {
            this.props.actions.loading.toggle({
              show: false,
            });
            this.handleNextAdd();
            this.checkUnsold(this.state.groupedData);
          }
        );
      }
    );
  };

  handleNextAdd = () => {
    if (this.state.ignore) {
      this.props.actions.planning.addCompleted(this.stepIndex);
      this.props.actions.planning.addCompleted(this.stepIndex + 1);
      this.props.actions.planning.addCompleted(this.stepIndex + 2);
      this.props.handleNext();
      this.props.handleNext();
      this.props.handleNext();
    } else if (this.state.show) {
      this.props.actions.planning.addCompleted(this.stepIndex);
      this.props.actions.planning.addCompleted(this.stepIndex + 1);
      this.props.actions.planning.addCompleted(this.stepIndex + 2);
    } else {
      this.props.actions.planning.removeCompleted(this.stepIndex);
      this.props.actions.planning.removeCompleted(this.stepIndex + 1);
      this.props.actions.planning.removeCompleted(this.stepIndex + 2);
    }
  };

  groupSKUs = (data) => {
    // this basically groups objects representing EANs into objects containing an array representing SKU et al.
    const output = reduce(
      data,
      (groups, value) => {
        if (groups[value.SKU_NO]) {
          groups[value.SKU_NO].rows.push(value);
          groups[value.SKU_NO].total = groups[value.SKU_NO].total + parseInt(value.UNITS_SOLD);
        } else {
          (groups[value.SKU_NO] || (groups[value.SKU_NO] = {})).rows = [value];
          groups[value.SKU_NO].total = parseInt(value.UNITS_SOLD);
        }

        return groups;
      },
      {}
    );
    return output;
  };

  /*
        handles removal of  productGroups

    */
  handleRemove = (names) => {
    const data = this.state.filteredData;
    let groups = this.props.planning.productGroups;
    names.forEach(function (name) {
      groups = groups.filter(function (group) {
        return group.name != name;
      });
    }, this);

    this.props.actions.planning.updateGroups(groups);
    this.props.actions.modal.toggle({ show: false });
    this.setState({ groupedData: data, filtered: true });
  };

  // handles 'ignore' toggle
  handleToggle = () => {
    const ignore = !this.state.ignore;
    this.setState(
      {
        ignore,
      },
      () => {
        this.handleNextAdd();
      }
    );
  };

  // exports table data to Excel spreadsheet
  export = () => {
    const data = reduce(
      this.state.groupedData,
      (array, value) => {
        value.rows.forEach(function (row) {
          row.group = value.group;
          row.type = value.type;
          array.push(row);
        }, this);
        return array;
      },
      []
    );
    let today = new Date();
    const dd = today.getDate();

    const mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();
    today = `Planning Count_${mm}-${dd}-${yyyy}`;

    SaveXLS(
      [
        {
          name: 'Planning Count',
          data,
        },
      ],
      today
    );
  };

  render() {
    const { classes } = this.stylesheet;

    return (
      <div style={{ marginTop: '50px' }}>
        <Box title="Get SKU data to export and filter unsold SKUS" static>
          <Button variant="contained" disabled={this.state.ignore} primary="true" onClick={this.handleDataRequest}>
            {this.state.show ? 'Refresh SKUs' : 'Get SKUs'}
          </Button>

          {this.state.show ? null : (
            <FormControlLabel
              label="Ignore"
              style={{ width: '10%', float: 'right' }}
              control={<Switch onChange={this.handleToggle} />}
            />
          )}
        </Box>
        {this.state.show ? (
          <Paper>
            <div className={classes.flexWrap}>
              <div className={jss.combine(classes.flexItem, classes.rightAlign)} style={{ marginTop: '1.8rem' }}>
                <Button
                  startIcon={
                    <span>
                      <MdFileDownload size={16} />
                    </span>
                  }
                  onClick={this.export}
                >
                  Export
                </Button>
                {this.state.unsold ? (
                  this.state.filtered ? null : (
                    <Button onClick={this.filterSkus}>Remove unsold skus from count</Button>
                  )
                ) : null}
              </div>
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>EAN</TableCell>
                  <TableCell>Units Sold</TableCell>
                  <TableCell>Group</TableCell>
                  <TableCell>Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {map(this.state.groupedData, (row, sku) => {
                  return [
                    map(row.rows, (ean, i) => (
                      <TableRow key={ean.SKU_NO + i} style={{ whiteSpace: 'pre-wrap' }}>
                        <TableCell>{ean.SKU_NO}</TableCell>
                        <TableCell>{ean.SKU_NAME}</TableCell>
                        <TableCell>{ean.EAN}</TableCell>
                        <TableCell>{parseInt(ean.UNITS_SOLD).withComma(',')}</TableCell>
                        <TableCell>{row.group}</TableCell>
                        <TableCell>{row.type}</TableCell>
                      </TableRow>
                    )),
                    <TableRow key={`${sku}-total`}>
                      <TableCell colSpan={3} />
                      <TableCell style={{ fontWeight: 'bold' }}>{parseInt(row.total).withComma(',')}</TableCell>
                    </TableRow>,
                  ];
                })}
              </TableBody>
            </Table>
          </Paper>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ planningtool }) => ({
  planning: planningtool,
});

const {
  targetedComms: { planning },
  modal,
  loading,
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    planning: {
      addCompleted: (index) => dispatch(planning.addCompleted(index)),
      removeCompleted: (index) => dispatch(planning.removeCompleted(index)),
      updateGroups: (groups) => dispatch(planning.updateGroups(groups)),
    },
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
      amendAction: (index, opts) => dispatch(modal.amendAction(index, opts)),
    },
    loading: {
      toggle: (options) => dispatch(loading.toggle(options)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SKUFilter);
