import React from 'react';
import PropTypes from 'prop-types';
import FilterInfo from './FilterInfo';
import RadioButton from '../../../../lib/Radio/Radio';

class FilterOpen extends React.Component {
  static propTypes = {
    saved: PropTypes.array.isRequired,
    filters: PropTypes.object.isRequired,
    radioName: PropTypes.string.isRequired,
    handleOpenChange: PropTypes.func.isRequired,
    value: PropTypes.string,
  };

  render() {
    const { saved } = this.props;
    return (
      <div className="open--filters">
        <RadioButton
          radioName="filterOpen"
          data={saved}
          labelKey="title"
          className="radio--group"
          value={this.props.value}
          handleChange={this.props.handleOpenChange}
        />
        <div>
          <FilterInfo filters={this.props.filters} />
        </div>
      </div>
    );
  }
}

export default FilterOpen;
