import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from '@material-ui/pickers';

const DatePick = (props) => {
  const { dateRef, ...rest } = props;
  return (
    <DatePicker
      id="datepicker"
      floatingLabelText={props.label}
      autoOk
      locale="en-GB"
      DateTimeFormat={Intl.DateTimeFormat}
      disableYearSelection={false}
      ref={dateRef}
      {...rest}
    />
  );
};

DatePick.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  defaultDate: PropTypes.object,
};

export default DatePick;
