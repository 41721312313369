import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { Link } from 'react-router-dom';
import { MdHome } from 'react-icons/md';
import { moduleGroups } from '../../../constants/moduleInfo';
import Icon from '../Icon';
import logo from '../../../../images/nectar360-white.svg';
import './style.css';

const SidebarContent = (props) => {
  const styles = StyleSheet.create({
    header: {
      width: '100%',
      paddingBottom: 0,
      background: '#212529',
      color: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      fontFamily: 'VennSemiEx, Helvetica, sans-serif',
    },
    menuGroup: {},
    menuGroupTitle: {
      padding: '10 15',
      fontSize: 13,
      userSelect: 'none',
      cursor: 'default',
      // borderBottom: '1px solid #e8e8e8',
    },
    menuItem: {
      padding: 10,
      backgroundColor: '#FFFFFF',
      // borderBottom: '1px solid #e8e8e8',
      cursor: 'pointer',
      transition: 'background-color 0.2s ease-in-out',
      ':hover': {
        backgroundColor: '#C4C4C4',
      },
      userSelect: 'none',
      display: 'flex',
      alignItems: 'center',
      color: '#212529',
      fontFamily: 'VennSemiEx, Helvetica, sans-serif',
      textDecoration: 'none',
    },
    menuItemNested: {
      paddingLeft: 10,
    },
  });

  const _parseItem = (item, index, nested = false) => {
    if (item.hasAccess() && !item.hideInSidebar) {
      return (
        <Link
          className={css(styles.menuItem, nested ? styles.menuItemNested : null)}
          onClick={() => handlePage()}
          to={item.path}
        >
          <Icon size={16} icon={item.icon} color={item.color} style={{ marginRight: 8 }} />
          {item.name}
        </Link>
      );
    }
  };

  const getItems = () => {
    const modules = [];
    moduleGroups.map((m, i) => {
      if (m.type === 'group') {
        const subModules = [];
        m.modules.map((module, n) => {
          const item = _parseItem(module, `${i}_${n}`, true);
          if (item) {
            subModules.push(item);
          }
        });
        if (subModules.length > 0) {
          modules.push(
            <div className={css(styles.menuGroup)} key={i}>
              <div className={css(styles.menuGroupTitle)}>{m.name}</div>
              {subModules}
            </div>
          );
        }
      } else {
        modules.push(_parseItem(m.module, i));
      }
    });

    return modules;
  };

  const handlePage = (page) => {
    props.handleSideBarChange(false);
  };

  return (
    <div>
      <div className={css(styles.header)}>
        <div className="nectar-brand">
          <img className="nectar-brand__logo" src={logo} alt="Nectar 360" />
        </div>
        Igloo
      </div>
      <div className={css(styles.menuGroup)}>
        <Link className={css(styles.menuItem)} to="/" onClick={() => handlePage()}>
          <Icon size={16} icon={<MdHome />} style={{ marginRight: 8 }} /> Home
        </Link>
      </div>
      {getItems()}
    </div>
  );
};

SidebarContent.propTypes = {
  handleSideBarChange: PropTypes.func.isRequired,
  user: PropTypes.shape({
    access_id: PropTypes.number,
  }),
};

export default SidebarContent;
