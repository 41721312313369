import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';

// Legacy Component

class Tile extends React.Component {
  getStyles = (reverse) => {
    return {
      tile: {
        width: 500,
        height: 250,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        boxShadow: '0 0 5px rgba(25, 25, 25, 0.25)',
        flexShrink: 0,
        marginLeft: 20,
        marginRight: 20,
      },
      title: {
        borderBottom: reverse ? 0 : '1px solid #D5DCE4',
        borderTop: reverse ? '1px solid #D5DCE4' : 0,
        width: 'calc(100% - 40px)',
        height: 30,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: '1em',
      },
    };
  };

  render() {
    const { style, contentTitle, content, tileTitle, reverse, tileId, titleId, ...remaining } = this.props;
    const { tile, title } = style;
    const styles = {
      tile: { ...this.getStyles(reverse).tile, ...tile },
      title: { ...this.getStyles(reverse).title, ...title },
    };
    if (reverse) {
      return (
        <div id={tileId} style={styles.tile} {...remaining}>
          {content}
          {tileTitle && (
            <div id={titleId} style={styles.title}>
              {contentTitle}
            </div>
          )}
        </div>
      );
    }
    return (
      <div id={tileId} style={styles.tile} {...remaining}>
        {tileTitle && (
          <div id={titleId} style={styles.title}>
            {contentTitle}
          </div>
        )}
        {content}
      </div>
    );
  }
}

Tile.propTypes = {
  style: PropTypes.object,
  contentTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.node,
  tileTitle: PropTypes.bool,
  reverse: PropTypes.bool,
  tileId: PropTypes.string,
  titleId: PropTypes.string,
};

Tile.defaultProps = {
  tileTitle: true,
  reverse: false,
  style: {
    tile: {},
    title: {},
  },
};

export default Tile;
