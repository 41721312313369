import ActionTypes from '../../../../constants/ActionTypes';
import { syncToServer, syncToServerDebounced } from './index';

const {
  targetedComms: {
    smartcounts: { campaign },
  },
} = ActionTypes;

export const updateBarcodeForCell = (cell, value) =>
  syncToServerDebounced([{ type: campaign.UPDATE_BARCODE, update: { [cell]: value } }]);
