import React from 'react';
import { connect } from 'react-redux';
import PermissionCheck from '../../../../../utils/PermissionCheck';

export default (WrappedComponent) => {
  class Wrapper extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
      const access = { execute: PermissionCheck('TargetedComms_Smartcounts_Campaign_Execute') };
      return <WrappedComponent SCAccess={access} {...this.props} />;
    }
  }

  const mapStateToProps = ({ user }) => ({ user });
  return connect(mapStateToProps)(Wrapper);
};
