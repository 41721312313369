import React from 'react';
import { connect } from 'react-redux';
import ContentHeader from '../../../../lib/ContentHeader/index';
import Actions from '../../../../../actions';

class SCNewCount4 extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return nextProps.activeStep === 3 || this.props.activeStep === 3 || nextProps.queue !== this.props.queue;
  }

  componentWillReceiveProps(nextProps, nextState) {
    this.queueData = this.getQueueData(nextProps);

    if (nextProps.activeStep === 3 && this.props.activeStep !== 3) {
      this.props.actions.newcount.addCompleted(3);
    }
    if (nextProps.newcount.completed.includes(3) && this.queueData && this.queueData.status === 'running') {
      this.props.actions.newcount.deleteCompleted(3);
    }
  }

  getQueueData = (props) => {
    return props.queue.data.filter((o) => o.id === props.newcount.jobDetails.id)[0];
  };

  render() {
    const SensitivityNames = {
      BWS: 'Beers, Wines and Spirits',
      BABY: 'Baby',
      PET_DOG: 'Pets - Dog',
      PET_CAT: 'Pets - Cat',
      INCONTINENCE: 'Incontinence',
      FEMCARE: 'Feminine Care',
      SMOKING: 'Smoking Cessation',
    };
    return (
      <div style={{ width: '100%', userSelect: 'none', cursor: 'default' }}>
        <ContentHeader header="Summary of Count" />
        <table>
          <tbody>
            <tr>
              <td style={{ fontWeight: 'bold', paddingRight: 10 }}>Aisle Name</td>
              <td style={{ padding: 5 }}>{this.props.newcount.aisle}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold', paddingRight: 10 }}>Aisle Rule</td>
              <td style={{ padding: 5 }}>{this.props.newcount.aisle_rule === 1 ? 26 : 52} weeks</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold', paddingRight: 10 }}>Sensitivity</td>
              <td style={{ padding: 5 }}>{SensitivityNames[this.props.newcount.aisle_sensitivity] || 'None'}</td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold', paddingRight: 10 }}>Store List</td>
              <td style={{ padding: 5 }}>
                {this.props.newcount.stores ? (
                  <div>
                    {this.props.newcount.stores.length} store
                    {this.props.newcount.stores.length > 1 && 's'}
                  </div>
                ) : (
                  'None'
                )}
              </td>
            </tr>
            <tr>
              <td style={{ fontWeight: 'bold', paddingRight: 10, verticalAlign: 'top' }}>SKUs</td>
              <td style={{ padding: 5 }}>
                Redemption: {this.props.newcount.redemptionSKUs.length}
                <br />
                Brand: {this.props.newcount.checkedSKUs.brand.length}
                <br />
                Competitor: {this.props.newcount.checkedSKUs.competitor.length}
                <br />
                Overlay: {this.props.newcount.checkedSKUs.overlay.length}
                <br />
                Additional Overlay:{' '}
                {(this.props.newcount.additionalSKUs && this.props.newcount.additionalSKUs.length) || 0}
              </td>
            </tr>
            {this.queueData && this.queueData.payload.name && (
              <tr>
                <td style={{ fontWeight: 'bold', paddingRight: 10, verticalAlign: 'top' }}>Count Name</td>
                <td>{this.queueData.payload.name}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    queue,
    TargetedComms: {
      Smartcounts: { count },
    },
  } = state;
  return {
    newcount: count,
    queue,
  };
};

const {
  targetedComms: {
    smartcounts: { newcount },
  },
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    newcount: {
      reset: () => dispatch(newcount.reset()),
      addCompleted: (index) => dispatch(newcount.addCompleted(index)),
      deleteCompleted: (index) => dispatch(newcount.deleteCompleted(index)),
      updateStores: (data) => dispatch(newcount.updateStores(data)),
      restore: (id) => dispatch(newcount.restoreCount(id)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SCNewCount4);
