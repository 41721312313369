import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import jss from '../../../utils/jss';

import { colorNames } from '../../../constants/colors';

const styles = {
  box: {
    display: 'flex',
    flexFlow: 'column',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',
    transition: 'border 0.1s linear',
  },
  box__highlight: {
    borderColor: (data) => data.box__highlight.borderColor,
  },
  boxHeader: {
    height: 40,
    boxSizing: 'border-box',
    fontSize: '11pt',
    padding: 10,
    backgroundColor: colorNames.lightGrey,
    fontWeight: 'bold',
    userSelect: 'none',
    textAlign: 'left',
  },
  boxContent: {
    padding: 10,
    position: 'relative',
  },
  iconStyle: {
    float: 'right',
    color: colorNames.grey,
  },
};

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, { meta: 'Card', classNamePrefix: 'Card-', link: true });
  }

  componentDidMount() {
    this.stylesheet.attach();
    this.stylesheet.update({
      box__highlight: {
        borderColor: this.props.highlightColor || colorNames.blue,
      },
    });
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  render() {
    const { classes } = this.stylesheet;
    const {
      header,
      children,
      onHeaderClick,
      contentStyle,
      icon,
      height,
      style,
      className,
      vcenter,
      highlight,
      headerColor,
      ...props
    } = this.props;
    return (
      <div
        style={{ height, ...style }}
        className={jss.combine(classes.box, highlight && classes.box__highlight, className)}
        {...props}
      >
        <Paper style={{ flex: 1 }}>
          {header && (
            <div
              className={classes.boxHeader}
              style={{
                ...(typeof headerColor === 'object'
                  ? { backgroundColor: headerColor.background, color: headerColor.text }
                  : { backgroundColor: headerColor }),
              }}
              onClick={onHeaderClick}
            >
              {header}
              {icon && React.cloneElement(icon, { className: classes.iconStyle, size: 24 })}
            </div>
          )}
          <div
            className={classes.boxContent}
            style={{ ...(height && { maxHeight: height - 40, overflowY: 'auto' }), ...contentStyle }}
          >
            {children}
          </div>
        </Paper>
      </div>
    );
  }
}

Card.propTypes = {
  header: PropTypes.string,
  onHeaderClick: PropTypes.func,
  icon: PropTypes.element,
  height: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  vcenter: PropTypes.bool,
  highlight: PropTypes.bool,
  highlightColor: PropTypes.string,
};

export default Card;
