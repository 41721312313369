import React from 'react';
import { Route } from 'react-router';
import { modules } from '../../../constants/moduleInfo';
import EvaluationStatusView from '../../views/EvaluationStatus';
import { HasAccess } from './has-access';

const { evaulationStatus } = modules;

export const EvaluationStatus = (
  <Route
    path={evaulationStatus.path}
    element={
      <HasAccess accessFn={evaulationStatus.hasAccess}>
        <EvaluationStatusView />
      </HasAccess>
    }
  />
);
