import React from 'react';
import { connect } from 'react-redux';
import { Paper, TextField } from '@material-ui/core';
import Actions from '../../../../../actions';
import { colorNames } from '../../../../../constants/colors';
import getCampaignObject, { getProvisionalBase } from './selectors/getCampaignObject';
import { returnJSON } from '../../../../../utils/async';

const {
  targetedComms: {
    smartcounts: { campaign },
  },
  loading,
} = Actions;

class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      base_ids: [],
    };
  }

  componentDidMount() {
    if (this.props.campaign.stage == 0) {
      this.props.actions.loading.toggle({ show: true, text: 'Getting existing base ids...' });
      returnJSON('/api/targeted-comms/smartcounts/campaign/base_ids', (err, results) => {
        if (err) {
          this.props.actions.loading.toggle({ show: false });
          throw new Error(`error with getting existing base ids: ${err}`);
        }
        this.props.actions.loading.toggle({ show: false });
        this.setState({ base_ids: results });
      });
    }
  }

  checkComplete = () => {
    if (
      (this.props.campaign.stage === 0 &&
        this.props.campaign.base_id &&
        !this.state.base_ids.includes(this.props.campaign.base_id)) ||
      ((this.props.campaign.stage === 1 || this.props.campaign.stage === 2) && this.props.campaign.rejectionComment)
    ) {
      this.props.modal.updateButton(1, { disabled: false });
    } else {
      this.props.modal.updateButton(1, { disabled: true });
    }
  };

  baseIDChange = (e) => {
    const v = e.target.value;
    this.props.actions.campaign.updateProvisionalBase(v).then(() => this.checkComplete());
  };

  render() {
    const {
      campaign: { base_id },
    } = this.props;
    return (
      <Paper style={{ padding: 10 }}>
        {this.props.campaign.stage === 0 ? (
          <div>
            <TextField
              name="baseID"
              label="Base Asset ID"
              type="number"
              inputProps={{
                maxlength: 5,
              }}
              value={base_id || ''}
              fullWidth
              onChange={this.baseIDChange}
            />
            <span
              style={{
                fontSize: '10pt',
                color:
                  !base_id || base_id === '' || this.state.base_ids.includes(this.props.campaign.base_id)
                    ? colorNames.red
                    : colorNames.grey,
              }}
            >
              {(!base_id || base_id === '') &&
                'A Base Asset ID is required before submitting the campaign for approval.'}
              {this.state.base_ids.includes(this.props.campaign.base_id) &&
                'Base Asset ID is already in use by another count'}
            </span>
          </div>
        ) : (
          <TextField
            name="comment"
            multiline
            rows={5}
            fullWidth
            value={this.props.campaign.rejectionComment || ''}
            onChange={(e) => this.props.actions.campaign.updateComment(e.target.value).then(() => this.checkComplete())}
          />
        )}
      </Paper>
    );
  }
}

export default connect(
  (state) => ({
    campaign: {
      base_id: getProvisionalBase(state),
      stage: getCampaignObject(state).stage,
      rejectionComment: getCampaignObject(state).rejectionComment,
    },
  }),
  (dispatch) => ({
    actions: {
      campaign: {
        updateComment: (comment) => dispatch(campaign.updateRejectionComment(comment)),
        updateBaseID: (base_id) => dispatch(campaign.updateBaseID(base_id)),
        getExistingBaseIds: (ids) => dispatch(campaign.getExistingBaseIds(ids)),
        updateProvisionalBase: (base) => dispatch(campaign.updateProvisionalBase(base)),
      },
      loading: {
        toggle: (data) => dispatch(loading.toggle(data)),
      },
    },
  })
)(Comment);
