export default {
  outer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
  },
  content: {
    width: '90%',
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 30,
    borderBottom: '1px solid rgba(70, 71, 73, 0.2)',
  },
  heading: {
    width: '100%',
    fontFamily: "'Roboto', sans-serif",
    fontStyle: 'italic',
    fontSize: '0.9em',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '10px 0px',
  },
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginBottom: 10,
  },
  item: {
    cursor: 'pointer',
  },
  run: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    height: 100,
  },
};
