import { createSelectorCreator } from 'reselect';
import { forEach, memoize } from 'lodash';
import {
  getAvailableVolumes,
  getPrintVolumes,
  getRedemptionRates,
  getRequiredFlags,
  getCellOffers,
  getRSP,
  getAisle,
} from './getCells';

const createDeepEqualSelector = createSelectorCreator(memoize, (...args) =>
  args.reduce((acc, val) => `${acc}-${JSON.stringify(val)}`, '')
);

const printVolumes = createDeepEqualSelector(
  [getRequiredFlags, getAvailableVolumes, getPrintVolumes],
  (required, available, prints) => {
    const errors = {};
    forEach(available, (vols, code) => {
      const print = prints[code];
      if (print && required[code].required) {
        if (
          code !== 'transactional' &&
          ((print.method === 'manual' && print.value > vols.standard) ||
            (print.method === 'all' && print.value > vols.standard) ||
            (print.method === 'overlay' && print.value > vols.overlay))
        ) {
          errors[code] = 'Prints cannot exceed available.';
        }

        if (print.value === 0) {
          errors[code] = 'Prints cannot be 0 when cell is required.';
        }

        if (/_a$/.test(code) && print.value < 5000) {
          errors[code] = 'Prints must be 5,000 or more';
        }
      }
    });
    return errors;
  }
);

const redemptionRate = createDeepEqualSelector([getRedemptionRates, getRequiredFlags], (rates, required) => {
  const errors = {};

  forEach(rates, (rate, code) => {
    if (required[code] && required[code].required && rate.adjusted) {
      if (isNaN(rate.adjusted)) {
        errors[code] = 'Rate must be a number.';
      } else if (rate.adjusted > 100) {
        errors[code] = 'Rate cannot exceed 100%.';
      } else if (rate.adjusted <= 0) {
        errors[code] = 'Rate must be greater than 0%.';
      }
    } else if (required[code] && required[code].required && rate.original <= 0) {
      errors[code] = 'Manual Redemption Rate required.';
    }
  });

  return errors;
});

const offers = createDeepEqualSelector(
  [getCellOffers, getRequiredFlags, getRSP, getAisle],
  (offers, required, rsp, aisle) => {
    const errors = {};
    forEach(offers, (offer, code) => {
      if (required[code].required) {
        if (!offer.type) {
          errors[code] = {
            ...errors[code],
            type: 'Select an offer type',
          };
        }

        if (!offer.value) {
          errors[code] = {
            ...errors[code],
            value: 'Select an offer value',
          };
        }

        if (offer.type === 'points' && offer.value % 1 !== 0) {
          errors[code] = {
            ...errors[code],
            value: 'Points value must be whole.',
          };
        }

        if (offer.value < 0.01) {
          errors[code] = {
            ...errors[code],
            value: 'Offer must be greater than 0.',
          };
        }

        if (/_b$/.test(code)) {
          const offerA = offers[code.replace(/_b$/, '_a')];
          if (offer.value === offerA.value && offer.type === offerA.type) {
            errors[code] = {
              ...errors[code],
              dupe: 'Offer for B must differ from A.',
            };
          }
        }

        if (aisle.sensitivity === 'BWS') {
          if (offer.type === 'points' && offer.value > rsp * 200 * 0.2) {
            errors[code] = {
              ...errors[code],
              value: `Maximum offer for BWS is 20% of RSP - ${rsp * 200 * 0.2} points.`,
            };
          } else if (offer.type === 'money' && offer.value > rsp * 0.2) {
            errors[code] = {
              ...errors[code],
              value: `Maximum offer for BWS is 20% of RSP - ${(rsp * 0.2).formatCurrency()}.`,
            };
          }
        } else if (offer.type === 'points') {
          if (offer.value < rsp * 200 * 0.2) {
            errors[code] = {
              ...errors[code],
              value: `Minimum offer is 20% of the RSP – ${Math.ceil(rsp * 200 * 0.2)} points.`,
            };
          } else if (offer.value > rsp * 200) {
            errors[code] = {
              ...errors[code],
              value: `Offer cannot exceed 100% of the RSP - ${rsp * 200} points.`,
            };
          }
        } else if (offer.type === 'money') {
          if (offer.value < (rsp * 0.2).toFixed(2)) {
            errors[code] = {
              ...errors[code],
              value: `Minimum offer is 20% of RSP - ${(rsp * 0.2).formatCurrency()}.`,
            };
          } else if (offer.value > rsp) {
            errors[code] = {
              ...errors[code],
              value: `Offer cannot exceed 100% of the RSP - ${rsp.formatCurrency()}.`,
            };
          }
        }
      }
    });
    return errors;
  }
);

export default createDeepEqualSelector([printVolumes, redemptionRate, offers], (prints, redemptionRates, offers) => {
  const total = Object.keys(prints).length + Object.keys(redemptionRates).length + Object.keys(offers).length;
  return {
    prints,
    redemptionRates,
    offers,
    total,
  };
});
