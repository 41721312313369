import React from 'react';
import { MdTimeline, MdArrowBack, MdFileDownload, MdInfoOutline, MdHelpOutline } from 'react-icons/md';
import PropTypes from 'prop-types';
import Tile from '../../../../../lib/Tiles/tile';
import { formatProper, downloadCSV } from '../../../../../../utils/utils';
import ProgressArc from '../../../../../lib/Charts/ProgressArc/ProgressArc';
import { ResultChart } from './Charts/ResultChart';
import { calcSummaries, csvSummaries } from '../../lib/utils';
import { InfoDialog } from '../../../../../lib/Dialog/Dialog';
import FilterInfo from '../../lib/FilterInfo';

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      summary: calcSummaries(this.props.data),
      widgetData: null,
      order: ['KNOWN_SPEND', 'UNITS', 'TOTAL_CUSTS', 'NEW_CUSTS', 'GCS_CUSTS', 'VISITS'],
    };
  }

  static propTypes = {
    data: PropTypes.array.isRequired,
    funcs: PropTypes.object.isRequired,
    selectedFilters: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className="grid--results">
        <Tile
          style={{
            tile: {
              width: '100%',
              height: 950,
              flexShrink: 0,
              alignItems: 'center',
            },
            title: {
              height: 50,
              fontSize: '1.5em',
            },
          }}
          contentTitle={
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <MdTimeline height="25px" width="25px" />
                <span style={{ paddingLeft: 10 }}>Integrated Results</span>
              </div>
              <div style={{ display: 'flex' }}>
                <div
                  title="Information"
                  style={{
                    cursor: 'pointer',
                    width: 30,
                    heigh: 30,
                    display: 'flex',
                    alignConent: 'centre',
                    justifyContent: 'centre',
                  }}
                  onClick={() => {
                    this.info.handleOpen();
                  }}
                >
                  <MdInfoOutline height="25px" width="25px" />
                </div>
                <div
                  title="Download results"
                  style={{
                    cursor: 'pointer',
                    width: 30,
                    heigh: 30,
                    display: 'flex',
                    alignConent: 'centre',
                    justifyContent: 'centre',
                  }}
                  onClick={() => {
                    const json = csvSummaries(this.props.data);
                    downloadCSV(json, 'results.csv');
                  }}
                >
                  <MdFileDownload height="25px" width="25px" />
                </div>
                <div
                  title="Back to options"
                  style={{
                    cursor: 'pointer',
                    width: 30,
                    heigh: 30,
                    display: 'flex',
                    alignConent: 'centre',
                    justifyContent: 'centre',
                  }}
                  onClick={() => this.props.funcs.returnToOptions()}
                >
                  <MdArrowBack height="25px" width="25px" />
                </div>
              </div>
            </div>
          }
          content={
            <div className="tile--reuslts">
              <div className="summary title">Summary</div>
              <div style={{ height: 50 }}>Uplifts base on {this.state.summary.campaigns.All.length} campaigns.</div>
              <div className="summary">
                {this.state.order.map((value, key) => {
                  const summary = this.state.summary.uplifts.summary_results[value];
                  if (!summary) {
                    return null;
                  }
                  let percentage;
                  const { perc } = summary;
                  if (perc > 1) {
                    percentage = 1;
                  } else if (perc < 0) {
                    percentage = 0;
                  } else {
                    percentage = perc;
                  }
                  return (
                    <ProgressArc
                      key={key}
                      data={{
                        value: percentage,
                        text: formatProper(value, ['GCS']),
                      }}
                      arcID={`summary--${key}`}
                      height={140}
                      width={140}
                      pathStyle={{
                        width: 8,
                        fill: '#BA6A5D',
                      }}
                      valueClass="summary--tiles--value"
                      labelClass="summary--tiles--label"
                    />
                  );
                })}
              </div>
              <ResultChart data={this.state.summary} />
            </div>
          }
        />
        <InfoDialog
          dialogContent={<FilterInfo filters={this.props.selectedFilters} />}
          ref={(child) => {
            this.info = child;
          }}
        />
      </div>
    );
  }
}

export default Results;
