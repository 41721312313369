import { find, reduce } from 'lodash';
import ActionTypes from '../../../constants/ActionTypes/index';
import SCAisleData from '../../../components/views/TargetedComms/Smartcounts/NewCount/lib/SCAisleData';
import { returnJSON } from '../../../utils/async';
import * as loading from '../../loading';

const {
  targetedComms: {
    smartcounts: { newcount },
  },
  general,
} = ActionTypes;

const reset = () => {
  return { type: newcount.RESET };
};

const addCompleted = (index) => {
  return { type: newcount.ADD_COMPLETED, index };
};

const deleteCompleted = (index) => {
  return { type: newcount.DELETE_COMPLETED, index };
};

const resetFrom = (index) => {
  return { type: newcount.RESET_FROM, index };
};

const receivedProductData = (data) => {
  return (dispatch) => {
    dispatch(updateProductDetails(data.productDetails));
    dispatch(updateAisleSensitivity(data.aisle_sensitivity));
    dispatch(updateAisleRule(data.aisle_rule));
  };
};

const getExistingCountNames = () => {
  return (dispatch) =>
    returnJSON('/api/targeted-comms/smartcounts/counts/names', (err, results) => {
      if (!err) {
        dispatch({ type: newcount.EXISTING_NAMES, names: results });
      }
    });
};

const restoreCount = (jobid, cloned) => {
  return (dispatch, getState) => {
    dispatch(loading.toggle({ show: true, text: 'Loading count data...' }));
    const job = find(getState().queue.data, (o) => {
      return o.id === jobid;
    });
    const { payload } = job;
    if (payload) {
      SCAisleData({ skus: payload.skus.redemption, aisle: payload.aisle, brand: payload.brand }).then((results) => {
        dispatch([
          {
            type: newcount.RESTORE,
            payload,
            aisleData: results,
            job,
            cloned,
          },
          loading.toggle({ show: false }),
        ]);
      });
    }
  };
};

const updateData = (data) => {
  return {
    type: newcount.CHANGE_DATA,
    data,
  };
};

const updateRedemptionSKUs = (s) => {
  return (dispatch) =>
    Promise.resolve(
      dispatch({
        type: newcount.CHANGE_REDEMPTION_SKUS,
        skus: s,
      })
    );
};

const updateAdditionalSKUs = (s) => {
  return {
    type: newcount.CHANGE_ADDITIONAL_SKUS,
    skus: s,
  };
};

const updateAisle = (aisle) => {
  return {
    type: newcount.CHANGE_AISLE,
    aisle,
  };
};

const updateBrand = (data) => {
  if (typeof data === 'object') {
    data = reduce(
      data,
      (array, value) => {
        if (!array.includes(value)) {
          array.push(value);
        }
        return array;
      },
      []
    );
  }

  return {
    type: newcount.CHANGE_BRAND,
    data,
  };
};

const updateProductDetails = (data) => {
  return {
    type: newcount.CHANGE_SKU_DETAILS,
    data,
  };
};

const updateOverlay = (data) => {
  return {
    type: newcount.CHANGE_OVERLAY,
    data,
  };
};

const updateStores = (data) => {
  return {
    type: newcount.CHANGE_STORES,
    data,
  };
};

const updateCheckedSKUs = (data) => {
  return {
    type: newcount.CHECKED_SKUS,
    data,
  };
};

const updateName = (name) => {
  return {
    type: newcount.CHANGE_NAME,
    name,
  };
};

const updateAisleRule = (rule) => {
  return {
    type: newcount.CHANGE_AISLE_RULE,
    aisle_rule: rule,
  };
};

const updateAisleSensitivity = (sense) => {
  return {
    type: newcount.CHANGE_AISLE_SENSE,
    aisle_sensitivity: sense,
  };
};

export default {
  reset,
  addCompleted,
  deleteCompleted,
  restoreCount,
  resetFrom,
  updateData,
  updateAisle,
  updateBrand,
  updateOverlay,
  updateProductDetails,
  updateRedemptionSKUs,
  updateAdditionalSKUs,
  updateStores,
  updateCheckedSKUs,
  updateName,
  updateAisleRule,
  updateAisleSensitivity,
  receivedProductData,
  getExistingCountNames,
};
