import General from './General';
import Smartcounts from './TargetedComms/Smartcounts';
import Planning from './TargetedComms/Planning';
import error from './Error';
import Queue from './Queue';

export default {
  error,
  general: General,
  targetedComms: {
    smartcounts: Smartcounts,
    planning: Planning,
    SAVED_TARGETED_COMMS: 'SAVED_TARGETED_COMMS',
  },
  queue: Queue,
};
