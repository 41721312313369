import React from 'react';
import _ from 'lodash';
import { MdArrowBack, MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import { Button, FormControlLabel } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import RadioButton from '../../../../../../lib/Radio/Radio';
import DropDown from '../../../../../../lib/DropDown/DropDown';
import ButtonGroup from '../../../../../../lib/Buttons/ButtonGroup';
import HoriztonalBar from '../../../../../../lib/Charts/HorizontalBar/HorizontalBar';
import Tile from '../../../../../../lib/Tiles/tile';
import { formatProper, copyToClipboard } from '../../../../../../../utils/utils';
import muiTheme from '../../../../../../../constants/muiTheme';

const returnChart = function () {
  let data = this.state.upliftData[this.state.selectedType][this.state.selectedMetric];
  if (this.state.selectedRadio === 0) {
    if (this.state.selectedType === 'Channel') {
      data = data.perc[this.state.selectedChannelType];
    } else {
      data = data.perc;
    }
  } else if (this.state.selectedType === 'Channel') {
    data = data.custs[this.state.selectedChannelType];
  } else {
    data = data.custs;
  }
  const sideBarUplifts = (d) => {
    if (this.state.selectedRadio === 0) {
      return `${(d.value * 100).toFixed(2)}%`;
    }
    if (this.state.selectedMetric === 'KNOWN_SPEND') {
      return `£${d.value.toFixed(2)}`;
    }
    return d.value.toFixed(2);
  };
  const sideBarCampaignCount = (d) => {
    return d.campaigns.length;
  };
  const tickFormat = (d) => {
    if (this.state.selectedRadio === 0) {
      return parseInt(d.toFixed(2) * 100);
    }
    return parseInt(d.toFixed(2));
  };
  const channelType = (
    <div style={{ width: '80%', height: 50, display: 'flex' }}>
      <ButtonGroup
        data={['Single', 'Multiple', 'All']}
        selectedRadio={this.state.selectedChannelType}
        selectRadio={(selectedChannelType) => this.setState({ selectedChannelType })}
      />
    </div>
  );
  const selectedTypeLabels = ['Channel', 'Category', 'Super_Category', 'Manufacturer', 'Brand'];
  const selectedMetricLabels = [
    'KNOWN_SPEND',
    'UNITS',
    'TOTAL_CUSTS',
    'NEW_CUSTS',
    'GCS_CUSTS',
    'VISITS',
    'FOP',
    'SPC',
    'UPV',
    'SPV',
    'PEN',
    'SOW',
  ];
  return (
    <div className="chart--container integrated">
      <div className="top--bar">
        <DropDown
          handleChange={(selectedType) => {
            const selectedRadio = 0;
            this.setState({ selectedType, selectedRadio });
          }}
          labels={selectedTypeLabels}
          style={{ height: 50, width: 200 }}
          labelstyle={{ lineHeight: '50px' }}
          underlinestyle={{ bottom: 10 }}
          selectedValue={selectedTypeLabels.indexOf(this.state.selectedType)}
        />
        <RadioButton
          data={[{ title: '% uplift overall' }, { title: 'uplift per 1000 customers' }]}
          handleChange={(event) => this.setState({ selectedRadio: parseInt(event.target.value) })}
          value={this.state.selectedRadio}
          radioName="result--chart"
          className="radio--group"
          labelKey="title"
          buttonStyle={{
            paddingBottom: 0,
            width: 250,
          }}
          labelstyle={{ width: '100%' }}
          disabled={['KNOWN_SPEND', 'UNITS'].includes(this.state.selectedMetric) ? null : [1]}
        />
        <DropDown
          handleChange={(selectedMetric) => {
            const selectedRadio = 0;
            this.setState({ selectedMetric, selectedRadio });
          }}
          labels={selectedMetricLabels}
          ignoreFormat={['GCS', 'JS', 'FOP', 'SPC', 'UPV', 'SPV', 'PEN', 'SOW']}
          style={{ height: 50, width: 200 }}
          labelstyle={{ lineHeight: '50px' }}
          underlinestyle={{ bottom: 10 }}
          selectedValue={selectedMetricLabels.indexOf(this.state.selectedMetric)}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <HoriztonalBar
          data={data}
          margin={{ top: 20, right: 200, bottom: 30, left: 300 }}
          chartHeight={400}
          chartWidth={1120}
          chartID="uplift--chart"
          negative
          domain={this.domain(data)}
          ticks={tickFormat}
          xAttr="value"
          yAttr="type"
          tooltipHTML={(d) => `${(d.value * 100).toFixed(2)}%` + `<br>${d.type}`}
          chartStyle={{ overflow: 'visibile' }}
          sideBar
          sideBarData={[data, data]}
          sideBarText={[sideBarUplifts, sideBarCampaignCount]}
          handleChartClick={this.handleChartClick}
        />
      </div>
      {this.state.selectedType === 'Channel' ? channelType : null}
    </div>
  );
};

const returnInfo = function (d) {
  const metrics = findMetrics(
    d.type,
    this.props.data.uplifts[this.state.selectedType],
    this.state.selectedType,
    this.state.order
  );
  const campaigns = metrics[0][0].campaigns.length === 0 ? metrics[0][0].campaigns : metrics[1][0].campaigns;
  const ignoreFormat = ['GCS', 'FOP', 'SPC', 'UPV', 'SPC', 'UPV', 'SPV', 'PEN', 'SOW'];
  return (
    <Tile
      style={{
        tile: {
          width: 1120,
          height: 510,
          flexShrink: 0,
          alignItems: 'center',
          marginTop: 30,
        },
        title: {
          height: 50,
        },
      }}
      contentTitle={
        <div
          style={{
            width: '100%',
            display: 'flex',
          }}
        >
          <div className="integrated--info--topbar type">
            <span>{d.type}</span>
          </div>
          <div className="integrated--info--topbar back">
            <div
              title="Back to chart"
              style={{
                cursor: 'pointer',
                width: 30,
                height: 30,
                display: 'flex',
                alignConent: 'centre',
                justifyContent: 'centre',
              }}
              onClick={() => this.handleBackToChart()}
            >
              <MdArrowBack height="25px" width="25px" />
            </div>
          </div>
        </div>
      }
      content={
        <div className="integrated--info">
          {metrics.map((item, key) => {
            return (
              <div key={key} className="integrated--info--widget">
                <div className="integrated--info--widget--title">{formatProper(item[0].metric, ignoreFormat)}</div>
                <div className="integrated--info--widget--result">
                  {item.map((i, k) => {
                    return (
                      <div key={k} className="integrated--info--widget--result--value">
                        <span>{formatValue(i)}</span>
                        {returnArrow(i.value)}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className="integrated--info--widget">
            <div className="integrated--info--widget--title">Master Campaign ID's</div>
            <div className="integrated--info--widget--result">
              <MuiThemeProvider theme={muiTheme}>
                <Button variant="contained" onClick={() => copyToClipboard(campaigns)}>
                  Copy to clipboard
                </Button>
              </MuiThemeProvider>
            </div>
          </div>
        </div>
      }
    />
  );
};

const findMetrics = (filter, data, selection, order) => {
  const allMetrics = _.reduce(
    data,
    (r, v, k) => {
      r[k] = _.flatten(
        _.reduce(
          v,
          (result, item) => {
            result.push(_.filter(item, (i) => i.type === filter));
            return result;
          },
          []
        )
      );
      return r;
    },
    {}
  );
  return order.map((value) => {
    if (allMetrics[value].length === 0) {
      return [
        {
          campaigns: [],
          metric: 'GCS',
          result_type: null,
          Selection: selection,
          type: filter,
          value: 'N/A',
        },
      ];
    }
    return allMetrics[value];
  });
};

const formatValue = (item) => {
  if (item.result_type === 'perc') {
    return `${(item.value * 100).toFixed(2)}%`;
  }
  if (item.result_type !== null) {
    if (item.metric === 'KNOWN_SPEND') {
      return `£${item.value.toFixed(2)}`;
    }
    return item.value.toFixed(0);
  }
  return item.value;
};

const returnArrow = (value) => {
  if (value > 0) {
    return <MdArrowUpward height="25px" width="25px" fill="#AFC576" />;
  }
  if (value !== 'N/A') {
    return <MdArrowDownward height="25px" width="25px" fill="#BA6A5D" />;
  }
};

export { returnChart, returnInfo };
