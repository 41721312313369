import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router';
import Actions from '../../../../actions';
import { Content } from '../../../lib/Content';
import Stages from './Landing/lib/Stages';
import CampaignList from './Landing/CampaignList';
import ExecutionList from './Landing/ExecutionList';
import Banner from '../../../lib/Banner';
import Transition from '../../../lib/Transition';
import jss from '../../../../utils/jss';
import Spinner from '../../../lib/Loading/Spinner';
import { colorNames } from '../../../../constants/colors';
import PermissionCheck from '../../../../utils/PermissionCheck';
import { returnJSON } from '../../../../utils/async';
import { modules } from '../../../../constants/moduleInfo';

const styles = {
  split: {
    display: 'flex',
  },
  splitCell: {
    flex: 1,
    alignItems: 'center',
    '&:last-child': {
      textAlign: 'right',
    },
  },
};

const Smartcounts = () => {
  const navigate = useNavigate();
  const list = useSelector((state) => state.TargetedComms.Smartcounts.list);

  const [views, setViews] = useState('all');
  const [status, setStatus] = useState(null);
  const [running, setRunning] = useState(false);
  let executionInterval;

  const dispatch = useDispatch();

  const stylesheet = jss
    .createStyleSheet(styles, {
      meta: 'Smartcounts',
      classNamePrefix: 'Smartcounts-',
    })
    .attach();

  const { classes } = stylesheet;

  useEffect(() => {
    dispatch(Actions.targetedComms.smartcounts.list.toggleLock());
    dispatch(Actions.targetedComms.smartcounts.list.requestList(list.start, list.end, navigate));
    return () => {
      dispatch(Actions.targetedComms.smartcounts.list.clear());
      stylesheet.detach();
    };
  }, []);

  useEffect(() => {
    if (list.locked) setRunning(true);
    else setRunning(false);
  }, [list.locked]);

  useEffect(() => {
    if (running) {
      executionInterval = setInterval(() => {
        returnJSON('/api/targeted-comms/smartcounts/status', (err, res) => {
          if (err) return console.error(err);
          setStatus(res.status);
        });
      }, 1000);
    }
    return () => {
      clearInterval(executionInterval);
      dispatch(Actions.targetedComms.smartcounts.list.requestList(list.start, list.end, navigate));
    };
  }, [running, status]);

  const handleViewChange = (view) => {
    if (views === view) view = 'all';
    setViews(view);
  };

  return (
    <Content title="Smartcounts" fullBleed>
      <Banner show={list.locked} type="info">
        Smartcounts is currently locked. Some actions will be unavailable.
      </Banner>

      <div className={classes.split}>
        {PermissionCheck('TargetedComms_Smartcounts_Count_New') && (
          <div className={classes.splitCell}>
            <Button
              variant="contained"
              style={{ backgroundColor: colorNames.primaryButton, color: '#FFFFFF' }}
              startIcon={
                <span>
                  <MdAdd size={16} style={{ marginBottom: 3 }} />
                </span>
              }
              onClick={() => navigate(`${modules.smartCounts.path}/count/new`)}
            >
              New Count
            </Button>
          </div>
        )}
        <div className={classes.splitCell}>
          {status && (
            <span>
              <Spinner size={16} color={colorNames.blue} /> Status: {status}
            </span>
          )}
        </div>
      </div>
      <Stages currentView={views} onViewChange={handleViewChange} />
      <Transition>
        {views !== 'execution' ? (
          <CampaignList currentView={views} handleViewChange={handleViewChange} />
        ) : (
          <ExecutionList />
        )}
      </Transition>
    </Content>
  );
};

export default Smartcounts;
