import { reduce } from 'lodash';
import moment from 'moment';
import ActionTypes from '../../../constants/ActionTypes/index';

const {
  targetedComms: {
    smartcounts: { newcount: count },
  },
} = ActionTypes;

const handleCompleted = (state, index) => {
  const { completed } = state;
  if (!completed.includes(index)) {
    return [...completed, index];
  }
  return completed;
};

const handleRemoveCompleted = (state, index) => {
  const { completed } = state;
  return completed.filter((item) => item !== index);
};

const handleResetFrom = (state, index) => {
  const { completed } = state;
  this.setState({ completed: completed.filter((item) => item <= index) });
};

const defaultState = {
  name: null,
  existing_names: [],
  completed: [],
  redemptionSKUs: [],
  additionalSKUs: [],
  productDetails: {
    redemption: [],
    brand: [],
    competitor: [],
    overlay: [],
  },
  checkedSKUs: { brand: [], competitor: [], overlay: [] },
  aisle: null,
  brand: null,
  manufacturer: null,
  isManufacturer: false,
  stores: [],
  aisle_rule: null,
  aisle_sensitivity: null,
  jobDetails: {
    id: null,
    results: [],
  },
  locked: false,
  cloned: false,
};
const scnewcount = (state = defaultState, action) => {
  switch (action.type) {
    case count.RESET:
      const { existing_names } = state;
      return { ...defaultState, existing_names };

    case count.ADD_COMPLETED: {
      let c;
      if (typeof action.index === 'object') {
        c = action.index;
      } else {
        c = handleCompleted(state, action.index);
      }
      return {
        ...state,
        completed: c,
      };
    }
    case count.DELETE_COMPLETED: {
      return {
        ...state,
        completed: handleRemoveCompleted(state, action.index),
      };
    }
    case count.RESET_FROM: {
      switch (action.index) {
        case 0:
          return {
            ...defaultState,
            existing_names: state.existing_names,
            redemptionSKUs: state.redemptionSKUs,
            completed: [0],
          };
        default:
          return {
            ...state,
            existing_names: state.existing_names,
            completed: handleResetFrom(state, action.index),
          };
      }
    }

    case count.RESTORE: {
      const {
        payload: { aisle, brand, manufacturer, skus, stores, name, aisle_rule, aisle_sensitivity },
        job,
        cloned,
        aisleData,
      } = action;
      const brands = reduce(
        aisleData.brand,
        (array, value) => {
          if (skus.brand.includes(value.sku_no)) {
            array.push(value);
          }
          return array;
        },
        []
      );
      const competitors = reduce(
        aisleData.comp,
        (array, value) => {
          if (skus.competitor.includes(value.sku_no)) {
            array.push(value);
          }
          return array;
        },
        []
      );
      const overlay = reduce(
        aisleData.overlay,
        (array, value) => {
          if (skus.overlay.includes(value.sku_no)) {
            array.push(value);
          }
          return array;
        },
        []
      );

      const generateName = () => {
        if (cloned) {
          const num = moment().format('YYYYMMDDHHmm');
          return name.replace(/(_Clone_(\d*))?$/, `_Clone_${num}`);
        }
        return name;
      };
      const completedSteps = !cloned && job.status === 'completed' ? [0, 1, 2, 3, 4] : [0, 1, 2];
      return {
        ...state,
        completed: completedSteps,
        redemptionSKUs: skus.redemption,
        additionalSKUs: skus.additionalOverlay,
        checkedSKUs: {
          brand: brands,
          competitor: competitors,
          overlay,
        },
        productDetails: {
          redemption: aisleData.redemption,
          brand: aisleData.brand,
          competitor: aisleData.comp,
          overlay: aisleData.overlay,
        },
        jobDetails: {
          id: !cloned && job.id,
          results: !cloned && job.results,
        },
        locked: !cloned && job.results && job.results.length > 0,
        cloned,
        aisle,
        brand,
        manufacturer,
        stores: stores || [],
        name: generateName(),
        aisle_rule,
        aisle_sensitivity,
      };
    }

    case count.EXISTING_NAMES:
      return {
        ...state,
        existing_names: action.names,
      };

    case count.CHANGE_DATA:
      return {
        ...state,
        ...action.data,
      };

    case count.CHANGE_REDEMPTION_SKUS:
      return {
        ...state,
        redemptionSKUs: action.skus,
      };

    case count.CHANGE_ADDITIONAL_SKUS:
      return {
        ...state,
        additionalSKUs: action.skus,
      };

    case count.CHANGE_AISLE:
      return {
        ...state,
        aisle: action.aisle,
      };
    case count.CHANGE_SKU_DETAILS:
      return {
        ...state,
        productDetails: { ...state.productDetails, ...action.data },
      };
    case count.CHANGE_BRAND:
      return {
        ...state,
        brand: action.data,
      };
    case count.CHANGE_STORES:
      return {
        ...state,
        stores: action.data || [],
      };
    case count.CHECKED_SKUS:
      return {
        ...state,
        checkedSKUs: {
          ...state.checkedSKUs,
          ...action.data,
        },
      };
    case count.CHANGE_NAME:
      return {
        ...state,
        name: action.name,
      };
    case count.CHANGE_AISLE_RULE:
      return {
        ...state,
        aisle_rule: action.aisle_rule,
      };
    case count.CHANGE_AISLE_SENSE:
      return {
        ...state,
        aisle_sensitivity: action.aisle_sensitivity,
      };

    // Run the count
    case count.SAVED_ID: {
      return {
        ...state,
        jobDetails: {
          ...state.jobDetails,
          id: action.id,
          progress: action.progress,
          running: action.progress < 1,
        },
      };
    }
    case count.RUN_RESULT:
      return {
        ...state,
        completed: handleCompleted(state, 4),
        jobDetails: {
          ...state.jobDetails,
          results: action.data,
        },
      };
    default:
      return state;
  }
};

export default scnewcount;
