import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import Actions from '../../../../../actions';
import { Content } from '../../../../lib/Content';
import { Horizontal } from '../../../../lib/Scroll';
import jss from '../../../../../utils/jss';
import { colorNames } from '../../../../../constants/colors';
import ApprovalComment from './CB_Comment';

// Steps
import CB_Products from './CB_Products';
import CB_Timings from './CB_Timings';
import CB_Targeting from './CB_Targeting';
import CB_Coupon from './CB_Coupon';
import CB_Summary from './CB_Summary';
import CB_Execute from './CB_Execute';
import { returnJSON } from '../../../../../utils/async';
import getCampaignObject from './selectors/getCampaignObject';
import PermissionCheck from '../../../../../utils/PermissionCheck';
import { modules } from '../../../../../constants/moduleInfo';

const styles = {
  floatingBanner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: '10px 25px',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  floatingBanner__text: {
    display: 'flex',
    alignItems: 'center',
  },
  contentWithBanner: {
    height: 'calc(100% - 20px)',
    paddingTop: '60px !important',
  },
};

class CCNew extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, { meta: 'CCNew', classNamePrefix: 'CCNew-' });
    this.canExec = PermissionCheck('TargetedComms_Smartcounts_Campaign_Execute');
  }

  componentDidMount() {
    this.stylesheet.attach();
    const { countId: count_id, queueId: queue_id, navigate } = this.props;

    console.log('COUNT_ID', count_id);

    // TODO: HOW IS THIS CALLED?
    if (queue_id) {
      this.props.actions.loading.toggle({ show: true, text: 'Loading count data...' });
      returnJSON(`/api/targeted-comms/smartcounts/count/meta/queue-id/${queue_id}`, (err, result) => {
        if (err) {
          this.props.actions.loading.toggle({ show: false });
          console.error(err);
          this.props.actions.modal.toggle({
            show: true,
            title: 'Campaign Error',
            style: 'error',
            body: 'There was an error retreiving the count data.',
            actions: [
              {
                text: 'ok',
                action: () => {
                  this.props.actions.modal.toggle({ show: false });
                  navigate(modules.smartCounts.path);
                },
              },
            ],
          });
        } else {
          this.load(result.id);
        }
      });
    } else {
      this.load(count_id);
    }
  }

  load = (count_id) => {
    if (count_id) {
      this.props.actions.campaign.start(count_id).catch((err) => {
        this.props.actions.modal.toggle((modal) => ({
          show: true,
          title: 'Campaign Error',
          style: 'error',
          body: 'An error occurred when fetching the data. Please try again later.',
          actions: [
            {
              text: 'ok',
              action: () => {
                modal.close();
                this.props.navigate(modules.smartCounts.path);
              },
            },
          ],
        }));
      });
    } else {
      console.error('Smartcounts Campaign: Attempted to load without specifying a count ID');
      this.props.actions.modal.toggle({
        show: true,
        title: 'Campaign Error',
        style: 'error',
        body: 'No count was specified when starting Campaign Builder.',
        actions: [
          {
            text: 'ok',
            action: () => {
              this.props.actions.modal.toggle({ show: false });
              this.props.navigate(modules.smartCounts.path);
            },
          },
        ],
      });
    }
  };

  componentWillUnmount() {
    this.props.actions.campaign.reset();
    this.stylesheet.detach();
  }

  handleRejection = () => {
    this.props.actions.modal.toggle((modal) => ({
      show: true,
      title: 'Reject Campaign',
      body: <ApprovalComment modal={modal} />,
      actions: [
        {
          text: 'Cancel',
          action: modal.close,
        },
        {
          text: 'Reject',
          position: 'right',
          color: colorNames.red,
          disabled: true,
          action: () => {
            modal.close();
            this.props.actions.loading.toggle({
              show: true,
              text: 'Rejecting Campaign',
            });
            this.props.actions.campaign.updateStage(0).then(() => {
              this.props.actions.loading.toggle({
                complete: true,
                doneText: 'Campaign Rejected',
                onDone: () => this.props.navigate(modules.smartCounts.path),
              });
            });
          },
        },
      ],
    }));
  };

  handleApprove = () => {
    this.props.actions.modal.toggle((modal) => ({
      show: true,
      title: 'Approve Campaign',
      style: 'warning',
      body: 'Are you sure you want to approve this campaign?',
      actions: [
        {
          text: 'Cancel',
          action: modal.close,
        },
        {
          text: 'Approve',
          position: 'right',
          color: colorNames.green,
          action: () => {
            modal.close();
            this.props.actions.loading.toggle({
              show: true,
              text: 'Approving campaign...',
            });
            this.props.actions.campaign.updateStage(2).then(() => {
              this.props.actions.loading.toggle({
                complete: true,
                doneText: 'Campaign Approved',
                onDone: () => this.props.navigate(modules.smartCounts.path),
              });
            });
          },
        },
      ],
    }));
  };

  renderBanner = () => {
    let message;
    let color;
    let screen;
    const { classes } = this.stylesheet;
    switch (this.props.cc.stage) {
      case 1:
        message = 'Campaign is awaiting approval';
        color = colorNames.orange;
        screen = 'approvals';
        break;
      case 2:
        message = 'Campaign has been approved and can be executed';
        color = colorNames.green;
        screen = 'approved';
        break;
      case 3:
        message = 'Campaign is being prepared for execution';
        color = colorNames.purple;
        screen = 'executions';
        break;
      case 4:
        message = 'Campaign is ready for execution';
        color = colorNames.purple;
        screen = 'executions';
        break;
      case 5:
        message = 'Campaign is being executed';
        color = colorNames.red;
        screen = 'executed';
        break;
      case 6:
        message = 'Campaign has been executed';
        color = colorNames.grey;
        screen = 'executed';
        break;
    }

    if (
      this.props.cc.stage === 0 &&
      this.props.cc.temp.audit[this.props.cc.temp.audit.length - 1].event === 'PD_REJECT'
    ) {
      message = 'Campaign has been rejected, check the summary step for comments.';
      color = colorNames.red;
    }

    return (
      <div className={classes.floatingBanner} style={{ backgroundColor: color }}>
        {message}
        <div>
          {(this.props.cc.stage === 1 || this.props.cc.stage === 2) && this.canExec && (
            <Button
              style={{ marginRight: 5, backgroundColor: colorNames.red, labelColor: '#FFFFFF' }}
              onClick={this.handleRejection}
              variant="contained"
            >
              Reject Campaign
            </Button>
          )}
          <Button variant="contained" onClick={() => this.props.navigate(modules.smartCounts.path)}>
            Return to Smartcounts
          </Button>
        </div>
      </div>
    );
  };

  actionNext = (props, state, next) => {
    switch (state.selectedValue) {
      case 5:
        this.handleApprove();
        break;
      default:
        next();
    }
  };

  render() {
    const { classes } = this.stylesheet;
    const canExecute = this.props.cc.stage > 0 && PermissionCheck('TargetedComms_Smartcounts_Campaign_Execute');
    return (
      <div>
        {this.props.cc.stage > 0 && this.renderBanner()}
        <Content
          className={this.props.cc.stage > 0 && classes.contentWithBanner}
          title="Campaign Builder"
          subtitle={this.props.cc.fullName}
          fullBleed
        >
          <Horizontal
            completed={this.props.cc.completed}
            stepTitles={[
              'Products',
              'Timings',
              'Targeting',
              'Coupon Preview',
              'Summary',
              canExecute ? 'Execution' : undefined,
            ]}
            buttonLabels={{
              ...(canExecute &&
                this.props.cc.stage < 2 && {
                  5: { next: { text: 'Approve', color: colorNames.green } },
                }),
            }}
            handleNext={this.actionNext}
          >
            <CB_Products />
            <CB_Timings />
            <CB_Targeting />
            <CB_Coupon />
            <CB_Summary />
            {canExecute && <CB_Execute />}
          </Horizontal>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    cc: {
      stage: getCampaignObject(state).stage,
      completed: getCampaignObject(state).completed,
      count_id: getCampaignObject(state).count_id,
      fullName: getCampaignObject(state).fullName,
    },
    user,
  };
};

const {
  loading,
  modal,
  targetedComms: {
    smartcounts: { campaign },
  },
} = Actions;
const mapDispatchToProps = (dispatch) => ({
  actions: {
    loading: {
      toggle: (data) => dispatch(loading.toggle(data)),
    },
    modal: {
      toggle: (data) => dispatch(modal.toggle(data)),
    },
    campaign: {
      reset: () => dispatch(campaign.resetCampaign()),
      start: (id) => dispatch(campaign.start(id)),
      updateStage: (stage) => dispatch(campaign.updateStage(stage)),
    },
  },
});

const CampaignBuilder = ({ ...props }) => {
  const navigate = useNavigate();
  const { countId } = useParams();

  return <CCNew {...props} countId={countId} navigate={navigate} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignBuilder);
