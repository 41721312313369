import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import Actions from '../../../../../actions';
import SKUValidation from './lib/SKUValidation';
import SCAisleData from './lib/SCAisleData';
import SCFormatPayload from './lib/SCFormatPayload';
import SCCountNameField from './lib/SCCountNameField';
import { colorNames } from '../../../../../constants/colors';

// Components
import { Content } from '../../../../lib/Content/index';
import { Horizontal } from '../../../../lib/Scroll/index';
import RedemptionProducts from './RedemptionProducts';
import SCNewCount2 from './SCNewCount2';
import SCNewCount4 from './SCNewCount4';
import SCNewCount5 from './SCNewCount5';
import StoreList from './StoreList';
import PermissionCheck from '../../../../../utils/PermissionCheck';
import TargetingView from './TargetingView';
import { postJSON } from '../../../../../utils/async';
import { modules } from '../../../../../constants/moduleInfo';

class SCNewCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completed: [],
    };
    this.queueData = this.getQueueData(props);
  }

  componentDidMount() {
    PermissionCheck('TargetedComms_Smartcounts_Count_New', true);
    const { queueId, step, clone } = this.props;

    if (queueId) {
      this.props.actions.newcount.restore(queueId, clone);
      if (step) {
        this.refs.scroller.jumpToStep(step);
      }
    }
    this.props.actions.newcount.getExistingNames();
  }

  componentWillUnmount() {
    this.props.actions.newcount.reset();
  }

  componentWillReceiveProps(nextProps, nextState) {
    if ((!this.props.queueId || nextProps.queueId !== this.props.queueId) && nextProps.queueId) {
      this.props.actions.newcount.restore(nextProps.queueId, nextProps.clone);
    }

    this.queueData = this.getQueueData(nextProps);
  }

  getQueueData = (props) =>
    props.queue.data.find(
      ({ id }) => id === props.queueId || (props.newcount.jobDetails && props.newcount.jobDetails.id)
    );

  runCount = (newcount) => {
    const formattedCount = SCFormatPayload(newcount); // change name of this file
    return new Promise((resolve, reject) => {
      postJSON('/api/targeted-comms/smartcounts/count', formattedCount, (err, res) => {
        if (err) reject(`error running count: ${err}`);
        resolve();
      });
    });
  };

  /*
    Call to remove a step index from the completed array.
     */
  handleRemoveCompleted = (index) => {
    this.props.actions.newcount.deleteCompleted(index);
  };

  actionNext = (props, state, next) => {
    const { actions, newcount } = this.props;

    switch (state.selectedValue) {
      case 0: {
        if (this.props.newcount.locked) {
          next();
        } else {
          // Aisle should be ommited from the request body if it's not been set
          const aisle = newcount.aisle || undefined;

          actions.loading.toggle({
            show: true,
            text: 'Validating SKUs...',
          });
          SKUValidation({ skus: newcount.redemptionSKUs, aisle }, actions)
            .then(() => {
              actions.loading.toggle({
                show: true,
                text: 'Loading aisle data...',
              });
              return SCAisleData({
                skus: this.props.newcount.redemptionSKUs,
                brand: this.props.newcount.brand,
                aisle: this.props.newcount.aisle,
              });
            })
            .then((result) => {
              actions.newcount.updateAisleSensitivity(result.sensitivity);
              actions.newcount.updateAisleRule(result.rule);
              actions.newcount.updateProductDetails({
                redemption: result.redemption,
                brand: result.brand,
                competitor: result.comp,
                overlay: result.overlay,
              });
              actions.loading.toggle({ show: false });
              next();
            })
            .catch((error) => {
              if (error === 'choose_aisle') {
                this.actionNext(props, state, next);
              } else if (error.errorMessage === 'brand unknown') {
                actions.modal.toggle({
                  show: true,
                  title: 'Brand Unknown',
                  style: 'warning',
                  body: 'The brand is unknown for the SKUs provided. Manual brand selection will be required.',
                  actions: [
                    {
                      text: 'Cancel',
                      action: () => actions.modal.toggle({ show: false }),
                    },
                    {
                      text: 'Continue',
                      position: 'right',
                      action: () => actions.modal.toggle({ show: false }),
                    },
                  ],
                });
              } else {
                console.error(error);
                actions.modal.toggle({
                  show: true,
                  title: 'Error Loading Data',
                  style: 'error',
                  body: 'There was an issue loading the necessary data, preventing igloo from continuing.',
                  actions: [
                    {
                      text: 'OK',
                      action: () => {
                        actions.modal.toggle({ show: false });
                      },
                    },
                  ],
                });
              }
            });
        }
        break;
      }
      case 3:
        if (!this.props.clone && this.queueData && this.queueData.results) {
          next();
        } else {
          actions.modal.toggle({
            show: true,
            title: 'Name Count',
            body: <SCCountNameField />,
            actions: [
              {
                position: 'right',
                color: colorNames.green,
                text: 'Run Count',
                action: () => {
                  this.handleRemoveCompleted(4);
                  actions.modal.toggle({ show: false });
                  this.props.actions.loading.toggle({ show: true, text: 'Saving Count...' });
                  // TODO: Reintroduce warning before navigation
                  // this.props.actions.page.warn(false);
                  return this.runCount(this.props.newcount).then(() => {
                    setTimeout(
                      () =>
                        this.props.actions.loading.toggle({
                          complete: true,
                          doneText: 'Count is now running',
                          onDismiss: () => this.props.navigate(modules.smartCounts.path),
                        }),
                      2000
                    );
                  });
                },
                disabled: !newcount.name,
              },
              {
                text: 'Cancel',
                action: () => actions.modal.toggle({ show: false }),
              },
            ],
          });
        }
        break;
      default:
        next();
    }
  };

  render() {
    const {
      queueData,
      props: { clone },
    } = this;

    const stepTitles =
      this.props.newcount.jobDetails.results.length > 0
        ? ['Redemption', 'Targeting', 'Stores', 'Summary', 'Results']
        : ['Redemption', 'Targeting', 'Stores', 'Summary'];

    return (
      <Content title="Count" fullBleed>
        <Horizontal
          ref="scroller"
          stepTitles={stepTitles}
          completed={this.props.newcount.completed}
          handleNext={this.actionNext}
          buttonLabels={{
            3: {
              next:
                (!clone &&
                  (queueData && queueData.status === 'running'
                    ? 'Counting...'
                    : queueData && queueData.results
                    ? 'View Results'
                    : 'Run Count')) ||
                'Clone Count',
            },
          }}
        >
          <RedemptionProducts clone={clone} />
          <SCNewCount2 />
          <StoreList props={clone} />
          <SCNewCount4 />
          {this.props.newcount.jobDetails.results.length > 0 ? <SCNewCount5 /> : null}
        </Horizontal>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    queue,
    TargetedComms: {
      Smartcounts: { count },
    },
  } = state;
  return {
    newcount: count,
    queue,
  };
};

const {
  targetedComms: {
    smartcounts: { newcount },
  },
  loading,
  modal,
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    newcount: {
      reset: () => dispatch(newcount.reset()),
      deleteCompleted: (index) => dispatch(newcount.deleteCompleted(index)),
      restore: (job, clone) => dispatch(newcount.restoreCount(job, clone)),
      updateData: (data) => dispatch(newcount.updateData(data)),
      updateName: (name) => dispatch(newcount.updateName(name)),
      updateAisle: (aisle) => dispatch(newcount.updateAisle(aisle)),
      updateSKUs: (type, skus) => dispatch(newcount.updateSKUs(type, skus)),
      updateBrand: (brand) => dispatch(newcount.updateBrand(brand)),
      updateProductDetails: (data) => dispatch(newcount.updateProductDetails(data)),
      updateAisleSensitivity: (sense) => dispatch(newcount.updateAisleSensitivity(sense)),
      updateAisleRule: (rule) => dispatch(newcount.updateAisleRule(rule)),
      getExistingNames: () => dispatch(newcount.getExistingCountNames()),
    },
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
      amendAction: (index, opts) => dispatch(modal.amendAction(index, opts)),
    },
    loading: {
      toggle: (opts) => dispatch(loading.toggle(opts)),
    },
  },
});

const CountWrapper = ({ ...props }) => {
  const [initialLoad, setInitialLoad] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { countId } = useParams();

  const queueId = countId && parseInt(countId);
  const step = searchParams.get('step') && parseInt(searchParams.get('step'));

  // This is to circumvent a problem when loading the Count component before
  // the queue data has been fully loaded on initial mount.
  //
  // Long term solution is to remove queue updating as a side effect of loading
  // a count
  useEffect(() => {
    if (props.queue.data.length >= 1) setInitialLoad(true);
  }, [props.queue]);

  return initialLoad && <SCNewCount {...props} queueId={queueId} step={step} navigate={navigate} />;
};

const CloneCountWrapper = ({ ...props }) => {
  const [initialLoad, setInitialLoad] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const queueId = searchParams.get('queue_id') && parseInt(searchParams.get('queue_id'));

  // This is to circumvent a problem when loading the Count component before
  // the queue data has been fully loaded on initial mount.
  //
  // Long term solution is to remove queue updating as a side effect of loading
  // a count
  useEffect(() => {
    if (props.queue.data.length >= 1) setInitialLoad(true);
  }, [props.queue]);

  return initialLoad && <SCNewCount {...props} clone queueId={queueId} navigate={navigate} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(CountWrapper);

export const CloneCount = connect(mapStateToProps, mapDispatchToProps)(CloneCountWrapper);
