import React from 'react';
import { Paper, TextField, List, ListItem } from '@material-ui/core';
import { connect } from 'react-redux';
import Actions from '../../../../../../actions';
import getCampaignObject from '../../CampaignBuilder/selectors/getCampaignObject';

class SCClashes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clashes: props.clashes,
    };
  }

  render() {
    return (
      <div>
        {this.props.cc.stage < 3 && (
          <span>
            Provide the Base Asset IDs for the campaigns that contain customers you wish to exclude from targeting in
            the campaigns you are about to execute.
          </span>
        )}
        <Paper style={{ padding: 10, marginTop: 5 }}>
          <TextField
            style={{ width: '100%' }}
            multiline
            rows={5}
            value={this.state.clashes}
            onChange={(e) => {
              const v = e.target.value;
              this.setState({ clashes: v });
            }}
            onBlur={() =>
              this.props.actions.campaign.updateClashes(
                this.state.clashes
                  .replace(/\n|\s|\n\r/g, ',')
                  .split(',')
                  .filter((i) => i.length === 5)
                  .map((i) => parseInt(i))
              )
            }
            disabled={this.props.cc.stage === 3}
          />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cc: {
    stage: getCampaignObject(state),
  },
});

const {
  targetedComms: {
    smartcounts: { campaign },
  },
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    campaign: {
      updateClashes: (clashes) => dispatch(campaign.updateClashes(clashes)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SCClashes);
