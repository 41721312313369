import React from 'react';
import { Paper, Button, Table, TableHead, TableCell, TableRow, TableBody } from '@material-ui/core';
import { connect } from 'react-redux';
import { map, filter, reduce } from 'lodash';
import { MdFileDownload } from 'react-icons/md';
import CSVInput from '../../../../lib/CSVInput/CSVInput';
import { colorNames, hexToRgba } from '../../../../../constants/colors';
import Actions from '../../../../../actions';
import jss from '../../../../../utils/jss';

import Spinner from '../../../../lib/Loading/Spinner';
import { postJSON, saveDownload } from '../../../../../utils/async';

const styles = {
  paper: {
    padding: '0 10',
    paddingBottom: 10,
    marginBottom: 10,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  flexItem: {
    flex: 1,
  },
  subtle: {
    fontSize: '10pt',
    color: colorNames.grey,
  },
  action: {
    textAlign: 'right',
    color: colorNames.red,
    cursor: 'pointer',
  },
  error: {
    marginTop: 10,
    marginBottom: 10,
    padding: '5 10',
    backgroundColor: colorNames.red,
    color: colorNames.white,
    borderRadius: 2,
    cursor: 'default',
  },
};

class RedemptionProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skus: [],
      skusFound: {},
      processing: false,
      continue: false,
      cloneLoaded: false,
      exportProgress: null,
    };

    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'RedemptionProducts',
      classNamePrefix: 'RedemptionProducts-',
    });
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.productDetails !== this.props.productDetails && nextProps.locked) {
      this.setState({ skusFound: nextProps.productDetails });
    }
    if (nextProps.skus !== this.props.skus && nextProps.clone && !this.state.cloneLoaded) {
      this.setState(
        {
          skus: nextProps.skus,
          cloneLoaded: true,
        },
        () => nextProps.skus.length > 0 && this.handleAdd()
      );
    }
  }

  handleCSVInput = (e) => {
    if (e) {
      this.setState({ skus: e.split(',') });
    }
  };

  handleAdd = () => {
    this.setState(
      {
        processing: true,
      },
      () => {
        let { skus } = this.state;

        skus = [
          ...skus.filter((o) => !Object.keys(this.state.skusFound).includes(o)),
          ...map(this.state.skusFound, (sku) => sku.sku_no),
        ];

        postJSON('/api/lookup/sku/details', { skus }).then((skus) => {
          this.setState(
            {
              skusFound: map(skus, (o) => o),
              skus: [],
              processing: false,
            },
            this.handleUpdateRedux
          );
        });
      }
    );
  };

  handleRemove = (sku) => {
    this.setState((prevState) => {
      return { skusFound: filter(prevState.skusFound, (o) => o.sku_no !== sku) };
    }, this.handleUpdateRedux);
  };

  handleUpdateRedux = () => {
    this.props.actions.newcount
      .updateRedemptionSKUs(
        reduce(
          this.state.skusFound,
          (array, sku) => {
            array.push(sku.sku_no);
            return array;
          },
          []
        )
      )
      .then(() => {
        // TODO: Reintroduce warning before navigation
        // this.props.actions.page.warnBeforeChange(true);
        if (!this.state.cloneLoaded) {
          this.props.actions.newcount.resetFrom(0);
          if (this.state.skusFound.length > 0) {
            this.props.actions.newcount.addCompleted(0);
          } else {
            this.props.actions.newcount.removeCompleted(0);
          }
        }
      });
  };

  handleDownloadSkuList = () => {
    saveDownload(
      `/api/targeted-comms/smartcounts/count/skus/${this.props.queue_id}`,
      {
        save: true,
        path: `SKUS_${this.props.queue_id}.xlsx`,
      },
      (err) => {
        if (err) {
          this.props.actions.modal.toggle((modal) => ({
            show: true,
            title: 'Export Error',
            style: 'error',
            body: 'There was a problem exporting the SKUs.',
            actions: [{ text: 'Ok', action: modal.close }],
          }));
        }
      }
    );
  };

  render() {
    const { classes } = this.stylesheet;
    return (
      <div>
        {!this.props.locked && (
          <Paper className={classes.paper}>
            <CSVInput
              onChange={this.handleCSVInput}
              floatingLabelText="SKU"
              rows={1}
              value={this.state.skus.join(',') || ''}
              disabled={this.state.processing}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.handleAdd();
                }
              }}
            />
            <div className={classes.flex}>
              <div className={jss.combine(classes.flexItem, classes.subtle)}>
                You can provide SKUs individually, enter multiple using a comma to separate, or paste from Excel.
              </div>

              <div className={classes.flexItem} style={{ textAlign: 'right' }}>
                {this.state.processing ? (
                  <Spinner size={16} color={colorNames.blue} />
                ) : (
                  <Button
                    style={{ backgroundColor: this.state.skus.length === 0 ? 'transparent' : colorNames.blue }}
                    labelstyle={{
                      color: this.state.skus.length === 0 ? colorNames.grey : colorNames.white,
                    }}
                    onClick={this.handleAdd}
                    disabled={this.state.skus.length === 0}
                  >
                    Add
                  </Button>
                )}
              </div>
            </div>
          </Paper>
        )}
        {Object.keys(this.state.skusFound).length > 0 && (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>SKU</TableCell>
                  <TableCell>SKU Name</TableCell>
                  <TableCell>Aisle</TableCell>
                  <TableCell>Brand</TableCell>
                  {!this.props.locked && <TableCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                {map(this.state.skusFound, (sku, i) => {
                  return (
                    <TableRow key={sku.sku_no}>
                      <TableCell>{sku.sku_no}</TableCell>
                      <TableCell style={{ whiteSpace: 'pre-wrap' }}>{sku.sku_name}</TableCell>
                      <TableCell>{sku.aisle || sku.i2c_aisle}</TableCell>
                      <TableCell>
                        {sku.brand}
                        <br />
                        <small>{sku.manufacturer}</small>
                      </TableCell>
                      {!this.props.locked && (
                        <TableCell>
                          <div className={classes.action}>
                            <span onClick={() => this.handleRemove(sku.sku_no)}>Remove</span>
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
        )}
        {this.props.locked && (
          <Button variant="contained" onClick={this.handleDownloadSkuList} style={{ marginTop: 10 }}>
            {this.state.exportProgress ? (
              <span>
                Downloading {this.state.exportProgress * 100}
                %...
              </span>
            ) : (
              <span>Download SKU List</span>
            )}
          </Button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    TargetedComms: {
      Smartcounts: { count },
    },
  } = state;
  return {
    queue_id: count.jobDetails.id,
    skus: count.redemptionSKUs,
    productDetails: count.productDetails.redemption,
    locked: count.locked,
  };
};

const {
  targetedComms: {
    smartcounts: { newcount },
  },
  modal,
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    newcount: {
      addCompleted: (index) => dispatch(newcount.addCompleted(index)),
      removeCompleted: (index) => dispatch(newcount.deleteCompleted(index)),
      resetFrom: (index) => dispatch(newcount.resetFrom(index)),
      updateRedemptionSKUs: (skus) => dispatch(newcount.updateRedemptionSKUs(skus)),
    },
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RedemptionProducts);
