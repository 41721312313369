import { createSelector } from 'reselect';
import { reduce, map } from 'lodash';
import getCampaignObject from './getCampaignObject';

export const getSkusInCount = (state) => getCampaignObject(state).count.payload.skus;
export const getMissingSkus = (state) => getCampaignObject(state).skus_missing;
const getSkuDetails = (state) => getCampaignObject(state).sku_details;

export const getProductTableTabs = createSelector([getSkusInCount], (skus) =>
  reduce(
    skus,
    (output, s, name) => {
      if (s.length > 0) {
        output.push({
          tab: name,
          name: name.fromCamelCase().toSentenceCase(),
          count: s.length,
        });
      }
      return output;
    },
    []
  )
);

export default createSelector([getSkusInCount, getSkuDetails], (skus, details) =>
  reduce(
    skus,
    (output, s, type) => {
      if (!output[type]) {
        output[type] = [];
      }
      map(s, (sku) => {
        output[type].push(details[sku]);
      });

      return output;
    },
    {}
  )
);
