import React from 'react';
import { connect } from 'react-redux';
import { map, reduce } from 'lodash';
import { Paper, Table, TableBody, TableHead, TableCell, TableRow, TextField, Button, Popover } from '@material-ui/core';
import { FaBarcode } from 'react-icons/fa';
import Actions from '../../../../../actions';
import { colorNames } from '../../../../../constants/colors';
import SCClashes from '../NewCount/lib/SCClashes';
import jss from '../../../../../utils/jss';
import { getBuiltCells } from './selectors/getCells';
import getCampaignObject from './selectors/getCampaignObject';
import getBarcodes from './selectors/getBarcodes';
import getBarcodeErrors from './selectors/getBarcodeErrors';
import { saveDownload } from '../../../../../utils/async';

const styles = {
  split: {
    marginTop: 10,
    display: 'flex',
  },
  splitCol: {
    flex: 1,
    width: '50%',
  },
};

class CB_Execute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barcodes: props.campaign.barcodes,
      errors: {},
      downloading: false,
    };

    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'CB_Execute',
      classNamePrefix: 'CB_Execute-',
    });

    this.barcode = {};
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeStep === nextProps.stepIndex) {
      if (
        Object.keys(nextProps.campaign.errors).length === 0 &&
        !nextProps.campaign.completed.includes(nextProps.stepIndex)
      ) {
        this.props.actions.campaign.addCompleted(nextProps.stepIndex);
      } else if (
        Object.keys(nextProps.campaign.errors).length > 0 &&
        nextProps.campaign.completed.includes(nextProps.stepIndex)
      ) {
        this.props.actions.campaign.deleteCompleted(nextProps.stepIndex);
      }
    }
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  handleguide = () => {
    this.setState({ downloading: true });
    saveDownload(
      `/api/targeted-comms/smartcounts/guide/coupon-id`,
      {
        save: true,
        path: `coupon_barcode_guide.pdf`,
      },
      (err) => {
        this.setState({ downloading: false });
        if (err) {
          console.error(err);
        }
      }
    );
  };

  handleBarcode = (cell, value) => {
    this.props.actions.campaign.updateBarcodeForCell(cell, value);
  };

  handleOpenClashesUI = () => {
    const clashesUI = () => (
      <SCClashes ref={(n) => (this.clashesUI = n)} clashes={this.props.campaign.clashes.join(',')} />
    );
    this.props.actions.modal.toggle((modal) => ({
      show: true,
      title: 'Campaign Clashes',
      body: clashesUI(),
      actions: [
        {
          text: 'Done',
          position: 'right',
          action: modal.close,
        },
      ],
    }));
  };

  render() {
    const { classes } = this.stylesheet;

    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cell</TableCell>
                <TableCell>Offer</TableCell>
                <TableCell>Barcodes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(this.props.campaign.cells, (cell, cellCode) => {
                const barcodeColor =
                  this.props.campaign.barcodes[cellCode] === '' || !this.props.campaign.barcodes[cellCode]
                    ? colorNames.black
                    : this.props.campaign.errors[cellCode]
                    ? colorNames.red
                    : colorNames.green;

                return (
                  cell.required && (
                    <TableRow key={cellCode}>
                      <TableCell>{cell.display}</TableCell>
                      <TableCell>
                        {cell.offer_type === 'points'
                          ? `${cell.offer_value} bonus points`
                          : cell.offer_value > 1
                          ? `£${cell.offer_value} off`
                          : `${cell.offer_value * 100}p off`}
                      </TableCell>
                      <TableCell>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            ...(this.props.campaign.errors &&
                              this.props.campaign.errors[cellCode] && {
                                paddingBottom: 15,
                              }),
                          }}
                        >
                          <FaBarcode color={barcodeColor} />
                          {this.props.campaign.stage === 1 ? (
                            <div style={{ position: 'relative' }}>
                              <TextField
                                name="barcode"
                                style={{ marginLeft: 10 }}
                                onChange={(e) => {
                                  const v = e.target.value;
                                  this.handleBarcode(cellCode, v);
                                }}
                                value={this.props.campaign.barcodes[cellCode]}
                                error={this.props.campaign.errors && this.props.campaign.errors[cellCode]}
                                helperText={this.props.campaign.errors ? this.props.campaign.errors[cellCode] : null}
                              />

                              <small style={{ marginLeft: 5, color: colorNames.grey }}>
                                {this.props.campaign.barcodes[cellCode]
                                  ? this.props.campaign.barcodes[cellCode].length
                                  : 0}
                                /{cell.offer_type === 'points' ? 15 : 13}
                              </small>
                            </div>
                          ) : (
                            <span
                              style={{
                                marginLeft: '5px',
                                fontFamily: 'Menlo',

                                letterSpacing: '2px',
                              }}
                            >
                              {this.props.campaign.barcodes[cellCode]}
                            </span>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        <div className={classes.split}>
          <div className={classes.splitCol}>
            <Button variant="contained" onClick={this.handleOpenClashesUI}>
              {`${this.props.campaign.clashes.length} Clashes`}
            </Button>
          </div>
          <div className={classes.splitCol} style={{ textAlign: 'right', cursor: 'pointer' }}>
            <div onClick={this.handleguide}>Download Coupon Barcode Guide</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaign: {
      completed: getCampaignObject(state).completed,
      cells: getBuiltCells(state),
      stage: getCampaignObject(state).stage,
      clashes: getCampaignObject(state).clashes,
      barcodes: getBarcodes(state),
      errors: getBarcodeErrors(state),
    },
  };
};

const {
  modal,
  targetedComms: {
    smartcounts: { campaign },
  },
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
    },
    campaign: {
      addCompleted: (index) => dispatch(campaign.addCompleted(index)),
      deleteCompleted: (index) => dispatch(campaign.removeCompleted(index)),
      updateBarcodeForCell: (cell, value) => dispatch(campaign.updateBarcodeForCell(cell, value)),
      updateClashes: (clashes) => dispatch(campaign.updateClashes(clashes)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CB_Execute);
