import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

class ContextMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      offsetTop: false,
      mouse: {
        x: 0,
        y: 0,
      },
    };
  }

  componentDidMount() {
    if (document.getElementById(this.props.attachTo)) {
      document.getElementById(this.props.attachTo).addEventListener('contextmenu', (e) => {
        this.setState({ show: true, mouse: { x: e.clientX, y: e.clientY } });
        const wHeight = window.innerHeight;
        const mouseY = this.state.mouse.y;
        const mHeight = this.menu.offsetHeight;

        if (mHeight > wHeight - mouseY) {
          this.menu.style.bottom = 0;
          this.menu.style.top = 'auto';
        } else {
          this.menu.style.bottom = 'auto';
        }
      });

      this.setState({
        offsetTop: document.getElementById(this.props.attachTo).getBoundingClientRect().top,
      });
    }
  }

  render() {
    const style = StyleSheet.create({
      dismissZone: {
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 4,
      },
      wrapper: {
        display: this.state.show ? 'block' : 'none',
        position: 'absolute',
        top: this.state.mouse.y - (this.state.offsetTop + window.scrollY),
        left: this.state.mouse.x,
        zIndex: 5,
        backgroundColor: 'white',
      },
    });

    if (this.state.show) {
      const eleDismiss = document.createElement('div');
      eleDismiss.id = 'ContextMenuDismissZone';
      eleDismiss.className = css(style.dismissZone);
      eleDismiss.oncontextmenu = () => {
        this.setState({ show: false });
      };
      eleDismiss.onclick = () => {
        this.setState({ show: false });
      };
      document.getElementsByTagName('body')[0].appendChild(eleDismiss);
    } else if (document.getElementById('ContextMenuDismissZone')) {
      document.getElementsByTagName('body')[0].removeChild(document.getElementById('ContextMenuDismissZone'));
    }
    return (
      <div className={css(style.wrapper)} ref={(node) => (this.menu = node)}>
        <Paper>
          <Menu desktop>
            {this.props.menuItems.map((item, i) => {
              if (item.type === 'copy') {
                return (
                  <MenuItem
                    key={i}
                    primaryText="Copy"
                    onClick={(e) => {
                      if (e.nativeEvent.which === 1) {
                        document.execCommand('copy');
                        this.setState({ show: false });
                      }
                    }}
                  >
                    Copy
                  </MenuItem>
                );
              }
              return (
                <MenuItem
                  key={i}
                  onClick={(e) => {
                    if (e.nativeEvent.which === 1) {
                      item.action();
                      this.setState({ show: false });
                    }
                  }}
                >
                  {item.text}
                </MenuItem>
              );
            })}
          </Menu>
        </Paper>
      </div>
    );
  }
}

export default ContextMenu;
