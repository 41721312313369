import _ from 'lodash';

const addValues = function (value, date) {
  const { modal } = this.state;
  const page = modal.view.toLowerCase().replace(/ /g, '_');
  let selectedValues;
  let values;
  if (page !== 'date') {
    values = this.state.placeholder.slice(0);
    if (typeof value === 'object') {
      values = [...new Set([...values, ...value])];
    } else {
      values.push(value);
    }
    values.sort();
    selectedValues = this.state.selectedValues[page].sort();
  } else {
    values = { ...this.state.placeholder };
    values[date] = value;
    selectedValues = this.state.selectedValues.date;
  }
  modal.submit = !_.isEqual(values, selectedValues);
  this.setState({
    placeholder: values,
    modal,
  });
};

const filter = function () {
  let { data } = this.state;
  const filters = this.state.selectedValues;
  _.forEach(filters, (value, key) => {
    if (value.length !== 0 || key === 'date') {
      if (key !== 'channel' && key !== 'date' && key !== 'primary_channel') {
        data = _(data).groupBy(key).at(value).flatten().value();
      } else if (key === 'channel') {
        data = _.uniqWith(
          _.flatten(
            _.map(value, (item) =>
              _.filter(data, (o) => {
                return _.indexOf(o.channel, item) !== -1;
              })
            )
          ),
          _.isEqual
        );
      } else if (key === 'date') {
        if (data[0] !== null) {
          data = _.filter(data, (item) => {
            return item.start_date >= value.start && item.start_date <= value.end;
          });
        }
      }
    }
  });
  data = data.filter((item) => item);
  this.setState(
    {
      filteredData: data,
      numberCampaigns: data[0] !== null ? data.length : 0,
    },
    function () {
      checkValues.bind(this)();
    }
  );
};

const checkValues = function () {
  const { filteredData, selectedValues } = this.state;
  _.forEach(selectedValues, (value, key) => {
    if (value.length !== 0 && key !== 'date') {
      const allData =
        key !== 'channel'
          ? _(filteredData).groupBy(key).keys().value()
          : _(filteredData).map('channel').flatten().uniq().value();
      if (key !== 'primary_channel') {
        selectedValues[key] = _.remove(value, (item) => _.indexOf(allData, item) !== -1);
      }
    } else if (key === 'date') {
      const dates = _.map(filteredData, 'start_date');
      selectedValues.date.start = new Date(Math.min.apply(null, dates));
      selectedValues.date.end = new Date(Math.max.apply(null, dates));
    }
  });
  this.setState({
    selectedValues,
  });
};

const removeValue = function (value) {
  let { modal, placeholder, selectedValues } = this.state;
  const page = modal.view.toLowerCase().replace(/ /g, '_');
  value =
    modal.view === 'master_campaign_id' || modal.view === 'channel' || modal.view === 'primary_channel'
      ? value
      : value.toUpperCase();
  placeholder = _.remove(placeholder, (item) => item !== value);
  if (!_.isEqual(placeholder.sort(), selectedValues[page].sort())) {
    selectedValues[page] = placeholder;
    modal.submit = true;
    this.setState({ selectedValues, modal });
  } else {
    modal.submit = false;
  }
  this.setState({ placeholder });
};

export { addValues, filter, removeValue };
