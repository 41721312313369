import React from 'react';
import Button from '@material-ui/core/Button';
import { Table, TableBody, TableHead, TableCell, TableRow, Select, FormControl, InputLabel } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { reduce, map, flattenDeep } from 'lodash';
import { unitsSold_lookup, unitsSold_download } from '../../../utils/Analytics';
import CSVInput from '../../lib/CSVInput/CSVInput';
import { postJSON } from '../../../utils/async';
import Actions from '../../../actions';
import { Content } from '../../lib/Content';
import jss from '../../../utils/jss';
import SaveXLS from '../../../utils/SaveXLS';
import { colorNames } from '../../../constants/colors';

const styles = {
  flexWrap: {
    display: 'flex',
    alignItems: 'inline-block',
    padding: '1rem',
  },
  flexItem: {
    flex: 1,
  },
  rightAlign: {
    textAlign: 'right',
  },
  skuInput: {
    marginRight: '3rem',
  },
};

class UnitsSold extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'SkuSaleVerifier',
      classNamePrefix: 'SkuSaleVerifier-',
    });
    this.state = {
      period: '26weeks',
      data: {},
      groupedData: {},
      skuInput: '',
      excelled: false,
    };
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  handleDataRequest = () => {
    const { period, skuInput } = this.state;

    unitsSold_lookup(period, skuInput.split(',').length);

    this.props.loading.toggle({
      show: true,
      text: 'Calculating Volumes...',
    });
    let datePeriod;
    switch (period) {
      case '26weeks':
        datePeriod = 181;
        break;
      case '52weeks':
        datePeriod = 363;
        break;
    }
    postJSON(
      '/api/lookup/units-sold',
      {
        sku_no: skuInput.split(',').map((s) => parseInt(s)),
        datePeriod,
      },
      (error, result) => {
        if (error) return console.error(error);

        this.setState(
          {
            data: result,
            groupedData: this.groupSKUs(result),
          },
          () => {
            this.props.loading.toggle({
              show: false,
            });
            this.groupSKUs();
          }
        );
      }
    );
  };

  handleSkuInput = (value, error) => {
    this.setState({
      skuInput: value,
      data: {},
    });
  };

  handlePeriodChange = (event, index) => {
    const { value } = event.target;
    this.setState({
      period: value,
      data: {},
    });
  };

  handleXls = () => {
    const numberfy = flattenDeep(
      map(this.state.data, (data) => ({
        ...data,
        UNITS_SOLD: parseInt(data.UNITS_SOLD),
      }))
    );

    unitsSold_download('units_sold_download');

    SaveXLS(
      [
        {
          name: 'Units Sold',
          data: numberfy,
        },
      ],
      'Sku Sales',
      true
    );
  };

  groupSKUs = (data) => {
    return reduce(
      data,
      (groups, value) => {
        if (groups[value.SKU_NO]) {
          groups[value.SKU_NO].rows.push(value);
          groups[value.SKU_NO].total = groups[value.SKU_NO].total + parseInt(value.UNITS_SOLD);
        } else {
          (groups[value.SKU_NO] || (groups[value.SKU_NO] = {})).rows = [value];
          groups[value.SKU_NO].total = parseInt(value.UNITS_SOLD);
        }
        return groups;
      },
      {}
    );
  };

  render() {
    const { classes } = this.stylesheet;
    return (
      <Content title="Units Sold" fullBleed>
        <Paper>
          <div className={classes.flexWrap}>
            <div className={jss.combine(classes.flexItem, classes.skuInput)}>
              <CSVInput onChange={this.handleSkuInput} value={this.state.skuInput} floatingLabelText="SKUs" rows={1} />
            </div>

            <div className={classes.flexItem}>
              <FormControl style={{ minWidth: 120 }}>
                <InputLabel id="demo-mutiple-name-label">Sales Period</InputLabel>
                <Select labelId="demo-mutiple-name-label" value={this.state.period} onChange={this.handlePeriodChange}>
                  <MenuItem value="26weeks">26 weeks</MenuItem>
                  <MenuItem value="52weeks">52 weeks</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className={classes.flexItem} style={{ marginTop: '1.8rem' }}>
              <Button
                variant="contained"
                disabled={this.state.skuInput === ''}
                primary="true"
                onClick={this.handleDataRequest}
              >
                RUN
              </Button>
            </div>
            <div className={jss.combine(classes.flexItem, classes.rightAlign)} style={{ marginTop: '1.8rem' }}>
              <Button
                style={{ backgroundColor: colorNames.excelGreen, labelColor: '#fff' }}
                onClick={this.handleXls}
                disabled={Object.keys(this.state.data).length === 0}
                value={this.state.value}
                variant="contained"
              >
                Open in Excel
              </Button>
            </div>
          </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SKU</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>EAN</TableCell>
                <TableCell>Units Sold</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(this.state.groupedData, (row, sku) => {
                return [
                  map(row.rows, (ean, i) => (
                    <TableRow key={ean.SKU_NO + i} style={{ whiteSpace: 'pre-wrap' }}>
                      <TableCell>{ean.SKU_NO}</TableCell>
                      <TableCell>{ean.SKU_NAME}</TableCell>
                      <TableCell>{ean.EAN}</TableCell>
                      <TableCell>{parseInt(ean.UNITS_SOLD).withComma(',')}</TableCell>
                    </TableRow>
                  )),
                  <TableRow key={`${sku}-total`}>
                    <TableCell colSpan={3} />
                    <TableCell style={{ fontWeight: 'bold' }}>{parseInt(row.total).withComma(',')}</TableCell>
                  </TableRow>,
                ];
              })}
            </TableBody>
          </Table>
        </Paper>
      </Content>
    );
  }
}

const { loading } = Actions;

const mapDispatchToProps = (dispatch) => ({
  loading: {
    toggle: (options) => dispatch(loading.toggle(options)),
  },
});

export default connect(null, mapDispatchToProps)(UnitsSold);
