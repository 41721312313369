import Papa from 'papaparse';
import FileSaver from 'file-saver';

/**
 * Saves JSON or CSV data to a CSV file
 * @param {Object | String} data - the data to parse in either JSON or CSV format
 * @param {string} filename - the name of the file excluding .csv
 * @param {bool=true} openOnSave - should the file be opened on save
 * @returns {Promise<string>} - returns the file path
 */
export default (data, filename) => {
  if (typeof data === 'object') {
    data = Papa.unparse(data);
  }

  return new Promise((resolve, reject) => {
    if (data) {
      const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
      resolve(FileSaver.saveAs(blob, `${filename}.csv`));
    } else {
      alert('Could not generate CSV.');
      reject('csv_error');
    }
  });
};
