import { createSelectorCreator } from 'reselect';
import { reduce, memoize } from 'lodash';
import getCampaignObject from './getCampaignObject';

const createDeepEqualSelector = createSelectorCreator(memoize, (...args) =>
  args.reduce((acc, val) => `${acc}-${JSON.stringify(val)}`, '')
);

export const getCountIsClone = (state) => getCampaignObject(state).count.payload.cloned;
const getCountData = (state) => getCampaignObject(state).count.payload;
const getCountResults = (state) => getCampaignObject(state).count.results;
export default createDeepEqualSelector([getCountData, getCountResults], (data, results) => ({ data, results }));
