import React from 'react';
import jss from '../../../utils/jss';
import { colorNames } from '../../../constants/colors';

const styles = {
  small: {
    fontSize: '8pt',
    whiteSpace: 'normal',
    borderLeft: `2px solid ${colorNames.blue}`,
    padding: '2px 5px',
  },
  red: {
    borderLeftColor: colorNames.red,
  },
  orange: {
    borderLeftColor: colorNames.orange,
  },
  blue: {
    borderLeftColor: colorNames.blue,
  },
  green: {
    borderLeftColor: colorNames.green,
  },
};

export default class Info extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'Info',
      classNamePrefix: 'Info-',
    });
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  render() {
    const { classes } = this.stylesheet;
    switch (this.props.type) {
      default:
        return (
          <div
            className={jss.combine(classes.small, this.props.color && classes[this.props.color])}
            style={this.props.style}
          >
            {this.props.children}
          </div>
        );
    }
  }
}
