import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import { formatProper } from '../../../../../../utils/utils';
import CarParkPosters from '../../../../../../../images/channels/CarParkPosters.png';
import ConvenienceSampling from '../../../../../../../images/channels/ConvenienceSampling.png';
import DirectMail from '../../../../../../../images/channels/DirectMail.png';
import EntranceGates from '../../../../../../../images/channels/EntranceGates.png';
import JSMagazine from '../../../../../../../images/channels/JSMagazine.png';
import Mobile from '../../../../../../../images/channels/Mobile.png';
import SecondarySpace from '../../../../../../../images/channels/SecondarySpace.png';
import TVWall from '../../../../../../../images/channels/TVWall.png';
import Weve from '../../../../../../../images/channels/Weve.png';
import CarParkSampling from '../../../../../../../images/channels/CarParkSampling.png';
import CouponAtTill from '../../../../../../../images/channels/CouponAtTill.png';
import Email from '../../../../../../../images/channels/Email.png';
import InstoreSampling from '../../../../../../../images/channels/InstoreSampling.png';
import MilkMedia from '../../../../../../../images/channels/MilkMedia.png';
import Programmatic from '../../../../../../../images/channels/Programmatic.png';
import SixSheets from '../../../../../../../images/channels/SixSheets.png';
import Proximity from '../../../../../../../images/channels/Proximity.png';
import Trolleys from '../../../../../../../images/channels/Trolleys.png';
import Yahoo from '../../../../../../../images/channels/Yahoo.png';

const images = {
  CarParkPosters,
  ConvenienceSampling,
  DirectMail,
  EntranceGates,
  JSMagazine,
  Mobile,
  SecondarySpace,
  TVWall,
  Weve,
  CarParkSampling,
  CouponAtTill,
  Email,
  InstoreSampling,
  MilkMedia,
  Programmatic,
  SixSheets,
  Proximity,
  Trolleys,
  Yahoo,
};

const Channels = (props) => {
  const removeChannels = (item) => {
    if (_.indexOf(props.state.placeholder, item) === -1) {
      props.state.func.addValues(item);
    } else {
      props.state.func.removeValue(item);
    }
  };
  let { state, data, filtered } = props;
  data = _(data).flatMap('channel').uniq().value().sort();
  filtered = _(filtered).flatMap('channel').uniq().value().sort();
  const { placeholder } = state;
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 100,
      }}
    >
      {data.map((item, key) => {
        const avail = _.indexOf(filtered, item) !== -1;
        const clicked = _.indexOf(placeholder, item) !== -1;
        return (
          <div
            key={key}
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              width: '33%',
              height: 100,
              paddingBottom: 20,
            }}
          >
            <div
              className={classNames('channel--item', {
                avail,
                clicked,
              })}
              onClick={() => {
                if (avail) {
                  removeChannels(item);
                }
              }}
            >
              <img className="channel--image" src={images[item.replace(/ /g, '')]} />
              <span style={{ textAlign: 'center' }}>{formatProper(item, ['TV', 'JS'])}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

Channels.propTypes = {
  data: PropTypes.array.isRequired,
  filtered: PropTypes.array.isRequired,
  state: PropTypes.object.isRequired,
};

export default Channels;
