import React from 'react';
import './Spinner.css';

class Spinner extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { color, size, complete, style } = this.props;
    const stroke = size > 100 ? 6 : 8;
    return (
      <div className={complete ? 'loader_spin complete' : 'loader_spin'} style={style}>
        <div className="spinner" style={{ width: size, height: size }}>
          <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" style={{ stroke: color }}>
            <circle className="length" fill="none" strokeWidth={stroke} strokeLinecap="round" cx="33" cy="33" r="28" />
          </svg>
        </div>
      </div>
    );
  }
}

export default Spinner;
