import React, { useRef } from 'react';
import { Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { MdExpandMore } from 'react-icons/md';
import igloo from '../../../../images/igloo-logo.svg';
import { colorNames } from '../../../constants/colors';
import FullPageContent from '../FullPageContent';
import { getUser } from '../../../utils/authProvider';

const SUPPORT_LINK =
  'https://sainsburys.service-now.com/assist?id=sc_cat_item&table=sc_cat_item&sys_id=79e8de141b3b38d03f00a6c7b04bcb3a';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
    padding: '24px 48px',
    boxSizing: 'border-box',
    color: '#2e2e2e',
  },
  messageContainer: {
    flexGrow: '1',
  },
  heading: {
    fontFamily: 'VennSemiEx, Helvetica, sans-serif',
    fontSize: '3rem',
  },
  message: {
    fontSize: '1.25rem',
  },
  logoContainer: {
    alignSelf: 'flex-end',
    maxWidth: '320px',
  },
  debugContainer: {
    marginBottom: '24px',
    maxWidth: '960px',
  },
  debugDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  stackTrace: {
    maxHeight: '320px',
    overflowY: 'auto',
    userSelect: 'all',
    whiteSpace: 'pre-wrap',
    margin: '0',
    marginBottom: '10px',
    borderBottom: '1px solid #d7d7d7',
  },
  logo: {
    maxWidth: '100%',
  },
};

const errors = {
  404: {
    title: 'Error 404',
    message: 'The module you have tried to access could not be found.',
    actions: [
      <Button href="/" style={{ backgroundColor: colorNames.primaryButton, color: '#FFFFFF' }} variant="contained">
        Home
      </Button>,
    ],
  },
  403: {
    title: 'Error 403',
    message: 'You do not have access to this resource.',
    actions: [
      <Button href="/" style={{ backgroundColor: colorNames.primaryButton, color: '#FFFFFF' }} variant="contained">
        Home
      </Button>,
    ],
  },
  401: {
    title: 'Access Denied',
    message: 'You do not have access to Igloo.',
    actions: [
      <Button
        href="https://sainsburys.service-now.com/assist?id=sc_cat_item&table=sc_cat_item&sys_id=967a08021b6a85d07b38404cd34bcbfa"
        style={{ backgroundColor: colorNames.primaryButton, color: '#FFFFFF' }}
        variant="contained"
      >
        Request Access via Tech Assist
      </Button>,
    ],
  },
  500: {
    title: 'Application Error',
    message: `An unexpected error occurred preventing Igloo from continuing.`,
    actions: [
      <Button
        onClick={() => (window.location = window.location)}
        style={{ backgroundColor: colorNames.primaryButton, color: '#FFFFFF' }}
        variant="contained"
      >
        Refresh
      </Button>,
    ],
  },
  503: {
    title: 'Service Unavailable',
    message: 'Our systems are currently unavailable. Please try again shortly.',
    actions: [
      <Button
        onClick={() => (window.location = window.location)}
        style={{ backgroundColor: colorNames.primaryButton, color: '#FFFFFF' }}
        variant="contained"
      >
        Refresh
      </Button>,
    ],
  },
};

const Debug = ({ stackTrace }) => {
  const stackRef = useRef();
  const user = getUser();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<MdExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
        Show debug information for support team
      </AccordionSummary>
      <AccordionDetails>
        <div style={styles.debugDetails}>
          <pre ref={stackRef} style={styles.stackTrace}>
            {stackTrace}
            {`\n\n`}
            Location: {window.location.href}
            {`\n\n`}
            {user ? (
              <>
                User: {user.givenName} {user.surname} (ID: {user.id}){`\n`}
                Groups:{`\n`}
                {user.groups.map((ln) => `\t${ln.name}\n`)}
                Permissions:{`\n`}
                {user.permissions.map((ln) => `\t${ln}\n`)}
              </>
            ) : (
              'User: Unauthenticated'
            )}
          </pre>
          <div style={{ alignSelf: 'flex-end' }}>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(stackRef.current.textContent);
              }}
              style={{ backgroundColor: colorNames.blueGrey, color: '#FFFFFF' }}
              variant="contained"
            >
              Copy to Clipboard
            </Button>
            <Button
              href={SUPPORT_LINK}
              target="_blank"
              style={{ backgroundColor: colorNames.primaryButton, color: '#FFFFFF', marginLeft: '10px' }}
              variant="contained"
            >
              Report an issue
            </Button>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const ErrorPage = ({ errorInfo, includeFurniture, stackTrace }) => {
  const error = errors[errorInfo.code] || errors[500];

  return (
    <FullPageContent includeFurniture={includeFurniture}>
      <div style={styles.container}>
        <div style={styles.messageContainer}>
          <h1 style={styles.heading}>{error.title}</h1>
          <p style={styles.message}>{error.message}</p>
          {stackTrace && (
            <div style={styles.debugContainer}>
              <Debug stackTrace={stackTrace} />
            </div>
          )}
        </div>
        <div>{error.actions}</div>

        {includeFurniture && (
          <div style={styles.logoContainer}>
            <img src={igloo} alt="" style={styles.logo} />
          </div>
        )}
      </div>
    </FullPageContent>
  );
};

export default ErrorPage;
