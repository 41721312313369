import React from 'react';
import { Route } from 'react-router';
import { modules } from '../../../constants/moduleInfo';
import Integrated from '../../views/Benchmark/Integrated/Integrated';
import { HasAccess } from './has-access';

const { benchmarks } = modules;

export const Benchmarks = (
  <Route
    path={benchmarks.path}
    element={
      <HasAccess accessFn={benchmarks.hasAccess}>
        <Integrated />
      </HasAccess>
    }
  />
);
