// // Horizontal Bar Char
// ToDo: Add axis labels
// ToDo: Add max/min bar widths when less bars are shown
// ToDo:Remove negative PropType and have it check itself if negative values are in data
// ToDo: Fix transition so transitions from new origin.
// ToDo: Fix x axis in place when scrolling so always visible.

import React from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';

class HorizontalBar extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.data !== nextProps.data;
  }

  componentDidMount() {
    this.updateChart();
  }

  componentDidUpdate() {
    this.updateChart();
  }

  updateChart = () => {
    const {
      data,
      margin,
      chartHeight,
      chartWidth,
      chartID,
      negative,
      domain,
      xAttr,
      yAttr,
      ticks,
      sideBar,
      sideBarData,
      sideBarText,
      handleChartClick,
    } = this.props;
    const height = chartHeight - margin.top - margin.bottom;
    const width = chartWidth - margin.left - margin.right;
    const body = d3.select(`#${chartID}`).select('.chart--body');
    const xAxis = d3.select(`#${chartID}`).select('.axis.x--axis');
    const yAxis = d3.select(`#${chartID}`).select('.axis.y--axis');
    const x = d3.scaleLinear().range([0, width]);
    const xHeight = Math.max(height, 20 * data.length);
    const y = d3.scaleBand().range([xHeight, 0]);
    let tooltip;
    if (this.props.tooltip) {
      if (d3.select('.toolTip').empty()) {
        tooltip = d3.select('body').append('div').attr('class', 'toolTip');
      } else {
        tooltip = d3.select('.toolTip');
      }
    }
    y.domain(data.map((d) => d[yAttr])).padding(0.1);
    x.domain(domain).nice();

    xAxis
      .transition()
      .duration(750)
      .call(
        d3
          .axisBottom(x)
          .ticks(7)
          .tickFormat((d) => ticks(d))
          .tickSizeInner([-xHeight])
      );
    yAxis.transition().duration(750).call(d3.axisLeft(y));

    const bars = body.selectAll('.bar').data(data);

    bars.exit().remove();

    bars
      .attr('x', () => (negative ? x(0) : 0))
      .attr('height', y.bandwidth()) // Need to  create max width here
      .attr('y', (d) => y(d[yAttr]))
      .on('click', (event, d) => {
        if (handleChartClick !== null) {
          handleChartClick(d);
        }
      })
      .transition()
      .duration(750)
      .attr('width', (d) => Math.abs(x(d[xAttr]) - x(0)))
      .attr('x', (d) => (negative ? x(Math.min(0, d[xAttr])) : null))
      .attr('fill', (d) => (d[xAttr] < 0 ? '#BA6A5D' : '#AFC576'));

    bars
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', () => (negative ? x(0) : 0))
      .attr('height', y.bandwidth()) // Need to create max width of bars here
      .attr('y', (d) => y(d[yAttr]))
      .on('click', (event, d) => {
        if (handleChartClick !== null) {
          handleChartClick(d);
        }
      })
      .on('mousemove', (event, d) => {
        if (this.props.tooltip) {
          tooltip
            .style('left', `${event.pageX - 100}px`)
            .style('top', `${event.pageY - 75}px`)
            .style('display', 'inline-block')
            .html(this.props.tooltipHTML(d));
        }
      })
      .on('mouseout', () => {
        if (this.props.tooltip) tooltip.style('display', 'none');
      })
      .transition()
      .duration(750)
      .attr('width', (d) => Math.abs(x(d[xAttr]) - x(0)))
      .attr('x', (d) => (negative ? x(Math.min(0, d[xAttr])) : null))
      .attr('fill', (d) => (d[xAttr] < 0 ? '#BA6A5D' : '#AFC576'));

    if (sideBar) {
      sideBarData.reverse();
      sideBarText.reverse();
      sideBarData.map((value, key) => {
        let label;
        if (d3.select(`#${chartID}--labels--${key}`).empty()) {
          label = d3.select(`#${chartID}`).select('.chart--body').append('g').attr('id', `${chartID}--labels--${key}`);
        } else {
          label = d3.select(`#${chartID}--labels--${key}`);
        }
        this.addLabels(
          label.selectAll(`.labels`).data(value),
          sideBarText[key],
          width,
          margin.right,
          key + 1,
          yAttr,
          y
        );
      });
    }
  };

  addLabels = (labels, textFormat, width, margin, index, yAttr, y) => {
    labels.exit().remove();
    labels
      .transition()
      .duration(750)
      .attr('x', width + margin / (index * 2))
      .attr('y', (d) => y(d[yAttr]) + y.bandwidth() / 2)
      .attr('text-anchor', 'middle')
      .attr('font-size', 10)
      .text((d) => textFormat(d));

    labels
      .enter()
      .append('text')
      .attr('class', 'labels')
      .attr('text-anchor', 'middle')
      .attr('x', width + margin / (index * 2))
      .attr('y', (d) => y(d[yAttr]) + y.bandwidth() / 2)
      .attr('font-size', 10)
      .text((d) => textFormat(d));
  };

  render() {
    const { margin, chartID, chartWidth, chartHeight, data } = this.props;
    const xHeight = Math.max(chartHeight, 20 * data.length + margin.top + margin.bottom);
    return (
      <div
        style={{
          height: chartHeight + 10,
          overflowY: 'auto',
        }}
      >
        <svg id={chartID} height={xHeight} width={chartWidth} style={this.props.chartStyle}>
          <g className="axis x--axis" transform={`translate(${margin.left}, ${xHeight - margin.bottom})`} />
          <g className="axis y--axis" transform={`translate(${margin.left}, ${margin.top})`} />
          <g className="chart--body" transform={`translate(${margin.left}, ${margin.top})`} />
        </svg>
      </div>
    );
  }
}

HorizontalBar.propTypes = {
  data: PropTypes.array.isRequired,
  margin: PropTypes.object.isRequired,
  chartHeight: PropTypes.number.isRequired,
  chartWidth: PropTypes.number.isRequired,
  chartID: PropTypes.string.isRequired,
  domain: PropTypes.array.isRequired,
  xAttr: PropTypes.string.isRequired,
  yAttr: PropTypes.string.isRequired,
  ticks: PropTypes.func.isRequired,
  negative: PropTypes.bool,
  tooltip: PropTypes.bool,
  tooltipHTML: PropTypes.func,
  chartStyle: PropTypes.object,
  sideBar: PropTypes.bool,
  sideBarData: PropTypes.array,
  sideBarText: PropTypes.array,
  handleChartClick: PropTypes.func,
};

HorizontalBar.defaultProps = {
  tooltip: false,
  sideBar: false,
};

export default HorizontalBar;
