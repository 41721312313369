import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { map, reduce } from 'lodash';
import { Switch, FormControlLabel } from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { MdWarning, MdError } from 'react-icons/md';
import BatchUpload from '../../../../lib/BatchUpload/BatchUpload.js';
import CSVInput from '../../../../lib/CSVInput/CSVInput';
import { Split, Left, Right } from '../../../../lib/Split/index';
import Box from '../../../../lib/Box/Box_editable';
import Actions from '../../../../../actions';

/**
 * Step 5 of Planning Tool - Add Coupons
 */
class PlanningToolNew5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listName: null,
      couponList: null,
      error: null,
      nameError: null,
      complete: false,
      maxReached: false,
      ignoreList: false,

      maxGroups: false,
      groupCount: 0,
      skuCount: 0,
      groupError: {
        type: null,
        message: null,
        field: null,
      },
      batchArray: [],
      badBatchName: false,
    };
    this.stepIndex = this.props.stepIndex;
    this.colNames = ['LIST_NAME', 'BARCODE'];
  }

  handleBatchAdd = (batchArray) => {
    const newArray = [];
    batchArray.forEach(function (i) {
      newArray.push({ name: i.name, barcodes: i.list });
    }, this);
    if (batchArray.length === 0) {
      this.props.actions.planning.updateCoupons(newArray);
      this.props.actions.planning.removeCompleted(this.stepIndex);
    } else {
      this.props.actions.planning.updateCoupons(newArray);
      this.props.actions.planning.addCompleted(this.stepIndex);
    }
  };

  render() {
    const data = this.props.planning.rerun
      ? this.props.planning.coupons.map(function (i) {
          return { name: i.name, list: i.barcodes };
        })
      : null;

    return (
      <div>
        <BatchUpload
          maxSize={8}
          onChange={this.handleBatchAdd}
          style={{ opacity: this.state.ignoreList ? 0.4 : 1 }}
          title="Coupons"
          colNames={this.colNames}
          groups={data}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ planningtool }) => ({
  planning: planningtool,
});

const {
  targetedComms: { planning },
  modal,
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    planning: {
      addCompleted: (index) => dispatch(planning.addCompleted(index)),
      removeCompleted: (index) => dispatch(planning.removeCompleted(index)),
      updateCoupons: (coupons) => dispatch(planning.updateCoupons(coupons)),
    },
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
      amendAction: (index, opts) => dispatch(modal.amendAction(index, opts)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanningToolNew5);
