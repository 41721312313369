import React from 'react';
import { connect } from 'react-redux';
import { Switch, FormControlLabel } from '@material-ui/core';
import Actions from '../../../../../actions';
import { Right } from '../../../../lib/Split/index';

import BatchUpload from '../../../../lib/BatchUpload/BatchUpload.js';

class PlanningToolNew3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listName: null,
      storeList: null,
      error: null,
      nameError: null,
      complete: false,
      ignoreList: false,
      maxGroups: false,
      groupCount: 0,
      skuCount: 0,
      groupError: {
        type: null,
        message: null,
        field: null,
      },
      batchArray: [],
      badBatchName: false,
    };
    this.stepIndex = this.props.stepIndex;
    this.colNames = ['STORELIST', 'STORE_NO'];
  }

  handleBatchAdd = (batchArray) => {
    const newArray = [];
    batchArray.forEach(function (i) {
      newArray.push({ name: i.name, stores: i.list });
    }, this);
    if (batchArray.length === 0) {
      this.props.actions.planning.updateStoreList(newArray);
      this.props.actions.planning.removeCompleted(this.stepIndex);
    } else {
      this.props.actions.planning.updateStoreList(newArray);
      this.props.actions.planning.addCompleted(this.stepIndex);
    }
  };

  render() {
    const data = this.props.planning.rerun
      ? this.props.planning.stores.map(function (i) {
          return { name: i.name, list: i.stores };
        })
      : null;
    return (
      <div>
        <BatchUpload maxSize={8} onChange={this.handleBatchAdd} title="Stores" colNames={this.colNames} groups={data} />
      </div>
    );
  }
}

const mapStateToProps = ({ planningtool }) => ({
  planning: planningtool,
});

const {
  targetedComms: { planning },
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    planning: {
      addCompleted: (index) => dispatch(planning.addCompleted(index)),
      removeCompleted: (index) => dispatch(planning.removeCompleted(index)),
      updateStoreList: (stores) => dispatch(planning.updateStoreList(stores)),
    },
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(PlanningToolNew3);
