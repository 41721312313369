import { postJSON } from '../../../../../../utils/async';

const getAisleData = (data) => {
  return new Promise((resolve, reject) => {
    postJSON(
      '/api/targeted-comms/smartcounts/counts/new/aisledata',
      {
        skus: data.skus,
        brand: data.brand,
        aisle: data.aisle,
      },
      (err, result) => {
        if (!err) {
          resolve(result);
        } else {
          reject({ errorCode: err, errorMessage: result.error });
        }
      }
    );
  });
};

export default getAisleData;
