import React from 'react';
import { connect } from 'react-redux';
import { IconButton, Menu, MenuItem, Divider, ListItemIcon } from '@material-ui/core';
import { MdMenu, MdEqualizer, MdRefresh } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { postJSON } from '../../../../../utils/async';
import Actions from '../../../../../actions';
import { modules } from '../../../../../constants/moduleInfo';

class PTMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchor: null,
      campaignLoading: false,
    };
  }

  closeMenu = () => {
    this.setState({ open: false });
  };

  getData = () => {
    this.props.getData();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleRefresh = () => {
    const {
      rowData: { queue_id },
    } = this.props;
    this.closeMenu();
    this.props.modal.toggle((modal) => ({
      show: true,
      title: 'Rerun Count',
      body: 'Would you like to adjust the count before it is rerun?',
      actions: [
        {
          text: 'Cancel',
          action: modal.close,
        },

        {
          text: 'Adjust',
          position: 'right',
          action: () => {
            new Promise((res, rej) => {
              res(this.props.restore(queue_id));
            }).then(() => {
              modal.close();
              this.props.navigate(`${modules.planningCounts.path}/clone?queue_id=${queue_id}`);
            });
          },
        },

        {
          text: 'Refresh',
          position: 'right',
          action: () => {
            modal.close();
            this.props.queue.requestQueueCheck('planningtool');
            postJSON('/api/targeted-comms/planningtool/count/refresh', { id: queue_id })
              .then(() => {
                this.getData();
              })
              .catch((err) => {
                console.error(err);
                this.props.modal.toggle((modal) => ({
                  show: true,
                  title: 'Error Refreshing Count',
                  style: 'error',
                  body: 'There was an error refreshing your count. Please try again.',
                  actions: [
                    {
                      action: modal.close,
                      text: 'Ok',
                    },
                  ],
                }));
                console.error(err);
              });
          },
        },
      ],
    }));
  };

  render() {
    return (
      <div>
        <IconButton
          onClick={(e) =>
            this.setState({
              open: true,
              anchor: e.currentTarget,
            })
          }
        >
          <MdMenu size={20} />
        </IconButton>

        <Menu onClose={this.handleClose} open={this.state.open} anchorEl={this.state.anchor}>
          {/*
                            Open Count
                        */}

          <MenuItem
            onClick={() => this.props.navigate(`${modules.planningCounts.path}/queue/${this.props.rowData.queue_id}`)}
          >
            <ListItemIcon>
              <MdEqualizer />
            </ListItemIcon>
            Open Count
          </MenuItem>

          <Divider />

          {/*
                            Refresh
                        */}

          <MenuItem
            lefticon={
              <ListItemIcon>
                <MdRefresh />
              </ListItemIcon>
            }
            onClick={this.handleRefresh}
          >
            <ListItemIcon>
              <MdRefresh />
            </ListItemIcon>{' '}
            Refresh
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const {
  queue,
  modal,
  targetedComms: { planning },
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  modal: {
    toggle: (opts) => dispatch(modal.toggle(opts)),
    update: (opts) => dispatch(modal.update(opts)),
  },
  queue: {
    requestQueueCheck: (codeName) => dispatch(queue.requestQueueCheck(codeName)),
  },
  restore: (id) => dispatch(planning.restoreCount(id)),
});

const PTMenuWrapper = ({ ...props }) => {
  const navigate = useNavigate();
  return <PTMenu {...props} navigate={navigate} />;
};

export default connect(null, mapDispatchToProps)(PTMenuWrapper);
