import React from 'react';
import { Button, Paper, Table, TableBody, TableHead, TableCell, TableRow } from '@material-ui/core';
import jss from 'jss';
import * as hexToRgba from 'color';
import { filter, map, reduce } from 'lodash';
import { connect } from 'react-redux';
import { MdClear, MdCheck } from 'react-icons/md';
import CSVInput from '../../../../lib/CSVInput/CSVInput';

import Spinner from '../../../../lib/Loading/Spinner';
import { colorNames } from '../../../../../constants/colors';
import { postJSON } from '../../../../../utils/async';
import Actions from '../../../../../actions';

const styles = {
  paper: {
    padding: '0 10',
    paddingBottom: 10,
    marginBottom: 10,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  flexItem: {
    flex: 1,
  },
  subtle: {
    fontSize: '10pt',
    color: colorNames.grey,
  },
  action: {
    textAlign: 'right',
    color: colorNames.red,
    cursor: 'pointer',
  },
  error: {
    display: 'inline-block',
    marginTop: 10,
    marginBottom: 10,
    padding: '5 10',
    backgroundColor: colorNames.red,
    color: colorNames.white,
    borderRadius: 5,
    cursor: 'default',
  },
  notCatable: {
    backgroundColor: hexToRgba(colorNames.red).alpha(0.15),
  },
  info: {
    fontSize: '10pt',
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 10,
    cursor: 'default',
  },
};

class StoreList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      processing: false,
      cloneLoaded: false,
    };
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'StoreList',
      classNamePrefix: 'StoreList-',
    });
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeStep === 2 && this.props.activeStep !== 2) {
      this.props.actions.newcount.addCompleted(2);
    }
    if (nextProps.stores !== this.props.stores && nextProps.clone && !this.state.cloneLoaded) {
      this.setState(
        {
          stores: nextProps.stores.map((store) => store.store_no),
          cloneLoaded: true,
        },
        () => nextProps.stores.length > 0 && this.handleAdd()
      );
    }
  }

  handleCSVInput = (e) => {
    if (e) {
      this.setState({ stores: e.split(',') });
    }
  };

  handleAdd = () => {
    this.setState(
      {
        processing: true,
      },
      () => {
        const { stores: storeInput } = this.state;
        const { stores } = this.props;
        let searchFor;

        searchFor = [...map(stores, (o) => o.store_no), ...storeInput];
        postJSON('/api/lookup/store/details', { stores: searchFor }, (err, res) => {
          if (err) {
            return console.error(err);
          }
          if (res) {
            this.props.actions.newcount.updateStores(res);
            this.setState({
              processing: false,
              stores: [],
            });
          }
        });
      }
    );
  };

  handleRemove = (store) => {
    let { stores } = this.props;
    stores = filter(stores, (o) => o.store_no !== store);
    this.props.actions.newcount.updateStores(stores);
  };

  render() {
    const { classes } = this.stylesheet;
    return (
      <div>
        {!this.props.locked && (
          <Paper className={classes.paper}>
            <CSVInput
              onChange={this.handleCSVInput}
              floatingLabelText="Store Numbers"
              rows={1}
              value={this.state.stores.join(',') || ''}
              disabled={this.state.processing}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  this.handleAdd();
                }
              }}
            />
            <div className={classes.flex}>
              <div className={jss.combine(classes.flexItem, classes.subtle)}>
                You can provide Stores individually, enter multiple using a comma to separate, or paste from Excel.
              </div>

              <div className={classes.flexItem} style={{ textAlign: 'right' }}>
                {this.state.processing ? (
                  <Spinner size={16} color={colorNames.blue} />
                ) : (
                  <Button
                    style={{ backgroundColor: this.state.stores.length === 0 ? 'transparent' : colorNames.blue }}
                    labelstyle={{
                      color: this.state.stores.length === 0 ? colorNames.grey : colorNames.white,
                    }}
                    onClick={this.handleAdd}
                    disabled={this.state.stores.length === 0}
                  >
                    Add
                  </Button>
                )}
              </div>
            </div>
          </Paper>
        )}
        {this.props.stores.length > 0 ? (
          <div>
            <div className={classes.info}>{this.props.stores.length} stores</div>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Store Number</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Area</TableCell>
                    <TableCell>Region</TableCell>
                    <TableCell>Nation</TableCell>
                    <TableCell>Format</TableCell>
                    <TableCell>CATable</TableCell>
                    {!this.props.locked && <TableCell />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(this.props.stores, (store) => {
                    return (
                      <TableRow key={store.store_no}>
                        <TableCell>{store.store_no}</TableCell>
                        <TableCell style={{ whiteSpace: 'pre-wrap' }}>{store.location_name}</TableCell>
                        <TableCell>{store.area_name}</TableCell>
                        <TableCell style={{ whiteSpace: 'pre-wrap' }}>{store.geog_region}</TableCell>
                        <TableCell>{store.geog_nation}</TableCell>
                        <TableCell>{store.store_format}</TableCell>
                        <TableCell>
                          {store.cat === 1 ? (
                            <MdCheck size={20} color={colorNames.black} />
                          ) : (
                            <MdClear size={20} color={colorNames.black} />
                          )}
                        </TableCell>
                        {!this.props.locked && (
                          <TableCell>
                            <div className={classes.action}>
                              <span onClick={() => this.handleRemove(store.store_no)}>Remove</span>
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </div>
        ) : (
          this.props.locked && <div className={classes.info}>No stores</div>
        )}
      </div>
    );
  }
}

const {
  targetedComms: {
    smartcounts: { newcount },
  },
} = Actions;

const mapStateToProps = (state) => ({
  stores: state.TargetedComms.Smartcounts.count.stores,
  locked: state.TargetedComms.Smartcounts.count.locked,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    newcount: {
      reset: () => dispatch(newcount.reset()),
      addCompleted: (index) => dispatch(newcount.addCompleted(index)),
      deleteCompleted: (index) => dispatch(newcount.deleteCompleted(index)),
      updateStores: (data) => dispatch(newcount.updateStores(data)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreList);
