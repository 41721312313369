// Dependencies
import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import Actions from '../../../../../actions';
import { Content, ContentTabbed } from '../../../../lib/Content';
import { modules } from '../../../../../constants/moduleInfo';
// Components
import ContentHeader from '../../../../lib/ContentHeader/index';
import SCTable from './SCTable';
import SCAdditionalSKUs from './SCAdditionalSKUs';
import Banner from '../../../../lib/Banner';

class SCNewCount2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completedTabs: ['brand', 'competitor', 'overlay'],
      unknownBrandSelected: null,
    };
    this.loading = false;
  }

  /**
   * Implemented to limit the number of re-renders
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    /**
     * When a tab is marked complete, we want to check if the next button should be enabled, but dont need to
     * re-render the component to do this.
     */
    if (nextState.completedTabs !== this.state.completedTabs) {
      if (
        nextState.completedTabs.includes('brand') &&
        nextState.completedTabs.includes('competitor') &&
        nextState.completedTabs.includes('overlay')
      ) {
        this.props.actions.newcount.addCompleted(1);
      } else {
        this.props.actions.newcount.removeCompleted(1);
      }
      return false;
    }

    return (
      (nextProps.activeStep === 1 || this.props.activeStep === 1) &&
      (nextState !== this.state ||
        this.props.activeStep !== nextProps.activeStep ||
        this.props.newcount !== nextProps.newcount)
    );
  }

  componentWillReceiveProps(nextProps, nextState) {
    /**
     * We need the aisle data to be fetched when this step is reached from step 1.
     * Listen for the props to change in that fashion otherwise we are making the call each time
     * something changes on a different step.
     */
    if (this.props.activeStep === 0 && nextProps.activeStep === 1) {
      this.props.actions.newcount.addCompleted(1);
    }
  }

  tabComplete = (tab) => {
    this.setState((prevState) => {
      if (!prevState.completedTabs.includes(tab)) {
        return { completedTabs: [...prevState.completedTabs, tab] };
      }
    });
  };

  tabIncomplete = (tab) => {
    this.setState((prevState) => {
      if (prevState.completedTabs.includes(tab)) {
        return { completedTabs: prevState.completedTabs.filter((item) => item !== tab) };
      }
    });
  };

  handleUnknownBrandChecked = (t) => {
    this.setState({ unknownBrandSelected: t });
  };

  render() {
    const qRunning = this.props.queueData && this.props.queueData.status === 'running';

    if (this.props.newcount.products.redemption.length > 0) {
      return (
        <div style={{ width: '100%' }}>
          <ContentHeader subHeader="− Aisle −" header={this.props.newcount.aisle} />
          {qRunning && <Banner type="info">Selections cannot be changed while the count is running.</Banner>}
          <Content fullBleed>
            <ContentTabbed
              tabLabels={['Redemption', 'Brand', 'Competitor', 'Overlay', 'Additional Overlay']}
              tabBadge={[this.props.newcount.redemptionSKUs.length]}
              tintColor={modules.smartCounts.color}
            >
              <div>
                <SCTable tab="redemption" />
              </div>

              <div>
                <SCTable
                  tab="brand"
                  tabComplete={{ add: this.tabComplete, remove: this.tabIncomplete }}
                  disableBrand={this.state.unknownBrandSelected === 'competitor'}
                  handleUnknownBrand={this.handleUnknownBrandChecked}
                />
              </div>
              <div>
                <SCTable
                  tab="competitor"
                  tabComplete={{ add: this.tabComplete, remove: this.tabIncomplete }}
                  disableBrand={this.state.unknownBrandSelected === 'brand'}
                  handleUnknownBrand={this.handleUnknownBrandChecked}
                />
              </div>
              <div>
                <SCTable tab="overlay" tabComplete={{ add: this.tabComplete, remove: this.tabIncomplete }} />
              </div>
              <div>
                <SCAdditionalSKUs />
              </div>
            </ContentTabbed>
          </Content>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  const {
    queue,
    TargetedComms: {
      Smartcounts: { count },
    },
  } = state;
  return {
    newcount: {
      locked: count.locked,
      redemptionSKUs: count.redemptionSKUs,
      aisle: count.aisle,
      brand: count.brand,
      products: count.productDetails,
    },
    queueData: find(queue.data, (o) => {
      return o.id === count.jobDetails.id;
    }),
  };
};

const {
  targetedComms: {
    smartcounts: { newcount },
  },
  loading,
  modal,
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loading: { toggle: (opts) => dispatch(loading.toggle(opts)) },
    modal: { toggle: (opts) => dispatch(modal.toggle(opts)) },
    newcount: {
      addCompleted: (index) => dispatch(newcount.addCompleted(index)),
      removeCompleted: (index) => dispatch(newcount.deleteCompleted(index)),
      updateAisleSensitivity: (sense) => dispatch(newcount.updateAisleSensitivity(sense)),
      updateAisleRule: (rule) => dispatch(newcount.updateAisleRule(rule)),
      receivedProductDetails: (data) => dispatch(newcount.receivedProductData(data)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SCNewCount2);
