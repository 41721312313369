import React from 'react';
import Button from '@material-ui/core/Button';
import { MdFileDownload } from 'react-icons/md';
import { Table, TableBody, TableHead, TableCell, TableRow, Paper } from '@material-ui/core';
import { reduce, map, find, sortBy } from 'lodash';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import ContentHeader from '../../../../lib/ContentHeader/index';
import SaveXLS from '../../../../../utils/SaveXLS';
import Actions from '../../../../../actions';
import { modules } from '../../../../../constants/moduleInfo';

const cell_names = ['New', 'Lapsed', 'Existing Solus', 'Existing Repertoire', 'Existing Brand', 'Acquisition', 'Total'];

const SCNewCount5 = ({ newcount, actions, queueData }) => {
  const navigate = useNavigate();
  const {
    jobDetails: { results },
  } = newcount;

  const volumes = reduce(
    results && results[0],
    (v, values) => {
      if (parseInt(values.OVERLAYS) === 0) {
        const total = reduce(
          values,
          (total, value, key) => {
            if (key !== 'CELL' && key !== 'OVERLAYS') {
              total += parseInt(value);
            }
            return total;
          },
          0
        );
        v.push({ ...values, TOTAL: total });
      }
      return v;
    },
    []
  );

  const additional = reduce(
    results && results[0],
    (a, values) => {
      if (parseInt(values.OVERLAYS) === 1) {
        const total = reduce(
          values,
          (total, value, key) => {
            if (key !== 'CELL' && key !== 'OVERLAYS') {
              total += parseInt(value);
            }
            return total;
          },
          0
        );
        a.push({ ...values, TOTAL: total });
      }
      return a;
    },
    []
  );

  const handleDownload = () => {
    const v = reduce(
      sortBy(volumes, (o) => o.CELL).concat(sortBy(additional, (o) => o.CELL)),
      (array, object) => {
        array.push({
          ...object,
          CELL: cell_names[object.CELL - 1],
          OVERLAYS: object.OVERLAYS === '0' ? 'Available Volumes' : 'Overlay Applied',
        });
        return array;
      },
      []
    );

    const skus = reduce(
      queueData.payload.skus,
      (array, s, t) => {
        map(s, (sku) => {
          array.push({ sku, type: t });
        });
        return array;
      },
      []
    );

    SaveXLS(
      [
        { name: 'SKUs', data: skus },
        { name: 'Volumes', data: v },
      ],
      `Smartcounts Volumes - ${queueData.payload.name}`
    );
  };

  if (volumes && volumes.length > 0) {
    return (
      <div style={{ marginTop: 20 }}>
        <div style={{ marginBottom: 20 }}>
          <div style={{ float: 'right' }}>
            <Button
              variant="contained"
              startIcon={
                <span>
                  <MdFileDownload />
                </span>
              }
              onClick={handleDownload}
              style={{ marginRight: 10 }}
            >
              Download XLS
            </Button>
            <Button variant="contained" onClick={() => navigate(modules.smartCounts.path)}>
              Smartcounts
            </Button>
          </div>
        </div>
        <ContentHeader subHeader="Available Volumes" />
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cell</TableCell>
                <TableCell>Coupon</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Direct Mail</TableCell>
                <TableCell>MR</TableCell>
                <TableCell>New Nectar</TableCell>
                <TableCell>Digital</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortBy(volumes, (o) => o.CELL).map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{cell_names[row.CELL - 1]}</TableCell>
                    <TableCell>{row.COUPON}</TableCell>
                    <TableCell>{row.EMAIL}</TableCell>
                    <TableCell>{row.DM}</TableCell>
                    <TableCell>{row.MR}</TableCell>
                    <TableCell>{row.NEW_NECTAR}</TableCell>
                    <TableCell>{row.DIGITAL}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
        {additional.length > 0 && (
          <div style={{ marginTop: 20 }}>
            <ContentHeader subHeader="Overlay Applied" />
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Cell</TableCell>
                    <TableCell>Coupon</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Direct Mail</TableCell>
                    <TableCell>MR</TableCell>
                    <TableCell>New Nectar</TableCell>
                    <TableCell>Digital</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortBy(additional, (o) => o.CELL).map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{cell_names[row.CELL - 1]}</TableCell>
                        <TableCell>{row.COUPON}</TableCell>
                        <TableCell>{row.EMAIL}</TableCell>
                        <TableCell>{row.DM}</TableCell>
                        <TableCell>{row.MR}</TableCell>
                        <TableCell>{row.NEW_NECTAR}</TableCell>
                        <TableCell>{row.DIGITAL}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Paper>
          </div>
        )}
      </div>
    );
  }
  return <div>Loading results...</div>;
};

const mapStateToProps = (state) => {
  const {
    queue,
    TargetedComms: {
      Smartcounts: { count },
    },
  } = state;
  return {
    newcount: count,
    queueData: find(queue.data, (o) => {
      return o.id === count.jobDetails.id;
    }),
  };
};

const {
  targetedComms: {
    smartcounts: { newcount },
  },
  modal,
  loading,
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    newcount: {
      addCompleted: (index) => dispatch(newcount.addCompleted(index)),
      deleteCompleted: (index) => dispatch(newcount.deleteCompleted(index)),
    },
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
    },
    loading: {
      toggle: (opts) => dispatch(loading.toggle(opts)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SCNewCount5);
