import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import Toggle from 'material-ui/Toggle';
import { reduce } from 'lodash';

import Dropzone from 'react-dropzone';
import { MdWarning, MdError } from 'react-icons/md';
import CSVInput from '../CSVInput/CSVInput';
import { Split, Left, Right } from '../Split/index';
import Box from '../Box/Box_editable';
import Actions from '../../../actions';
import Badge from '../Badge/index';

/*

    Props:

    handleFileDrop
    colNames
    maxSize
    error

*/

class Filedrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { colNames } = this.props;
    const colString =
      colNames.length == 3 ? `${colNames[1]}, ${colNames[0]} and ${colNames[2]}` : `${colNames[0]} and ${colNames[1]}`;
    return (
      <Dropzone onDrop={this.props.handleFileDrop}>
        {({ getRootProps, getInputProps }) => (
          <section className="container">
            <div
              {...getRootProps({ className: 'dropzone' })}
              style={{
                border: '2px dashed grey',
                borderRadius: 10,
                padding: 20,
                textAlign: 'center',
                cursor: 'pointer',
              }}
            >
              <input {...getInputProps()} />
              <p>Drop a CSV here to upload or click here to browse your computer.</p>
              <small>
                <b>Note:</b> The file must consist of {this.props.colNames.length} columns, {colString} and the overall
                number of groups must not exceed {this.props.maxSize}.
              </small>
              {this.props.error.type === 'warning' ? (
                <div style={{ marginTop: 10, color: 'orange' }}>
                  <MdWarning /> {this.props.error.message}
                </div>
              ) : (
                ''
              )}
              {this.props.error.type === 'error' ? (
                <div style={{ marginTop: 10, color: 'red' }}>
                  <MdError /> {this.props.error.message}
                </div>
              ) : (
                ''
              )}
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}

export default Filedrop;
