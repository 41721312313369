import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';

class ProgressArc extends React.Component {
  componentDidMount() {
    this.addArc();
    this.addArc().remove();
    this.addArc()
      .transition()
      .duration(750)
      .call(this.arcTween, 2 * Math.PI * this.props.data.value, this.arc());
  }

  getSVG = () => {
    return d3.select(`#${this.props.arcID}`).select('g');
  };

  arc = () => {
    const { width, height, pathStyle } = this.props;
    const radius = Math.min(width, height) / 2;
    return d3
      .arc()
      .innerRadius(radius - pathStyle.width)
      .outerRadius(radius)
      .startAngle(0);
  };

  addArc = () => {
    const { pathStyle } = this.props;
    return this.getSVG().append('path').datum({ endAngle: 0 }).style('fill', pathStyle.fill).attr('d', this.arc());
  };

  arcTween = (transition, newAngle, arc) => {
    transition.attrTween('d', (d) => {
      const interpolate = d3.interpolate(d.endAngle, newAngle);
      const newArc = d;
      return (t) => {
        newArc.endAngle = interpolate(t);
        return arc(newArc);
      };
    });
  };

  render() {
    const { width, height, arcID, data } = this.props;
    return (
      <svg id={arcID} height={width} width={height}>
        <g transform={`translate(${width / 2},${height / 2})`} />
        <g transform={`translate(${width / 2},${height / 2})`}>
          <text
            style={{
              textAnchor: 'middle',
            }}
          >
            <tspan x="0" dy="0" className={this.props.valueClass}>
              {`${(data.value * 100).toFixed(2)}%`}
            </tspan>
            <tspan x="0" dy="15" className={this.props.labelClass}>
              {data.text}
            </tspan>
          </text>
        </g>
      </svg>
    );
  }
}

ProgressArc.propTypes = {
  data: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  arcID: PropTypes.string.isRequired,
  pathStyle: PropTypes.object.isRequired,
  valueClass: PropTypes.string,
  labelClass: PropTypes.string,
};

export default ProgressArc;
