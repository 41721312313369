import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { extent } from 'd3';
import { returnChart, returnInfo } from './utils';

class ResultChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upliftData: null,
      selectedType: 'Channel',
      selectedMetric: 'KNOWN_SPEND',
      selectedChannelType: 'Single',
      selectedRadio: 0,
      view: 'chart',
      clickDetails: null,
      order: [
        'KNOWN_SPEND',
        'UNITS',
        'TOTAL_CUSTS',
        'NEW_CUSTS',
        'GCS_CUSTS',
        'VISITS',
        'FOP',
        'SPC',
        'UPV',
        'SPV',
        'PEN',
        'SOW',
      ],
      upliftData: this.getData(),
    };
  }

  getData() {
    const { data } = this.props;
    const { uplifts } = data;
    const { campaigns } = data;
    const upliftData = _.reduce(
      uplifts,
      (result, value, key) => {
        if (key === 'Channel') {
          result[key] = _.reduce(
            value,
            (r, v, k) => {
              if (k === 'KNOWN_SPEND' || k === 'UNITS') {
                r[k] = {
                  perc: {
                    All: v.perc.sort((a, b) => a.value - b.value),
                    Single: _.filter(v.perc, (o) => o.type.indexOf(',') === -1).sort((a, b) => a.value - b.value),
                    Multiple: _.filter(v.perc, (o) => o.type.indexOf(',') !== -1).sort((a, b) => a.value - b.value),
                  },
                  custs: {
                    All: v.custs.sort((a, b) => a.value - b.value),
                    Single: _.filter(v.custs, (o) => o.type.indexOf(',') === -1).sort((a, b) => a.value - b.value),
                    Multiple: _.filter(v.custs, (o) => o.type.indexOf(',') !== -1).sort((a, b) => a.value - b.value),
                  },
                };
              } else {
                r[k] = {
                  perc: {
                    All: v.perc.sort((a, b) => a.value - b.value),
                    Single: _.filter(v.perc, (o) => o.type.indexOf(',') === -1).sort((a, b) => a.value - b.value),
                    Multiple: _.filter(v.perc, (o) => o.type.indexOf(',') !== -1).sort((a, b) => a.value - b.value),
                  },
                };
              }
              return r;
            },
            {}
          );
        } else if (key !== 'Channel' && key !== 'summary_results') {
          result[key] = _.reduce(
            value,
            (r, v, k) => {
              if (k === 'KNOWN_SPEND' || k === 'UNITS') {
                const arrPerc = v.perc.sort((a, b) => a.value - b.value).slice(-10);
                arrPerc.push({
                  type: 'All',
                  value: uplifts.summary_results[k].perc,
                  campaigns: campaigns.All,
                });
                const arrCusts = v.custs.sort((a, b) => a.value - b.value).slice(-10);
                arrCusts.push({
                  type: 'All',
                  value: uplifts.summary_results[k].custs,
                  campaigns: campaigns.All,
                });
                r[k] = { perc: arrPerc, custs: arrCusts };
              } else {
                const arr = v.perc.sort((a, b) => a.value - b.value).slice(-10);
                arr.push({
                  type: 'All',
                  value: uplifts.summary_results[k].perc,
                  campaigns: campaigns.All,
                });
                r[k] = { perc: arr };
              }
              return r;
            },
            {}
          );
        }
        return result;
      },
      {}
    );
    return upliftData;
  }

  handleChartClick = (d) => {
    this.setState({
      view: 'info',
      clickDetails: d,
    });
  };

  handleBackToChart = () => {
    this.setState({
      view: 'chart',
    });
  };

  domain = (data) => {
    const domain = extent(data, (d) => d.value);
    if (domain[0] === domain[1]) {
      if (domain[0] > 0) {
        domain[0] = 0;
      } else if (domain[0] < 0) {
        domain[1] = -domain[1];
      }
    } else if (domain[0] > 0) {
      domain[0] = 0;
    }
    if (domain[0] < 0 && domain[1] < 0) {
      domain[1] = -domain[0];
    }
    return domain;
  };

  render() {
    if (this.state.view === 'chart') {
      return returnChart.bind(this)();
    }
    return returnInfo.bind(this)(this.state.clickDetails);
  }
}

ResultChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export { ResultChart };
