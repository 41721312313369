import { combineReducers } from 'redux';
import campaign from './campaign';
import list from './list';
import count from './count';

export default combineReducers({
  campaign,
  list,
  count,
});
