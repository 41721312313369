import ActionTypes from '../constants/ActionTypes/index';

const { general } = ActionTypes;

const loading = (
  state = {
    show: false,
    text: null,
    doneText: null,
    fadeDelay: 0,
    progress: null,
    complete: false,
  },
  action
) => {
  switch (action.type) {
    case general.TOGGLE_LOADING:
      return {
        ...state,
        ...action.options,
      };
    case general.UPDATE_PROGRESS:
      return {
        ...state,
        progress: action.progress,
      };
    default:
      return state;
  }
};

export default loading;
