import React from 'react';
import { connect } from 'react-redux';
import { MenuItem, FormControl, Select } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { filter } from 'lodash';
import moment from 'moment';
import jss from '../../../../../utils/jss';
import { colorNames } from '../../../../../constants/colors';
import Actions from '../../../../../actions';
import Card from '../../../../lib/Card';
import {
  getCyclesList,
  getCycleStart,
  getFixedRedemptionEndDate,
  getPrintPeriod,
  getRedemptionPeriod,
  getSelectedCycle,
} from './selectors/getTimePeriods';
import getCampaignObject from './selectors/getCampaignObject';
import { getCountIsClone } from './selectors/getCount';

const styles = {
  splitWrap: {
    display: 'flex',
    marginTop: 20,
  },
  splitBox: {
    flex: '1',
    textAlign: 'center',
    marginRight: 10,
    '&:last-child': {
      marginRight: 0,
    },
  },
  cycleMenu: {
    lineHeight: '20px',
    paddingTop: 7.5,
    paddingBottom: 7.5,
  },
};

const hasDatePassed = (date) => moment(date).isBefore();

class CB_Timings extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'campaignCreator2',
      classNamePrefix: 'CampaignCreator-Step2-',
    });
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  handleCycle = (e, index) => {
    const { value } = e.target;
    this.props.actions.campaign.updateCycle(value);
    const selectedCycle = filter(this.props.cc.cycles, (cycle) => cycle.cycle === value)[0];
    this.setState({ cycle_start: selectedCycle.start_date });
    this.props.actions.campaign.updateRedemptionDate(null);
  };

  handlePrint = (e, index) => {
    this.props.actions.campaign.updatePrintPeriod(e.target.value);
    this.props.actions.campaign.updateRedemptionDate(null);
  };

  handleRedemption = (e, index) => {
    this.props.actions.campaign.updateRedemptionPeriod(e.target.value);
  };

  handleDate = (e, value) => {
    this.props.actions.campaign.updateRedemptionDate(e);
  };

  render() {
    const {
      classes: { splitWrap, splitBox, cycleMenu },
    } = this.stylesheet;

    return (
      <div className={splitWrap}>
        <Card header="Cycle" className={splitBox}>
          <FormControl style={{ minWidth: 120 }}>
            <Select
              labelId="regexThing"
              style={{ textAlign: 'left' }}
              onChange={this.handleCycle}
              value={this.props.cc.selectedCycle}
              autoWidth
              disabled={this.props.cc.stage > 0}
            >
              {this.props.cc.cycles.map((cycle, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={cycle.cycle}
                    label={cycle.cycle}
                    disabled={this.props.cc.isCloned ? false : hasDatePassed(cycle.start_date)}
                  >
                    <div className={cycleMenu}>
                      <div>{cycle.cycle}</div>
                      <div style={{ fontSize: 10, color: colorNames.grey }}>
                        <span style={{ paddingRight: 5 }}>{moment(cycle.start_date).format('Do MMM YYYY')}</span>
                        <span style={{ paddingRight: 5 }}>&rarr;</span>
                        <span style={{ paddingRight: 5 }}>{moment(cycle.end_date).format('Do MMM YYYY')}</span>
                        <span>&#9679; Book by {moment(cycle.booking_deadline).format('Do MMM YYYY')}</span>

                        {hasDatePassed(cycle.booking_deadline) && (
                          <span style={{ color: colorNames.orange, paddingLeft: 10 }}>Booking deadline passed</span>
                        )}
                      </div>
                    </div>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Card>
        <Card header="Print Period" className={splitBox}>
          <FormControl style={{ minWidth: 120 }}>
            <Select
              labelId="regexThing"
              onChange={this.handlePrint}
              value={this.props.cc.printPeriod}
              disabled={this.props.cc.stage > 0}
            >
              <MenuItem value={2}>2 weeks</MenuItem>
              <MenuItem value={3}>3 weeks</MenuItem>
              <MenuItem value={4}>4 weeks</MenuItem>
            </Select>
          </FormControl>
        </Card>
        <Card header="Redemption Period" className={splitBox}>
          <FormControl style={{ minWidth: 120 }}>
            <Select
              labelId="regexThing"
              style={{ textAlign: 'left' }}
              onChange={this.handleRedemption}
              value={this.props.cc.redemptionPeriod}
              disabled={this.props.cc.stage > 0}
            >
              <MenuItem value={2}>2 weeks rolling</MenuItem>
              <MenuItem value={3}>3 weeks rolling</MenuItem>
              <MenuItem value={4}>4 weeks rolling</MenuItem>
              <MenuItem value="fixed">Fixed End Date</MenuItem>
            </Select>
          </FormControl>
          {this.props.cc.redemptionPeriod === 'fixed' && (
            <DatePicker
              disableToolbar
              format="do MMM yyyy"
              variant="inline"
              autoOk
              emptyLabel="Select End Date"
              value={this.props.cc.redemptionEnd ? moment(this.props.cc.redemptionEnd).toDate() : null}
              onChange={this.handleDate}
              disabled={this.props.cc.stage > 0 || !this.props.cc.printPeriod}
              error={!this.props.cc.printPeriod && 'Select Print Period First' != null}
              helperText={!this.props.cc.printPeriod && 'Select Print Period First'}
              minDate={
                (this.props.cc.cycleStart &&
                  moment(this.props.cc.cycleStart)
                    .add(this.props.cc.printPeriod, 'weeks')
                    .subtract(1, 'days')
                    .toDate()) ||
                moment().add(this.props.cc.printPeriod, 'weeks').subtract(1, 'days').toDate()
              }
            />
          )}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cc: {
      stage: getCampaignObject(state).stage,
      cycles: getCyclesList(state),
      selectedCycle: getSelectedCycle(state),
      cycleStart: getCycleStart(state),
      printPeriod: getPrintPeriod(state),
      redemptionPeriod: getRedemptionPeriod(state),
      redemptionEnd: getFixedRedemptionEndDate(state),
      isCloned: getCountIsClone(state),
    },
  };
};

const {
  targetedComms: {
    smartcounts: { campaign },
  },
  modal,
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
    },
    campaign: {
      updateCycle: (cycle) => dispatch(campaign.updateCycle(cycle)),
      updatePrintPeriod: (period) => dispatch(campaign.updatePrintPeriod(period)),
      updateRedemptionPeriod: (period) => dispatch(campaign.updateRedemptionPeriod(period)),
      updateRedemptionDate: (date) => dispatch(campaign.updateRedemptionDate(date)),
    },
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CB_Timings);
