import React from 'react';
import { Route } from 'react-router-dom';
import { modules } from '../../../constants/moduleInfo';
import PlanningTool from '../../views/TargetedComms/PlanningTool';
import PlanningToolNew, { PlanningCountClone } from '../../views/TargetedComms/PlanningTool/views/New';
import PlanningPivot from '../../views/TargetedComms/PlanningTool/views/Pivot';
import { HasAccess } from './has-access';

const { planningCounts } = modules;

const HasPlanningCountsAccess = ({ children }) => <HasAccess accessFn={planningCounts.hasAccess}>{children}</HasAccess>;

export const PlanningCounts = (
  <Route path={planningCounts.path}>
    <Route
      index
      element={
        <HasPlanningCountsAccess>
          <PlanningTool />
        </HasPlanningCountsAccess>
      }
    />
    <Route
      path="new"
      element={
        <HasPlanningCountsAccess>
          <PlanningToolNew />
        </HasPlanningCountsAccess>
      }
    />
    <Route
      path="clone"
      element={
        <HasPlanningCountsAccess>
          <PlanningCountClone />
        </HasPlanningCountsAccess>
      }
    />
    <Route
      path="queue/:id"
      element={
        <HasPlanningCountsAccess>
          <PlanningPivot />
        </HasPlanningCountsAccess>
      }
    />
  </Route>
);
