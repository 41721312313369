import ActionTypes from '../constants/ActionTypes/index';

const { error } = ActionTypes;

const errorInfo = (
  state = {
    code: null,
    data: null,
  },
  action
) => {
  switch (action.type) {
    case error.INFO:
      return {
        ...state,
        code: action.code,
        data: action.data,
      };
    default:
      return state;
  }
};

export default errorInfo;
