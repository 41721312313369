import { createSelectorCreator } from 'reselect';
import { memoize, reduce } from 'lodash';
import { getCellOffers, getRequired } from './getCells';
import { getBarcodes } from './getBarcodes';

const createDeepEqualSelector = createSelectorCreator(memoize, (...args) =>
  args.reduce((acc, val) => `${acc}-${JSON.stringify(val)}`, '')
);

export default createDeepEqualSelector([getRequired, getCellOffers, getBarcodes], (required, offers, barcodes) => {
  if (Object.keys(barcodes).length > 0) {
    return reduce(
      required,
      (errors, code) => {
        let { type, value } = offers[code];
        value =
          type === 'points'
            ? value.length === 3
              ? value
              : `0${value}`
            : (parseFloat(value) * 100).toString().length === 3
            ? parseFloat(value) * 100
            : `0${value * 100}`;
        const conf = {
          start: 0,
          length: 0,
          value: 0,
        };

        if (type === 'points') {
          conf.start = 28;
          conf.slength = 15;
          conf.ovalue = 4;
          conf.unique_start = 7;
          conf.unique_len = 7;
        } else if (type === 'money') {
          conf.start = 99;
          conf.slength = 13;
          conf.ovalue = 9;
          conf.unique_start = 2;
          conf.unique_len = 7;
        }
        const barcode = barcodes[code];
        if (barcode) {
          const unique = barcode.substr(conf.unique_start, conf.unique_len);
          const count = Object.values(barcodes).filter((o) => o === barcode).length;
          if (barcode === '') {
            errors[code] = 'Barcode cannot be blank';
          } else if (barcode.indexOf(conf.start) !== 0) {
            errors[code] = `Barcode must start with ${conf.start}`;
          } else if (parseInt(barcode.substr(conf.ovalue, 3)) !== parseInt(value)) {
            errors[code] = 'Barcode offer value is incorrect';
          } else if (barcode.length !== conf.slength) {
            errors[code] = 'Barcode length must be 15 characters';
          } else if (/[A-Za-z]|\s/g.test(barcode)) {
            errors[code] = 'Barcode can only be numerical';
          } else if (count > 1) {
            errors[code] = 'Barcode already included in this campaign';
          } else if (Object.values(barcodes).includes(unique)) {
            errors[code] = 'Barcode must be unique';
          }
        } else {
          errors[code] = 'Barcode required';
        }
        return errors;
      },
      {}
    );
  }
});
