import jwt_decode from 'jwt-decode';
import FileSaver from 'file-saver';
import env from './env';
import config from '../../config';

const { logout } = config;

const API = env.api[env.name];
const LEGACY_API = env.legacyApi[env.name];

const LEGACY_ROUTES = [
  'smartcounts/campaign/proof',
  'smartcounts/data/catalina',
  'smartcounts/execution/catalina',
  'smartcounts/data/loyalty',
  'smartcounts/data/sainsburys',
  'smartcounts/count/skus',
  'smartcounts/list',
  'planningtool/xls',
  'queue',
];

const getApiEndpoint = (url) => {
  const isLegacyRoute = LEGACY_ROUTES.find((route) => url.includes(route));

  return isLegacyRoute ? LEGACY_API : API;
};

const checkToken = () => {
  let token = sessionStorage.getItem('token');
  const refreshToken = sessionStorage.getItem('refreshToken');
  const toke = token && jwt_decode(token);
  const reToke = token && jwt_decode(refreshToken);
  const current_time = new Date().getTime() / 1000;
  if (current_time > toke.exp) {
    if (current_time > reToke.exp) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = logout;
    }
    return fetch(`${API}/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${refreshToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('refreshToken', data.refreshToken);
        token = sessionStorage.getItem('token');
        return data.token;
      });
  }
  return new Promise(function (resolve, reject) {
    resolve(token);
  });
};

export const logoff = (url) => {
  const token = sessionStorage.getItem('token');
  return fetch(`${API}${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => res.json())
    .then((data) => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = logout;
    });
};

const returnJSON = (url, callback) => {
  return checkToken().then((token) => {
    const server = getApiEndpoint(url);
    const cb = callback || ((err, res) => new Promise((resolve, reject) => (err ? reject(err) : resolve(res))));
    return fetch(`${server}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200 || response.status === 202) {
          if (response.headers.get('content-type').indexOf('application/json') === 0) {
            return response.json();
          }
          return response.text();
        }
        return Promise.reject(response.text());
      })
      .then((json) => {
        return cb(null, json);
      })
      .catch((err) => {
        console.error(err);
        cb(err.status);
      });
  });
};

const postJSON = (url, json, callback) => {
  return checkToken().then((token) => {
    const server = getApiEndpoint(url);
    const cb = callback || ((err, res) => new Promise((resolve, reject) => (err ? reject(err) : resolve(res))));
    const req = fetch(`${server}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(json),
    }).then((response) => {
      if (response.status === 200 || response.status === 202) {
        if (response.headers.get('content-type').indexOf('application/json') === 0) {
          return response.json();
        }
        return response.text();
      }
      return Promise.reject(response.text());
    });

    if (callback) {
      req
        .then((res) => {
          cb(null, res);
        })
        .catch((err) => {
          cb(err);
        });
    } else {
      return req;
    }
  });
};

const postDownload = (url, json, callback) => {
  return checkToken().then((token) => {
    const server = getApiEndpoint(url);
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${server}${url}`, true);
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.setRequestHeader('Content-type', 'application/json');
    // xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
    xhr.responseType = 'arraybuffer';
    xhr.onload = () => {
      if (xhr.status !== 200) {
        callback(xhr.statusText, null);
        return;
      }
      const byteArray = new Uint8Array(xhr.response);
      callback(null, byteArray);
    };
    xhr.send(JSON.stringify(json));
  });
};

const deleteJSON = (url, json, callback) => {
  return checkToken().then((token) => {
    const server = getApiEndpoint(url);
    const cb = callback || ((err, res) => new Promise((resolve, reject) => (err ? reject(err) : resolve(res))));
    return fetch(`${server}${url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(json),
    })
      .then((response) => {
        if (response.headers.get('content-type').indexOf('application/json') === 0) {
          return response.json();
        }
        return response.text();
      })
      .then((res) => {
        return cb(null, res);
      })
      .catch((err) => cb(err.status));
  });
};

const patchJSON = (url, json, callback) => {
  return checkToken().then((token) => {
    const server = getApiEndpoint(url);
    const cb = callback || ((err, res) => new Promise((resolve, reject) => (err ? reject(err) : resolve(res))));
    return fetch(`${server}${url}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(json),
    })
      .then((response) => {
        if (response.headers.get('content-type').indexOf('application/json') === 0) {
          return response.json();
        }
        return response.text();
      })
      .then((res) => {
        return cb(null, res);
      })
      .catch((err) => cb(err.status));
  });
};

const saveDownload = (url, opts, callback) => {
  return checkToken().then((token) => {
    const server = getApiEndpoint(url);
    const cb = typeof opts === 'function' ? opts : callback;

    const xhr = new XMLHttpRequest();

    xhr.open('GET', `${server}${url}`, true);
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    xhr.responseType = 'arraybuffer';

    xhr.onload = () => {
      if (xhr.status !== 200) {
        cb(xhr.statusText, null);
        return;
      }

      const byteArray = new Uint8Array(xhr.response);
      if (typeof opts === 'object') {
        if (opts.save) {
          const blob = new Blob([byteArray], { type: opts.mimeType });
          FileSaver.saveAs(blob, `${opts.path}`);
          cb(null, opts.path);
        }
      } else {
        cb(null, byteArray);
      }
    };
    xhr.send();
  });
};

export { returnJSON, postJSON, deleteJSON, patchJSON, postDownload, saveDownload };
