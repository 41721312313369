import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import * as hexToRgba from 'color';
import { colorNames, theme } from '../../../constants/colors';

const { palette } = theme;

const styles = StyleSheet.create({
  line: {
    display: 'flex',
    flex: '1 0 auto',
    margin: '0 5px',
    height: 2,
    backgroundColor: '#C8C8C8',
    content: ' ',
    position: 'relative',
  },
  lineInner: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: 0,
    transition: 'width 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    backgroundColor: hexToRgba(colorNames.lightGreen).alpha(0.5),
  },
  breadcrumbContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    height: '40px',
    transition: 'all 0.2s ease-in-out',
  },
  breadcrumb: {
    opacity: 0.5,
    borderRadius: 20,
    padding: '5 10',
    color: 'white',
    userSelect: 'none',
    cursor: 'default',
    backgroundColor: palette.disabledColor,
    transition: 'all 0.5s ease-in-out',
    transform: 'scale(0.9)',
  },
  active: {
    opacity: 1,
    transform: 'scale(1)',
    transition: '0.5s',
  },
  complete: {
    backgroundColor: colorNames.lightGreen,
    cursor: 'pointer',
  },
  breadcrumbStep: {
    fontWeight: 'bold',
    marginRight: 5,
  },
  svg: {
    opacity: 0.5,
    height: '32px',
    width: '32px',
    cursor: 'pointer',
    transition: 'all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    transform: 'scale(0.8)',
  },
  circle: {
    transition: 'all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    fill: palette.disabledColor,
  },
  circleComplete: {
    fill: colorNames.lightGreen,
  },
});

export const Breadcrumbs = ({ selectedValue, stepTitles, completed, onClick, ...rest }) => {
  if (typeof stepTitles === 'number') {
    return (
      <div className={css(styles.breadcrumbContainer)} {...rest}>
        {new Array(stepTitles).fill(0).reduce((result, item, index) => {
          result.push(
            <svg
              key={`circle_${index}`}
              viewBox="0 0 24 24"
              onClick={onClick}
              className={css(styles.svg, selectedValue === index && styles.active)}
              style={{ ...(!onClick && { cursor: 'default' }) }}
              data-id={index}
            >
              <circle
                className={css(styles.circle, completed.includes(index) && styles.circleComplete)}
                cx="12"
                cy="12"
                r="10"
              />
              <text x="12" y="16" textAnchor="middle" fontSize="12" fill="#fff">
                {index + 1}
              </text>
            </svg>
          );
          if (index < stepTitles - 1) {
            result.push(
              <div key={`line_${index}`} className={css(styles.line)}>
                <div
                  className={css(styles.line_inner)}
                  style={{
                    width: selectedValue > index ? '100%' : 0,
                  }}
                />
              </div>
            );
          }
          return result;
        }, [])}
      </div>
    );
  }
  stepTitles = stepTitles.filter((t) => t !== undefined);
  return (
    <div className={css(styles.breadcrumbContainer)} {...rest}>
      {stepTitles.reduce((result, title, index) => {
        result.push(
          <div
            key={`step_${index}`}
            onClick={onClick}
            className={css(
              styles.breadcrumb,
              index === selectedValue && styles.active,
              completed.includes(index) && styles.complete
            )}
            data-id={index}
          >
            <span className={css(styles.breadcrumbStep)}>{index + 1}</span>
            {title}
          </div>
        );
        if (index < stepTitles.length - 1) {
          result.push(
            <div key={`line_${index}`} className={css(styles.line)}>
              <div
                className={css(styles.lineInner)}
                style={{
                  width: selectedValue > index ? '100%' : 0,
                }}
              />
            </div>
          );
        }
        return result;
      }, [])}
    </div>
  );
};
