import ActionTypes from '../constants/ActionTypes/index';
import { toggle as loadingToggle } from './loading';

/**
 * Switch the modal
 * @param {boolean} opts.show - create/destorys modal
 * @param {string} opts.title - the title of the modal
 * @param {string} [opts.style] - the type of modal dictates the icon to display next to the title; error, warning, success, info
 * @param {ReactElement} opts.body - the content of the modal
 * @returns {function(*)}
 */
const toggle = (args) => {
  return (dispatch, getState) => {
    // Force closure of the loading screen if its visible.
    let opts;
    if (typeof args === 'object') {
      opts = args;
    } else if (typeof args === 'function') {
      opts = args({
        updateButton: (index, props) => dispatch(amendAction(index, props)),
        close: () => dispatch({ type: ActionTypes.general.TOGGLE_MODAL, show: false }),
      });
    }

    if (opts.show && getState().loading.show) {
      dispatch(loadingToggle({ show: false }));
    }

    dispatch({
      type: ActionTypes.general.TOGGLE_MODAL,
      ...opts,
    });
  };
};

const update = (opts) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.general.AMEND_MODAL,
      ...opts,
    });
  };
};

/**
 * amendAction
 * @param {Integer} actionIndex - the index number for the action to mutate
 * @param {Object} actionChange - the action changes to be made in e.g. {disabled: false}
 */
const amendAction = (actionIndex, actionChange) => {
  return (dispatch, getState) => {
    const state = getState().modal;
    const { actions } = state;
    actions[actionIndex] = { ...actions[actionIndex], ...actionChange };
    return dispatch({
      type: ActionTypes.general.TOGGLE_MODAL,
      ...state,
      actions,
    });
  };
};

export default { toggle, update, amendAction };
