import React from 'react';
import { connect } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import { unset, map, cloneDeep } from 'lodash';
import moment from 'moment';
import { MdInfoOutline } from 'react-icons/md';
import Dialog from '@material-ui/core/Dialog';
import { FormControlLabel, Button } from '@material-ui/core';
import Actions from '../../../../../actions';
import Box from '../../../../lib/Box/Box';
import { Left, Right, Split } from '../../../../lib/Split/index';
import MetaBanding from '../../../../lib/Banding/MetaBanding.js';
import Banding from '../../../../lib/Banding/index.js';
import { returnJSON } from '../../../../../utils/async';
import TextInput from '../../../../lib/TextInput/TextInput';
import { colorNames } from '../../../../../constants/colors';
import jss from '../../../../../utils/jss';
import Badge from '../../../../lib/Badge';

import { DialogModal, InfoDialog, SaveModal, OpenModal, Modal } from '../../../../lib/Dialog/Dialog.js';
import Flags from './flags.js';

const styles = {
  hr: {
    borderTop: 'none',
    borderBottom: `1px solid ${colorNames.grey}`,
    margin: '20 0',
  },
};

/**
 * Step 6 of Planning Tool - Select Variables
 */
class PlanningToolNew6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      variables: {},
      AISLE_UNITS: [{ low: 0, high: 0 }],
      AISLE_SPEND: false,
      AISLE_VISITS: false,
      open: [],
    };
    this.styleSheet = jss.createStyleSheet(styles, {
      Banding: 'PlanningToolStep6',
      classNamePrefix: 'PlanningTool-Step6-',
    });
    this.styleSheet.attach();
    this.stepIndex = this.props.stepIndex;
  }

  /**
   * When component loads, grab i2C Segmentations from server
   */
  componentDidMount() {
    returnJSON('/api/targeted-comms/planningtool/variables', (err, results) => {
      this.setState({ variables: results });
    });
  }

  componentWillUnmount() {
    this.styleSheet.detach();
  }

  /**
   * Called when togglging a variable
   * @param event
   * @param {bool} checked
   */
  handleToggle = (event, checked, key) => {
    let state;
    const toggle = event.target.name;
    if (key === 'i2C_Segmentations') {
      state = this.props.planning.segmentations;
      if (!checked) {
        state = state.filter((item) => item !== toggle);
      } else {
        state.push(toggle);
      }
      this.props.actions.planning.updateSegmentations(state);
    } else {
      state = this.props.planning.variables;
      if (!checked) {
        unset(state, [toggle]);
        this.props.actions.planning.updateVariables({ ...state });
      } else {
        this.props.actions.planning.updateVariables({ [toggle]: true, ...state });
      }
    }
  };

  /**
   * Called when toggling a banding component
   * @param {string} type - the name of the banding
   * @param {bool} isToggled
   */
  handleBandingToggle = (type, isToggled) => {
    const state = this.props.planning.variables;
    if (isToggled) {
      this.props.actions.planning.updateVariables({ [type]: [{ low: 0, high: 0 }], ...state });
    } else {
      unset(state, [type]);
      this.props.actions.planning.updateVariables({ ...state });
    }
  };

  /**
   * Called when the banding is updates
   * @param {string} type - banding/variable name
   * @param {object} data - the data to update to
   */
  handleBandingChange = (type, data) => {
    const { variables } = this.props.planning;
    variables[type] = data;
    this.props.actions.planning.updateVariables(variables);
  };

  /**
   * Called when clicking a Product Group to show the bandings
   * @param {string} group - group name
   */
  handleExpandProductGroup = (group) => {
    const { open } = this.state;
    if (open.includes(group)) {
      open.splice(open.indexOf(group), 1);
      this.setState({ open });
    } else {
      open.push(group);
      this.setState({ open });
    }
  };

  /**
   * Called when updating the banding of a product group
   * @param {number} i - the product group index
   * @param {string} type - the name of the variable
   * @param {object} data - new data to update to
   */
  handlePGBanding = (i, type, data) => {
    const productGroups = cloneDeep(this.props.planning.productGroups);
    const bandings = { ...productGroups[i].bandings, [type]: data };

    productGroups[i].bandings = bandings;
    this.props.actions.planning.updateGroups(productGroups);
  };

  handleAddBanding = (i, type, state) => {
    this.props.actions.planning.toggleBandingVisibility(i, type);
  };

  /**
   * Handle the change of the planning name
   * @param event
   * @param value
   */
  handleNameChange = (event) => {
    const { value } = event.target;
    this.props.actions.planning.updateName(value);
    if (value !== '') {
      this.props.actions.planning.addCompleted(this.stepIndex);
    } else {
      this.props.actions.planning.removeCompleted(this.stepIndex);
    }
  };

  render() {
    const { maxValue } = this.props.planning;

    const {
      classes: { hr },
    } = this.styleSheet;
    return (
      <Split layout={2575}>
        <Left>
          <Box static style={{ paddingTop: 0 }}>
            <TextInput
              floatinglabeltext="Plan Name"
              style={{ width: '100%', marginTop: '5px' }}
              value={this.props.planning.name}
              onChange={this.handleNameChange}
            />
          </Box>
          <Box static>
            <MdInfoOutline
              onClick={() => {
                this.info.handleOpen();
              }}
            />
            <InfoDialog
              scroll
              ref={(child) => {
                this.info = child;
              }}
              open={this.state.defs}
              title="Definitions"
              dialogContent={<Flags />}
            />
            {map(this.state.variables, (value, key, index) => {
              const output = [
                <div key={`header_${index}`}>
                  <h3 style={index === 0 ? { marginTop: 0 } : null}>{key}</h3>
                </div>,
              ];

              value.map((item, i) => {
                output.push(
                  <FormControlLabel
                    label={item}
                    control={
                      <Switch
                        key={`toggle_${i}`}
                        name={item}
                        data-group={key}
                        onChange={(event, checked) => this.handleToggle(event, checked, key)}
                        checked={
                          key === 'i2C_Segmentations'
                            ? this.props.planning.segmentations.includes(item)
                            : this.props.planning.variables[item]
                        }
                        disabled={this.props.freezeVariables}
                      />
                    }
                  />
                );
              });

              return output;
            })}
          </Box>
          <Box
            title="Aisle Quantity"
            removeTextFade
            open={'AISLE_UNITS' in this.props.planning.variables}
            onChanger={
              this.props.freezeVariables ? null : (event, checked) => this.handleBandingToggle('AISLE_UNITS', checked)
            }
          >
            <Banding
              data={this.props.planning.variables.AISLE_UNITS}
              disabled={this.props.freezeVariables}
              onChange={(rows) => this.handleBandingChange('AISLE_UNITS', rows)}
            />
          </Box>
          <Box
            title="Aisle Revenue (£)"
            removeTextFade
            open={'AISLE_SPEND' in this.props.planning.variables}
            onChanger={
              this.props.freezeVariables ? null : (event, checked) => this.handleBandingToggle('AISLE_SPEND', checked)
            }
          >
            <Banding
              data={this.props.planning.variables.AISLE_SPEND}
              incrementBy={0.01}
              disabled={this.props.freezeVariables}
              onChange={(rows) => this.handleBandingChange('AISLE_SPEND', rows)}
            />
          </Box>
          <Box
            title="Aisle Visits"
            removeTextFade
            open={'AISLE_VISITS' in this.props.planning.variables}
            onChanger={
              this.props.freezeVariables ? null : (event, checked) => this.handleBandingToggle('AISLE_VISITS', checked)
            }
          >
            <Banding
              data={this.props.planning.variables.AISLE_VISITS}
              disabled={this.props.freezeVariables}
              onChange={(rows) => this.handleBandingChange('AISLE_VISITS', rows)}
            />
          </Box>
        </Left>
        <Right>
          {map(this.props.planning.productGroups, (group, index) => {
            return (
              <Box
                key={index}
                removeTextFade
                open={this.state.open.includes(index)}
                title={
                  <div>
                    {group.name} <Badge style={{ borderColor: 'transparent' }}>{group.type}</Badge>
                  </div>
                }
                onClick={() => this.handleExpandProductGroup(index)}
              >
                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e, s) => this.handleAddBanding(index, 'qty', s)}
                      checked={group.bandings.show.includes('qty')}
                    />
                  }
                  label={<h3 style={{ margin: 0 }}>Quantity</h3>}
                />
                {group.bandings.show.includes('qty') && (
                  <Banding
                    data={group.bandings.qty}
                    disabled={this.props.freezeVariables}
                    onChange={(rows) => this.handlePGBanding(index, 'qty', rows)}
                  />
                )}
                <hr className={hr} />

                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e, s) => this.handleAddBanding(index, 'qty_t', s)}
                      checked={group.bandings.show.includes('qty_t')}
                    />
                  }
                  label={<h3 style={{ margin: 0 }}>Quantity in time period</h3>}
                />
                {group.bandings.show.includes('qty_t') && (
                  <MetaBanding
                    data={group.bandings.qty_t}
                    disabled={this.props.freezeVariables}
                    onChange={(rows) => this.handlePGBanding(index, 'qty_t', rows)}
                    maxValue={maxValue}
                  />
                )}
                <hr className={hr} />

                <FormControlLabel
                  control={
                    <Switch
                      onChange={(e, s) => this.handleAddBanding(index, 'rev', s)}
                      checked={group.bandings.show.includes('rev')}
                    />
                  }
                  label={<h3 style={{ margin: 0 }}>Revenue (&pound;)</h3>}
                />

                {group.bandings.show.includes('rev') && (
                  <Banding
                    data={group.bandings.rev}
                    incrementBy={0.01}
                    disabled={this.props.freezeVariables}
                    onChange={(rows) => this.handlePGBanding(index, 'rev', rows)}
                  />
                )}

                <div>
                  <hr className={hr} />
                  <FormControlLabel
                    label={<h3 style={{ margin: 0 }}>Share of Wallet (%)</h3>}
                    control={
                      <Switch
                        onChange={(e, s) => this.handleAddBanding(index, 'sow', s)}
                        checked={group.bandings.show.includes('sow')}
                      />
                    }
                  />

                  {group.bandings.show.includes('sow') && (
                    <Banding
                      data={group.bandings.sow}
                      maxValue={100}
                      units="%"
                      disabled={this.props.freezeVariables}
                      onChange={(rows) => this.handlePGBanding(index, 'sow', rows)}
                    />
                  )}
                </div>

                {this.props.planning.periodType === 'aislerule'
                  ? [
                      <hr className={hr} key={0} />,
                      <FormControlLabel
                        label={<h3 style={{ margin: 0 }}>Last Purchase (Weeks)</h3>}
                        control={
                          <Switch
                            key={1}
                            onChange={(e, s) => this.handleAddBanding(index, 'lp', s)}
                            checked={group.bandings.show.includes('lp')}
                          />
                        }
                      />,
                      group.bandings.show.includes('lp') && (
                        <Banding
                          key={2}
                          data={group.bandings.lp}
                          minValue={1}
                          units=" weeks"
                          disabled={this.props.freezeVariables}
                          maxValue={this.props.planning.rules}
                          onChange={(rows) => this.handlePGBanding(index, 'lp', rows)}
                        />
                      ),
                    ]
                  : null}
              </Box>
            );
          })}
        </Right>
      </Split>
    );
  }
}

const mapStateToProps = ({ planningtool }) => {
  return {
    planning: planningtool,
  };
};

const {
  targetedComms: { planning },
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    planning: {
      addCompleted: (index) => dispatch(planning.addCompleted(index)),
      removeCompleted: (index) => dispatch(planning.removeCompleted(index)),
      updateSegmentations: (data) => dispatch(planning.updateSegmentations(data)),
      updateVariables: (data) => dispatch(planning.updateVariables(data)),
      updateGroups: (data) => dispatch(planning.updateGroups(data)),
      toggleBandingVisibility: (i, f) => dispatch(planning.toggleBandingVisibility(i, f)),
      updateName: (data) => dispatch(planning.updateName(data)),
    },
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(PlanningToolNew6);
