import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Breadcrumbs } from '../Breadcrumbs';
import { calculateLeftPosition } from './utils/positions';
import jss from '../../../utils/jss';

class HorizontalScroll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: 0,
      dimensions: [],
      hideBreadcrumbs: false,
      enableButtons: true,
    };
    const { style = {}, children } = this.props;
    this.childrenArray = React.Children.toArray(children);
    this.styleSheet = jss.createStyleSheet({
      rootStyle: {
        width: '100%',
        minWidth: 960,
        alignItems: 'center',
        ...style.root,
      },
      container: {
        height: 500, // was 'calc(100% - 210px)', changed to arbitrary large height, as this caused x-browser issue
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        transition: 'height 0.2s ease-in-out',
        ...style.container,
      },
      slide: {
        opacity: 0.5,
        transform: 'scale(0.8)',
        transition: '1s',
        padding: '0 2',
        ...style.slide,
      },
      slideActive: {
        opacity: 1,
        padding: '0 2',
        transform: 'scale(1)',
        transition: '1s',
        ...style.slideActive,
      },
      slideContainer: {
        position: 'absolute',
        transition: '1s',
        height: '100%',
        display: 'flex',
        ...style.slideContainer,
      },
      actionBar: {
        display: 'flex',
        height: 60,
        width: '100%',
        padding: '0 2',
        boxSizing: 'border-box',
        alignItems: 'center',
        ...style.actionBar,
      },
      actionLeft: {
        flex: 1,
        ...style.actionLeft,
      },
      actionRight: {
        flex: 1,
        textAlign: 'right',
        ...style.actionRight,
      },
    });
  }

  componentDidMount() {
    this.styleSheet.attach();
    const ele = this.sizeElements;
    this.resizeListener = () => {
      ele();
    };
    window.addEventListener('resize', this.resizeListener);

    // not pretty but will do for now
    setTimeout(ele);
  }

  componentWillUnmount() {
    this.styleSheet.detach();
    window.removeEventListener('resize', this.resizeListener);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const { children } = nextProps;
    this.childrenArray = React.Children.toArray(children);
  }

  sizeElements = () => {
    const { childrenArray } = this;
    this.setState({
      dimensions: childrenArray.map((child, key) => {
        return this[`child-${key}`] && this[`child-${key}`].clientWidth;
      }),
      ...(this.element && {
        containerMiddle: this.element.offsetWidth / 2,
        containerWidth: this.element.offsetWidth,
      }),
    });
  };

  jumpToStep = (index) => {
    if (index < this.props.children.length && this.state.enableButtons) {
      this.setState({ selectedValue: index });
    }
  };

  handleChange = (event) => {
    const { completed } = this.props;
    const dataId = event.currentTarget.attributes.getNamedItem('data-id');
    if (dataId) {
      if (parseInt(dataId.value) <= Math.max(...completed)) {
        this.setState({ selectedValue: parseInt(dataId.value) });
      }
    }
  };

  handleNext = () => {
    const { handleNext } = this.props;

    if (handleNext) {
      handleNext(this.props, this.state, this._handleNextSetState);
      return;
    }
    this._handleNextSetState();
  };

  _handleNextSetState = () => {
    this.setState({ enableButtons: false }, () => {
      this.setState(
        (prevState, props) => {
          return {
            selectedValue:
              prevState.selectedValue < props.children.length ? prevState.selectedValue + 1 : prevState.selectedValue,
          };
        },
        () => {
          setTimeout(() => this.setState({ enableButtons: true }), 500);
        }
      );
    });
  };

  handleBack = () => {
    this.setState({ enableButtons: false }, () => {
      this.setState(
        (prevState) => {
          return {
            selectedValue: prevState.selectedValue === 0 ? prevState.selectedValue : prevState.selectedValue - 1,
          };
        },
        () => {
          setTimeout(() => this.setState({ enableButtons: true }), 500);
        }
      );
    });
  };

  _buttonText = (button) => {
    const { buttonLabels } = this.props;
    const { selectedValue } = this.state;
    if (buttonLabels && selectedValue in buttonLabels && button in buttonLabels[selectedValue]) {
      return buttonLabels[selectedValue][button];
    }
    return button === 'next' ? 'Next' : 'Back';
  };

  handleHideBreadcrumbs = (hide) => {
    if (hide !== this.state.hideBreadcrumbs) this.setState({ hideBreadcrumbs: hide });
  };

  render() {
    let { childrenArray } = this;

    const { classes } = this.styleSheet;

    const { selectedValue, containerWidth, containerMiddle, dimensions } = this.state;
    const { completed, stepTitles, fullBleed, handleFinish } = this.props;
    childrenArray = childrenArray.filter((o) => o !== false);
    const buttons = {
      next: this._buttonText('next'),
      back: this._buttonText('back'),
    };
    return (
      <div
        ref={(element) => {
          this.element = element;
        }}
        className={classes.rootStyle}
      >
        <Breadcrumbs
          selectedValue={selectedValue}
          stepTitles={stepTitles || childrenArray.length}
          onClick={this.handleChange}
          completed={completed}
          style={{ height: 60, ...(this.state.hideBreadcrumbs && { height: 0, overflow: 'hidden' }) }}
        />
        <div
          className={classes.container}
          ref={(element) => {
            this.container = element;
          }}
          style={{ ...(this.state.hideBreadcrumbs && { height: 'calc(100% - 150px)' }) }}
        >
          <div
            className={classes.slideContainer}
            style={{
              left: calculateLeftPosition(containerMiddle, dimensions, selectedValue, fullBleed),
            }}
          >
            {childrenArray.map((child, key) => {
              return (
                <div
                  style={{
                    display: 'inline-block',
                    boxSizing: 'border-box',
                    height: '100%',
                    overflowY: 'auto',
                    ...(fullBleed && {
                      width: containerWidth,
                    }),
                  }}
                  className={selectedValue === key ? classes.slideActive : classes.slide}
                  key={key}
                  data-id={key}
                  ref={(element) => {
                    this[`child-${key}`] = element;
                  }}
                >
                  {React.cloneElement(child, {
                    activeStep: selectedValue,
                    hideBreadcrumbs: this.handleHideBreadcrumbs,
                    handleNext: this.handleNext,
                    handleBack: this.handleBack,
                    jumpToStep: this.jumpToStep,
                    stepIndex: key,
                    stepInView: key === selectedValue,
                  })}
                </div>
              );
            })}
          </div>
        </div>
        {!this.state.hideUI && (
          <div className={classes.actionBar}>
            <div className={classes.actionLeft}>
              {selectedValue > 0 ? (
                <Button
                  style={{
                    backgroundColor: typeof buttons.back === 'object' ? buttons.back.color : null,
                    labelColor: typeof buttons.back === 'object' && buttons.back.color ? '#FFFFFF' : null,
                  }}
                  disabled={!this.state.enableButtons}
                  onClick={this.handleBack}
                  variant="contained"
                >
                  {typeof buttons.back === 'object' ? buttons.back.text : buttons.back}
                </Button>
              ) : null}
            </div>
            <div className={classes.actionRight}>
              {selectedValue < childrenArray.length - 1 || this._buttonText('next') !== 'Next' ? (
                <Button
                  style={{
                    backgroundColor: typeof buttons.next === 'object' ? buttons.next.color : null,
                    labelColor: typeof buttons.next === 'object' && buttons.next.color ? '#FFFFFF' : null,
                  }}
                  disabled={!completed.includes(selectedValue) || !this.state.enableButtons}
                  onClick={this.handleNext}
                  variant="contained"
                >
                  {typeof buttons.next === 'object' ? buttons.next.text : buttons.next}
                </Button>
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

HorizontalScroll.propTypes = {
  style: PropTypes.shape({
    container: PropTypes.object,
    slideContainer: PropTypes.object,
    slide: PropTypes.object,
    slideActive: PropTypes.object,
    actionBar: PropTypes.object,
    actionLeft: PropTypes.object,
    actionRight: PropTypes.object,
  }),
  completed: PropTypes.array.isRequired,
  buttonLabels: PropTypes.object,
  stepTitles: PropTypes.array,
  fullBleed: PropTypes.bool,
  handleFinish: PropTypes.func,
};

HorizontalScroll.defaultProps = {
  fullBleed: true,
};

export default HorizontalScroll;
