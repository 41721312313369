import * as hexToRgba from 'color';

const colorNames = {
  red: '#F44336',
  pink: '#E91E63',
  purple: '#9C27B0',
  deepPurple: '#673AB7',
  idigo: '#3F51B5',
  blue: '#2196F3',
  lightBlue: '#03A9F4',
  cyan: '#00BCD4',
  teal: '#009688',
  green: '#4CAF50',
  lightGreen: '#8BC34A',
  lime: '#CDDC39',
  excelGreen: '#2A7A52',
  yellow: '#FFEB3B',
  amber: '#FFC107',
  orange: '#f90',
  deepOrange: '#FF5722',
  brown: '#795548',
  grey: '#9E9E9E',
  lightGrey: '#e8e8e8',
  lighterGrey: '#F2F2F2',
  blueGrey: '#607D8B',
  graphite: '#263238',
  black: '#1b1b1b',
  white: '#FFFFFF',
};

const nectarColours = {
  pink: '#EB0050',
};

colorNames.primaryButton = nectarColours.pink;
colorNames.errorButton = colorNames.red;

const colors = [
  '#C3395C',
  '#7C35A2',
  '#303F9E',
  '#2A76D2',
  '#3687D1',
  '#4498A7',
  '#377A6B',
  '#438E3C',
  '#AEB332',
  '#f7c03e',
  '#EE7B33',
  '#E54A2C',
];

const theme = {
  typography: {
    fontFamily: 'Venn, Helvetica, sans-serif',
    button: {
      textTransform: 'none',
      fontSize: '16px',
      fontFamily: 'VennSemiEx, Helvetica, sans-serif',
    },
  },
  palette: {
    textColor: colorNames.graphite,
    primary1Color: colorNames.blueGrey,
    primary2Color: '#90A4AE',
    disabledColor: hexToRgba(colorNames.blueGrey).alpha(0.5),
    accent1Color: '#EF9A9A',
    pickerHeaderColor: '#8B98B6',
  },
  overrides: {
    MuiTableCell: {
      head: {
        fontWeight: 'bold',
      },
    },
  },
};

const colorIsDark = (c) => {
  c = c.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  return luma < 150;
};

export { colorNames, colors, theme, hexToRgba, colorIsDark };
