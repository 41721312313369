import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { reduce, isEqual, sortBy, filter } from 'lodash';
import moment from 'moment';
import { queueByIds } from '../../queue';
import ActionTypes from '../../../constants/ActionTypes/index';
import PermissionCheck from '../../../utils/PermissionCheck';
import { getUser } from '../../../utils/authProvider';

const {
  targetedComms: { smartcounts },
} = ActionTypes;

const defaultState = {
  loading: true,
  list: [],
  cycles: [],
  selectedCycle: 'all',
  filterText: null,
  locked: false,
  executionStatus: null,
  showMine: false,
  showArchived: false,
  start: moment().subtract(2, 'months').toDate(),
  end: moment().toDate(),
};

const list = (state = defaultState, action) => {
  switch (action.type) {
    case smartcounts.list.UPDATE: {
      return { ...state, ...action.data };
    }

    case smartcounts.list.STATUS: {
      return { ...state, executionStatus: action.status };
    }

    case smartcounts.list.CYCLE: {
      return { ...state, selectedCycle: action.cycle };
    }

    case smartcounts.list.LOCKED: {
      return { ...state, locked: action.locked };
    }

    case smartcounts.list.CLEAR: {
      return defaultState;
    }

    case smartcounts.list.FILTER: {
      return { ...state, filterText: action.text };
    }

    case smartcounts.list.START: {
      return { ...state, start: action.start };
    }

    case smartcounts.list.END: {
      return { ...state, end: action.end };
    }

    case smartcounts.list.CYCLES: {
      return { ...state, cycles: action.cycles };
    }

    case smartcounts.list.TOGGLE: {
      return {
        ...state,
        ...((action.showMine === true || action.showMine === false) && { showMine: action.showMine }),
        ...((action.showArchived === true || action.showArchived === false) && {
          showArchived: action.showArchived,
        }),
      };
    }

    default:
      return state;
  }
};

export const getEntireList = (state) => state.TargetedComms.Smartcounts.list.list;
export const getToggleState = (state) => ({
  showMine: state.TargetedComms.Smartcounts.list.showMine,
  showArchived: state.TargetedComms.Smartcounts.list.showArchived,
});
export const getList = createSelector([getEntireList, getToggleState, getUser], (list, toggles, user) => {
  if (toggles.showMine) {
    list = filter(list, (list) => list.user.id === user.id);
  }
  if (!toggles.showArchived) {
    list = filter(list, (list) => {
      const execution = filter(list.audit, (a) => a.type === 'EXECUTED');
      if (execution.length > 0 && list.stage === 6) {
        return moment(execution.date) > moment().subtract(30, 'days');
      }
      return true;
    });
  }
  return list;
});

const getQueueByIds = (state) => queueByIds(state);

export const countList = createSelector([getList], (list) => list.length);

export const getStageCount = createSelector(getList, (list) => {
  return reduce(
    list,
    (obj, row) => {
      if (row.type === 'count') {
        obj.counts++;
      } else {
        switch (row.stage) {
          case 0:
            obj.inprogress++;
            break;
          case 1:
            obj.approvals++;
            break;
          case 2:
            obj.approved++;
            break;
          case 3:
          case 4:
          case 7:
            obj.executions++;
            break;
          case 5:
          case 6:
            obj.executed++;
            break;
        }
      }
      obj.all++;
      return obj;
    },
    { all: 0, counts: 0, inprogress: 0, approvals: 0, approved: 0, executions: 0, executed: 0 }
  );
});

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
const getActualSelectedCycle = (state) => state.TargetedComms.Smartcounts.list.selectedCycle;
export const getFilterText = (state) => state.TargetedComms.Smartcounts.list.filterText;
export const getStart = (state) => state.TargetedComms.Smartcounts.list.start;
export const getEnd = (state) => state.TargetedComms.Smartcounts.list.end;

export const getSelectedCycle = createSelector([getList, getActualSelectedCycle], (list, b) => {
  const a = reduce(
    list,
    (a, r) => {
      if (r.type === 'campaign' && r.payload.time_periods && !a.includes(r.payload.time_periods.cycle)) {
        a.push(r.payload.time_periods.cycle);
      }
      return a;
    },
    []
  );
  if (a.length === 1) {
    return a[0];
  }
  return b;
});

const containsString = (needle, haystacks) => {
  const search = (needle, haystack) => {
    if (typeof needle === 'string' && typeof haystack === 'string')
      return haystack.toLowerCase().indexOf(needle.toLowerCase()) > -1;
  };

  if (Array.isArray(haystacks)) {
    let output = false;
    haystacks.forEach((h) => {
      if (!output) output = search(needle, h);
    });
    return output;
  }
  return search(needle, haystacks);
};

export const getCampaignList = createDeepEqualSelector(
  [getList, getQueueByIds, getSelectedCycle, getUser, getFilterText],
  (list, queue, filterCycle, currentUser, filterText) => {
    return list
      .filter((o) => {
        const { type, payload } = o;
        const cycle = type === 'campaign' && payload.time_periods ? payload.time_periods.cycle : undefined;
        return type === 'count' || !payload.time_periods || filterCycle === 'all' || (cycle && cycle === filterCycle);
      })
      .filter((o) => {
        if (filterText) {
          return containsString(filterText, [
            o.base_id,
            o.count_name,
            o.campaign_name,
            o.aisle,
            o.count_payload.brand,
            o.count_payload.manufacturer,
          ]);
        }
        return true;
      })
      .map((item) => {
        const {
          id,
          type,
          count_id,
          campaign_id,
          stage,
          queue_id,
          base_id,
          base_status,
          base_live_date,
          audit,
          date_created,
          count_name,
          campaign_name,
          aisle,
          count_complete,
          payload,
          count_payload,
          count_results,
          priority,
          rejected,
          user,
        } = item;

        const volumesExpired =
          (stage < 1 && count_complete && moment(count_complete).isBefore(moment().startOf('week'))) || false;
        const q = queue[queue_id];

        let status;
        if (stage === 0 || stage === null) {
          if (volumesExpired) {
            status = 'expired';
          } else if (q && q.status === 'completed') {
            if (rejected) {
              status = 'rejected';
            } else if (stage === null) {
              status = 'counted';
            } else {
              status = 'in progress';
            }
          } else if (q && q.status === 'error') {
            status = 'error';
          } else {
            status = 'counting';
          }
        } else if (stage === 3 && q && q.status !== 'completed') {
          if (q.status === 'error') {
            status = 'error';
          } else {
            status = 'executing';
          }
        } else {
          status = `stage:${stage}`;
        }

        const isOwner = user.id === currentUser.id;
        const isClientServices = PermissionCheck('TargetedComms_Smartcounts_Campaign_Execute');
        const cycle = type === 'campaign' && payload.time_periods ? payload.time_periods.cycle : undefined;

        return {
          id,
          type: type.toSentenceCase(),
          name: campaign_name || count_name,
          fullName: payload.fullName,
          aisle: aisle && aisle.toSentenceCase(),
          priority,
          cycle,
          status,
          count: {
            id: count_id,
            queue: queue_id,
            name: count_name,
            payload: count_payload,
            completed: count_complete,
            results: count_results,
            progress: q && q.progress,
            expired: volumesExpired,
          },
          campaign: {
            id: campaign_id,
            name: campaign_name,
            stage,
            created: date_created,
            rejected,
            audit,
            payload,
            execution: payload.execution,
          },
          base: {
            id: base_id,
            status: base_status,
            live_date: base_live_date,
          },
          actions: {
            canEdit: isOwner || isClientServices,
            canDelete: isOwner || isClientServices,
            canExecute: isClientServices,
          },
          user,
        };
      });
  }
);

export const getExecutionList = createSelector([getCampaignList], (list) => {
  return list && list.filter((o) => o.campaign.stage === 3 || o.campaign.stage === 4 || o.campaign.stage === 7);
});

export const getExecutionCycle = createSelector([getExecutionList], (list) => {
  return list[0] && list[0].cycle;
});

export const getExecutionListByBaseId = createSelector([getExecutionList], (list) => {
  return reduce(
    list,
    (array, row) => {
      array[row.base.id] = row;
      return array;
    },
    {}
  );
});

export const getCycles = createSelector([getList], (list) => {
  return sortBy(
    reduce(
      list,
      (c, r) => {
        if (r.payload.time_periods && r.payload.time_periods && !c.includes(r.payload.time_periods.cycle)) {
          c.push(r.payload.time_periods.cycle);
        }
        return c;
      },
      []
    ),
    (o) => o
  );
});
export default list;
