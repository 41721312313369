import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';

const Check = (props) => {
  return (
    <FormControlLabel
      labelPlacement="start"
      control={<Checkbox color="primary" />}
      label={props.label}
      // disabled={props.disabled}
      onChange={props.handleChange}
      defaultChecked={props.checked}
      labelposition={props.labelposition}
    />
  );
};

Check.propTypes = {
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  outerStyle: PropTypes.object,
  innerStyle: PropTypes.object,
  labelposition: PropTypes.string,
};

Check.defaultProps = {
  labelposition: 'right',
};

export default Check;
