import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { reduce } from 'lodash';
import { Radio, RadioGroup, FormControl, FormControlLabel } from '@material-ui/core';
import { MdWarning, MdError } from 'react-icons/md';
import Badge from '../Badge/index';
import CSVInput from '../CSVInput/CSVInput';
import { Split, Left, Right } from '../Split/index';
import Box from '../Box/Box_editable';
import Actions from '../../../actions';
import Filedrop from '../Filedrop';

// const fs = require('fs');

/*
    Pulls grouped numerical data from a CSV, returning it as an object

    Props:

    onChange - function called when groups added or removed/edited
    maxSize - maximum number of groups
    colNames - the expected names of the columns on the CSV
    title - title for the page

*/

class BatchUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listName: null,
      list: null,
      type: null,
      error: null,
      nameError: null,
      complete: false,
      ignoreList: false,
      groupError: {
        type: null,
        message: null,
        field: null,
      },
      groups: this.props.groups || [],
    };
  }

  /// when you type in a name
  handleNameChange = (name) => {
    let error = null;
    const { groups } = this.state;
    const names = reduce(
      groups,
      (array, group) => {
        array.push(group.name);
        return array;
      },
      []
    );
    if (/^[0-9]/.test(name)) {
      error = 'Name cannot start with a number';
    } else if (/\s/g.test(name)) {
      error = 'Name cannot contain a space';
    } else if (name.length > 10) {
      error = 'Name is too long, max 10 characters';
    } else if (names.includes(name)) {
      error = 'Name already in use';
    } else if (name === 'aisle' || name === 'aisles') {
      error = 'Name cannot be aisle';
    } else if (name && !/^[A-Za-z0-9]+$/g.test(name)) {
      error = 'Name cannot contain special characters';
    }
    this.setState({ listName: name, nameError: error }, () => this.handleComplete());
  };

  // when changing the input field
  listChange = (list) => {
    this.setState({ list }, () => this.handleComplete());
  };

  // Pressing Add button
  // adding the group (details are already in another part of state)
  handleAdd = () => {
    // const groups = [...this.props.planning.stores, { name: this.state.listName, stores: this.state.list }];
    // this.props.actions.planning.updatelist(groups);
    const pushedGroup =
      this.props.colNames.length == 3
        ? {
            name: this.state.listName,
            list: this.state.list,
            type: this.state.type,
            bandings: {
              show: [],
              qty: [{ low: 0, high: 0 }],
              rev: [{ low: 0, high: 0 }],
              sow: [{ low: 0, high: 0 }],
              lp: [{ low: 0, high: 0 }],
            },
          }
        : { name: this.state.listName, list: this.state.list };
    this.handleBatchAdd([pushedGroup]);
    this.setState(
      {
        listName: null,
        list: null,
        type: null,
      },
      () => this.handleComplete()
    );
  };

  // when a group is ready to be submitted, enables the add button
  handleComplete = () => {
    const { listName, list, error, nameError } = this.state;
    const type = this.props.colNames.length == 3 ? this.state.type : true;
    if (listName && list && type && !error && !nameError) {
      this.setState({ complete: true });
    } else {
      this.setState({ complete: false });
    }
  };

  // deleting a group
  handleRemove = (x) => {
    const { groups } = this.state;
    const newGroups = [];
    groups.forEach(function (g, i) {
      if (i !== x) newGroups.push(g);
    });
    this.setState({ groups: newGroups }, () => this.submitChanges(newGroups));
  };

  handleEdit(i) {
    const { groups } = this.state;

    const editedGroup = groups[i];
    const newGroups = [];
    groups.forEach(function (g, x) {
      if (i !== x) newGroups.push(g);
    });
    this.setState(
      { groups: newGroups, listName: editedGroup.name, list: editedGroup.list, complete: true, type: editedGroup.type },
      () => this.submitChanges(newGroups)
    );
  }

  warnEdit(i) {
    if (this.state.name || this.state.list) {
      this.props.actions.modal.toggle({
        show: true,
        title: 'Discard group currently being edited?',
        style: 'warning',
        body: '',
        actions: [
          {
            text: 'Cancel',
            action: () => this.props.actions.modal.toggle({ show: false }),
          },
          {
            text: 'Continue',
            position: 'right',
            action: () => {
              this.handleEdit(i);
              this.props.actions.modal.toggle({ show: false });
            },
          },
        ],
      });
    } else {
      this.handleEdit(i);
    }
  }

  // handles the actual submitting of new groups, whether single or in batches
  handleBatchAdd = (groups) => {
    if (this.state.groups.length + groups.length <= this.props.maxSize) {
      const longGroups = this.state.groups.concat(groups);
      this.setState({ groups: longGroups }, () => this.submitChanges(longGroups));
    } else {
      this.setState({
        groupError: {
          type: 'error',
          message: 'Adding these groups would exceed limit',
          field: null,
        },
      });
    }
  };

  submitChanges(changes) {
    this.props.onChange(changes);
  }

  formatCsvField = (field) => {
    return field.replace(/(\r\n|\n|\r)/gm, '');
  };

  handleFileDrop = (accepted, rejected) => {
    // return

    if (accepted && accepted[0].name.indexOf('.csv') > -1) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(accepted[0]);
        reader.onload = (e) => {
          resolve(reader.result);
        };
      })
        .then((data) => {
          const rows = data.split('\n');

          if (rows[0].split(',')[0] !== this.props.colNames[1]) {
            this.setState({ groupError: { type: 'warning', message: 'Incorrect column headings.', field: null } });
          } else if (
            rows.map((r, i) => i > 0 && r[0] !== this.props.colNames[1] && isNaN(r[0])).filter((f) => f).length > 0
          ) {
            this.setState({ groupError: { type: 'warning', message: 'Some rows are not numbers.', field: null } });
          } else {
            const batchGroups = {};

            const groupTypes = {};
            /* For each row... */
            for (let i = 1; i < rows.length; i++) {
              const fields = rows[i].split(',');
              const groupName = this.formatCsvField(String(fields[1]));

              // check group name is valid
              let error = null;
              if (/^[0-9]/.test(groupName)) {
                error = 'Group name cannot start with a number';
              } else if (/\s/g.test(groupName)) {
                error = 'Group name cannot contain a space';
              } else if (groupName.length > 10) {
                error = 'Group name is too long, max 10 characters';
              }
              if (error) {
                this.setState({
                  groupError: {
                    type: 'warning',
                    message: error,
                    field: null,
                  },
                });
                return;
              }

              if (groupName !== 'undefined' && groupName !== '') {
                if (!batchGroups[groupName]) {
                  batchGroups[groupName] = [fields[0]];
                } else {
                  batchGroups[groupName].push(fields[0]);
                }

                // If using types:

                if (this.props.colNames.length == 3) {
                  if (!fields[2].startsWith('Targeted') && !fields[2].startsWith('Redemption')) {
                    this.setState({
                      groupError: {
                        type: 'warning',
                        message: `Type column must contain 'Redemption' or 'Targeted' only. Please check your spelling.`,
                        field: null,
                      },
                    });
                    return;
                  }
                  if (!groupTypes[groupName]) {
                    groupTypes[groupName] = this.formatCsvField(fields[2]);
                  } else if (groupTypes[groupName] != this.formatCsvField(fields[2])) {
                    this.setState({
                      groupError: {
                        type: 'warning',
                        message:
                          'A group in this CSV has a type conflict. Groups must be all Redemption or all Targeted ',
                        field: null,
                      },
                    });
                    return;
                  }
                }
              }
            }
          }

          const keys = Object.keys(batchGroups);
          if (!this.batchNameChecks(keys)) {
            this.setState({
              groupError: {
                type: 'error',
                message: 'Includes a name already assigned to a group',
                field: null,
              },
            });
            return;
          }

          /* Creating groups, containing objects representing groups */
          const groups = [];
          keys.forEach(function (key) {
            let numString = '';
            batchGroups[key].forEach(function (x) {
              if (numString == '') {
                numString = x;
              } else {
                numString = `${numString},${x}`;
              }
            });
            numString = numString.slice(0, -1);

            const pushedGroup =
              this.props.colNames.length == 3
                ? {
                    name: key,
                    list: numString,
                    type: groupTypes[key],
                    bandings: {
                      show: [],
                      qty: [{ low: 0, high: 0 }],
                      rev: [{ low: 0, high: 0 }],
                      sow: [{ low: 0, high: 0 }],
                      lp: [{ low: 0, high: 0 }],
                    },
                  }
                : {
                    name: key,
                    list: numString,
                  };
            groups.push(pushedGroup);
          }, this);

          if (this.state.groups.length - Object.keys(batchGroups).length < this.props.maxSize) {
            this.setState({ groupError: { type: null, message: null, field: null } }, () => {
              this.handleBatchAdd(groups);
            });
          } else {
            this.setState({
              groupError: {
                type: 'warning',
                message: `Maximum group number exceeded. Found ${Object.keys(batchGroups).length} in the CSV provided.`,
                field: null,
              },
            });
          }
        })
        .catch((err) => {});
    }
    if (accepted[0].type !== 'text/csv') {
      this.setState({
        groupError: {
          type: 'warning',
          message: 'The file provided is not a CSV. Please try again with a CSV file.',
          field: null,
        },
      });
    } else if (rejected) {
      this.setState({
        groupError: { type: 'error', message: 'The file was rejected. Please try again.', field: null },
      });
    } else {
      this.setState({
        groupError: {
          type: 'error',
          message: 'An unknown groupError occurred. Please try again later.',
          field: null,
        },
      });
    }
  };

  batchNameChecks = (batchNames) => {
    let error = false;
    const { groups } = this.state;
    const names = reduce(
      groups,
      (array, group) => {
        array.push(group.name);
        return array;
      },
      []
    );

    batchNames.forEach(function (name) {
      if (names.includes(name)) {
        error = true;
      }
    });

    if (error) {
      return false;
    }
    return true;
  };

  renderBoxes() {
    const output = [];
    for (let i = 0; i < this.props.maxSize; i++) {
      if (this.state.groups[i]) {
        const count = this.state.groups[i].list.split(',').length;
        output.push(
          <Box
            key={i}
            onRemove={() => this.handleRemove(i)}
            onEdit={() => this.warnEdit(i)}
            static
            style={{ opacity: this.state.ignoreList ? 0.4 : 1 }}
            title={this.state.groups[i].name}
          >
            {count} Item
            {count > 1 ? 's' : null}
            {this.props.colNames.length == 3 ? (
              <div style={{ marginTop: 5 }}>
                <Badge>{this.state.groups[i].type}</Badge>
              </div>
            ) : null}
          </Box>
        );
      } else {
        output.push(<Box key={i} style={{ opacity: 0.4 }} static title={`Group ${i + 1}`} />);
      }
    }
    return output;
  }

  render() {
    const maxReached = this.state.groups.length == this.props.maxSize;
    return (
      <Split layout={7525}>
        <Left>
          <Box title={this.props.title} static style={{ opacity: this.state.ignoreList ? 0.4 : 1 }}>
            <TextField
              placeholder="Group name"
              style={{ width: '100%', marginBottom: 10 }}
              disabled={maxReached || this.state.ignoreList}
              value={this.state.listName || ''}
              onChange={(event) => {
                const { value } = event.target;
                this.handleNameChange(event.target.value);
              }}
              error={this.state.nameError != null}
              helperText={this.state.nameError}
              multiline
              rows={2}
              rowsMax={4}
            />
            <CSVInput
              placeholder="Group List"
              disabled={maxReached || this.state.ignoreList}
              value={this.state.list || ''}
              onChange={(list, err) => {
                this.setState({ error: err });
                if (!err) {
                  this.listChange(list);
                }
              }}
              disallowNegative
            />
            {this.props.colNames.length == 3 ? (
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  onChange={(event) => {
                    const { value } = event.target;
                    this.setState({ type: value }, () => this.handleComplete());
                  }}
                  disabled={this.state.maxGroups}
                  name="type"
                  value={this.state.type}
                >
                  <FormControlLabel
                    control={<Radio />}
                    value="Redemption"
                    label="Redemption"
                    style={{
                      width: 'auto',
                      float: 'left',
                      paddingRight: 20,
                    }}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value="Targeted"
                    label="Targeted"
                    style={{ width: 'auto', float: 'left' }}
                  />
                </RadioGroup>
              </FormControl>
            ) : null}
          </Box>
          {!maxReached ? (
            <Button
              variant="contained"
              disabled={!this.state.complete || this.state.ignoreList}
              onClick={() => this.handleAdd()}
              style={{ float: 'right' }}
            >
              Add
            </Button>
          ) : (
            <Button style={{ float: 'right' }} variant="contained" disabled>
              Max store lists reached
            </Button>
          )}
          <Box
            static
            style={{
              margin: 10,
              right: 10,
              top: 10,
            }}
          >
            {/* Filedrop for Planning Counts NewStep1 */}
            <Filedrop
              handleFileDrop={this.handleFileDrop}
              maxSize={this.props.maxSize}
              colNames={this.props.colNames}
              error={this.state.groupError}
            />
          </Box>
        </Left>
        <Right>{this.renderBoxes()}</Right>
      </Split>
    );
  }
}

const { modal } = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
      amendAction: (index, opts) => dispatch(modal.amendAction(index, opts)),
    },
  },
});
export default connect(null, mapDispatchToProps)(BatchUpload);
