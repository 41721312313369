import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import multi from 'redux-multi';
import reducers from '../reducers';

const middleware = compose(
  applyMiddleware(thunk, multi),
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
);

export default createStore(reducers, middleware);
