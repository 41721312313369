import React from 'react';
import PropTypes from 'prop-types';
import jss from '../../../utils/jss';

const styles = {
  wrap: {
    width: '100%',
    padding: '10 0',
    userSelect: 'none',
    cursor: 'default',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  subHeader: {
    fontWeight: 'lighter',
  },
  header: {
    fontSize: 25,
  },
};

const sheet = jss.createStyleSheet(styles, { meta: 'contentHeader', classNamePrefix: 'content-header-' }).attach();

const ContentHeader = ({ header, subHeader, style = {}, reverse, ...rest }) => {
  return (
    <div
      style={{ ...style.wrap, ...(reverse && { flexDirection: 'column-reverse' }) }}
      className={sheet.classes.wrap}
      {...rest}
    >
      {subHeader && (
        <div style={style.subHeader} className={sheet.classes.subHeader}>
          {subHeader}
        </div>
      )}
      <div style={style.header} className={sheet.classes.header}>
        {header}
      </div>
    </div>
  );
};

ContentHeader.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  style: PropTypes.object,
  reverse: PropTypes.bool,
};

ContentHeader.defaultProps = {
  reverse: false,
};

export default ContentHeader;
