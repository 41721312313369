import React from 'react';
import { connect } from 'react-redux';
import { getFilterText } from '../../../../../../reducers/TargetedComms/Smartcounts/list';
import { colorNames } from '../../../../../../constants/colors';

function highlight(inputText, text) {
  let out = inputText;
  const index = inputText.toLowerCase().indexOf(text.toLowerCase());
  if (index >= 0) {
    out = (
      <span style={{ userSelect: 'auto' }}>
        {out.substring(0, index)}
        <span style={{ backgroundColor: colorNames.yellow, userSelect: 'auto' }}>
          {out.substring(index, index + text.length)}
        </span>
        {out.substring(index + text.length)}
      </span>
    );
  }
  return out;
}

class HighlightString extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children: string, filterText } = this.props;
    return (
      <span style={{ userSelect: 'auto' }}>
        {filterText && typeof string === 'string' ? highlight(string, filterText) : string}
      </span>
    );
  }
}

const mapStateToProps = (state) => ({
  filterText: getFilterText(state),
});

export default connect(mapStateToProps)(HighlightString);
