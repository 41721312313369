import ActionTypes from '../../constants/ActionTypes';

const { targetedComms } = ActionTypes;

const save_targeted_comms = (
  state = {
    lastSave: null,
    error: null,
    saving: false,
  },
  action
) => {
  switch (action.type) {
    case targetedComms.SAVED_TARGETED_COMMS: {
      return {
        ...state,
        ...(action.lastSave && { lastSave: action.lastSave, error: null, saving: false }),
        ...(action.error && { lastSave: null, error: action.error, saving: false }),
        ...(action.saving && { lastSave: null, error: null, saving: action.saving }),
      };
    }

    default:
      return state;
  }
};

export default save_targeted_comms;
