import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdArrowDropUp, MdKeyboardArrowRight } from 'react-icons/md';
import { StyleSheet, css } from 'aphrodite/no-important';
import moment from 'moment';
import Actions from '../../../actions';
import Icon from '../Icon';
import { findModuleByQueueRoute } from '../../../constants/moduleInfo';
import ProgressBar from '../ProgressBar';
import packageJson from '../../../../../package.json';
import env from '../../../utils/env';
import logo from '../../../../images/nectar360-white.svg';
import Spinner from '../Loading/Spinner';

const runEnv = env.name;

const { version } = packageJson;

const SUPPORT_LINK =
  'https://sainsburys.service-now.com/assist?id=sc_cat_item&table=sc_cat_item&sys_id=79e8de141b3b38d03f00a6c7b04bcb3a';

const styles = StyleSheet.create({
  footer: {
    width: '100%',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#212529;',
    color: '#ffffff',
    justifyContent: 'space-between',
    '-webkit-app-region': 'none',
    flex: '0 0 auto',
    userSelect: 'none',
    fontSize: '12px',
    zIndex: 1,
  },
  queueWrap: {
    position: 'absolute',
    width: 500,
    left: 0,
    bottom: 0,
    background: 'inherit',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'all 0.5s ease-in-out',
    zIndex: 0,
    maxHeight: 500,
    overflowY: 'auto',
  },
  queue: {
    maxWidth: 500,
    // margin: '0 auto',
    padding: 10,
  },
  queueShow: {
    opacity: 1,
    bottom: 25,
    pointerEvents: 'auto',
  },
  queueButton: {
    cursor: 'pointer',
  },
  queueButtonFlip: {
    transform: 'rotate(180deg)',
  },
  qItem: {
    display: 'flex',
    alignContent: 'center',
    cursor: 'default',
    userSelect: 'none',
    marginBottom: '0.5rem',
  },
  qItem__Click: {
    cursor: 'pointer',
  },
  qItem__Icon: {
    display: 'flex',
    width: '100%',
  },
  qItem__Name: {
    marginLeft: 10,
  },
  qItem__Progress: {
    width: '50%',
    alignItems: 'center',
  },
  logo: {
    maxWidth: 20,
    marginLeft: 10,
    marginRight: 6,
  },
});

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  queueToggle = () => {
    this.props.actions.queue.toggle();
    if (this.props.queue.show) {
      this.props.actions.queue.removeQueueCheckRequest('footer');
    } else {
      this.props.actions.queue.requestQueueCheck('footer');
    }
  };

  openQueue = (route, id) => {
    this.queueToggle();
    this.props.navigate(route);
    // TODO: Improve targeting of queue items
    // this.props.actions.page.change(routeMapping[route].route, { id: id, ...routeMapping[route].params });
  };

  render() {
    const queueData = this.props.queue.data.filter((q) => {
      return moment(q.added) >= moment().subtract(4, 'hours');
    });
    const queueRunning = this.props.queue.data.filter((q) => q.status === 'running').length > 0;

    return (
      <div className={css(styles.footer)}>
        <div className={css(styles.queueWrap, this.props.queue.show && styles.queueShow)}>
          <div className={css(styles.queue)}>
            <ul>
              <li className={css(styles.qItem)}>Recent Jobs</li>
              {queueData.map((q) => {
                const module = findModuleByQueueRoute(q.route);

                return (
                  <li
                    key={q.id}
                    className={css(styles.qItem, q.status === 'completed' && styles.qItem__Click)}
                    onClick={() => q.status === 'completed' && module.path && this.openQueue(module.path, q.id)}
                  >
                    <div className={css(styles.qItem__Icon)}>
                      <Icon size={16} icon={module.icon} color={module.color} />
                      <span className={css(styles.qItem__Name)}>
                        {q.payload.name || q.friendly_name || `Job ${q.id}`}
                      </span>
                    </div>
                    <div
                      className={css(styles.qItem__Progress)}
                      style={{
                        ...(q.status !== 'complete' && { paddingRight: 12 }),
                        ...(q.status === 'complete' && { display: 'flex' }),
                      }}
                    >
                      {q.status === 'running' ? (
                        <ProgressBar progress={q.progress} textPosition="right" showText />
                      ) : q.status === 'error' ? (
                        'Error'
                      ) : q.status === 'completed' ? (
                        <>
                          Complete <MdKeyboardArrowRight />
                        </>
                      ) : (
                        'Pending...'
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <span style={{ marginLeft: 10.625, display: 'flex', alignItems: 'center' }}>
          {queueRunning && <Spinner size={16} style={{ marginRight: 5 }} />}
          {queueData.length > 0 && (
            <span className={css(styles.queueButton)} title="Switch Queue">
              <MdArrowDropUp
                size={22}
                onClick={() => this.queueToggle()}
                style={{ ...(this.props.queue.show && { transform: 'rotate(180deg)' }) }}
              />
            </span>
          )}
        </span>

        <span style={{ marginRight: 10.625, display: 'flex', alignItems: 'center' }}>
          <span style={{ float: 'left', marginRight: '16px' }}>
            {runEnv.toCapitalized()} v{version}
          </span>
          <a
            href={SUPPORT_LINK}
            style={{ color: '#FFFFFF', marginRight: '16px', fontSize: 14 }}
            target="_blank"
            rel="noreferrer"
          >
            Report an issue
          </a>
          <img src={logo} alt="" className={css(styles.logo)} />
          &copy; Nectar 360 Limited {new Date().getFullYear()}
        </span>
      </div>
    );
  }
}

const { queue } = Actions;

const mapStateToProps = ({ queue, globalSettings }) => ({
  queue,
  globalSettings,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    queue: {
      toggle: () => dispatch(queue.toggle()),
      removeQueueCheckRequest: (codeName) => dispatch(queue.removeQueueCheckRequest(codeName)),
      requestQueueCheck: (codeName) => dispatch(queue.requestQueueCheck(codeName)),
    },
  },
});

const FooterWrapper = ({ ...props }) => {
  const navigate = useNavigate();
  return <Footer {...props} navigate={navigate} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterWrapper);
