import React from 'react';
import { connect } from 'react-redux';
import { MenuItem, Paper, TextField, Select, FormControl, InputLabel } from '@material-ui/core';
import Actions from '../../../../../../actions';
import { getCycles, getFilterText, getSelectedCycle } from '../../../../../../reducers/TargetedComms/Smartcounts/list';
import jss from '../../../../../../utils/jss';

const styles = {
  paper: {
    display: 'flex',
    padding: '5px 10px',
  },

  cycles: {
    flex: 1,
    marginRight: 10,
  },
  filter: {
    flex: 3,
  },
};

class FilterText extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'Filter',
      classNamePrefix: 'Filter-',
    });
    this.state = {
      lekanVideo: true,
    };
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  handleFilter = (e) => {
    const v = e.target.value;
    this.props.actions.updateFilterText(v);
  };

  handleKey = (e) => {
    const { value } = e.target;
    if (e.keyCode === 13 && value === 'lekanmm') {
      const body = document.getElementsByTagName('body')[0];
      const container = document.createElement('div');
      container.style =
        'position: absolute; width: 304px; height: 504px; left: 50%; margin-left: -152px; top: 50%; margin-top:-252px';
      const iframe = document.createElement('iframe');
      iframe.src = 'http://igloo.lmuk.local/lekanmm.mp4';
      iframe.height = '100%';
      iframe.border = 'none';
      container.appendChild(iframe);
      body.appendChild(container);
      document.addEventListener('keyup', (e) => {
        if (e.keyCode === 27) {
          body.removeChild(container);
        }
      });
    }
    // else if (e.keyCode === 13 && value === 'soundfx') {
    //   SoundToggle()
    // }
  };

  render() {
    const { classes } = this.stylesheet;
    return (
      <Paper className={classes.paper}>
        <div className={classes.cycles}>
          <FormControl style={{ minWidth: 120 }}>
            <InputLabel id="regexThing">Cycle</InputLabel>
            <Select
              labelId="regexThing"
              label="Cycle"
              value={this.props.cycle}
              onChange={(e) => this.props.actions.selectCycle(e.target.value)}
              style={{ width: '100%' }}
            >
              <MenuItem value="all">All</MenuItem>
              {this.props.cycles.map((c) => (
                <MenuItem key={c} value={c}>
                  {c}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.filter}>
          <TextField
            type="search"
            label="Filter List"
            style={{ width: '100%' }}
            value={this.props.filterText || ''}
            onChange={this.handleFilter}
            onKeyUp={this.handleKey}
          />
        </div>
      </Paper>
    );
  }
}

const {
  targetedComms: {
    smartcounts: {
      list,
      list: { selectCycle, updateFilterText },
    },
  },
} = Actions;
const mapStateToProps = (state) => ({
  cycle: getSelectedCycle(state),
  cycles: getCycles(state),
  filterText: getFilterText(state),
});
const mapDispatchToProps = (dispatch) => ({
  actions: {
    selectCycle: (cycle) => dispatch(selectCycle(cycle)),
    updateFilterText: (text) => dispatch(updateFilterText(text)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterText);
