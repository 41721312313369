import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Actions from '../../../../../actions';
import { Content } from '../../../../lib/Content/index';

import PlanningToolNew1 from './NewStep1';
import PlanningToolNew2 from './NewStep2';
import PlanningToolNew3 from './NewStep3';
import PlanningToolNew4 from './NewStep4';
import PlanningToolNew5 from './NewStep5';
import PlanningToolNew6 from './NewStep6';
import SKUFilter from './SKUFilter';
import { postJSON } from '../../../../../utils/async';
import { Horizontal } from '../../../../lib/Scroll/index';
import { modules } from '../../../../../constants/moduleInfo';

class PlanningToolNew extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.props.actions.planning.reset();
  }

  componentDidMount() {
    if (this.props.clone) {
      this.props.actions.planning.restore(this.props.id);
    }
  }

  handleRun = () => {
    const p = this.props.planning;
    const payload = {
      ...p,
      completed: undefined,
      aisles: undefined,
      rerun: undefined,
    };
    postJSON('/api/targeted-comms/planningtool/new', payload, (err) => {
      if (!err) {
        this.props.navigate(modules.planningCounts.path);
      } else {
        this.props.actions.error(500);
      }
    });
  };

  actionNext = (props, state, next) => {
    switch (state.selectedValue) {
      case 0:
        this.props.actions.loading.toggle({
          show: true,
          text: 'Loading...',
        });
        this.props.actions.planning
          .getAisles()
          .then(() => {
            this.props.actions.loading.toggle({
              show: false,
            });
            next();
          })
          .catch((err) => {
            console.error(err);
          });
        break;
      case 6:
        this.handleRun();
        break;
      default:
        next();
    }
  };

  render() {
    const { planning } = this.props;
    return (
      <Content title="New Plan">
        <Horizontal
          fullBleed
          stepTitles={['SKUs', 'Aisles', 'Period', 'Filter', 'Stores', 'Coupons', 'Variables']}
          completed={planning.completed}
          handleNext={this.actionNext}
          buttonLabels={{
            6: {
              next: 'Run',
            },
          }}
        >
          <PlanningToolNew1 />
          <PlanningToolNew2 />
          <PlanningToolNew4 />
          <SKUFilter />
          <PlanningToolNew3 />
          <PlanningToolNew5 />
          <PlanningToolNew6 />
        </Horizontal>
      </Content>
    );
  }
}

const mapStateToProps = ({ planningtool }) => ({ planning: planningtool });

const {
  error,
  loading,
  targetedComms: { planning },
} = Actions;
const mapDispatchToProps = (dispatch) => ({
  actions: {
    loading: {
      toggle: (p) => dispatch(loading.toggle(p)),
    },
    planning: {
      reset: () => dispatch(planning.reset()),
      receivedAisles: (aisles) => dispatch(planning.receivedProductData(aisles)),
      getAisles: () => dispatch(planning.getAisles()),
      restore: (id) => dispatch(planning.restoreCount(id)),
    },
    error: (code) => dispatch(error(code)),
  },
});

const PlanningToolNewWrapper = ({ ...props }) => {
  const navigate = useNavigate();

  return <PlanningToolNew {...props} navigate={navigate} />;
};

const PlanningCountCloneWrapper = ({ ...props }) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('queue_id') && parseInt(searchParams.get('queue_id'));

  return <PlanningToolNewWrapper {...props} clone id={id} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanningToolNewWrapper);

export const PlanningCountClone = connect(mapStateToProps, mapDispatchToProps)(PlanningCountCloneWrapper);
