export default {
  list: {
    CLEAR: 'SMARTCOUNTS_CLEAR_LIST',
    UPDATE: 'SMARTCOUNTS_UPDATE_LIST',
    CYCLES: 'SMARTCOUNTS_CYCLES',
    LOCKED: 'SMARTCOUNTS_LOCK',
    CYCLE: 'SMARTCOUNTS_SELECT_CYCLE',
    STATUS: 'SMARTCOUNTS_EXEC_STATUS',
    FILTER: 'SMARTCOUNTS_FILTER_TEXT',
    TOGGLE: 'SMARTCOUNTS_LIST_TOGGLE',
    START: 'SMARTCOUNTS_START',
    END: 'SMARTCOUNTS_END',
  },
  newcount: {
    RESET: 'RESET_SMARTCOUNT_NEW_COUNT',
    CHANGE_DATA: 'CHANGE_SMARTCOUNT_NEW_COUNT_DATA',
    CHANGE_AISLE: 'CHANGE_SMARTCOUNT_NEW_COUNT_AISLE',
    CHANGE_SKU_DETAILS: 'CHANGE_SMARTCOUNT_NEW_COUNT_SKU_DETAILS',
    CHANGE_BRAND: 'CHANGE_SMARTCOUNT_NEW_COUNT_BRAND',
    CHANGE_REDEMPTION_SKUS: 'CHANGE_SMARTCOUNT_NEW_COUNT_REDEMPTION_SKUS',
    CHANGE_ADDITIONAL_SKUS: 'CHANGE_SMARTCOUNT_NEW_COUNT_ADDITIONAL_SKUS',
    CHANGE_STORES: 'CHANGE_SMARTCOUNT_NEW_COUNT_STORES',
    CHANGE_OVERLAY: 'CHANGE_SMARTCOUNT_ADD_OVERLAY',
    CHANGE_NAME: 'CHANGE_SMARTCOUNT_NEW_COUNT_NAME',
    CHANGE_AISLE_RULE: 'CHANGE_SMARTCOUNT_NEW_COUNT_AISLE_RULE',
    CHANGE_AISLE_SENSE: 'CHANGE_SMARTCOUNT_NEW_COUNT_AISLE_SENSE',
    CHECKED_SKUS: 'CHANGE_SMARTCOUNT_NEW_COUNT_CHECKED_SKUS',
    SAVED_ID: 'CHANGE_SMARTCOUNTS_NEW_COUNT_RUN_ID',
    RUN_RESULT: 'CHANGE_SMARTCOUNTS_NEW_COUNT_RUN_RESULT',
    ADD_COMPLETED: 'ADD_SMART_COUNTS_NEW_COUNT_COMPLETED',
    DELETE_COMPLETED: 'DELETE_SMART_COUNTS_NEW_COUNT_COMPLETED',
    RESET_FROM: 'RESET_SMARTCOUNTS_NEW_COUNT_STEPS',
    RESTORE: 'RESTORE_SMARTCOUNTS_NEW_COUNT',
    EXISTING_NAMES: 'GET_SMARTCOUNTS_EXISTING_NAMES',
  },
  campaign: {
    ADD_COMPLETED: 'SMARTCOUNTS/CAMPAIGN/COMPLETED/ADD',
    REMOVE_COMPLETED: 'SMARTCOUNTS/CAMPAIGN/COMPLETED/REMOVE',
    RESET: 'SMARTCOUNTS/CAMPAIGN/RESET',
    START_CAMPAIGN: 'SMARTCOUNTS/CAMPAIGN/START',
    TOGGLE_REQUIRED: 'SMARTCOUNTS/CAMPAIGN/TOGGLE_REQUIRED',
    UPDATE_CYCLE: 'SMARTCOUNTS/CAMPAIGN/CYCLE',
    UPDATE_PRINT: 'SMARTCOUNTS/CAMPAIGN/PRINT_PERIOD',
    UPDATE_REDEMPTION: 'SMARTCOUNTS/CAMPAIGN/REDEMPTION_PERIOD',
    UPDATE_REDEMPTION_DATE: 'SMARTCOUNTS/CAMPAIGN/REDEMPTION_DATE',
    UPDATE_GROUPED_EXISTING: 'SMARTCOUNTS/CAMPAIGN/GROUPED_EXISTING',
    UPDATE_TRANSACTIONAL_TOGGLE: 'SMARTCOUNTS/CAMPAIGN/TRANSACTIONAL_TOGGLE',
    UPDATE_RSP: 'SMARTCOUNTS/CAMPAIGN/RSP',
    UPDATE_PCR: 'SMARTCOUNTS/CAMPAIGN/PCR',
    UPDATE_VISIBLE: 'SMARTCOUNTS/CAMPAIGN/VISIBLE',
    UPDATE_REQUIRED: 'SMARTCOUNTS/CAMPAIGN/REQUIRED',
    UPDATE_CONTINUITY: 'SMARTCOUNTS/CAMPAIGN/CONTINUITY',
    UPDATE_AB: 'SMARTCOUNTS/CAMPAIGN/AB',
    UPDATE_CELL_PRINTS: 'SMARTCOUNTS/CAMPAIGN/PRINTS',
    UPDATE_TEMPLATE: 'SMARTCOUNTS/CAMPAIGN/TEMPLATE',
    UPDATE_CELL_REDEMPTION: 'SMARTCOUNTS/CAMPAIGN/CELL_REDEMPTION',
    UPDATE_OFFER_TYPE: 'SMARTCOUNTS/CAMPAIGN/OFFER/TYPE',
    UPDATE_OFFER_VALUE: 'SMARTCOUNTS/CAMPAIGN/OFFER/VALUE',
    ALL_TEMPLATES_TO_STANDARD: 'SMARTCOUNTS/CAMPAIGN/TEMPLATES/ALL_STANDARD',
    UPDATE_COUPON_TEXT: 'SMARTCOUNTS/CAMPAIGN/COUPON/TEXT',
    UPDATE_ARTWORK: 'SMARTCOUNTS/CAMPAIGN/ARTWORK',
    UPDATE_STAGE: 'SMARTCOUNTS/CAMPAIGN/STAGE',
    UPDATE_SUPPLIER: 'SMARTCOUNTS/CAMPAIGN/SUPPLIER',
    UPDATE_NAME: 'SMARTCOUNTS/CAMPAIGN/NAME',
    UPDATE_BASE_ID: 'SMARTCOUNTS/CAMPAIGN/BASE_ID',
    UPDATE_BARCODE: 'SMARTCOUNTS/CAMPAIGN/BARCODE',
    UPDATE_REJECTION_COMMENT: 'SMARTCOUNTS/CAMPAIGN/REJECTION_COMMENT',
    UPDATE_CLASHES: 'SMARTCOUNTS/CAMPAIGN/CLASHES',
    PROVISIONAL_BASE: 'SMARTCOUNTS/CAMPAIGN/PROVISIONAL_BASE',
  },
  campaignCreator: {
    RESET: 'SMARTCOUNTS_CAMPAIGN_RESET',
    ADD_COMPLETED: 'ADD_SMARTCOUNTS_CC_COMPLETED',
    DELETE_COMPLETED: 'DELETE_SMARTCOUNTS_CC_COMPLETED',
    START_CAMPAIGN: 'SMARTCOUNTS_CAMPAIGN_START',
    TC_ID: 'SMARTCOUNTS_CAMPAIGN_TARGETED_COMMS_ID',
    SELECT_COUNT: 'SMARTCOUNTS_CAMPAIGN_SET_COUNT',
    SET_TIME_PERIODS: 'SMARTCOUNTS_CAMPAIGN_TIME_PERIODS',
    UPDATE_TEMP: 'SMARTCOUNTS_CAMPAIGN_UPDATE_TEMP',
    UPDATE_CELLS: 'SMARTCOUNTS_CAMPAIGN_CELLS',
    UPDATE_TEXT: 'SMARTCOUNTS_CAMPAIGN_COUPON_TEXT',
    UPDATE_RSP: 'SMARTCOUNTS_CAMPAIGN_RSP',
    UPDATE_PCR: 'SMARTCOUNTS_CAMPAIGN_PCR',
    UPDATE_ARTWORK_POSITION: 'SMARTCOUNTS_CAMPAIGN_ARTWORK_POSITION',
    UPDATE_ARTWORK_PATH: 'SMARTCOUNTS_CAMPAIGN_ARTWORK_PATH',
    UPDATE_SUPPLIER: 'SMARTCOUNTS_CAMPAIGN_SUPPLIER',
    UPDATE_STAGE: 'SMARTCOUNTS_CAMPAIGN_STAGE',
    UPDATE_NAME: 'SMARTCOUNTS_CAMPAIGN_NAME',
    UPDATE_COMMENT: 'SMARTCOUNTS_CAMPAIGN_COMMENT',
    UPDATE_BASE_ID: 'SMARTCOUNTS_CAMPAIGN_BASE_ID',
    UPDATE_CLASHES: 'SMARTCOUNTS_CLASHES',
  },
  saved: {
    ERROR: 'ERROR_SAVED_SC_CAMPAIGNS',
    RECEIVED: 'RECEIVED_SAVED_SC_CAMPAIGNS',
  },
};
