import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { reduce } from 'lodash';
import { Split, Left, Right } from '../Split';

class Banding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incrementBy: props.incrementBy || 0,
      rows: props.data || [
        {
          low: 0,
          high: props.incrementBy || 0,
        },
      ],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      rows: nextProps.data,
    });
  }

  addRow = () => {
    this.setState(
      (prevState) => {
        const { rows, incrementBy } = prevState;
        const lastRow = rows[rows.length - 1];

        rows.push({
          low: parseFloat((parseFloat(lastRow.high) + incrementBy).toFixed(incrementBy.decimalPlaces())),
          high: parseFloat((parseFloat(lastRow.high) + incrementBy).toFixed(incrementBy.decimalPlaces())),
        });
        return { rows };
      },
      () => {
        this.props.onChange && this.props.onChange(this.state.rows);
      }
    );
  };

  removeRow = () => {
    this.setState(
      (prevState) => {
        const { rows } = prevState;
        rows.splice(-1, 1);
        return { rows };
      },
      () => {
        this.props.onChange && this.props.onChange(this.state.rows);
      }
    );
  };

  handleChange = (e, row, field) => {
    const { value } = e.target;

    // Accept only numeric values
    if (!/[A-Za-z]/g.test(value) || value === '') {
      this.setState((prevState) => {
        const { rows } = prevState;
        rows[row][field] = value;
        return { rows };
      });
    }
  };

  handleBlur = (e) => {
    const { value } = e.target;
    const row_n = e.currentTarget.getAttribute('data-row');

    const rows = reduce(
      this.state.rows,
      (obj, row) => {
        const high =
          row.high > this.props.maxValue || row.high === '' || parseFloat(row.high) < parseFloat(row.low)
            ? parseFloat(row.low)
            : parseFloat(row.high);
        obj.push({ low: parseFloat(row.low), high });
        return obj;
      },
      []
    );
    this.props.onChange(rows);
  };

  overflowValue = () => {
    const highestValue = this.state.rows[this.state.rows.length - 1].high;
    if (this.props.maxValue) {
      if (highestValue < this.props.maxValue - this.state.incrementBy) {
        return (
          <span>
            {`${
              this.props.prepend +
              (parseFloat(this.state.rows[this.state.rows.length - 1].high) + this.state.incrementBy).toFixed(
                this.state.incrementBy.decimalPlaces()
              ) +
              this.props.append
            } - ${this.props.prepend}${this.props.maxValue}${this.props.append}`}
          </span>
        );
      }
      return this.props.prepend + this.props.maxValue + this.props.append;
    }
    return (
      <span>
        {this.props.prepend}
        {(parseFloat(this.state.rows[this.state.rows.length - 1].high) + this.state.incrementBy).toFixed(
          this.state.incrementBy.decimalPlaces()
        )}
        {this.props.append}+
      </span>
    );
  };

  render() {
    const highestValue = this.state.rows[this.state.rows.length - 1].high;
    return (
      <div>
        {this.state.rows.map((row, index) => {
          return (
            <Split key={index}>
              <Left>
                <TextField
                  name="1"
                  value={this.props.prepend + row.low}
                  data-row={index}
                  data-field="low"
                  underlineDisabledStyle={{
                    height: 1,
                    border: 'none',
                    backgroundColor: '#E0E0E0',
                  }}
                  style={{ width: '100%' }}
                  disabled={this.props.disabled || index === 0 || index > 0 || this.state.rows.length > 1}
                  onChange={(e) => this.handleChange(e, index, 'low')}
                />
              </Left>
              <Right>
                <TextField
                  name="2"
                  value={this.props.prepend + row.high}
                  data-row={index}
                  data-field="high"
                  underlineDisabledStyle={{
                    height: 1,
                    border: 'none',
                    backgroundColor: '#E0E0E0',
                  }}
                  style={{ width: '100%' }}
                  disabled={this.props.disabled || index !== this.state.rows.length - 1}
                  onChange={(e) => this.handleChange(e, index, 'high')}
                  onBlur={this.handleBlur}
                />
              </Right>
            </Split>
          );
        })}
        <Split>
          <Left />
          <Right>
            {!isNaN(parseFloat(highestValue)) && (
              <div>
                {this.overflowValue()}
                <span style={{ float: 'right' }}>
                  {(!this.props.maxValue || highestValue < this.props.maxValue - this.state.incrementBy) && (
                    <MdAddCircleOutline onClick={this.addRow} />
                  )}
                  {this.state.rows.length > 1 && <MdRemoveCircleOutline onClick={this.removeRow} />}
                </span>
              </div>
            )}
          </Right>
        </Split>
      </div>
    );
  }
}

Banding.propTypes = {
  incrementBy: PropTypes.number,
  prepend: PropTypes.string,
  append: PropTypes.string,
  maxValue: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      low: PropTypes.number.isRequired,
      high: PropTypes.number.isRequired,
    })
  ),
  disabled: PropTypes.bool,
};

Banding.defaultProps = {
  incrementBy: 1,
  prepend: '',
  append: '',
  disabled: false,
};

export default Banding;
