import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import TextField from '@material-ui/core/TextField';
import Actions from '../../../../../actions';
import { formatAsArray, validateSKUs } from '../../../../../utils/NumberUtils';
import { ContentTile } from '../../../../lib/Tile/index';

class SCAdditionalSKUS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
    };
  }

  /**
   * Called when changing the value of the input
   * @param {event} e - the event
   */
  handleAdditionalChange = (e) => {
    this.props.actions.newcount.removeCompleted(1);
    const { value } = e.target;
    if (validateSKUs(value) || value === '') {
      this.props.actions.newcount.updateAdditionalSKUs(formatAsArray(value));
      this.props.actions.newcount.addCompleted(1);
      this.setState({ error: false });
    } else {
      this.setState({ error: true });
    }
  };

  render() {
    const qRunning = this.props.queueData && this.props.queueData.status === 'running';
    return (
      <div>
        <ContentTile header="Additional Overlay" style={{ marginTop: 10, marginBottom: 20 }}>
          <TextField
            name="additionalSkus"
            multiline
            rows={5}
            disabled={qRunning}
            style={{ width: '100%', marginBottom: 10 }}
            error={this.state.error && 'Invalid SKU format.' != null}
            helperText={this.state.error && 'Invalid SKU format.'}
            onChange={this.handleAdditionalChange}
            defaultValue={this.props.newcount.additionalSKUs.join(',') || ''}
          />
        </ContentTile>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    queue,
    TargetedComms: {
      Smartcounts: { count },
    },
  } = state;
  return {
    newcount: {
      additionalSKUs: count.additionalSKUs,
    },
    queueData: find(queue.data, (o) => {
      return o.id === count.jobDetails.id;
    }),
  };
};

const {
  targetedComms: {
    smartcounts: { newcount },
  },
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    newcount: {
      addCompleted: (index) => dispatch(newcount.addCompleted(index)),
      removeCompleted: (index) => dispatch(newcount.deleteCompleted(index)),
      updateAdditionalSKUs: (skus) => dispatch(newcount.updateAdditionalSKUs(skus)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SCAdditionalSKUS);
