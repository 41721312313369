import { createSelectorCreator } from 'reselect';
import { memoize } from 'lodash';
import getCampaignObject from './getCampaignObject';

const createDeepEqualSelector = createSelectorCreator(memoize, (...args) =>
  args.reduce((acc, val) => `${acc}-${JSON.stringify(val)}`, '')
);

export const getTimePeriods = (state) => getCampaignObject(state).time_periods || {};
export const getCyclesList = (state) => getCampaignObject(state).cycles;
export const getSelectedCycle = (state) => getTimePeriods(state).cycle;
export const getPrintPeriod = (state) => getTimePeriods(state).print;
export const getRedemptionPeriod = (state) => getTimePeriods(state).redemption;
export const getFixedRedemptionEndDate = (state) => getTimePeriods(state).date;
export const getCycleDetails = createDeepEqualSelector([getCyclesList, getSelectedCycle], (list, selected) => {
  return list.filter((c) => c.cycle === selected)[0];
});
export const getCycleStart = (state) => {
  const selected = getSelectedCycle(state);
  return (getCyclesList(state).filter((c) => c.cycle === selected)[0] || {}).start_date;
};
