import React from 'react';
import PropTypes from 'prop-types';
import jss from '../../../utils/jss';

const animationDuration = 300;

const styles = {
  riseUp_wrapper: {
    position: 'relative',
    overflowY: 'hidden',
  },
  riseUp_text: {
    transition: `all ${animationDuration}ms ease-in-out`,
  },
  riseUp_textStart: {
    transform: 'translateY(100%)',
    opacity: 0,
  },
  riseUp_textFinish: {
    transform: 'translateY(0)',
    opacity: 1,
  },
};

class TextEffect extends React.Component {
  constructor(props) {
    super(props);

    this.setup(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'TextEffect',
      classNamePrefix: 'TextEffect-',
    });
    this.state = {
      show: props.show,
      ...(this.effectFunction && { ...this.effectFunction().initialState }),
    };
  }

  setup = (props) => {
    this.effectFunctionName = props.effect.toCamelCase();
    switch (this.effectFunctionName) {
      case 'riseUp':
        this.effectFunction = this.riseUp;
        break;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.effect !== this.props.effect) {
      const currentEffectFunctionName = this.effectFunctionName;
      this.setup(nextProps);
      this.setState({
        [currentEffectFunctionName]: undefined,
        ...(this.effectFunction && { ...this.effectFunction().initialState }),
      });
    }

    if (nextProps.text !== this.props.text) {
      this.effectFunction().textChange(nextProps.text);
    }
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
    this.dismissTimer && clearTimeout(this.dismissTimer);
  }

  componentDidUpdate() {
    if (this.props.autoDismiss && this.state.show) {
      this.dismissTimer = setTimeout(() => {
        this.setState({ show: false });
      }, this.props.autoDismiss);
    }
  }

  riseUp = () => {
    const { text, revealDelay, animateTextChange } = this.props;
    const { classes } = this.stylesheet;

    const initialState = {
      riseUp: {
        text,
      },
    };

    const state = (this.state && this.state.riseUp) || initialState.riseUp;

    const textChange = (nextText) => {
      this.dismissTimer && clearTimeout(this.dismissTimer);
      if (nextText) {
        animateTextChange || !this.state.text
          ? this.setState(
              {
                show: false,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    riseUp: {
                      text: nextText,
                    },
                    show: true,
                  });
                }, animationDuration);
              }
            )
          : this.setState({ riseUp: { text: nextText } });
      } else {
        this.setState({ show: false, text: null });
      }
    };

    const render = (
      <div className={classes.riseUp_wrapper} style={this.props.style}>
        <div
          className={jss.combine(
            classes.riseUp_text,
            classes.riseUp_textStart,
            ((this.state && this.state.show) || false) && classes.riseUp_textFinish
          )}
        >
          {state.text}
        </div>
      </div>
    );

    return { initialState, textChange, render };
  };

  render() {
    const { text, style, ...rest } = this.props;

    if (this.effectFunction) {
      return this.effectFunction().render;
    }
    return (
      <div style={style} {...rest}>
        {text}
      </div>
    );
  }
}

TextEffect.propTypes = {
  text: PropTypes.element,
  effect: PropTypes.oneOf(['rise-up']),
  animateTextChange: PropTypes.bool,
  autoDismiss: PropTypes.number,
};

TextEffect.defaultProps = {
  animateTextChange: false,
};

export default TextEffect;
