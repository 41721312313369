const logout = 'https://login.microsoftonline.com/jsainsbury.onmicrosoft.com/oauth2/v2.0/logout';

const clientConfig = {
  auth: {
    clientId: '20f1d889-45aa-42de-939c-7f8c4cd50dca',
    authority: 'https://login.microsoftonline.com/jsainsbury.onmicrosoft.com',
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
};

const authenticationParameters = {
  scopes: ['https://graph.microsoft.com/User.Read'],
};

const appInfo = {
  build: '6837776',
};

export default {
  logout,
  clientConfig,
  authenticationParameters,
  appInfo,
  app_env: process.env.REACT_APP_ENV,
  skipAD: process.env.REACT_APP_SKIP_AD || false,
};
