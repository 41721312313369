import React from 'react';
import { reduce } from 'lodash';

export default (newcount) => {
  const checkedBrand = reduce(
    newcount.checkedSKUs.brand,
    (result, value) => {
      result.push(value.sku_no);
      return result;
    },
    []
  );
  const checkCompetitor = reduce(
    newcount.checkedSKUs.competitor,
    (result, value) => {
      result.push(value.sku_no);
      return result;
    },
    []
  );
  const checkedOverlay = reduce(
    newcount.checkedSKUs.overlay,
    (result, value) => {
      result.push(value.sku_no);
      return result;
    },
    []
  );
  const payload = {
    aisle: newcount.aisle,
    brand: newcount.brand,
    manufacturer: newcount.manufacturer,
    skus: {
      redemption: newcount.redemptionSKUs,
      brand: checkedBrand,
      competitor: checkCompetitor,
      overlay: checkedOverlay,
      additionalOverlay: newcount.additionalSKUs,
    },
    stores: newcount.stores,
    name: newcount.name,
    aisle_rule: newcount.aisle_rule,
    aisle_sensitivity: newcount.aisle_sensitivity,
    cloned: newcount.cloned,
  };
  return payload;
};
