import React from 'react';

class Flags extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <h2>Standard Definitions</h2>
        <h3>Aisle Rule</h3>
        <p>
          Flags customer who have purchased 2+ units into the aisle defined by ‘Redemption’ SKU list. Includes
          additional sensitivity requirements (e.g. BWS, Baby).
        </p>
        <h3>Brand Loyal</h3>
        <p>
          Flags whether customers pass the loyalty rule. Brand ‘loyals’ are defined as &gt;4 units or &gt;67% of total
          aisle units from a single brand during the specified analysis period.
        </p>

        <h2>Customer Demographics </h2>
        <p>The following are the standard variables as seen in SelfServe:</p>
        <ul>
          <li>Age</li>
          <li>Gender</li>
        </ul>

        <h2>Standard Segmentations</h2>
        <p>The following are the standard segmentation groups as seen in SelfServe:</p>
        <ul>
          <li>Household</li>
          <li>New My Shopping Group</li>
          <li>In-Store RFM</li>
          <li>Online RFM</li>
          <li>Affluence</li>
        </ul>

        <h2>Additional Segmentations</h2>
        <h3>Short Term Loyalty</h3>
        <p>‘Platinum’, ‘Gold’, ‘Silver’, ‘Bronze’ flags</p>
        <p>Calculated over 12wks </p>

        <h3>Long Term Loyalty</h3>
        <p>‘Platinum’, ‘Gold’, ‘Silver’, ‘Bronze’ flags</p>
        <p>Calculated over 52wks</p>

        <h3>Customer Mission</h3>
        <p>‘Food For Now’, ‘Food For Later Today’, ‘Food For A Couple Of Days’, ‘Food For Many Days’</p>
        <p>Calculated over 12wks</p>

        <h3>Health & Wellness</h3>
        <p>
          ‘Casual Lifestyle’, ‘Time-Poor Diet Seekers’, ‘Balanced Lifestyle’, ‘Naturally Healthy’, ‘Health Enthusiasts’
        </p>
        <p>Calculated over 52wks</p>

        <h3>Preferred Store Format</h3>
        <p>Shows preferred store format based on total spend. Includes a flag for inactive customers. </p>
        <p>Calculated over 12wks</p>

        <h3>Cross Store Formats</h3>
        <p>
          Shows cross-shopping combinations across Supermarket, Convenience and Online. Includes a flag for inactive
          customers.{' '}
        </p>
        <p>Calculated over 12wks</p>

        <h2>i2C Segmentations</h2>
        <p>
          Flags based on category-specific segmentations created as part of i2C insights projects (Coffee, Impulse,
          etc.). Please consult the relevant team(s) for further info.
        </p>
        <h2>Coupon Activity</h2>
        <p>
          52wk Coupon Activity – flags customers who have been selected for any (i2C) coupon campaign in the latest
          52wks.
        </p>
        <h2>Consent and Eligibility</h2>
        <p>
          The following flags show marketing consent and eligibility across the following channels (where 1 = Eligible)
        </p>
      </div>
    );
  }
}

export default Flags;
