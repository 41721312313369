import React from 'react';
import {
  MdTimeline,
  MdTrackChanges,
  MdMyLocation,
  MdBusiness,
  MdWidgets,
  MdSearch,
  MdToc,
  MdChromeReaderMode,
  MdTrendingUp,
} from 'react-icons/md';
import { FaBarcode } from 'react-icons/fa';
import { colorNames } from './colors';
import PermissionCheck from '../utils/PermissionCheck';

export const modules = {
  benchmarks: {
    name: 'Benchmarks',
    path: '/benchmarks',
    color: colorNames.teal,
    icon: <MdTimeline />,
    hasAccess: () => false,
  },
  planningCounts: {
    name: 'Planning Counts',
    path: '/planningcounts',
    color: colorNames.orange,
    icon: <MdTrackChanges />,
    hasAccess: () => PermissionCheck('TargetedComms_Planning_Tool'),
  },
  smartCounts: {
    name: 'Smartcounts',
    path: '/smartcounts',
    color: colorNames.green,
    icon: <MdWidgets />,
    hasAccess: () => PermissionCheck('TargetedComms_Smartcounts'),
  },
  skuLookup: {
    name: 'SKU Checker',
    path: '/lookups/skus',
    color: '#3567b7',
    icon: <FaBarcode />,
    hasAccess: () => true,
  },
  storeLookup: {
    name: 'Store Checker',
    path: '/lookups/stores',
    color: '#4B4B4B',
    icon: <MdBusiness />,
    hasAccess: () => false,
  },
  magazineLookup: {
    name: 'Magazines',
    path: '/lookups/magazines',
    color: '#35a1b7',
    icon: <MdChromeReaderMode />,
    hasAccess: () => false,
  },
  unitsSold: {
    name: 'Units Sold',
    path: '/lookups/units-sold',
    color: '#4141f4',
    icon: <MdTrendingUp />,
    hasAccess: () => false,
  },
  evaulationStatus: {
    name: 'Evaluation Status',
    path: '/evaluations',
    color: '#35a1b7',
    icon: <MdToc />,
    hasAccess: () => false,
  },
};

export const findModuleByPath = (pathname) => Object.values(modules).find(({ path }) => pathname.startsWith(path));

const moduleQueueRouteMap = {
  '/smartcounts/count/run': modules.smartCounts,
  '/smartcounts/execution/prime': modules.smartCounts,
  '/smartcounts/execution/model': modules.smartCounts,
  '/planningtool/new': modules.planningCounts,
  '/planningtool/count/refresh': modules.planningCounts,
  '/planningtool/count/new': modules.planningCounts,
  '/insights/new': null,
  '/retailinsights/queue/add': null,
};

export const findModuleByQueueRoute = (queueRoute) => moduleQueueRouteMap[queueRoute];

// export const getPathFromQueue = ()

/**
 * Defines the groupings for the modules for use on the homepage and sidebar
 * @type {array}
 */
export const moduleGroups = [
  { type: 'module', module: modules.benchmarks },
  {
    type: 'group',
    name: 'Targeted Comms',
    color: colorNames.blue,
    icon: <MdMyLocation />,
    modules: [modules.planningCounts, modules.smartCounts],
  },
  {
    type: 'group',
    name: 'Lookups',
    color: colorNames.orange,
    icon: <MdSearch />,
    modules: [modules.skuLookup, modules.storeLookup, modules.magazineLookup, modules.unitsSold],
  },
  { type: 'module', module: modules.evaulationStatus },
];
