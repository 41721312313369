import ActionTypes from '../constants/ActionTypes/index';
import { returnJSON } from '../utils/async';

export const toggle = () => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.queue.FLOATING,
      ...getState().queue,
      show: !getState().queue.show,
    });
  };
};

export const requestQueue = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      returnJSON('/api/queue', (err, res) => {
        if (err) return reject(err);
        resolve(dispatch({ type: ActionTypes.queue.SYNC, data: res }));
      });
    });
  };
};

export const requestQueueCheck = (codeName) => ({
  type: ActionTypes.queue.REQUEST_CHECK,
  codeName,
});

export const removeQueueCheckRequest = (codeName) => ({
  type: ActionTypes.queue.REMOVE_CHECK,
  codeName,
});
export const clearQueueRequests = () => ({
  type: ActionTypes.queue.CLEAR_REQUESTS,
});
export const updateMultiple = (data) => ({
  type: ActionTypes.queue.UPDATE_MULTIPLE,
  data,
});
