import React from 'react';
import { Button } from '@material-ui/core';
import { smartcounts_download } from '../../../../../../utils/Analytics';
import ProgressBar from '../../../../../lib/ProgressBar';
import { postJSON, saveDownload } from '../../../../../../utils/async';

class LoyaltyRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      progress: null,
      status: 'idle',
    };
  }

  componentDidMount() {
    postJSON('/api/targeted-comms/smartcounts/execution/catalina', { campaigns: [this.props.campaign] })
      .then((res) => {
        this.setState({
          status: 'download-ready',
        });
      })
      .catch((err) => {
        this.props.modal.toggle((modal) => ({
          show: true,
          title: 'Loyalty Data Error',
          style: 'error',
          body: 'An error occured when requesting the data.',
          actions: [
            {
              text: 'Ok',
              action: modal.close,
            },
          ],
        }));
      });
  }

  handleDownload = () => {
    this.props.modal.toggle({ show: false });
    const { campaign } = this.props;

    smartcounts_download('loyalty', campaign);

    saveDownload(
      `/api/targeted-comms/smartcounts/data/loyalty/${campaign}`,
      {
        save: true,
        path: `Loyalty_Data_${this.props.base}.zip`,
        mimeType: 'application/zip',
      },
      (err, result) => {
        if (err) {
          this.props.modal.toggle((modal) => ({
            show: true,
            title: 'Loyalty Data Error',
            style: 'error',
            body: <div>There was a problem with downloading the data file.</div>,
            actions: [{ text: 'Ok', action: modal.close }],
          }));
        }
        if (result) {
          postJSON(`/api/targeted-comms/smartcounts/data/remove/${campaign}`)
            .then((res) => {
              if (res === 'Success') return;
            })
            .catch((err) => {
              this.props.modal.toggle((modal) => ({
                show: true,
                title: 'Loyalty Remove Error',
                style: 'error',
                body: <div>There was a problem with removing the loyalty data.</div>,
                actions: [{ text: 'Ok', action: modal.close }],
              }));
            });
        }
      }
    );
  };

  render() {
    switch (this.state.status) {
      case 'idle':
      case 'running':
      case 'preparing':
        return (
          <div>
            <ProgressBar showText progress={this.state.progress} textPosition="bottom" runningText="Preparing TXT..." />
          </div>
        );
      case 'download-ready':
        return (
          <div>
            The file is ready for download.
            <br />
            <br />{' '}
            <Button variant="contained" onClick={this.handleDownload}>
              Download zip
            </Button>
          </div>
        );
      case 'no_campaign':
        return <div>There's a problem with the campaign ID.</div>;
    }
  }
}

export default LoyaltyRequest;
