import React from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { StyleSheet, css } from 'aphrodite/no-important';
import Button from '@material-ui/core/Button';
import { MdError, MdCheckCircle, MdWarning, MdInfo, MdClose } from 'react-icons/md';
import { colorIsDark, colorNames } from '../../../constants/colors';
import Actions from '../../../actions';
import Portal from '../Portal';

import store from '../../../store';

const styles = StyleSheet.create({
  windowBlur: {
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    position: 'absolute',
    pointerEvents: 'none',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 9,
    backgroundColor: 'rgba(0,0,0,0.9)',
  },
  elementShow: {
    opacity: '1 !important',
    pointerEvents: 'auto',
  },
  modalShow: {
    opacity: '1 !important',
  },
  modalWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
    pointerEvents: 'none',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    color: '#000000',
  },
  modalContent: {
    width: '100%',
    maxWidth: 960,
  },
  modalFull: {
    width: '100%',
  },
  modalCenter: {
    textAlign: 'center',
  },
  modalTitle: {
    display: 'inline-block',
    fontSize: 20,
    fontWeight: 'bold',
    userSelect: 'none',
    cursor: 'default',
    color: '#FFFFFF',
    padding: 10,
    paddingLeft: 0,
    paddingRight: 0,
    textShadow: '0px 0px 4px rgba(0, 0, 0, 1)',
  },
  modalBody: {
    userSelect: 'none',
    cursor: 'default',
    pointerEvents: 'none',
    color: '#FFFFFF',
  },
  modalActionWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 20,
  },
  modalActions: {
    flex: 1,
    width: '50%',
  },
  closeIcon: {
    position: 'absolute',
    top: 100,
    right: 25,
    zIndex: 10,
    cursor: 'pointer',
  },
});

class Modal2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTop: null,
    };
  }

  modalIcon = () => {
    switch (this.props.modalStyle) {
      case 'error':
        return (
          <MdError
            size={25}
            style={{
              float: 'left',
              marginRight: 5,
              color: colorNames.red,
            }}
          />
        );
      case 'success':
        return (
          <MdCheckCircle
            size={25}
            style={{
              float: 'left',
              marginRight: 5,
              color: colorNames.green,
            }}
          />
        );
      case 'warning':
        return (
          <MdWarning
            size={25}
            style={{
              float: 'left',
              marginRight: 5,
              color: colorNames.amber,
            }}
          />
        );
      case 'info':
        return (
          <MdInfo
            size={25}
            style={{
              float: 'left',
              marginRight: 5,
              color: colorNames.blue,
            }}
          />
        );
    }
  };

  handleESC = (e) => {
    if (e.code === 'Escape') {
      this.props.toggle({ show: false });
      document.addEventListener('keyup', this.handleESC);
    }
  };

  render() {
    if (this.props.open && !this.props.preventESC) {
      document.addEventListener('keyup', this.handleESC);
    }
    return (
      <Portal>
        <div>
          <div
            className={css(styles.windowBlur, this.props.open && styles.elementShow)}
            style={{
              opacity: 0,
              top:
                (document.getElementsByClassName('app--bar')[0] &&
                  document.getElementsByClassName('app--bar')[0].offsetHeight) ||
                0,
              transitionDelay: `${this.props.fadeDelay || 0}s`,
              ...(this.props.style && { zIndex: this.props.style.zIndex }),
            }}
            onClick={() => {
              this.props.dismissable && this.props.toggle({ show: false });
              this.props.onDismiss && this.props.onDismiss();
            }}
          />

          <div
            className={css(styles.closeIcon)}
            style={{
              display: this.props.open && this.props.dismissable ? 'block' : 'none',
            }}
          >
            <MdClose
              size={32}
              color="#FFFFFF"
              onClick={() => {
                this.props.dismissable && this.props.toggle({ show: false });
                this.props.onDismiss && this.props.onDismiss();
              }}
            />
          </div>
          <div
            className={css(
              styles.modalWrapper,
              this.props.open && styles.modalShow,
              this.props.full && styles.modalFull,
              this.props.center && styles.modalCenter
            )}
            style={{
              opacity: 0,
              marginTop: this.state.modalTop,
              transitionDelay: `${this.props.fadeDelay || 0}s`,
              ...(this.props.style && { zIndex: this.props.style.zIndex }),
            }}
            ref={(node) => (this.wrapper = node)}
          >
            <div className={css(styles.modalContent)}>
              <div className={css(styles.modalTitle)}>
                {this.modalIcon()}
                {this.props.title}
              </div>
              <div className={css(styles.modalBody, this.props.open && styles.elementShow)}>
                <Provider store={store}>{this.props.children}</Provider>
              </div>
              {this.props.actions ? (
                <div className={css(styles.modalActionWrapper)}>
                  <div className={css(styles.modalActions)}>
                    {this.props.actions.map((action, index) => {
                      if (action.position === 'left' || !action.position) {
                        return (
                          <Button
                            style={{
                              pointerEvents: 'auto',
                              marginLeft: 5,
                              color: action.color && colorIsDark(action.color) && colorNames.white,
                              backgroundColor: action.color || colorNames.white,
                            }}
                            variant="contained"
                            key={index}
                            onClick={action.action}
                            disabled={action.disabled}
                          >
                            {action.text}
                          </Button>
                        );
                      }
                    })}
                  </div>
                  <div className={css(styles.modalActions)} style={{ textAlign: 'right' }}>
                    {this.props.actions.map((action, index) => {
                      if (action.position === 'right') {
                        return (
                          <Button
                            style={{
                              pointerEvents: 'auto',
                              marginRight: 5,
                              color: action.color && colorIsDark(action.color) && colorNames.white,
                              backgroundColor: action.color || colorNames.white,
                            }}
                            variant="contained"
                            key={index}
                            onClick={action.action}
                            disabled={action.disabled}
                          >
                            {action.text}
                          </Button>
                        );
                      }
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Portal>
    );
  }
}

Modal2.propTypes = {
  open: PropTypes.bool,
  fadeDelay: PropTypes.number,
  modalStyle: PropTypes.oneOf(['error', 'success', 'warning', 'info', 'snackbar']),
  preventESC: PropTypes.bool,
};

const mapStateToProps = ({ modal }) => ({
  modal,
});

const { modal } = Actions;

const mapDispatchToProps = (dispatch) => ({
  toggle: (opts) => dispatch(modal.toggle(opts)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal2);
