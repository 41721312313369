import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { MdCheck, MdClear, MdMenu, MdHome, MdAccountBox } from 'react-icons/md';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import moment from 'moment';
import TextEffect from '../TextEffect';
import { findModuleByPath } from '../../../constants/moduleInfo';
import { logoff } from '../../../utils/async';
import { getUser } from '../../../utils/authProvider';
import logo from '../../../../images/nectar360-white.svg';
import './style.css';

const Appbar = ({ ...props }) => {
  const {
    handleSideBarChange,
    modal,
    loading,
    offline,
    targetedComms: {
      save: { lastSave, error: saveError },
    },
  } = props;

  const { givenName = '', groups = [] } = getUser() || {};

  const { pathname } = useLocation();
  const currentModule = findModuleByPath(pathname);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logoff('/logout');
  };

  return (
    <div className="app--bar no--select" style={false && !offline ? { height: '70px', alignItems: 'flex-end' } : null}>
      <div className="menu--left">
        <div className="menu--module--name">
          <Link to="/" className="underline_link">
            <div className="nectar-brand">
              <img className="nectar-brand__logo" src={logo} alt="Nectar 360" />
            </div>
          </Link>
        </div>
        {!offline && (
          <div
            className="menu--icon"
            style={{ ...((modal.isVisible || loading.isVisible) && { opacity: 0.5, cursor: 'default' }) }}
          >
            <MdMenu
              className="app-icons"
              onClick={() => {
                !(modal.isVisible || loading.isVisible) && handleSideBarChange(true);
              }}
            />
          </div>
        )}
        <div className="menu--module--name">
          {}
          {currentModule ? (
            <Link to={currentModule.path} className="underline_link">
              {currentModule.name}
            </Link>
          ) : (
            <Link to="/" className="underline_link">
              Igloo
            </Link>
          )}{' '}
          <div className="menu--auto--save">
            <TextEffect
              text={
                moment(lastSave) > moment().subtract(30, 'seconds') ? (
                  <span>
                    <MdCheck /> Last saved {moment(lastSave).fromNow()}
                  </span>
                ) : saveError ? (
                  <span>
                    <MdClear /> Error saving, check you are online.
                  </span>
                ) : null
              }
              effect="rise-up"
              autoDismiss={5000}
            />
          </div>
        </div>
      </div>
      <div className="menu--left">
        Welcome, {givenName.substring(0, 1).toUpperCase() + givenName.substring(1)} ({groups[0] && groups[0].name})
        <IconButton color="primary" aria-label="logout" component="span" onClick={handleClick}>
          <MdAccountBox style={{ color: 'white' }} />
        </IconButton>
        <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

const mapStateToProps = ({ savetargetedcomms, modal, loading }) => ({
  targetedComms: {
    save: { ...savetargetedcomms },
  },
  modal: {
    isVisible: modal.show,
  },
  loading: {
    isVisible: loading.show,
  },
});

export default connect(mapStateToProps)(Appbar);
