import React from 'react';
import PropTypes from 'prop-types';
import loadingGif from '../../../../images/app_loading.gif';
import staticIcon from '../../../../images/app_icon_100x100.png';
import './style.css';
import Spinner from './Spinner';
import { colorNames } from '../../../constants/colors';
import AnimatedCheck from '../AnimatedCheck';
import TextEffect from '../TextEffect';

class Loading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: false,
    };
    this.timer = null;
  }

  componentDidMount() {
    if (this.props.timeout) {
      this.timer = setTimeout(() => {
        this.setState({ time: true });
      }, this.props.timeout);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    if (this.props.inline) {
      return (
        <div
          style={{
            fontFamily: 'Helvetica Neue, Helvetica, sans-serif',
            fontSize: '11pt',
            textAlign: 'center',
            width: 'auto',
            display: 'inline-block',
            userSelect: 'none',
            cursor: 'default',
            ...this.props.style,
          }}
        >
          <img draggable={false} src={loadingGif} style={{ width: 50, float: 'left', userSelect: 'none' }} />
          <br />
          <div style={{ float: 'left', width: 'auto', marginLeft: 10 }}>{this.props.text}</div>
        </div>
      );
    }
    return (
      <div style={{ position: 'relative' }}>
        <Spinner size={110} color={this.props.spinColor || colorNames.white} complete={this.props.complete} />
        <AnimatedCheck
          outline={false}
          color={colorNames.white}
          delay={1}
          size={110}
          style={{
            width: '110px',
            height: '110px',
            display: 'inline-block',
            position: 'absolute',
            top: '0',
            left: '50%',
            marginLeft: '-55px',
          }}
          drawn={this.props.complete}
        />
        <img src={staticIcon} className={this.props.complete ? 'loading-igloo loading-igloo-hide' : 'loading-igloo'} />

        <TextEffect
          style={{ marginTop: 25 }}
          effect="rise-up"
          animateTextChange
          text={<div>{this.props.complete ? this.props.doneText || '' : this.props.text || ''}</div>}
        />
        {this.props.progress && (
          <div>
            <div
              style={{
                width: '50%',
                backgroundColor: 'rgba(255,255,255,0.4)',
                margin: '0 auto',
                marginTop: 10,
                fontSize: 5,
                borderRadius: 10,
              }}
            >
              <div
                style={{
                  width: `${this.props.progress * 100}%`,
                  backgroundColor: '#FFFFFF',
                  borderRadius: 'inherit',
                  transition: 'width 0.5s ease-out',
                }}
              >
                &nbsp;
              </div>
            </div>
            <div
              style={{
                fontSize: 10,
                color: 'rgba(255,255,255,0.8)',
                textAlign: 'center',
                marginTop: 5,
              }}
            >
              {this.props.progress * 100}%
            </div>
          </div>
        )}
      </div>
    );
  }
}

Loading.propTypes = {
  timeout: PropTypes.number,
  text: PropTypes.node,
  inline: PropTypes.bool,
  busy: PropTypes.bool,
};

Loading.defaultProps = {
  busy: true,
};

export default Loading;
