import React from 'react';
import ErrorPage from '../ErrorPage/ErrorPage';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    const stackTrace =
      errorInfo && errorInfo.componentStack
        ? error.toString() + errorInfo.componentStack
        : error.stack || error.toString();
    this.setState({ error: stackTrace });
  }

  render() {
    if (this.state.error) {
      const { error } = this.state;
      return <ErrorPage errorInfo={{ code: 500 }} stackTrace={error} includeFurniture />;
    }
    return this.props.children;
  }
}
