import { reduce } from 'lodash';
import moment from 'moment';
import ActionTypes from '../../../constants/ActionTypes/index';
import { returnJSON } from '../../../utils/async';
import modal from '../../modal';
import { requestQueue } from '../../queue';

const {
  targetedComms: {
    smartcounts: { list },
  },
} = ActionTypes;

const clear = () => {
  return { type: list.CLEAR };
};

const receiveList = (data) => {
  return (dispatch) => {
    dispatch(requestQueue()).then(() => {
      return new Promise((resolve, reject) => {
        let uniqueCycles = [];
        try {
          uniqueCycles = reduce(
            data.list,
            (array, c) => {
              if (c.type === 'campaign' && c.payload.time_periods) {
                if (!array.includes(c.payload.time_periods.cycle)) {
                  array.push(c.payload.time_periods.cycle);
                }
              }
              return array;
            },
            []
          );
        } catch (err) {
          console.error(err);
          reject(err);
        }

        resolve(
          dispatch({
            type: list.UPDATE,
            data: {
              list: data.list,
              cycles: {
                all: data.cycles,
                available: uniqueCycles,
                selected: uniqueCycles.length === 1 ? uniqueCycles[0] : 'all',
              },
              locked: data.locked,
              loading: false,
            },
          })
        );
      });
    });
  };
};

const requestList = (
  start = moment().subtract(2, 'months').format('YYYY-MM-DD'),
  end = moment().format('YYYY-MM-DD'),
  navigate
) => {
  return (dispatch) => {
    dispatch({
      type: list.UPDATE,
      data: {
        loading: true,
        list: [],
      },
    });
    const queryString = `?start=${start}&end=${end}`;
    dispatch(requestQueue()).then(() => {
      return new Promise((resolve, reject) => {
        returnJSON(`/api/targeted-comms/smartcounts/list${queryString}`, (err, res) => {
          if (err) {
            dispatch(
              modal.toggle((modal) => ({
                show: true,
                title: 'Smartcounts Error',
                style: 'error',
                body: 'There was an error when loading Smartcounts',
                actions: [
                  {
                    text: 'ok',
                    action: () => {
                      modal.close();
                      navigate('/');
                    },
                  },
                ],
              }))
            );
            return console.error(err);
          }
          if (res) {
            resolve(dispatch(receiveList(res)));
          }
        });
      });
    });
  };
};

const requestCycles = () => {
  return (dispatch) =>
    returnJSON('/api/targeted-comms/smartcounts/cycles', (err, res) => {
      if (err) return console.error(err);
      dispatch({ type: list.CYCLES, cycles: res });
    });
};

const selectCycle = (cycle) => {
  return { type: list.CYCLE, cycle };
};

const toggleLock = () => {
  return (dispatch) =>
    returnJSON('/api/targeted-comms/smartcounts/lock', (err, res) => {
      if (err) return console.error(err);
      dispatch({ type: list.LOCKED, locked: res.locked });
    });
};

const updateFilterText = (text) => {
  return { type: list.FILTER, text };
};

const updateEnd = (end) => {
  return { type: list.END, end };
};

const updateStart = (start) => {
  return { type: list.START, start };
};

const updateToggle = (toggle) => {
  return { type: list.TOGGLE, ...toggle };
};

export default {
  clear,
  requestList,
  requestCycles,
  selectCycle,
  toggleLock,
  updateFilterText,
  updateToggle,
  receiveList,
  updateEnd,
  updateStart,
};
