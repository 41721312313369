import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { formatProper } from '../../../utils/utils';

const ButtonWidget = (props) => {
  const isActive = props.selectedRadio === props.radioName;
  const classes = classnames('button--card', { active: isActive });
  return (
    <div
      className={classes}
      onClick={() => {
        if (props.func !== undefined) {
          props.func();
        }
        props.selectRadio(props.radioName);
      }}
    >
      {formatProper(props.radioName, props.ignoreFormat)}
    </div>
  );
};

ButtonWidget.propTypes = {
  selectedRadio: PropTypes.string.isRequired,
  radioName: PropTypes.string.isRequired,
  ignoreFormat: PropTypes.array,
  func: PropTypes.func,
};

export default ButtonWidget;
