import { createSelectorCreator } from 'reselect';
import { findIndex, memoize, reduce } from 'lodash';
import { getVisibleCells, getRequired, getCellOffers, cellTemplates } from './getCells';
import getCampaignObject from './getCampaignObject';

const createDeepEqualSelector = createSelectorCreator(memoize, (...args) =>
  args.reduce((acc, val) => `${acc}-${JSON.stringify(val)}`, '')
);

export const getCoupons = createDeepEqualSelector(
  [getRequired, getVisibleCells, getCellOffers, cellTemplates],
  (required, cells, offers, templates) => {
    return reduce(
      cells,
      (output, cell, code) => {
        if (required.includes(code)) {
          const couponIndex = findIndex(
            output,
            (o) => o.value === offers[code].value && o.type === offers[code].type && o.template === templates[code]
          );
          if (couponIndex > -1) {
            output[couponIndex].cells.push(code);
          } else {
            output.push({
              value: offers[code].value,
              type: offers[code].type,
              cells: [code],
              template: templates[code],
            });
          }
        }
        return output;
      },
      []
    );
  }
);
export const getCouponConfig = (state) => getCampaignObject(state).coupons;
export const getCouponText = (state) => getCouponConfig(state).text;
export const getCouponArtwork = (state) => ({
  position: getCouponConfig(state).artwork,
  image: getCouponConfig(state).artwork_path,
});
