import ActionTypes from '../constants/ActionTypes/index';

const { general } = ActionTypes;
const modal = (
  state = {
    show: false,
    title: null,
    body: null,
    actions: [],
    style: null,
    dismissable: false,
    preventESC: false,
  },
  action
) => {
  switch (action.type) {
    case general.TOGGLE_MODAL:
      return {
        ...state,
        show: action.show,
        title: action.title,
        body: action.body,
        actions: action.actions,
        style: action.style,
        dismissable: action.dismissable,
        preventESC: action.preventESC,
      };

    case general.AMEND_MODAL:
      delete action.type;
      return {
        ...state,
        ...action,
      };
    default:
      return state;
  }
};

export default modal;
