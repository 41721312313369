import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Actions from '../../../../../../actions';
import { colorNames } from '../../../../../../constants/colors';

class SCCountNameField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invalid: false,
    };
  }

  disableSaveButton = (state) => {
    this.props.actions.modal.amendAction(0, { disabled: state });
  };

  handleModalNameChange = (e) => {
    const v = e.target.value;
    this.props.actions.newcount.nameChange(v);
    if (v && v !== '') {
      if (this.props.newcount.existing_names.includes(v)) {
        this.setState({ invalid: 'Name already in use.' });
        this.disableSaveButton(true);
      } else if (/^[0-9]|\W/g.test(v)) {
        this.setState({ invalid: 'Name must be SQL safe, containing letter, numbers and underscores only.' });
        this.disableSaveButton(true);
      } else {
        this.setState({ invalid: false });
        this.disableSaveButton(false);
      }
    } else {
      this.disableSaveButton(true);
    }
  };

  render() {
    const { newcount } = this.props;
    return (
      <TextField
        inputProps={{ style: { color: 'white' } }}
        style={{ width: '100%' }}
        placeholder="Enter count name..."
        hintStyle={{ color: 'white' }}
        onChange={this.handleModalNameChange}
        defaultValue={newcount.name}
        error={this.state.invalid}
        helperText={this.state.invalid}
        errorStyle={{ color: colorNames.red }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  newcount: state.TargetedComms.Smartcounts.count,
});

const {
  targetedComms: {
    smartcounts: { newcount },
  },
  modal,
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    newcount: {
      nameChange: (name) => dispatch(newcount.updateName(name)),
    },
    modal: {
      amendAction: (index, opts) => dispatch(modal.amendAction(index, opts)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SCCountNameField);
