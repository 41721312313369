export const calculateLeftPosition = (containerMiddle, dimensions, selectedValue, fullBleed) => {
  if (!fullBleed) {
    if (dimensions.length === 0) {
      return 0;
    }
    const totalWidth = selectedValue === 0 ? 0 : dimensions.slice(0, selectedValue).reduce((a, b) => a + b);
    const currentSlideMiddle = dimensions[selectedValue] / 2;
    return containerMiddle - totalWidth - currentSlideMiddle;
  }
  const position = containerMiddle * 2 * -selectedValue;
  return isNaN(position) ? 0 : position;
};
