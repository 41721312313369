import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { formatProper } from '../../../../../utils/utils';

class FilterInfo extends React.Component {
  static propTypes = {
    filters: PropTypes.object.isRequired,
  };

  render() {
    const { filters } = this.props;
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {_.map(filters, (item, key) => {
          let body;
          if (key !== 'date') {
            if (item.length > 0) {
              body = (
                <ul>
                  {_.map(item, (i, k) => {
                    return <li key={k}>{formatProper(i, ['TV', 'JS', 'LTD'])}</li>;
                  })}
                </ul>
              );
            } else {
              body = 'None';
            }
            return (
              <div
                key={key}
                style={{
                  width: '33%',
                  minHeight: 100,
                }}
              >
                <div style={{ fontSize: 20 }}>{formatProper(key, ['ID'])}</div>
                <div
                  style={{
                    minHeight: 75,
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 12,
                  }}
                >
                  {body}
                </div>
              </div>
            );
          } else {
            const body = (
              <div>
                <div>Start: {item.start.toLocaleDateString('en-GB')}</div>
                <div>End: {item.end.toLocaleDateString('en-GB')}</div>
              </div>
            );
            return (
              <div
                key={key}
                style={{
                  width: '33%',
                  minHeight: 100,
                }}
              >
                <div style={{ fontSize: 20 }}>{formatProper(key, ['ID'])}</div>
                <div
                  style={{
                    minHeight: 75,
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 12,
                  }}
                >
                  {body}
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
}

export default FilterInfo;
