import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import './stye.css';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

class DialogModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  static propTypes = {
    submit: PropTypes.bool.isRequired,
    dialogContent: PropTypes.element.isRequired,
    submitFunc: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleCloseSubmit = () => {
    this.props.submitFunc();
    this.setState({
      open: false,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const actions = [
      <Button key={1} primary onClick={this.handleClose}>
        Cancel
      </Button>,
      <Button key={2} primary disabled={this.props.submit} onClick={this.handleCloseSubmit}>
        Submit
      </Button>,
    ];

    return (
      <Dialog
        title={this.props.title}
        modal
        open={this.state.open}
        bodyStyle={{
          color: '#707173',
          overflowY: 'scroll',
        }}
      >
        <DialogContent>{this.props.dialogContent}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

class InfoDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  static propTypes = {
    dialogContent: PropTypes.element.isRequired,
    title: PropTypes.string,
  };

  static defaultProps = {
    title: 'Information',
    scroll: false,
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <Dialog
        autoScrollBodyContent={this.props.scroll}
        title={this.props.title}
        modal={false}
        open={this.state.open}
        bodyStyle={{
          color: '#707173',
        }}
      >
        <DialogContent>{this.props.dialogContent}</DialogContent>
        <DialogActions>
          <Button key={1} primary onClick={this.handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

class SaveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  static propTypes = {
    submit: PropTypes.bool.isRequired,
    dialogContent: PropTypes.element.isRequired,
    submitFunc: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  static defaultProps = {
    title: 'Save Filters',
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleCloseSubmit = () => {
    this.props.submitFunc();
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const actions = [
      <Button key={1} primary onClick={this.handleClose}>
        Cancel
      </Button>,
      <Button key={2} primary disabled={this.props.submit} onClick={this.handleCloseSubmit}>
        Save
      </Button>,
    ];

    return (
      <Dialog
        title={this.props.title}
        modal
        open={this.state.open}
        bodyStyle={{
          color: '#707173',
          overflowY: 'scroll',
        }}
      >
        <DialogContent>{this.props.dialogContent}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

class OpenModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  static propTypes = {
    submit: PropTypes.bool.isRequired,
    delete: PropTypes.bool.isRequired,
    dialogContent: PropTypes.element.isRequired,
    submitFunc: PropTypes.func.isRequired,
    deleteFunc: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    contentStyle: PropTypes.object,
  };

  static defaultProps = {
    title: 'Open Filters',
  };

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleCloseSubmit = () => {
    this.props.submitFunc();
    this.setState({
      open: false,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    const actions = [
      <Button key={1} primary onClick={this.handleClose}>
        Cancel
      </Button>,
      <Button key={2} primary disabled={this.props.delete} onClick={this.props.deleteFunc}>
        Delete
      </Button>,
      <Button key={3} primary disabled={this.props.submit} onClick={this.handleCloseSubmit}>
        Open
      </Button>,
    ];

    return (
      <Dialog
        title={this.props.title}
        modal
        open={this.state.open}
        bodyStyle={{
          color: '#707173',
          overflowY: 'auto',
        }}
        contentStyle={this.props.contentStyle}
      >
        <DialogContent>{this.props.dialogContent}</DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

const Modal = (props) => {
  const { contentClassName, errorShake, title, actions, open, contentStyle, dialogContent, ...remaining } = props;
  const classes = classNames(contentClassName, { 'error--shake': errorShake });
  return (
    <Dialog
      contentClassName={classes}
      title={title}
      open={open}
      bodyStyle={{
        color: '#707173',
        overflowY: 'auto',
      }}
      contentStyle={contentStyle}
      {...remaining}
    >
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  actions: PropTypes.array,
  dialogContent: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contentStyle: PropTypes.object,
  modal: PropTypes.bool,
  contentClassName: PropTypes.string,
  errorShake: PropTypes.bool,
};

Modal.defaultProps = {
  modal: false,
  contentClassName: '',
  errorShake: false,
};

export { DialogModal, InfoDialog, SaveModal, OpenModal, Modal };
