import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';

class Icon extends React.Component {
  constructor(props) {
    super(props);
    this._createStyle(props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this._createStyle(props);
  }

  _createStyle = (props) => {
    this.styles = StyleSheet.create({
      icon: {
        width: props.size || 32,
        height: props.size || 32,
        fontSize: props.size || 32,
        color: props.color || '#CCCCCC',
      },
    });
  };

  render() {
    return (
      <div className={css(this.styles.icon)} style={{ ...this.props.style }}>
        {this.props.icon}
      </div>
    );
  }
}

Icon.propTypes = {
  icon: PropTypes.object,
  glyphColor: PropTypes.string,
  noCircle: PropTypes.bool,
  module: PropTypes.string,
  size: PropTypes.number,
};

export default Icon;
