import React from 'react';
import {
  Select,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  FormControlLabel,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { reduce, filter, map, sortBy } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import { MdIndeterminateCheckBox } from 'react-icons/md';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import jss from '../../../../utils/jss';
import { Content } from '../../../lib/Content';
import CSVInput from '../../../lib/CSVInput/CSVInput';
import { postJSON } from '../../../../utils/async';
import Actions from '../../../../actions';

const styles = {
  flexWrap: {
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
  },
  flexWrap2: {
    display: 'flex',
    minWidth: '100%',
    flexWrap: 'wrap',
  },
  flexWrap3: {
    display: 'flex',
    alignItems: 'left',
    position: 'relative',
    minWidth: '35%',
    flexWrap: 'wrap',
  },
  flexItem: {
    flex: 1,
    paddingLeft: 10,
    minWidth: 270,
  },
  flexItem2: {
    flex: 1,
    marginLeft: 10,
    paddingRight: 30,
    minWidth: 20,
  },

  flexItem3: {
    flex: 1,
    marginBottom: 15,
    minWidth: '95%',
    paddingLeft: 5,
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    flexDirection: 'row',
  },

  flexItem4: {
    flex: 1,
    marginBottom: 15,
    minWidth: '35%',
    marginRight: 20,
  },

  directionRow: {
    flexDirection: 'row',
  },

  paddingLeft: {
    paddingLeft: 10,
    marginBottom: 20,
  },

  marginLeft: {
    marginLeft: 10,
  },

  rightAlign: {
    textAlign: 'right',
  },
  leftAlign: {
    textAlign: 'left',
  },
  skuInput: {
    paddingLeft: 10,
  },
  secondFlexItem: {
    textAlign: 'right',
  },
  checkbox: {
    marginBottom: 0,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  areaTitle: {
    paddingLeft: 10,
    fontSize: '10pt',
  },
  tableWrapper: {
    paddingRight: 10,
    width: '100%',
  },
  noHighlight: {
    backgroundColor: 'transparent',
  },
};

class SkuChecker extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'SkuChecker',
      classNamePrefix: 'SkuChecker-',
    });
    this.state = {
      catLevel: 'category_no',
      data: [],
      skuInput: '',
      catInput: '',
      finalSkus: [],
      finalCats: [],
    };
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  handleDataRequest = () => {
    const { catLevel, skuInput, catInput } = this.state;

    this.props.loading.toggle({
      show: true,
      text: 'Searching Skus...',
    });

    postJSON(
      '/api/lookup/sku/check',
      {
        sku_no: skuInput.split(',').map((c) => parseInt(c)),
        category_no: catInput.split(','),
        category_level: catLevel,
      },
      (error, result) => {
        if (error) return console.error(error);

        const inAisleSkus = reduce(
          filter(result, (o) => o.inAisle),
          (array, sku) => {
            array.finalSkus.push(sku.sku_no);
            array.finalCats.push(sku[this.state.catLevel]);
            array.finalCats = array.finalCats.filter((value, index, self) => self.indexOf(value) === index).sort();
            return array;
          },
          { finalSkus: [], finalCats: [] }
        );

        const data = sortBy(result, (result) => result.exists);

        this.setState(
          {
            data,
            ...inAisleSkus,
          },

          () =>
            this.props.loading.toggle({
              show: false,
            })
        );
      }
    );
  };

  handleSkuInput = (value, error) => {
    this.setState({
      skuInput: value,
      finalSkus: [],
      finalCats: [],
    });
  };

  handleChange = (event, index) => {
    const { value } = event.target;
    this.setState({
      catLevel: value,
      finalSkus: [],
      finalCats: [],
    });
  };

  handleCategoryChange = (value, error) => {
    this.setState({
      catInput: value,
      finalSkus: [],
      finalCats: [],
    });
  };

  handleRowTick = (sku_no) => {
    this.setState((prevState) => {
      let { finalSkus } = prevState;
      let finalCats;
      if (prevState.finalSkus.includes(sku_no)) {
        finalSkus = finalSkus.filter((o) => o !== sku_no);
      } else {
        finalSkus.push(sku_no);
      }

      finalCats = reduce(
        prevState.data.filter((o) => finalSkus.includes(o.sku_no)),
        (array, row) => {
          array.push(row[this.state.catLevel]);
          return array;
        },
        []
      )
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();
      return {
        finalSkus,
        finalCats,
      };
    });
  };

  handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  handleSelectAll = (event, checked) => {
    let finalSkus;
    let finalCats;
    if (checked) {
      finalSkus = reduce(
        this.state.data,
        (array, value) => {
          if (value.exists) {
            array.push(value.sku_no);
          }
          return array;
        },
        []
      );
      finalCats = reduce(
        this.state.data.filter((o) => finalSkus.includes(o.sku_no)),
        (array, row) => {
          array.push(row[this.state.catLevel]);
          return array;
        },
        []
      )
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();
    } else {
      finalSkus = [];
      finalCats = [];
    }
    this.setState({ finalSkus, finalCats });
  };

  onRowHover = () => {
    this.setState({
      backgroundColor: 'none',
    });
  };

  render() {
    const { classes } = this.stylesheet;
    return (
      <Content title="SKU Checker" fullBleed>
        <Paper>
          <div className={classes.flexWrap}>
            <div className={jss.combine(classes.flexItem, classes.skuInput)}>
              <CSVInput
                onChange={this.handleSkuInput}
                value={this.state.skuInput}
                floatingLabelText="SKUs"
                style={{ marginBottom: 10 }}
                multiline
                rows={2}
                rowsMax={4}
              />
            </div>

            <div className={classes.flexItem}>
              <div className={classes.flexWrap2}>
                <div className={classes.flexItem2}>
                  <FormControl style={{ minWidth: 120 }}>
                    <InputLabel id="regexThing">Category Level</InputLabel>
                    <Select
                      labelId="regexThing"
                      floatinglabeltext="Category Level"
                      value={this.state.catLevel}
                      onChange={this.handleChange}
                      style={{ marginBottom: 10 }}
                    >
                      <MenuItem value="category_no">Category</MenuItem>
                      <MenuItem value="sub_category_no">Sub Category</MenuItem>
                      <MenuItem value="segment_no">Segment</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className={classes.flexItem2}>
                  <CSVInput
                    onChange={this.handleCategoryChange}
                    value={this.state.catInput}
                    floatingLabelText={`${
                      this.state.catLevel === 'category_no'
                        ? 'Category'
                        : this.state.catLevel === 'sub_category_no'
                        ? 'Sub Category'
                        : 'Segment'
                    } No`}
                    rows={1}
                    style={{ width: 120, paddingLeft: 50, whiteSpace: 'nowrap' }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* NEW BUTTONS STARTS HERE */}
          <div className={classes.paddingLeft}>
            <Button
              variant="contained"
              primary="true"
              onClick={this.handleDataRequest}
              value={this.state.value}
              disabled={this.state.skuInput === ''}
            >
              Check
            </Button>
            {this.state.finalSkus.length > 0 && [
              <Button
                variant="contained"
                secondary
                onClick={() => this.handleCopy(this.state.finalCats.join(','))}
                className={classes.marginLeft}
                key="copy_category"
              >
                {this.state.catLevel === 'category_no'
                  ? 'Copy Category nos'
                  : this.state.catLevel === 'sub_category_no'
                  ? 'Copy Sub Category nos'
                  : 'Copy Segment nos'}
              </Button>,

              <Button
                variant="contained"
                secondary
                onClick={() => this.handleCopy(this.state.finalSkus.join(','))}
                className={classes.marginLeft}
                key="copy_sku"
              >
                Copy SKUs
              </Button>,
            ]}
          </div>
          <Table multiselectable="true">
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormControlLabel
                    labelPlacement="start"
                    control={<Checkbox color="primary" />}
                    style={styles.checkbox}
                    uncheckedicon={this.state.finalSkus.length > 0 ? <MdIndeterminateCheckBox size={24} /> : null}
                    checked={
                      this.state.finalSkus.length > 0 &&
                      this.state.finalSkus.length === this.state.data.filter((o) => o.exists).length
                    }
                    onChange={this.handleSelectAll}
                  />
                </TableCell>

                <TableCell> SKU No </TableCell>
                <TableCell> SKU Name </TableCell>
                <TableCell>
                  {this.state.catLevel === 'category_no'
                    ? 'Category Name'
                    : this.state.catLevel === 'sub_category_no'
                    ? 'Sub Category Name'
                    : 'Segment Name'}
                </TableCell>

                <TableCell>
                  {this.state.catLevel === 'category_no'
                    ? 'Category No'
                    : this.state.catLevel === 'sub_category_no'
                    ? 'Sub Category No'
                    : 'Segment No'}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody deselectonclickaway="false">
              {map(this.state.data, (row, index) => (
                <TableRow
                  key={index}
                  style={{
                    color: row.sku_name === 'zero' ? 'lightgrey' : 'inherit',
                  }}
                >
                  <TableCell>
                    <FormControlLabel
                      labelPlacement="start"
                      control={<Checkbox color="primary" />}
                      style={styles.checkbox}
                      disabled={!row.exists}
                      onChange={() => this.handleRowTick(row.sku_no)}
                      checked={this.state.finalSkus.includes(row.sku_no)}
                    />
                  </TableCell>
                  <TableCell style={{ userSelect: 'auto' }}>{row.sku_no}</TableCell>
                  <TableCell style={{ whiteSpace: 'pre-wrap', userSelect: 'auto' }}>{row.sku_name}</TableCell>

                  <TableCell style={{ userSelect: 'auto' }}>
                    {this.state.catLevel === 'category_no'
                      ? row.category_name
                      : this.state.catLevel === 'sub_category_no'
                      ? row.sub_category_name
                      : row.segment_name}
                  </TableCell>
                  <TableCell style={{ userSelect: 'auto' }}>
                    {this.state.catLevel === 'category_no'
                      ? row.category_no
                      : this.state.catLevel === 'sub_category_no'
                      ? row.sub_category_no
                      : row.segment_no}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Content>
    );
  }
}

const { loading } = Actions;

const mapDispatchToProps = (dispatch) => ({
  loading: {
    toggle: (options) => dispatch(loading.toggle(options)),
  },
});

export default connect(null, mapDispatchToProps)(SkuChecker);
