import React from 'react';
import Chip from '@material-ui/core/Chip';

const styles = {
  chip: {
    margin: 4,
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
};

export default class GenericChips extends React.Component {
  render() {
    return (
      <div style={styles.wrapper}>
        {this.props.data.map((value, index) => (
          <Chip key={index} style={styles.chip} onDelete={() => this.props.onDelete(index)} label={value} />
        ))}
      </div>
    );
  }
}
