import React from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { DialogModal, InfoDialog, SaveModal, OpenModal } from '../../../lib/Dialog/Dialog';
import TextInput from '../../../lib/TextInput/TextInput';
import ModalContent from './views/Selections/ModalContent';
import SelectCategory from './views/Selections/SelectCategory';
import Loading from '../../../lib/Loading/Loading';
import Results from './views/Results/Results';
import PasteContent from '../../../lib/PasteContent/PasteContent';
import SelectionInfo from './views/Selections/SelectionInfo';
import PopUp from '../../../lib/PopUp/PopUp';
import Error from '../../../lib/ErrorPage/ErrorPage';
import FilterInfo from './lib/FilterInfo';
import FilterOpen from './lib/FilterOpen';

const returnPage = function (page) {
  switch (page) {
    case 'options':
      return (
        <div className="view--content">
          <DialogModal
            title={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <MdInfoOutline
                  height="25"
                  width="25"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => this.state.func.handleInfoOpen(this.state.modal.view)}
                />
                <div style={{ fontSize: 25, paddingLeft: 10 }}>{this.state.modal.title}</div>
              </div>
            }
            dialogContent={<ModalContent state={this.state} page={this.state.modal.view} />}
            submit={!this.state.modal.submit}
            ref={(child) => {
              this.modal = child;
            }}
            submitFunc={this.state.func.submit}
          />
          <DialogModal
            submit={false}
            dialogContent={<PasteContent className="paste--master--id" floatingLabelText="Paste Campaign ID" />}
            title="Paste Campaign ID"
            submitFunc={() => this.state.func.handlePaste()}
            ref={(child) => {
              this.masterModal = child;
            }}
          />
          <InfoDialog
            dialogContent={SelectionInfo[this.state.modal.info]}
            ref={(child) => {
              this.infoModal = child;
            }}
          />
          <SaveModal
            submit={!this.state.modal.submit}
            dialogContent={
              <div className="save--filters">
                <TextInput
                  placeholder="Save"
                  textID="save--filters"
                  onChange={this.handleSaveChange}
                  error={this.state.errorSaveText != null}
                  helperText={this.state.errorSaveText}
                />
                <FilterInfo filters={this.state.selectedValues} />
              </div>
            }
            submitFunc={() => this.handleSaveFilters()}
            ref={(child) => (this.saveModal = child)}
          />
          <OpenModal
            submit={!this.state.modal.submit}
            delete={!this.state.modal.delete}
            dialogContent={
              this.state.saved.length === 0 ? (
                <div>No saved filters</div>
              ) : (
                <FilterOpen
                  saved={this.state.saved}
                  filters={
                    this.state.open !== null
                      ? this.state.saved[this.state.open].selectedValues
                      : {
                          brand: [],
                          manufacturer: [],
                          master_campaign_id: [],
                          primary_channel: [],
                          channel: [],
                          category: [],
                          super_category: [],
                          date: this.state.selectedValues.date,
                        }
                  }
                  radioName="openFilters"
                  handleOpenChange={this.handleOpenChange}
                  value={this.state.open}
                />
              )
            }
            contentStyle={{
              width: 1000,
              maxWidth: 'none',
            }}
            submitFunc={() => this.handleOpenFilters()}
            deleteFunc={() => this.handleDeleteFilters()}
            ref={(child) => (this.openModal = child)}
          />
          <SelectCategory
            names={Object.values(this.state.names)}
            values={this.state.selectedValues}
            numberCampaigns={this.state.numberCampaigns}
            openModal={this.handleOpen}
            openInfoModal={this.handleInfoOpen}
            campaignFunc={this.handleSubmitData}
            deleteFilters={this.deleteFilters}
            handlePopOpen={this.handlePopOpen}
            openSaveModal={this.handleSaveOpen}
            openOpenModal={this.handleOpenOpen}
          />
          <PopUp message={this.state.popup.message} open={this.state.popup.open} handleclose={this.handlePopUpClose} />
        </div>
      );
    case 'results':
      return <Results data={this.state.results} funcs={this.state.func} selectedFilters={this.state.selectedValues} />;
    case 'loading':
      return <Loading timeout={5000} />;
    case 'error':
      return <Error imageSrc="./assets/images/Error/error.jpg" errorText="404 - Oh you gotta be fuckin' kiddin' me" />;
  }
};

export { returnPage };
