import ActionTypes from '../../../../constants/ActionTypes';
import { syncToServer } from './index';

const {
  targetedComms: {
    smartcounts: { campaign },
  },
} = ActionTypes;

export const updateClashes = (clashes) => {
  return (dispatch) => {
    return Promise.resolve(dispatch({ type: campaign.UPDATE_CLASHES, clashes })).then(() => {
      dispatch(syncToServer);
    });
  };
};
