import Excel from 'exceljs';
import { map, reduce } from 'lodash';
import FileSaver from 'file-saver';
import { getUser } from './authProvider';

/**
 * Create Excel workbook from JSON object
 * @param {Object[]} data - An array of worksheets
 * @param {string} data[].name - The name of the worksheet
 * @param {Object} data[].data - The data to place in this worksheet
 * @param {string} filename - The default filename for the resulting workbook (excl. extension)
 * @param {bool} [autoOpen=true] - Open the file automatically upon save
 * @returns {Promise<String>} - Returns the filepath
 */

export default (data, filename) => {
  return new Promise((resolve, reject) => {
    const wb = new Excel.Workbook();
    const user = getUser();
    wb.creator = `${user.givenName} ${user.surname}`;
    wb.lastModifiedBy = wb.creator;
    wb.created = new Date();
    wb.modified = new Date();
    wb.company = 'Nectar360';
    wb.description = 'Generated by igloo';

    if (data) {
      data.map((ws) => {
        const sheet = wb.addWorksheet(ws.name, ws.properties);
        if (ws.data.length > 0) {
          const cols = Object.keys(ws.data[0]);
          sheet.columns = reduce(
            cols,
            (array, col) => {
              array.push({ header: col, key: col });
              return array;
            },
            []
          );

          map(ws.data, (row) => {
            const newRow = [];
            map(row, (data) => {
              if (!isNaN(data)) newRow.push(parseInt(data));
              else newRow.push(data);
            });
            sheet.addRow(newRow);
          });
        }
      });
      wb.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        resolve(FileSaver.saveAs(blob, `${filename}.xlsx`));
      });
    } else {
      alert('Could not generate XLS.');
      reject('xls_error');
    }
  });
};
