import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IconButton, Popover, Menu, MenuItem, Divider, ListItemIcon } from '@material-ui/core';
import { MdMenu, MdContentCopy, MdDelete, MdInput, MdEqualizer, MdRefresh, MdFileDownload } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Actions from '../../../../../../actions';
import { colorNames } from '../../../../../../constants/colors';
import Spinner from '../../../../../lib/Loading/Spinner';
import { deleteJSON, postDownload, postJSON } from '../../../../../../utils/async';
import config from '../../../../../../../config';
import PermissionCheck from '../../../../../../utils/PermissionCheck';
import LoyaltyRequest from './LoyaltyRequest';
import { getStart, getEnd } from '../../../../../../reducers/TargetedComms/Smartcounts/list';
import { smartcounts_download } from '../../../../../../utils/Analytics';

const OpenCount = ({ queueId, stage, skus_missing }) => {
  const navigate = useNavigate();
  return (
    <MenuItem
      onClick={() => {
        navigate(`count/${queueId}?step=4`);
      }}
      disabled={stage < 4 && skus_missing.length > 0}
    >
      <ListItemIcon>
        <MdEqualizer />
      </ListItemIcon>{' '}
      Open Count
    </MenuItem>
  );
};

const OpenCampaign = ({ expired, stage, skus_missing, type, countId }) => {
  const navigate = useNavigate();
  return (
    <MenuItem
      disabled={expired || (stage < 4 && skus_missing.length > 0)}
      onClick={() => navigate(`campaign/${countId}`)}
    >
      <ListItemIcon>
        <MdInput />
      </ListItemIcon>
      {type === 'Campaign' ? 'Open Campaign' : 'Use in Campaign'}
    </MenuItem>
  );
};

const CloneCount = ({ closeMenu, stage, skus_missing, ...props }) => {
  const navigate = useNavigate();

  const handleClone = () => {
    const {
      rowData: {
        count: { id, queue },
      },
    } = props;

    closeMenu();
    props.modal.toggle((modal) => ({
      show: true,
      title: 'Clone Count',
      body: 'Would you like to adjust the count before it is cloned?',
      actions: [
        {
          text: 'Cancel',
          action: modal.close,
        },
        {
          text: 'Adjust',
          position: 'right',
          action: () => {
            modal.close();
            navigate(`count/clone?queue_id=${queue}`);
          },
        },
        {
          text: 'Clone',
          position: 'right',
          action: () => {
            modal.close();
            props.loading.toggle({
              show: true,
              text: 'Cloning count...',
            });
            postJSON('/api/targeted-comms/smartcounts/count/clone', { count_id: id }, (err, result) => {
              if (err) console.log(err);
              props.campaign.receiveList(result);
              props.loading.toggle({
                show: false,
              });
              props.queue.requestQueueCheck('smartcounts');
            });
          },
        },
      ],
    }));
  };

  return (
    <MenuItem disabled={stage < 4 && skus_missing.length > 0} onClick={handleClone}>
      <ListItemIcon>
        <MdContentCopy />
      </ListItemIcon>{' '}
      Clone Count
    </MenuItem>
  );
};

class SCMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchor: null,
      campaignLoading: false,
    };
  }

  closeMenu = () => {
    this.setState({ open: false });
  };

  handleRefresh = () => {
    const {
      rowData: {
        count: { id },
      },
    } = this.props;
    this.closeMenu();
    this.props.loading.toggle({ show: true, text: 'Loading...' });
    postJSON('/api/targeted-comms/smartcounts/count/refresh', { count_id: id }, (err, result) => {
      if (err) console.log(err);
      this.props.campaign.receiveList(result);
      this.props.loading.toggle({ show: false });
      this.props.queue.requestQueueCheck('smartcounts');
    });
  };

  handleDelete = () => {
    const { rowData: data } = this.props;
    this.closeMenu();
    if (data.type === 'Count') {
      this.props.modal.toggle((modal) => ({
        show: true,
        title: 'Delete Count',
        style: 'warning',
        body: `You are about to delete the count ${data.name}. Would you like to continue?`,
        actions: [
          { text: 'Cancel', action: modal.close },
          {
            text: 'Delete Count',
            position: 'right',
            color: colorNames.red,
            action: () => {
              modal.close();
              this.props.loading.toggle({ show: true, text: 'Deleting count...' });
              deleteJSON(
                '/api/targeted-comms/smartcounts/count',
                {
                  count_id: data.count.id,
                  start: this.props.start,
                  end: this.props.end,
                },
                (err, res) => {
                  if (err) {
                    modal.toggle({
                      title: 'Error',
                      style: 'error',
                      body: 'There was an error.',
                      actions: [{ text: 'OK', action: modal.close }],
                    });
                  } else {
                    this.props.campaign.receiveList(res);
                    this.props.loading.toggle({
                      complete: true,
                      doneText: 'Count Deleted',
                    });
                  }
                }
              );
            },
          },
        ],
      }));
    } else {
      this.props.modal.toggle((modal) => ({
        show: true,
        title: 'Delete Campaign',
        style: 'warning',
        body: `You are about to delete the campaign ${data.name}. Would you like to continue?`,
        actions: [
          {
            text: 'Cancel',
            action: modal.close,
          },
          {
            text: 'Delete Campaign',
            position: 'right',
            color: colorNames.red,
            action: () => {
              modal.close();
              this.props.loading.toggle((loading) => ({
                show: true,
                text: 'Deleting campaign...',
              }));
              deleteJSON(
                '/api/targeted-comms/smartcounts/campaign',
                {
                  count_id: data.count.id,
                  campaign_id: data.campaign.id,
                  start: this.props.start,
                  end: this.props.end,
                },
                (err, result) => {
                  if (err) {
                    modal.toggle({
                      title: 'Error',
                      style: 'error',
                      body: 'There was an error.',
                      actions: [{ text: 'OK', action: modal.close }],
                    });
                  } else {
                    this.props.campaign.receiveList(result);
                    this.props.loading.toggle({
                      complete: true,
                      doneText: 'Campaign Deleted',
                    });
                  }
                }
              );
            },
          },
        ],
      }));
    }
  };

  handleLoyaltyData = (campaign, base) => {
    this.closeMenu();
    this.props.modal.toggle((modal) => ({
      show: true,
      title: 'Request Loyalty Data',
      style: 'info',
      body: (
        <div>
          You are about to request a file that will contain customer Loyalty IDs and is therefore regarded as sensitive.
          <br />
          <br />
          The file will take a few minutes to generate.
        </div>
      ),
      actions: [
        { action: modal.close, text: 'Cancel' },
        {
          position: 'right',
          text: 'Request Data',
          color: colorNames.green,
          action: () => {
            this.props.modal.toggle((modal) => ({
              show: true,
              title: 'Gathering Loyalty Data',
              body: <LoyaltyRequest modal={this.props.modal} campaign={campaign} base={base} />,
              actions: [],
            }));
          },
        },
      ],
    }));
  };

  downloadFile = (content, suffix) => {
    if (!Array.isArray(content)) content = [content];
    const element = document.createElement('a');
    const file = new Blob(content, { type: 'application/zip' });
    element.href = URL.createObjectURL(file);
    element.download = `myFile.${suffix}`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  handleData = (type) => {
    this.setState({ campaignLoading: true });
    let endpoint;
    let filename;
    switch (type) {
      case 'js':
        endpoint = 'sainsburys';
        filename = 'Sainsburys';
        break;
      case 'catalina':
        endpoint = 'catalina';
        filename = 'Catalina';
        break;
    }
    smartcounts_download(endpoint, this.props.rowData.campaign.id);
    postDownload(
      `/api/targeted-comms/smartcounts/data/${endpoint}`,
      { campaign_id: this.props.rowData.campaign.id },
      (err, response) => {
        this.setState({ campaignLoading: false });
        this.closeMenu();
        if (err) {
          this.props.modal.toggle((modal) => ({
            show: true,
            title: 'Error building file',
            style: 'warning',
            body: (
              <div>
                {`An error occurred when building the ${filename} data file. Please try again or reach out to ${config.helpEmail} for assistance.`}
              </div>
            ),
            actions: [{ action: modal.close, text: 'Cancel' }],
          }));
        } else {
          this.downloadFile(response, 'zip');
        }
      }
    );
  };

  render() {
    const {
      rowData: {
        type,
        actions,
        campaign: {
          stage,
          id: campaign_id,
          payload: { base_id },
        },
        count: {
          id: count_id,
          expired,
          queue,
          payload: { skus_missing: missing },
        },
      },
    } = this.props;

    const skus_missing = missing || [];

    return (
      <div>
        <IconButton
          onClick={(e) =>
            this.setState({
              open: true,
              anchor: e.currentTarget,
            })
          }
        >
          <MdMenu size={20} />
        </IconButton>

        <Menu
          open={this.state.open}
          anchorEl={this.state.anchor}
          onClose={() =>
            this.setState({
              open: false,
            })
          }
        >
          {stage < 4 &&
            skus_missing.length > 0 && [
              <MenuItem key="1" disabled>
                {`${skus_missing.length} SKUs are missing`}
              </MenuItem>,
              <Divider key="2" />,
            ]}

          {/*
                            Open Campaign
                        */}
          <OpenCampaign expired={expired} stage={stage} skus_missing={skus_missing} countId={count_id} type={type} />

          {/*
                            Open Count
                        */}
          <OpenCount queueId={queue} stage={stage} skus_missing={skus_missing} />

          <Divider />

          {/*
                            Refresh Volumes
                        */}
          {(stage === 0 || !stage) && (
            <MenuItem onClick={this.handleRefresh}>
              <ListItemIcon>
                <MdRefresh />
              </ListItemIcon>{' '}
              Refresh Volumes
            </MenuItem>
          )}

          {/*
                            Clone Count
                        */}
          <CloneCount {...this.props} closeMenu={this.closeMenu} stage={stage} skus_missing={skus_missing} />

          <Divider />
          {/*
                            Data Files
                        */}
          {stage >= 1 &&
            stage !== 7 &&
            ((<Divider key="divider" />),
            (
              <MenuItem onClick={() => this.handleData('js')}>
                {
                  <ListItemIcon>
                    {this.state.campaignLoading ? (
                      <span>
                        <Spinner size={16} color={colorNames.blue} />
                      </span>
                    ) : (
                      <span>
                        <MdFileDownload />
                      </span>
                    )}
                  </ListItemIcon>
                }{' '}
                Sainsburys Data
              </MenuItem>
            ))}
          {stage >= 2 && stage !== 7 && (
            <MenuItem onClick={() => this.handleData('catalina')}>
              {
                <ListItemIcon>
                  <MdFileDownload />
                </ListItemIcon>
              }{' '}
              Catalina Data
            </MenuItem>
          )}

          {stage === 6 && stage !== 7 && PermissionCheck('TargetedComms_Smartcounts_Loyalty_Data') && (
            <MenuItem onClick={() => this.handleLoyaltyData(campaign_id, base_id)}>
              {
                <ListItemIcon>
                  <MdFileDownload />
                </ListItemIcon>
              }{' '}
              Loyalty Data
            </MenuItem>
          )}
          {/*
                            Delete
                        */}
          {actions.canDelete && (stage === 0 || !stage) && (
            <div>
              <Divider />

              {type === 'Count' ? (
                <MenuItem style={{ color: colorNames.red }} onClick={this.handleDelete}>
                  <ListItemIcon>
                    <MdDelete color={colorNames.red} />
                  </ListItemIcon>
                  Delete Count
                </MenuItem>
              ) : (
                <MenuItem style={{ color: colorNames.red }} onClick={this.handleDelete}>
                  <ListItemIcon>
                    <MdDelete color={colorNames.red} />
                  </ListItemIcon>{' '}
                  Delete Campaign
                </MenuItem>
              )}
            </div>
          )}
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  start: getStart(state),
  end: getEnd(state),
});

const {
  queue,
  modal,
  loading,
  targetedComms: {
    smartcounts: { list },
  },
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  loading: {
    toggle: (opts) => dispatch(loading.toggle(opts)),
  },
  modal: {
    toggle: (opts) => dispatch(modal.toggle(opts)),
    update: (opts) => dispatch(modal.update(opts)),
  },
  campaign: {
    receiveList: (data) => dispatch(list.receiveList(data)),
    requestList: () => dispatch(list.requestList()),
  },
  queue: {
    requestQueueCheck: (codeName) => dispatch(queue.requestQueueCheck(codeName)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SCMenu);
