import React from 'react';

const style = {
  outer: {
    paddingBottom: 15,
  },
  innerTop: {
    fontSize: 25,
  },
};

const SelectionInfo = {
  options: (
    <div>
      <div style={style.outer}>
        <div style={style.innerTop}>How To</div>
        <ul>
          <li>Click on each option to filter campaigns.</li>
          <li>Once selected click submit</li>
          <li>Filters will then only display available selection based on pervious filters</li>
          <li>You can select an option to remove one/all the filters by given option</li>
          <li>You can also remove all filters by selecting Delete all filters</li>
        </ul>
      </div>
    </div>
  ),
  details: (
    <div>
      <div style={style.outer}>
        <div style={style.innerTop}>Details</div>
        <ul>
          <li>Displays any filters that have been applied.</li>
          <li>If no filter is applied for a given option then 'All' will be displayed.</li>
          <li>If more than one filter is applied for a given option then 'Multiple' will be displayed.</li>
          <li>If only one filter has been applied for a given option then that one filter will be listed.</li>
        </ul>
      </div>
    </div>
  ),
  brand: (
    <div>
      <div style={style.outer}>
        <div style={style.innerTop}>How To</div>
        <ul>
          <li>Click on Search and either find or search for individual brand.</li>
        </ul>
      </div>
      <div>
        <div style={style.innerTop}>Bulk</div>
        <ul>
          <li>Drop or upload csv with Brand as the header for column under which name all brands you wish to add.</li>
        </ul>
      </div>
    </div>
  ),
  manufacturer: (
    <div>
      <div style={style.outer}>
        <div style={style.innerTop}>How To</div>
        <ul>
          <li>Click on Search and either find or search for individual manufacturer.</li>
        </ul>
      </div>
      <div>
        <div style={style.innerTop}>Bulk</div>
        <ul>
          <li>
            Drop or upload csv with Manufacturer as the header for column under which name all manufacturers you wish to
            add.
          </li>
        </ul>
      </div>
    </div>
  ),
  master_campaign_id: (
    <div>
      <div style={style.outer}>
        <div style={style.innerTop}>How To</div>
        <ul>
          <li>Click on Search and either find or search for individual Campaign ID.</li>
        </ul>
      </div>
      <div>
        <div style={style.innerTop}>Bulk</div>
        <ul>
          <li>
            Drop or upload csv with Master Campaign ID as the header for column under which name all ID's you wish to
            add.
          </li>
          <li>You can also use the Paste ID in csv format by clicking on "Paste campaign ID's".</li>
        </ul>
      </div>
    </div>
  ),
  primary_channel: (
    <div>
      <div style={style.outer}>
        <div style={style.innerTop}>How To</div>
        <ul>
          <li>
            Click on a channel(s) to become a primary channel. This means that all combinations of channles must have at
            least one of the primary channels in the combination.
          </li>
          <li>
            e.g. If Six Sheets & TV Wall were both selected then only combinations that contained either Six Sheets
            and/or TV Wall would be returned for combination channels. All channels that are in these combinations will
            also be displayed for individual channels.
          </li>
        </ul>
      </div>
    </div>
  ),
  channel: (
    <div>
      <div style={style.outer}>
        <div style={style.innerTop}>How To</div>
        <ul>
          <li>
            Click on a channel(s) to become a selected channel. Combinations will not be guareteed to include a
            particular channel. If you wish a channel to always be part of the combinations of channels then select such
            channel as a Primary Channel.
          </li>
        </ul>
      </div>
    </div>
  ),
  category: (
    <div>
      <div style={style.outer}>
        <div style={style.innerTop}>How To</div>
        <ul>
          <li>Click on Search and either find or search for individual category.</li>
        </ul>
      </div>
      <div>
        <div style={style.innerTop}>Bulk</div>
        <ul>
          <li>
            Drop or upload csv with Category as the header for column under which name all category you wish to add.
          </li>
        </ul>
      </div>
    </div>
  ),
  super_category: (
    <div>
      <div style={style.outer}>
        <div style={style.innerTop}>How To</div>
        <ul>
          <li>Click on Search and either find or search for individual super category.</li>
        </ul>
      </div>
      <div>
        <div style={style.innerTop}>Bulk</div>
        <ul>
          <li>
            Drop or upload csv with Super Category as the header for column under which name all super categories you
            wish to add.
          </li>
        </ul>
      </div>
    </div>
  ),
  date: (
    <div>
      <div style={style.outer}>
        <div style={style.innerTop}>How To</div>
        <ul>
          <li>Click on start date and end date to limit campaign periods.</li>
        </ul>
      </div>
    </div>
  ),
};

export default SelectionInfo;
