import React from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite/no-important';
import { colorNames } from '../../../constants/colors';
import ContentTabbed from './ContentTabbed';
import jss from '../../../utils/jss';

const styles = {
  wrapper: {
    maxWidth: 960,
    width: '100%',
    margin: '0 auto',
    marginBottom: 10,
    position: 'relative',
    padding: 0,
    boxSizing: 'border-box',
  },
  wrapperFullBleed: {
    minWidth: '100%',
    padding: '0 20',
  },
  title: {
    fontSize: '30pt',
    fontWeight: 600,
    color: colorNames.graphite,
    margin: '10 0',
    userSelect: 'none',
    cursor: 'default',
  },
};

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'contentBox',
      classNamePrefix: 'ContentBox-',
    });
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  render() {
    const { title, subtitle, children, fullBleed, className, onBack, confirmBack, ...rest } = this.props;
    const { classes } = this.stylesheet;
    return (
      <div className={jss.combine(classes.wrapper, fullBleed && classes.wrapperFullBleed, className)} {...rest}>
        {title && <div className={classes.title}>{title}</div>}
        {subtitle && <div>{subtitle}</div>}
        {children}
      </div>
    );
  }
}

Content.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  fullBleed: PropTypes.bool,
  children: PropTypes.node,
};

Content.defaultProps = {
  fullBleed: false,
};

export { Content, ContentTabbed };
