import React from 'react';
import { Route } from 'react-router';
import { modules } from '../../../constants/moduleInfo';
import Smartcounts from '../../views/TargetedComms/Smartcounts';
import SCNewCount, { CloneCount } from '../../views/TargetedComms/Smartcounts/NewCount';
import CampaignBuilder from '../../views/TargetedComms/Smartcounts/CampaignBuilder/CampaignBuilder';
import { HasAccess } from './has-access';

const { smartCounts } = modules;

const HasSmartCountsAccess = ({ children }) => <HasAccess accessFn={smartCounts.hasAccess}>{children}</HasAccess>;

export const SmartCounts = (
  <Route path={smartCounts.path}>
    <Route
      index
      element={
        <HasSmartCountsAccess>
          <Smartcounts />
        </HasSmartCountsAccess>
      }
    />
    <Route
      path="campaign/:countId"
      element={
        <HasSmartCountsAccess>
          <CampaignBuilder />
        </HasSmartCountsAccess>
      }
    />
    <Route
      path="count/:countId"
      element={
        <HasSmartCountsAccess>
          <SCNewCount />
        </HasSmartCountsAccess>
      }
    />
    <Route
      path="count/clone"
      element={
        <HasSmartCountsAccess>
          <CloneCount />
        </HasSmartCountsAccess>
      }
    />
  </Route>
);
