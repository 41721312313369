import React from 'react';
import { Table, TableBody, TableHead, TableCell, TableRow, FormControlLabel, Checkbox } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { map } from 'lodash';
import Actions from '../../../actions';
import { postJSON } from '../../../utils/async';
import { colorNames } from '../../../constants/colors';
import Spinner from '../../lib/Loading/Spinner';

class CampaignDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusUpdate: [],
      processing: false,
    };
  }

  handleCheck = (code) => {
    this.setState((prevState) => {
      let { statusUpdate } = prevState;
      if (!statusUpdate.includes(code)) {
        statusUpdate.push(code);
      } else {
        statusUpdate = statusUpdate.filter((r) => r !== code);
      }
      return { statusUpdate };
    });
  };

  handleApprove = () => {
    this.setState({ processing: true });
    postJSON(
      '/api/campaign-checker/update',
      {
        campaigns: map(this.state.statusUpdate, (m) => ({ id: this.props.dialogData.id, code: m })),
      },

      (error, result) => {
        this.setState({ statusUpdate: [], processing: false });
        this.props.handleClose();
        if (error) {
          this.props.modal.toggle({
            show: true,
            title: 'Update Error',
            style: 'error',
            body: 'An error occurred, when updating.',
            actions: [
              {
                text: 'OK',
                action: () => {
                  this.props.modal.toggle({
                    show: false,
                  });
                },
              },
            ],
          });
        } else {
          this.props.modal.toggle({
            show: true,
            title: 'Status Update',
            style: 'success',
            body: `Evaluation Status updated successfully.`,
            actions: [
              {
                text: 'OK',
                action: () => {
                  this.props.modal.toggle({
                    show: false,
                  });
                },
              },
            ],
          });
          this.props.processData(result);
        }
      }
    );
  };

  render() {
    const { dialogOpen, handleClose, dialogData, isAnalyst } = this.props;
    const showQC = isAnalyst && dialogData.validations && dialogData.validations.every((v) => v.type === 'WARNING');
    const checked = this.state.statusUpdate;

    return (
      <Dialog
        modal={false}
        open={dialogOpen}
        onRequestClose={!this.state.processing ? handleClose : null}
        title={`Brief Status For ${dialogData.id}`}
        titleStyle={{ fontWeight: 'bold', userSelect: 'auto' }}
        bodyStyle={{ padding: 0 }}
      >
        <DialogContent>
          <div>
            <Table fixedHeader>
              <TableHead fixedHeader>
                <TableRow>
                  <TableCell style={{ width: 125 }}>Status</TableCell>
                  <TableCell>Validation Message</TableCell>
                  <TableCell>Action</TableCell>
                  {showQC && <TableCell style={{ width: 50 }}>Approve</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogData.validations &&
                  dialogData.validations.map((v, index) => {
                    return (
                      <TableRow hover key={index}>
                        <TableCell style={{ width: 125, userSelect: 'auto' }}>{v.type}</TableCell>
                        <TableCell
                          style={{
                            whiteSpace: 'normal',
                            userSelect: 'auto',
                            padding: 5,
                            verticalAlign: 'top',
                          }}
                        >
                          {v.message}
                        </TableCell>
                        <TableCell
                          style={{
                            whiteSpace: 'normal',
                            userSelect: 'auto',
                            padding: 5,
                            verticalAlign: 'top',
                          }}
                        >
                          {v.action}
                        </TableCell>
                        {showQC && (
                          <TableCell style={{ width: 50 }}>
                            {this.state.processing && checked.includes(v.code) ? (
                              <Spinner size={20} color={colorNames.blue} />
                            ) : (
                              <FormControlLabel
                                labelPlacement="start"
                                control={<Checkbox color="primary" />}
                                onChange={() => this.handleCheck(v.code)}
                                checked={checked.includes(v.code)}
                                disabled={this.state.processing}
                              />
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {showQC && (
              <div style={{ textAlign: 'right', paddingBottom: 10, paddingRight: 10 }}>
                <Button
                  style={{ marginTop: 10, labelColor: '#ffffff', backgroundColor: colorNames.green }}
                  variant="contained"
                  onClick={(e) => this.handleApprove(e)}
                  disabled={this.state.statusUpdate.length === 0 || this.state.processing}
                >
                  {this.state.processing ? 'Submitting...' : 'Submit'}
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.props.handleClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const { loading, modal } = Actions;
const mapDispatchToProps = (dispatch) => ({
  loading: {
    toggle: (data) => dispatch(loading.toggle(data)),
  },
  modal: {
    toggle: (data) => dispatch(modal.toggle(data)),
  },
});

export default connect(null, mapDispatchToProps)(CampaignDialog);
