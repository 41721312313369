import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { connect } from 'react-redux';
import { colorNames, hexToRgba } from '../../../constants/colors';
import { queueByIds } from '../../../reducers/queue';

const keyframes1 = {
  '0%': {
    left: '-35%',
    right: '100%',
  },
  '60%': {
    left: '100%',
    right: '-90%',
  },
  '100%': {
    left: '100%',
    right: '-90%',
  },
};
const keyframes2 = {
  '0%': {
    left: '-200%',
    right: '100%',
  },
  '60%': {
    left: '107%',
    right: '-8%',
  },
  '100%': {
    left: '107%',
    right: '-8%',
  },
};

const pulseKeyFrames = {
  '0%': {
    transform: 'translateX(0)',
  },
  '100%': {
    transform: 'translateX(200%)',
  },
};

const style = StyleSheet.create({
  wrapper: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    lineHeight: 1,
  },
  wrapper_bottom: {
    flexDirection: 'column',
  },
  progress_outer: {
    position: 'relative',
    width: '100%',
    height: 10,
    backgroundColor: 'rgba(0,0,0,0.3)',
    borderRadius: 20,
    overflow: 'hidden',
  },
  progress_inner: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    backgroundColor: hexToRgba(colorNames.blue).alpha(0.8),
    borderRadius: 'inherit',
    transition: 'width 1s ease-out',
    overflow: 'hidden',
  },
  progress_text: {
    marginLeft: 5,
    fontSize: 10,
    textAlign: 'center',
  },
  progress_text_bottom: {
    marginTop: 5,
    marginLeft: 0,
  },
  progress_inner_undetermined1: {
    width: 'auto !important',
    animationName: [keyframes1],
    animationDuration: '2.1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'cubic-bezier(0.65, 0.815, 0.735, 0.395)',
  },
  progress_inner_undetermined2: {
    width: 'auto !important',
    animationName: [keyframes2],
    animationDuration: '2.1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
    animationDelay: '1.15s',
  },
  progress_pulse: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,0.2)',
    borderRadius: 'inherit',
    transition: 'width 1s ease-out',
  },
  progress_pulse_1: {
    width: 'auto !important',
    animationName: [keyframes1],
    animationDuration: '2.1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'cubic-bezier(0.65, 0.815, 0.735, 0.395)',
  },
  progress_pulse_2: {
    width: 'auto !important',
    animationName: [keyframes2],
    animationDuration: '2.1s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
    animationDelay: '1.15s',
  },
});

class ProgressBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { showText, textPosition, runningText, className, queue, queueData, dispatch, ...props } = this.props;
    let { progress, ...rest } = props;
    if (queue && queueData[queue]) {
      progress = queueData[queue].progress;
    }
    progress = progress && Math.floor(progress * 100);

    return (
      <div
        className={`${className || ''} ${css(style.wrapper, textPosition === 'bottom' && style.wrapper_bottom)}`}
        {...rest}
      >
        <div className={css(style.progress_outer)} title={progress ? `${progress}%` : runningText}>
          <div
            className={css(style.progress_inner, !progress && style.progress_inner_undetermined1)}
            style={{ width: `${progress}%` }}
          >
            {progress && [
              <div key={1} className={css(style.progress_pulse, style.progress_pulse_1)} />,
              <div key={2} className={css(style.progress_pulse, style.progress_pulse_2)} />,
            ]}
          </div>
          {!progress && (
            <div
              className={css(style.progress_inner, !progress && style.progress_inner_undetermined2)}
              style={{ width: `${progress}%` }}
            />
          )}
        </div>
        {showText && (
          <div className={css(style.progress_text, textPosition === 'bottom' && style.progress_text_bottom)}>
            {progress ? `${progress}%` : runningText}
          </div>
        )}
      </div>
    );
  }
}

ProgressBar.propTypes = {
  queue: PropTypes.number,
  progress: PropTypes.number,
  showText: PropTypes.bool,
  textPosition: PropTypes.oneOf(['right', 'bottom']),
};

ProgressBar.defaultProps = {
  progress: null,
  showText: false,
  textPosition: 'right',
  runningText: 'Running...',
};

export default connect((state) => ({ queueData: queueByIds(state) }))(ProgressBar);
