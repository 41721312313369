import { createSelectorCreator } from 'reselect';
import { reduce, memoize } from 'lodash';
import { cells } from './getCells';

const createDeepEqualSelector = createSelectorCreator(memoize, (...args) =>
  args.reduce((acc, val) => `${acc}-${JSON.stringify(val)}`, '')
);
export const getBarcodes = (state) => cells(state).barcodes;

export default createDeepEqualSelector([getBarcodes], (barcodes) => {
  return barcodes;
});
