import React from 'react';
import jss from '../../../../../../utils/jss';
import Spinner from '../../../../../lib/Loading/Spinner';
import { colorNames } from '../../../../../../constants/colors';

const styles = {
  campaignName: {
    padding: 10,
    fontSize: '14pt',
    color: colorNames.graphite,
    userSelect: 'none',
    cursor: 'default',
    transition: 'margin-left 0.2s ease-in-out',
  },
  campaignNameAisle: {
    textAlign: 'right',
    fontSize: '11pt',
  },
  campaignNameTitle: {
    textAlign: 'left',
  },
  campaignNameProcessing: {
    marginLeft: 30,
  },
  campaignProcessing: {
    position: 'absolute',
    display: 'none',
    left: 10,
    top: 10,
    width: 20,
  },
  splitWrap: {
    display: 'flex',
    textAlign: 'center',
  },
  splitCol: {
    flex: 1,
  },
  subtle: {
    fontSize: '10pt',
    color: colorNames.grey,
  },
};

class CampaignName extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'CampaignName',
      classNamePrefix: 'CampaignName-',
    });
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  render() {
    const { row } = this.props;
    const { classes } = this.stylesheet;
    return (
      <div className={jss.combine(classes.campaignName, row.campaign.stage === 3 && classes.campaignNameProcessing)}>
        <div
          className={classes.campaignProcessing}
          style={{
            ...(row.campaign.stage === 3 && { display: 'block' }),
          }}
        >
          <Spinner size={20} color={colorNames.blue} />
        </div>
        <div className={classes.splitWrap}>
          <div className={jss.combine(classes.splitCol, classes.campaignNameTitle)}>
            {row.base.id} - {row.name}
            <div className={classes.subtle}>{row.fullName}</div>
          </div>
          <div className={jss.combine(classes.splitCol, classes.campaignNameAisle)}>
            Aisle: <b>{row.aisle.toSentenceCase()}</b>
            <br />
            Cycle: <b>{row.cycle}</b>
          </div>
        </div>
      </div>
    );
  }
}

export default CampaignName;
