import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { MdInfo, MdError, MdWarning, MdCheckCircle } from 'react-icons/md';
import { colorNames, hexToRgba } from '../../../constants/colors';
import Spinner from '../Loading/Spinner';

const delay = 500;
const styles = StyleSheet.create({
  wrapHide: {
    height: 0,
    overflow: 'hidden',
  },
  banner: {
    padding: '5 10',
    paddingLeft: 0,
    margin: 10,
    marginLeft: 0,
    userSelect: 'none',
    cursor: 'default',
    opacity: 0,
  },
  show: {
    opacity: 1,
  },
  animateWrap: {
    transition: `all ${delay}ms linear`,
  },
  animate: {
    transition: 'all 0.5s linear',
  },
});

class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      preHide: false,
    };
    this.content = null;
    this.wrap = null;
    this.timer = undefined;
  }

  componentWillReceiveProps(nextProps) {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    if (nextProps.show) {
      this.wrap.style.height = this.content.clientHeight + 20;
      this.timer = setTimeout(() => {
        this.setState({ show: true });
      }, delay);
    } else {
      this.setState({ show: false });
      this.timer = setTimeout(() => {
        this.wrap.style.height = 0;
      }, delay);
    }
  }

  render() {
    const { type, children, style, ...props } = this.props;
    let icon;
    switch (type) {
      case 'info':
        icon = <MdInfo color={colorNames.blue} size={24} />;
        break;
      case 'error':
        icon = <MdError color={colorNames.red} size={24} />;
        break;
      case 'warning':
        icon = <MdWarning color={colorNames.orange} size={24} />;
        break;
      case 'success':
        icon = <MdCheckCircle color={colorNames.green} size={24} />;
        break;
      case 'working':
      case 'spinner':
        icon = <Spinner size={16} color={colorNames.blue} style={{ marginRight: 8 }} />;
        break;
    }
    return (
      <div
        ref={(n) => (this.wrap = n)}
        className={css(styles.wrapHide, this.props.animate && styles.animateWrap)}
        style={style}
      >
        <div
          ref={(n) => (this.content = n)}
          className={css(styles.banner, this.state.show && styles.show, this.props.animate && styles.animate)}
        >
          {icon} {children}
        </div>
      </div>
    );
  }
}

Banner.defaultProps = {
  animate: true,
};

export default Banner;
