import ActionTypes from '../../../../constants/ActionTypes';
import { syncToServer, syncToServerDebounced } from './index';
import { deleteJSON, postJSON } from '../../../../utils/async';
import getCampaignObject from '../../../../components/views/TargetedComms/Smartcounts/CampaignBuilder/selectors/getCampaignObject';
import modal from '../../../modal';

const {
  targetedComms: {
    smartcounts: { campaign },
  },
} = ActionTypes;

export const updateCouponText = (text) => (dispatch) =>
  Promise.resolve(dispatch(syncToServerDebounced({ type: campaign.UPDATE_COUPON_TEXT, text })));

export const updateCouponArtworkPosition = (data) => (dispatch) =>
  Promise.resolve(dispatch([{ type: campaign.UPDATE_ARTWORK, position: data }, syncToServer]));

export const uploadCouponArtwork = (path) => (dispatch, getState) => {
  const reader = new FileReader();
  reader.readAsDataURL(path);
  reader.onload = (e) => {
    const file = reader.result;
    const ext = file && file.match(/^data:([a-zA-Z]*)\/([a-zA-Z]*)/)[2];
    return new Promise((resolve, reject) => {
      if (path.name && ['jpeg', 'jpg', 'png'].includes(ext.toLowerCase())) {
        postJSON(
          '/api/targeted-comms/smartcounts/campaign/proof/pack-shot',
          {
            file,
            campaign: getCampaignObject(getState()).campaign_id,
          },
          (err, result) => {
            if (err) {
              reject(err);
            } else {
              resolve(dispatch({ type: campaign.UPDATE_ARTWORK, path: result.packShot }));
            }
          }
        );
      } else {
        reject('invalid_type');
      }
    });
  };
};

export const removeArtwork = () => (dispatch, getState) => {
  const { campaign_id } = getCampaignObject(getState());
  return new Promise((resolve, reject) => {
    deleteJSON('/api/targeted-comms/smartcounts/campaign/proof/pack-shot/', { campaign_id }, (error, result) => {
      if (error) {
        dispatch(
          modal.toggle({
            show: true,
            title: 'Error Removing Artwork',
            body: 'There was an error when removing the artwork.',
            style: 'error',
            actions: [{ text: 'Ok', action: dispatch(modal.toggle({ show: false })) }],
          })
        );
        reject(error);
      }
      if (result) {
        resolve(dispatch({ type: campaign.UPDATE_ARTWORK, path: null }));
      }
    });
  });
};
