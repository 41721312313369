import React from 'react';
import { connect } from 'react-redux';
import { MdPlaylistAdd, MdPrint, MdShoppingBasket } from 'react-icons/md';
import getCosts from './selectors/getCosts';
import Card from '../../../../lib/Card';
import { styles } from './CB_Targeting';
import jss from '../../../../../utils/jss';

class CB_Costs extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'CampaignCosts',
      classNamePrefix: 'CampaignCosts-',
    });
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  render() {
    const { costs } = this.props;
    const { classes } = this.stylesheet;

    return (
      <Card header="Costs">
        <div className={classes.flexWrap} style={{ padding: '34 0' }}>
          <div className={classes.flexCell} style={{ textAlign: 'center' }}>
            <MdPrint size={40} />
            <div>
              {costs.print.formatCurrency()}
              <br />
              <small className={classes.subtle}>Prints</small>
            </div>
          </div>
          <div className={classes.flexCell} style={{ textAlign: 'center' }}>
            <MdShoppingBasket size={40} />
            <div>
              {costs.redemption.formatCurrency()}
              <br />
              <small className={classes.subtle}>Redemptions</small>
            </div>
          </div>
          <div className={classes.flexCell} style={{ textAlign: 'center' }}>
            <MdPlaylistAdd size={40} />
            <div>
              {costs.total.formatCurrency()}
              <br />
              <small className={classes.subtle}>Total</small>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  costs: getCosts(state),
});

export default connect(mapStateToProps, null)(CB_Costs);
