import React from 'react';
import { reduce } from 'lodash';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import { postJSON } from '../../../../../../utils/async';
import SaveCSV from '../../../../../../utils/SaveCSV';

/**
 * SKU Validation
 * @param {Object|String} data - the JSON object of SKUs and optionally the aisle
 * @param {Object} actions - pass through the redux actions
 * @return {Promise}
 */
export default (data, actions) => {
  return new Promise((resolve, reject) => {
    const dismissModal = () => {
      actions.modal.toggle({ show: false });
    };

    /**
     * Takes the data and returns only the specified fields
     * @param {array} fields - the fields to return from the dataset
     * @param {Object} data - JSON data to filter
     * @returns {Object}
     */
    const reduceToFields = (fields, data) => {
      return reduce(
        data,
        (result, product) => {
          const o = {};
          fields.map((f) => {
            o[f] = product[f];
          });
          result.push(o);
          return result;
        },
        []
      );
    };

    /**
     * Make the API request to validate the SKUs
     */
    postJSON('/api/targeted-comms/smartcounts/counts/new/validate', data, (err, result) => {
      actions.loading.toggle({
        show: false,
      });

      let errorBox = false;
      switch (result.error) {
        /**
         * SKU not found
         */
        case 'skus_not_found':
          errorBox = {
            message: 'One or more of the SKUs do not exist.',
            actions: [
              {
                text: 'OK',
                action: () => {
                  dismissModal();
                  reject(result.error);
                },
              },
              {
                text: 'Download Report',
                action: () =>
                  SaveCSV(
                    [...reduceToFields(['sku_no', 'sku_name', 'aisle'], result.allProducts), ...result.rejected],
                    'report_skus_not_found'
                  )
                    .then(() => dismissModal())
                    .catch((err) => new Error(err)),
              },
            ],
          };
          break;

        /**
         * Product without aisle
         */
        case 'product_without_aisle':
          errorBox = {
            message: 'One or more of the SKUs do not belogn to a valid aisle.',
            actions: [
              {
                text: 'OK',
                action: () => {
                  dismissModal();
                  reject(result.error);
                },
              },
              {
                text: 'Download Report',
                action: () =>
                  SaveCSV(
                    [
                      ...reduceToFields(['sku_no', 'sku_name', 'aisle', 'i2c_aisle'], result.allProducts),
                      ...result.rejected,
                    ],
                    'report_products_without_aisle'
                  )
                    .then(() => dismissModal())
                    .catch((err) => new Error(err)),
              },
            ],
          };
          break;

        /**
         * Multiple Standard Aisles
         */
        case 'multiple_standard_aisles':
          errorBox = {
            message: 'The SKUs provided are not in a single aisle.',
            actions: [
              {
                text: 'OK',
                action: () => {
                  dismissModal();
                  reject(result.error);
                },
              },
              {
                text: 'Download Report',
                action: () =>
                  SaveCSV(
                    [...reduceToFields(['sku_no', 'sku_name', 'aisle', 'i2c_aisle'], result.allProducts)],
                    'report_multiple_aisles'
                  )
                    .then(() => dismissModal())
                    .catch((err) => new Error(err)),
              },
            ],
          };
          break;

        /**
         * Choose an aisle
         */
        case 'choose_aisle':
          actions.newcount.updateAisle(result.aisles[0]);
          actions.modal.toggle({
            show: true,
            title: 'SKU Validation',
            style: 'warning',
            body: (
              <div>
                The standard aisle and i2C aisle are the same for each SKU. Please choose which aisle you'd like to use.
                <br />
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="SelectAisle"
                    defaultSelected={result.aisles[0]}
                    onChange={(event) => {
                      const { value } = event.target;
                      actions.newcount.updateAisle(value);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value={result.aisles[0]}
                      label={result.aisles[0]}
                      style={{ marginBottom: 5, marginTop: 5 }}
                      labelstyle={{ color: '#FFFFFF' }}
                      iconStyle={{ fill: '#FFFFFF' }}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value={result.aisles[1]}
                      label={result.aisles[1]}
                      labelstyle={{ color: '#FFFFFF' }}
                      iconStyle={{ fill: '#FFFFFF' }}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            ),
            actions: [
              {
                text: 'OK',
                action: () => {
                  dismissModal();
                  reject(result.error);
                },
              },
            ],
          });
          break;

        /**
         * Manufacturer Mismatch
         */
        case 'brand_manufacturer_mismatch':
          errorBox = {
            message: 'One or more of the SKUs does not belong to the same brand or manufacturer.',
            actions: [
              {
                text: 'OK',
                action: () => {
                  dismissModal();
                  reject(result.error);
                },
              },
              {
                text: 'Download Report',
                action: () =>
                  SaveCSV(
                    [...reduceToFields(['sku_no', 'sku_name', 'brand', 'manufacturer'], result.allProducts)],
                    'report_manufacturer_mismatch'
                  )
                    .then(() => dismissModal())
                    .catch((err) => new Error(err)),
              },
            ],
          };
          break;

        /**
         * Proceed with manufacturer
         */
        case 'proceed_with_manufacturer':
          const brands = reduce(
            result.allProducts,
            (brands, value) => {
              if (!brands.includes(value.brand)) {
                brands.push(value.brand);
              }
              return brands;
            },
            []
          );

          const brand = brands[0];

          actions.modal.toggle({
            show: true,
            title: 'SKU Validation',
            style: 'warning',
            body: (
              <div>
                There are multiple brands in the SKU list, but they do all belong to the same manufacturer{' '}
                {result.manufacturer}.
                <br />
                <br />
                Brands in SKU list:
                <br />
                <ul style={{ margin: 0, marginTop: 5 }}>
                  {brands.map((brand, index) => {
                    return (
                      <li key={index} style={{ paddingBottom: 5 }}>
                        {brand}
                      </li>
                    );
                  })}
                </ul>
                <br />
                Would you like to continue?
              </div>
            ),
            actions: [
              {
                text: 'No',
                action: dismissModal,
              },
              {
                text: 'Yes',
                action: () => {
                  dismissModal();
                  actions.newcount.updateBrand(brand);
                  actions.newcount.updateAisle(result.aisle);
                  actions.newcount.updateData({ manufacturer: result.manufacturer });
                  resolve();
                },
              },
              {
                text: 'Download Report',
                position: 'right',
                action: () => {
                  SaveCSV(
                    [...reduceToFields(['sku_no', 'sku_name', 'brand', 'manufacturer'], result.allProducts)],
                    'report_brand_mismatch'
                  )
                    .then(() => dismissModal())
                    .catch((err) => new Error(err));
                },
              },
            ],
          });
          break;
        case 'sku_not_array':
          actions.modal.toggle({
            show: true,
            title: 'System Error',
            style: 'error',
            body: 'There was an issue validating the SKUs. This is an issue with igloo, not your SKU list.',
            actions: [
              {
                text: 'OK',
                action: () => actions.modal.toggle({ show: false }),
              },
            ],
          });
          console.error('SKUs were not submitted as an array and therefore could not be processed.');
          break;
        default:
          actions.newcount.updateData({
            aisle: result.aisle,
            brand: result.brand,
            manufacturer: result.manufacturer,
          });

          resolve();
          break;
      }

      if (errorBox) {
        actions.modal.toggle({
          show: true,
          style: 'error',
          title: 'SKU Validation Error',
          body: errorBox.message,
          actions: errorBox.actions,
        });
      }
    });
  });
};
