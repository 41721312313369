import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Homepage from '../../views/Homepage/Homepage';
import ErrorPage from '../../lib/ErrorPage/ErrorPage';
import { Benchmarks } from './benchmarks';
import { PlanningCounts } from './planning-counts';
import { SmartCounts } from './smart-counts';
import { Lookups } from './lookups';
import { EvaluationStatus } from './evaluation-status';

export default () => (
  <Routes>
    {Benchmarks}
    {PlanningCounts}
    {SmartCounts}
    {Lookups}
    {EvaluationStatus}
    <Route path="/" element={<Homepage />} />
    <Route path="*" element={<ErrorPage errorInfo={{ code: 404 }} />} />
  </Routes>
);
