import React from 'react';
import PropTypes from 'prop-types';
import SearchInput from 'react-search-input';
import Autosuggest from 'react-autosuggest';
import Autocomplete from 'react-autocomplete';
import { MdSearch } from 'react-icons/md';
import { filterData, formatProper } from '../../../utils/utils';
import './style.css';

class Search extends React.Component {
  render() {
    return <SearchInput {...this.props} />;
  }
}

Search.propTypes = {
  onChange: PropTypes.func.isRequired,
};

class SearchAutosuggest extends React.Component {
  constructor() {
    super();
    this.state = {
      value: '',
      suggestions: [],
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.props.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: this.props.placeholder,
      value,
      onChange: this.onChange,
    };
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.props.getSuggestionValue}
        renderSuggestion={this.props.renderSuggestion}
        inputProps={inputProps}
      />
    );
  }
}

SearchAutosuggest.propTypes = {
  getSuggestions: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  renderSuggestions: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

class SearchDepreciated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      allData: this.props.data,
      filteredData: this.props.data,
      loading: false,
    };
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    searchID: PropTypes.string.isRequired,
    dataValue: PropTypes.string.isRequired,
    searchFunction: PropTypes.func.isRequired,
    dataKey: PropTypes.string.isRequired,
    additional: PropTypes.string,
  };

  componentWillReceiveProps(props) {
    this.setState({
      allData: props.data,
      filteredData: props.data,
      value: '',
    });
  }

  styles = {
    wrapperStyle: {
      width: '100%',
      height: 40,
    },
    menu: {
      background: '#ffffff',
      border: '#E0E0E0 solid 1px',
      borderTop: 0,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      minWidth: 0,
      overflow: 'auto',
      maxHeight: 200,
      left: 'auto',
      top: 'auto',
      position: 'relative',
      zIndex: 3,
    },
    icon: {
      height: 30,
      width: 30,
    },
  };

  render() {
    return (
      <div className="search">
        <MdSearch className="search--icon" height={this.styles.icon.height} width={this.styles.icon.width} />
        <Autocomplete
          inputProps={{
            name: this.props.name,
            id: this.props.searchID,
            placeholder: 'Search...',
          }}
          ref="autocomplete"
          value={this.state.value}
          items={this.state.filteredData}
          getItemValue={(item) => item[this.props.dataValue]}
          onSelect={(value, item) => {
            this.setState({
              value: '',
              filteredData: [item],
            });
            this.props.searchFunction(value);
          }}
          onChange={(event) => {
            const { value } = event.target;
            this.setState({
              value,
              loading: true,
            });
            filterData(value, this.state.allData, this.props.dataValue, (items) => {
              this.setState({
                filteredData: items,
                loading: false,
              });
            });
          }}
          renderItem={(item) => {
            const itemValue = `${item[this.props.dataValue]}${
              this.props.additional == null ? '' : ` - ${item[this.props.additional]}`
            }`;
            return (
              <div className="search--item" key={item[this.props.dataKey]} id={item[this.props.dataKey]}>
                {formatProper(itemValue, ['LTD'])}
              </div>
            );
          }}
          wrapperStyle={this.styles.wrapperStyle}
          wrapperProps={{ className: 'search--input' }}
          menuStyle={this.styles.menu}
        />
      </div>
    );
  }
}

export { Search, SearchAutosuggest, SearchDepreciated };
