import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, css } from 'aphrodite/no-important';
import { theme } from '../../../constants/colors';

const { palette } = theme;
let scrollTimer;

class VerticalScroll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: 0,
      dimensions: [],
      containerHeight: null,
    };
    const { style = {}, children } = this.props;
    this.childrenArray = React.Children.toArray(children);
    this.children = [];
    this.styles = StyleSheet.create({
      rootStyle: {
        width: 500,
        height: 200,
        display: 'flex',
        flexDirection: 'center',
        alignItems: 'center',
        ...style.root,
      },
      container: {
        height: '100%',
        width: 'calc(100% - 1rem)',
        overflow: 'hidden',
        position: 'relative',
        ...style.container,
      },
      slide: {
        opacity: 0.5,
        transform: 'scale(0.8)',
        transition: '1s',
        padding: '10px 0px',
        boxSizing: 'border-box',
        ...style.slide,
      },
      slideActive: {
        opacity: 1,
        padding: '10px 0px',
        transform: 'scale(1)',
        transition: '1s',
        boxSizing: 'border-box',
        ...style.slideActive,
      },
      slideContainer: {
        position: 'absolute',
        transition: '1s',
        ...style.slideContainer,
      },
      navPanal: {
        zIndex: '1000',
        willChange: 'transform, opacity',
        width: '1rem',
        paddingLeft: 20,
        ...style.navPanal,
      },
      scrollButton: {
        width: '1rem',
        height: '1rem',
        border: `0.2rem solid ${palette.textColor}`,
        borderLeft: 'none',
        borderBottom: 'none',
        cursor: 'pointer',
        boxSizing: 'border-box',
        ...style.scrollButton,
      },
      scrollUp: {
        transform: 'rotate(-45deg)',
        ...style.scrollUp,
      },
      scrollDown: {
        transform: 'rotate(135deg)',
        ...style.scrollDown,
      },
      ul: {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        marginTop: 5,
        marginBottom: 5,
        ...style.ul,
      },
      li: {
        position: 'relative',
        overflow: 'hidden',
        width: '1rem',
        height: '1rem',
        marginBottom: '0.5rem',
        border: `0.12rem solid ${palette.textColor}`,
        borderRadius: '50%',
        cursor: 'pointer',
        transition: 'border-color, transform 0.3s',
        willChange: 'border-color, transform',
        padding: 0,
        boxSizing: 'border-box',
        ':after': {
          content: '""',
          height: '100%',
          width: '100%',
          backgroundColor: palette.textColor,
          position: 'absolute',
          borderRadius: '50%',
          top: '50%',
          left: '50%',
          transform: 'translateX(-50%) translateY(-50%) scale(0.7)',
          opacity: 0,
        },
        ':hover': {
          borderColor: palette.accent1Color,
          transform: 'scale(1.2)',
          ':after': {
            backgroundColor: palette.accent1Color,
            opacity: 1,
          },
        },
        ...style.li,
      },
      liSelected: {
        ':after': {
          opacity: 1,
        },
      },
    });
    this.dimensions = [];
    this.containerMiddle = null;
  }

  componentDidMount() {
    const { childrenArray } = this;
    this.dimensions = childrenArray.map((child, key) => this.children[key].clientHeight);
    this.containerMiddle = this.container.clientHeight / 2;
    this.element.addEventListener('wheel', this.handleScroll);
    this.forceUpdate();
  }

  componentDidUpdate() {
    const { childrenArray } = this;
    this.dimensions = childrenArray.map((child, key) => this.children[key].clientHeight);
    this.containerMiddle = this.container.clientHeight / 2;
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { children } = nextProps;
    this.childrenArray = React.Children.toArray(children);
    this.children = [];
  }

  componentWillUnmount() {
    if (scrollTimer) {
      clearTimeout(scrollTimer);
    }
    this.element.removeEventListener('wheel', this.handleScroll);
  }

  handleLiChange = (e) => {
    this.setState({ selectedValue: parseInt(e.target.attributes.getNamedItem('data-id').value) });
  };

  handleScrollUp = () => {
    let { selectedValue } = this.state;
    if (selectedValue === 0) {
      return;
    }
    selectedValue--;
    this.setState({ selectedValue });
  };

  handleScrollDown = () => {
    let { selectedValue } = this.state;
    const { childrenArray } = this;
    if (selectedValue + 1 === childrenArray.length) {
      return;
    }
    selectedValue++;
    this.setState({ selectedValue });
  };

  handleScroll = (e) => {
    if (scrollTimer) {
      clearTimeout(scrollTimer);
    }
    scrollTimer = setTimeout(() => {
      e.deltaY < 0 ? this.handleScrollUp() : this.handleScrollDown();
    }, 100);
  };

  handleFocus = (e) => {
    this.setState({ selectedValue: parseInt(e.currentTarget.attributes.getNamedItem('data-id').value) });
  };

  calculatePosition = () => {
    const { containerMiddle, dimensions } = this;
    const { selectedValue } = this.state;
    if (dimensions.length === 0) {
      return 0;
    }
    const totalHeight = selectedValue === 0 ? 0 : dimensions.slice(0, selectedValue).reduce((a, b) => a + b);
    const currentSlideMiddle = dimensions[selectedValue] / 2;
    return containerMiddle - totalHeight - currentSlideMiddle;
  };

  render() {
    const { styles, childrenArray } = this;
    const { selectedValue } = this.state;
    return (
      <div
        ref={(element) => (this.element = element)}
        style={styles.rootStyle._definition}
        onScroll={this.handleScroll}
      >
        <div style={styles.container._definition} ref={(element) => (this.container = element)}>
          <div style={{ top: this.calculatePosition(), width: '100%' }} className={css(styles.slideContainer)}>
            {childrenArray.map((child, key) => {
              return (
                <div
                  style={selectedValue === key ? styles.slideActive._definition : styles.slide._definition}
                  key={key}
                  data-id={key}
                  onFocus={this.handleFocus}
                  ref={(element) => {
                    if (element) {
                      this.children.push(element);
                    }
                  }}
                >
                  {child}
                </div>
              );
            })}
          </div>
        </div>
        <div className={css(styles.navPanal)}>
          <div className={css(styles.scrollButton, styles.scrollUp)} onClick={this.handleScrollUp} />
          <ul className={css(styles.ul)}>
            {childrenArray.map((child, key) => {
              return (
                <li
                  key={key}
                  className={css(styles.li, selectedValue === key && styles.liSelected)}
                  onClick={this.handleLiChange}
                  data-id={key}
                />
              );
            })}
          </ul>
          <div className={css(styles.scrollButton, styles.scrollDown)} onClick={this.handleScrollDown} />
        </div>
      </div>
    );
  }
}

VerticalScroll.propTypes = {
  style: PropTypes.shape({
    container: PropTypes.object,
    slideContainer: PropTypes.object,
    slide: PropTypes.object,
    slideActive: PropTypes.object,
    navPanal: PropTypes.object,
    scrollButton: PropTypes.object,
    scrollUp: PropTypes.object,
    scrollDown: PropTypes.object,
    ul: PropTypes.object,
    li: PropTypes.object,
    liSelected: PropTypes.object,
  }),
};

export default VerticalScroll;
