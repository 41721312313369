import React from 'react';
import PropTypes from 'prop-types';
import { map, unzip, zipObject, uniqWith, sortBy, isEqual, partial } from 'lodash';
import { SearchDepreciated } from '../../../../../lib/Search/Search';

const SearchBrand = (props) => {
  const { data } = props;
  let searchData = map(unzip(map(props.searchItems, partial(map, data))), (el) => zipObject(props.searchItems, el));
  searchData = uniqWith(searchData, isEqual);
  searchData = sortBy(searchData, props.searchItems);
  return (
    <SearchDepreciated
      data={searchData}
      dataValue={props.dataValue}
      dataKey="ID"
      name="search"
      searchID={props.searchID}
      searchFunction={props.searchFunction}
      additional={props.additional}
    />
  );
};

SearchBrand.propTypes = {
  data: PropTypes.array.isRequired,
  dataValue: PropTypes.string.isRequired,
  searchID: PropTypes.string.isRequired,
  searchItems: PropTypes.array.isRequired,
  additional: PropTypes.string,
};

export default SearchBrand;
