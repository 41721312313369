import React from 'react';
import { LinearProgress } from '@material-ui/core';
import igloo from '../../../../images/igloo-logo.svg';
import FullPageContent from '../FullPageContent';

const styles = {
  container: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    maxWidth: '300px',
  },
  iglooLogo: {
    maxWidth: '100%',
    marginBottom: '24px',
  },
  loadingText: {
    fontWeight: 'bold',
    fontSize: '18px',
    textAlign: 'center',
  },
};

const AppLoading = ({ includeFurniture }) => (
  <FullPageContent includeFurniture={includeFurniture}>
    <div style={styles.container}>
      <div style={styles.loadingContainer}>
        <h1>
          <img src={igloo} alt="Nectar360 - Igloo" style={styles.iglooLogo} />
        </h1>
        <p style={styles.loadingText}>Loading...</p>
        <LinearProgress color="secondary" />
      </div>
    </div>
  </FullPageContent>
);

export default AppLoading;
