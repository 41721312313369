import React from 'react';
import jss from '../../../../../../utils/jss';

import image_standard from '../../../../../../../images/Smartcounts/coupons/standard.png';
import image_extended from '../../../../../../../images/Smartcounts/coupons/extended.png';
import logo_orange from '../../../../../../../images/Smartcounts/Sainsburys_Logo_LWFL_Orange_Spot_152c.png';
import drinkaware from '../../../../../../../images/Smartcounts/logo_drinkaware.png';

import { colorNames } from '../../../../../../constants/colors';

const styles = {
  wrap: {
    height: 180,
    position: 'relative',
    textAlign: 'left',
    display: 'inline-block',
  },
  image: {
    height: '100%',
  },
  textWrap_s: {
    position: 'absolute',
    fontFamily: 'Mary-Ann',
    fontWeight: 'bold',
    top: 50,
    left: 23,
    cursor: 'default',
  },
  textWrap_e: {
    position: 'absolute',
    fontFamily: 'Mary-Ann',
    fontWeight: 'bold',
    top: 50,
    left: 156,
    cursor: 'default',
  },
  offerValue: {
    fontSize: '23pt',
    fontWeight: 'bold',
    lineHeight: '28pt',
    color: colorNames.orange,
  },
  offerType: {
    fontWeight: 300,
    fontSize: '13pt',
    color: colorNames.orange,
  },
  offerWording: {
    fontSize: '9pt',
    color: 'black',
  },
  extended: {
    position: 'absolute',
    fontFamily: 'Mary-Ann',
    width: '23.5%',
    top: 0,
    bottom: 0,
    left: 0,
    fontSize: '12pt',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    textAlign: 'center',
    '& > div': {
      flex: 1,
    },
  },
  artworkWrap: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 395,
  },
  logo: { position: 'absolute', top: '38%', right: '6%', width: '81px' },
  logo_lwfl: { position: 'absolute', top: '34%', right: '8%', width: '81px' },
  logo_drink: { position: 'absolute', bottom: '21%', right: '34%', width: '40px' },
  logo150: { position: 'absolute', right: '6%', width: '94px', top: '34%' },
};

class CouponDesign extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'CouponDesign',
      classNamePrefix: 'CouponDesign-',
    });
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  handleDragStart = (e) => {
    if (this.props.artwork.movable) {
      this.mouseStart = { x: e.clientX, y: e.clientY };
    }
  };

  handleDragEnd = (e) => {
    this.mouseEnd = { x: e.clientX, y: e.clientY };
    if (this.props.artwork.movable) {
      this.handleDrop();
    }
  };

  handleDrop = () => {
    const mouseMoved = { x: this.mouseEnd.x - this.mouseStart.x, y: this.mouseEnd.y - this.mouseStart.y };
    const couponSize = { w: this.artworkWrap.offsetWidth, h: this.artworkWrap.offsetHeight };
    const artworkPositionChange = {
      l: this.artworkImage.offsetLeft + mouseMoved.x,
      t: this.artworkImage.offsetTop + mouseMoved.y,
    };

    this.props.artwork.onPositionChange({
      l: (artworkPositionChange.l / couponSize.w) * 100,
      t: (artworkPositionChange.t / couponSize.h) * 100,
      s: this.props.artwork.position.s,
    });
  };

  render() {
    const { classes } = this.stylesheet;
    const {
      type,
      offer,
      template,
      layout,
      text: { line1, line2, extended },
      artwork,
    } = this.props;

    return (
      <div className={classes.wrap}>
        <img src={layout === 'standard' ? image_standard : image_extended} className={classes.image} />
        <div className={classes.artworkWrap} ref={(n) => (this.artworkWrap = n)}>
          <img src={logo_orange} className={classes.logo150} />
          {template === 3 && <img src={drinkaware} className={classes.logo_drink} />}
          {artwork.image && (
            <img
              ref={(n) => (this.artworkImage = n)}
              src={artwork.image}
              style={{
                position: 'absolute',
                top: `${artwork.position.t}%`,
                left: `${artwork.position.l}%`,
                width: `${artwork.position.s}%`,
                ...(artwork.movable && { cursor: 'move' }),
              }}
              onDragStart={this.handleDragStart}
              onDragEnd={this.handleDragEnd}
              draggable={artwork.movable}
            />
          )}
        </div>
        {layout === 'extended' && (
          <div className={classes.extended}>
            <div>{extended}</div>
          </div>
        )}
        <div className={layout === 'standard' ? classes.textWrap_s : classes.textWrap_e}>
          <div className={classes.offerValue}>
            {type === 'points' ? offer : parseFloat(offer) >= 1 ? <span>£{offer} off</span> : <span>{offer}p off</span>}
          </div>
          {type === 'points' && <div className={classes.offerType}>Bonus Nectar points</div>}
          <div className={classes.offerWording}>when you buy</div>
          <div className={classes.offerWording}>{line1}</div>
          {line2 && <div className={classes.offerWording}>{line2}</div>}
        </div>
      </div>
    );
  }
}

export default CouponDesign;
