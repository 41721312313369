import ReactGA from 'react-ga4';
import GroupCheck from './GroupCheck';
import env from './env';
import { getUser } from './authProvider';

// group check and env check
export const groupAndEnvCheck = () => {
  if (!GroupCheck('Developers') || env.name !== 'production') return true;
  return false;
};

// configure GA
export const configureGA = () => {
  if (groupAndEnvCheck()) {
    const user = getUser();
    ReactGA.initialize([
      {
        trackingId: env.ga[env.name],
        gtagOptions: { user_id: user.id },
      },
    ]);
    ReactGA.gtag('set', 'user_properties', {
      u_id: user.id,
    });
  }
};

// page view event (send to GA)
export const pageView = (page) => {
  if (groupAndEnvCheck()) {
    ReactGA.send({
      hitType: 'pageview',
      page: `/${page}`,
      title: page,
    });
  }
};

// units sold lookup ran (send event to GA)
export const unitsSold_lookup = (sales_period, no_sku) => {
  if (groupAndEnvCheck()) {
    ReactGA.gtag('event', 'units_sold_lookup', {
      sales_period,
      no_sku,
    });
  }
};

// units sold download function was run (send event to GA)
export const unitsSold_download = () => {
  if (groupAndEnvCheck()) {
    ReactGA.gtag('event', 'units_sold_download', {});
  }
};

// all smartcounts export/download function - proof, sainsburys, catalina and loyalty data (send event to GA)
export const smartcounts_download = (event_name, campaign_id) => {
  if (groupAndEnvCheck()) {
    ReactGA.gtag('event', `smartcounts_${event_name}_export`, {
      campaign_id,
    });
  }
};
