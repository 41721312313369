import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reduce, map } from 'lodash';
import moment from 'moment';
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Button,
  Switch,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import jss from 'jss';
import { useNavigate } from 'react-router';
import Actions from '../../../../../actions';
import { postJSON } from '../../../../../utils/async';
import { getCampaignList, getExecutionCycle } from './selector';
import { colorNames } from '../../../../../constants/colors';
import SCMenu from './lib/SCMenu';
import LockMessage from '../Constants/LockMessage';
import SmartcountsShared from '../Constants/SmartcountsShared';
import FilterText from './lib/FilterText';
import HighlightString from './lib/HighlightString';
import CampaignStatus from './lib/CampaignStatus';
import Spinner from '../../../../lib/Loading/Spinner';
import { countList, getToggleState, getStart, getEnd } from '../../../../../reducers/TargetedComms/Smartcounts/list';
import PermissionCheck from '../../../../../utils/PermissionCheck';
import DatePick from '../../../../lib/DatePicker/DatePicker';

const styles = {
  split: {
    display: 'flex',
    alignItems: 'center',
  },
  splitCell: {
    flex: 2,
  },
  restrictionMessage: {
    textAlign: 'right',
    color: colorNames.red,
    paddingTop: 20,
  },
  subtle: {
    color: colorNames.grey,
  },
  menu: {
    width: 20,
  },
  w50: {
    width: 50,
  },
  noneMessage: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 50,
    userSelect: 'none',
    cursor: 'default',
  },
  busyMessage: {
    marginLeft: 10,
  },
  cycle: {
    fontSize: 16,
  },
  cycleM: {
    fontWeight: 'bold',
    color: colorNames.blue,
    marginLeft: 3,
  },
  cycleMDisabled: {
    color: colorNames.grey,
  },
  textHighlight: {
    backgroundColor: colorNames.yellow,
  },
  paper: {
    display: 'flex',
    padding: '5px 10px',
  },
};

const CampaignList = (props) => {
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectingCycle, setSelectingCycle] = useState(null);

  const {
    end,
    start,
    list,
    currentView,
    executionCycle,
    SCAccess: { execute: canExecute },
  } = props;

  const showSelect = currentView === 'approved' && canExecute;
  const filteredList = list.filter((o) => {
    if (currentView === 'counts' && o.type === 'Count') return true;
    if (currentView === 'all') return true;
    switch (o.campaign.stage) {
      case 0:
        return currentView === 'inprogress';
      case 1:
        return currentView === 'approvals';
      case 2:
        return currentView === 'approved';
      case 3:
        return currentView === 'execution';
      case 4:
        return currentView === 'executing';
      case 5:
      case 6:
        return currentView === 'executed';
      default:
        return currentView === 'all';
    }
  });

  const stylesheet = jss
    .createStyleSheet(styles, {
      meta: 'CampaignList',
      classNamePrefix: 'CampaignList-',
    })
    .attach();

  const { classes } = stylesheet;

  useEffect(() => {
    return () => {
      stylesheet.detach();
    };
  }, []);

  useEffect(() => {
    const campaignIds = [...selectedCampaigns];
    if (selectedRow) {
      if (!selectingCycle) setSelectingCycle(selectedRow.cycle);
      if (selectedCampaigns.length >= 0 && !campaignIds.includes(selectedRow.campaign.id)) {
        campaignIds.push(selectedRow.campaign.id);
        setSelectedCampaigns(campaignIds);
      } else {
        const removedId = campaignIds.filter((c) => c !== selectedRow.campaign.id);
        setSelectedCampaigns(removedId);
      }
    }
    if (campaignIds.length === 0) setSelectingCycle(null);
    setSelectedRow(null);
  }, [selectedRow]);

  const handleExecute = () => {
    props.actions.loading.toggle({
      show: true,
      text: `Executing ${selectedCampaigns.length} ${'campaign'.pluralise(selectedCampaigns.length > 1)}...`,
    });

    postJSON(
      '/api/targeted-comms/smartcounts/execution/prime',
      {
        campaigns: selectedCampaigns,
      },
      (err) => {
        if (err) return console.error(err);
        props.actions.loading.toggle({
          complete: true,
          doneText: `Preparing ${selectedCampaigns.length} ${'campaign'.pluralise(selectedCampaigns.length > 1)}...`,
        });
        setTimeout(() => {
          props.actions.smartcounts.toggleLock();
          props.handleViewChange('execution');
        }, 1000);
      }
    );
  };

  const handleStartDate = (start) => {
    props.actions.smartcounts.updateStartDate(start);
    requestListBetweenDates(start, props.end);
  };

  const handleEndDate = (end) => {
    props.actions.smartcounts.updateEndDate(end);
    requestListBetweenDates(props.start, end);
  };

  const requestListBetweenDates = (start, end) => {
    props.actions.smartcounts.requestList(
      moment(start).format('YYYY-MM-DD'),
      moment(end).format('YYYY-MM-DD'),
      navigate
    );
  };

  return props.listLoading ? (
    <div style={{ textAlign: 'center', paddingTop: 20 }}>
      <Spinner color={colorNames.blue} />
      <br />
      Loading...
    </div>
  ) : (
    <div>
      <div className={classes.split}>
        <div style={{ flex: 4, marginRight: 30, marginTop: 20 }}>
          <Paper className={classes.paper}>
            <DatePick
              id="startDate"
              label="Start"
              minDate={moment('2013-01-01')}
              onChange={handleStartDate}
              value={start}
              maxDate={end}
            />
            <DatePick
              id="endDate"
              label="End"
              minDate={start}
              value={end}
              maxDate={moment()}
              onChange={handleEndDate}
            />
          </Paper>
        </div>
        <div style={{ flex: 6, marginTop: 20 }}>
          <FilterText />
        </div>
        <div className={classes.splitCell} style={{ textAlign: 'right', paddingTop: 30, paddingLeft: 30 }}>
          {PermissionCheck('TargetedComms_Smartcounts_Campaign_Execute') && (
            <div style={{ display: 'inline-block' }}>
              <FormControlLabel
                style={{ width: 200 }}
                control={
                  <Switch
                    checked={props.toggles.showMine}
                    onChange={(e) => {
                      const v = e.target.checked;
                      props.actions.smartcounts.updateToggle({ showMine: v });
                    }}
                  />
                }
                label="Show Mine"
              />
            </div>
          )}
        </div>
      </div>
      {currentView === 'approved' && (executionCycle || selectingCycle) && (
        <div className={classes.restrictionMessage}>
          Only campaigns in the cycle {executionCycle || selectingCycle} can be selected.
          {executionCycle && ' To select a different cycle, you must first reject all campaigns awaiting execution.'}
        </div>
      )}
      {filteredList && filteredList.length > 0 ? (
        <div>
          <Paper style={{ marginTop: 20, marginBottom: 10 }}>
            <Table>
              <TableHead>
                <TableRow>
                  {showSelect ? <TableCell /> : null}
                  <TableCell style={{ width: 50 }}>Base Asset ID</TableCell>
                  <TableCell className={classes.w50}>Cycle</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Campaign Name</TableCell>
                  <TableCell>Brand / Manufacturer</TableCell>
                  <TableCell>Aisle</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell className={classes.menu} />
                </TableRow>
              </TableHead>
              <TableBody>
                {map(filteredList, (row) => {
                  let selectable = true;
                  if (
                    (selectingCycle && selectingCycle !== row.cycle) ||
                    (executionCycle && row.cycle !== executionCycle)
                  ) {
                    selectable = false;
                  }

                  const cycleY = row.cycle && row.cycle.toString().substring(0, 5);
                  const cycleM = row.cycle && row.cycle.toString().substring(5);

                  return (
                    <TableRow
                      key={row.id}
                      selected={selectedCampaigns.includes(row.campaign.id)}
                      style={{
                        ...(currentView === 'approved' && !selectable && { color: colorNames.grey }),
                        cursor: 'default',
                      }}
                    >
                      {showSelect ? (
                        <TableCell>
                          <FormControlLabel
                            control={<Checkbox />}
                            disabled={!selectable}
                            onChange={() => setSelectedRow(row)}
                            checked={selectedCampaigns.includes(row.campaign.id)}
                          />
                        </TableCell>
                      ) : null}
                      <TableCell style={{ width: 50 }}>
                        {<HighlightString>{row.base.id}</HighlightString> || '-'}
                      </TableCell>
                      <TableCell className={classes.w50}>
                        {row.cycle && (
                          <div className={currentView === 'approved' && classes.cycle}>
                            <span style={{ userSelect: 'auto' }}>{cycleY}</span>
                            <span
                              className={
                                currentView === 'approved' &&
                                jss.combine(classes.cycleM, !selectable && classes.cycleMDisabled)
                              }
                              style={{ userSelect: 'auto' }}
                            >
                              {cycleM}
                            </span>
                          </div>
                        )}
                      </TableCell>
                      <TableCell style={{ userSelect: 'auto' }}>{row.type}</TableCell>
                      <TableCell style={{ whiteSpace: 'normal' }}>
                        <HighlightString>{row.name}</HighlightString>
                        {row.type === 'Campaign' && (
                          <div className={classes.subtle}>
                            <HighlightString>{row.count.name}</HighlightString>
                          </div>
                        )}
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'normal' }}>
                        <HighlightString>
                          {(Array.isArray(row.count.payload.brand)
                            ? row.count.payload.brand.join(', ')
                            : row.count.payload.brand
                          ).toSentenceCase()}
                        </HighlightString>
                        <br />
                        <span className={classes.subtle}>
                          <HighlightString>
                            {row.count.payload.manufacturer ? row.count.payload.manufacturer.toSentenceCase() : ''}
                          </HighlightString>
                        </span>
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'normal' }}>
                        <HighlightString>{row.aisle}</HighlightString>
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'normal', userSelect: 'auto' }}>
                        {moment(row.campaign.created).format('DD/MM/YYYY HH:mm')}
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'normal', userSelect: 'auto' }}>
                        {row.user.first_name} {row.user.last_name}
                      </TableCell>
                      <TableCell style={{ whiteSpace: 'normal' }}>
                        <CampaignStatus data={row} />
                      </TableCell>
                      <TableCell className={classes.menu}>
                        {row.status !== 'counting' ? (
                          <SCMenu rowData={row} />
                        ) : (
                          <Spinner size={16} color={colorNames.blue} />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Paper>
          {currentView === 'approved' &&
            canExecute && [
              <Button
                variant="contained"
                key={1}
                disabled={props.locked || selectedCampaigns.length === 0}
                onClick={handleExecute}
              >
                Prepare Selected
              </Button>,
              props.locked && (
                <span key={2} className={classes.busyMessage}>
                  <LockMessage />
                </span>
              ),
            ]}
        </div>
      ) : (
        <div className={classes.noneMessage}>No counts or campaigns</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  unfilteredCount: countList(state),
  list: getCampaignList(state),
  locked: state.TargetedComms.Smartcounts.list.locked,
  listLoading: state.TargetedComms.Smartcounts.list.loading,
  executionCycle: getExecutionCycle(state),
  toggles: getToggleState(state),
  start: getStart(state),
  end: getEnd(state),
});

const {
  loading,
  targetedComms: {
    smartcounts: {
      list,
      list: { updateStart, updateEnd },
    },
  },
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    loading: {
      toggle: (opts) => dispatch(loading.toggle(opts)),
    },
    smartcounts: {
      updateToggle: (t) => dispatch(list.updateToggle(t)),
      requestList: (start, end, navigate) => dispatch(list.requestList(start, end, navigate)),
      toggleLock: () => dispatch(list.toggleLock()),
      updateStartDate: (start) => dispatch(updateStart(start)),
      updateEndDate: (end) => dispatch(updateEnd(end)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SmartcountsShared(CampaignList));
