import ActionTypes from '../../../../constants/ActionTypes';
import getCampaignObject from '../../../../components/views/TargetedComms/Smartcounts/CampaignBuilder/selectors/getCampaignObject';
import Modal from '../../../modal';
import { syncToServer, syncToServerDebounced } from './index';

const {
  targetedComms: {
    smartcounts: { campaign },
  },
} = ActionTypes;

export const toggleGroupedExisting = (v) => (dispatch, getState) => {
  let {
    cells: { visible, required },
  } = getCampaignObject(getState());
  if (v) {
    visible = visible.filter((c) => !/new|lapsed|solus|repertoire/g.test(c));
    visible.push('existing');
  } else {
    visible = visible.filter((c) => !/existing/.test(c));
    visible = [...visible, 'new', 'lapsed', 'solus', 'repertoire'];
  }
  dispatch([
    { type: campaign.UPDATE_GROUPED_EXISTING, existing: v },
    { type: campaign.UPDATE_VISIBLE, visible },
    { type: campaign.UPDATE_REQUIRED, required: visible },
    syncToServer,
  ]);
};

export const toggleTransactional = (v) => (dispatch, getState) => {
  const {
    cells: { visible, prints },
  } = getCampaignObject(getState());

  delete prints.transactional;

  let newVisible = [...visible];
  if (v) {
    newVisible.push('transactional');
  } else {
    newVisible = newVisible.filter((c) => c !== 'transactional');
  }

  dispatch([
    {
      type: campaign.UPDATE_TRANSACTIONAL_TOGGLE,
      transactional: v,
    },
    {
      type: campaign.UPDATE_VISIBLE,
      visible: newVisible,
    },
    Modal.toggle({
      show: v,
      style: 'snackbar',
      body: 'Transactional cell is added at the bottom.',
    }),
    syncToServer,
  ]);
};

export const updateRSP = (rsp) => (dispatch) =>
  dispatch([{ type: campaign.UPDATE_RSP, rsp: rsp.replace(/£/g, '') }, syncToServer]);

export const updatePCR = (pcr) => (dispatch) => dispatch([{ type: campaign.UPDATE_PCR, pcr }, syncToServer]);

export const updateRequired = (cell, value) => (dispatch, getState) => {
  let required = [...getCampaignObject(getState()).cells.required];

  if (!value) {
    required = required.filter((c) => c !== cell || (c === cell && value));
  } else if (!required.includes(cell)) {
    required.push(cell);
  }

  dispatch([{ type: campaign.UPDATE_REQUIRED, required }, syncToServer]);
};

export const updateContinuity = (cell, value) => (dispatch, getState) => {
  let visible = [...getCampaignObject(getState()).cells.visible];
  let required = [...getCampaignObject(getState()).cells.required];

  if (value && !visible.includes(`cont_${cell}`)) {
    visible.push(`cont_${cell}`);
    required.push(`cont_${cell}`);
    required = required.filter((c) => c !== cell);
  } else {
    visible = visible.filter((c) => c !== `cont_${cell}`);
    required = required.filter((c) => c !== `cont_${cell}`);
    required.push(cell);
  }

  dispatch([{ type: campaign.UPDATE_REQUIRED, required }, { type: campaign.UPDATE_VISIBLE, visible }, syncToServer]);
};

export const updateAB = (cell, value) => (dispatch, getState) => {
  let visible = [...getCampaignObject(getState()).cells.visible];
  let required = [...getCampaignObject(getState()).cells.required];
  if (value) {
    visible.push(`${cell}_a`);
    visible.push(`${cell}_b`);
    visible = visible.filter((c) => c !== cell);
    required.push(`${cell}_a`);
    required.push(`${cell}_b`);
    required = required.filter((c) => c !== cell);
  } else {
    visible.push(cell.replace(/_a$/, ''));
    visible = visible.filter((c) => !/_a$|_b$/.test(c));
    required.push(cell.replace(/_a$/, ''));
    required = required.filter((c) => !/_a$|_b$/.test(c));
  }

  dispatch([{ type: campaign.UPDATE_VISIBLE, visible }, { type: campaign.UPDATE_REQUIRED, required }, syncToServer]);
};

export const updatePrintVolume = (cell, value, method) => (dispatch) => {
  dispatch(
    syncToServerDebounced([
      {
        type: campaign.UPDATE_CELL_PRINTS,
        cell,
        method,
        value,
      },
    ])
  );
};

export const updateTemplate = (cell, template) => (dispatch) => {
  dispatch([
    {
      type: campaign.UPDATE_TEMPLATE,
      template: { [cell]: template },
    },
    syncToServer,
  ]);
};

export const updateRedemptionRate = (cell, value) => [
  syncToServerDebounced({
    type: campaign.UPDATE_CELL_REDEMPTION,
    redemption: { [cell]: value },
  }),
];

export const updateOfferType = (cell, type) => (dispatch, getState) => {
  const {
    cells: { offers },
    rsp,
  } = getCampaignObject(getState());

  // 500 pts = £2.50
  // Pts -> £ = pts*0.005
  // pence -> pts = pence * 200
  let value = offers[cell] && offers[cell].value;
  if (offers[cell] && offers[cell].type !== type) {
    if (type === 'money') {
      value = (value * 0.005).toFixed(2);
    } else if (type === 'points') {
      value = Math.ceil(value * 200);
    }
  } else if (!value) {
    if (type === 'money') {
      value = rsp.used * 0.2;
    } else if (type === 'points') {
      value = (rsp.used * 200 * 0.2).toFixed(0);
    }
  }

  dispatch(
    syncToServerDebounced([
      {
        type: campaign.UPDATE_OFFER_TYPE,
        cell,
        offer_type: type,
      },
      {
        type: campaign.UPDATE_OFFER_VALUE,
        cell,
        value,
      },
    ])
  );
};

export const updateOfferValue = (cell, value) =>
  syncToServerDebounced({
    type: campaign.UPDATE_OFFER_VALUE,
    cell,
    value,
  });
