import React from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { map, find, reduce } from 'lodash';
import { StyleSheet, css } from 'aphrodite/no-important';
import ListSubheader from '@material-ui/core/ListSubheader';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Chip,
  Switch,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { MdViewColumn, MdAdd, MdCheck, MdClear, MdFileDownload } from 'react-icons/md';
import Actions from '../../../../../actions';
import { postDownload, postJSON, saveDownload, returnJSON } from '../../../../../utils/async';
import Box from '../../../../lib/Box/Box';
import { Split, Left, Right } from '../../../../lib/Split/index';
import ContextMenu from '../../../../lib/ContextMenu/index';
import { Content } from '../../../../lib/Content/index';
import Loading from '../../../../lib/Loading/Loading';
import { colorNames } from '../../../../../constants/colors';
import { modules } from '../../../../../constants/moduleInfo';

class PlanningPivot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        AISLE_RULE: 1,
        BRAND_LOYAL_RULE: 1,
      },
      activeFilters: {},
      title: null,
      redemptionGroup: null,
      fields: null,
      fieldsAvailable: [],
      col: null,
      row: null,
      activeRow: null,
      activeCol: null,
      data: [],
      tableData: {},
      isLoading: false,
      editFilters: false,
      dragging: null,
      expandOptions: true,
      exportProgress: null,
      categories: {
        Customer_Demographics: {},
        Standard_Segmentations: {},
        i2C_Segmentations: {},
        Additional_Segmentations: {},
        Consent_and_Eligibility: {},
        Coupon: {},
      },
      suppression: false,
    };
  }

  componentDidMount() {
    this.setupPivot();
  }

  suppress() {
    const toggleSwitch = !this.state.suppression;
    this.setState({ suppression: toggleSwitch });
  }

  /*
    Creates 'categories' object in state, which has options inside filters, and filters within categories, in same arrangement as on
    last page of new count. This is then used to create the filters dialog box.

    This would need to be generalised
    */

  sortData(dataPoints) {
    const Customer_Demographics = {};
    const Standard_Segmentations = {};
    const Additional_Segmentations = {};
    const Consent_and_Eligibility = {};
    const Coupon = {};
    const i2C_Segmentations = {};

    const categories = {};

    returnJSON('/api/targeted-comms/planningtool/variables', (err, variables) => {
      Object.keys(dataPoints).forEach(function (filter) {
        Object.keys(variables).forEach(function (key) {
          if (variables[key].includes(filter.replace(/_/g, ' '))) {
            if (!categories.hasOwnProperty(key)) {
              categories[key] = {};
            }
            categories[key][filter] = dataPoints[filter];
          }
        });
      });
      this.setState({
        categories,
      });
    });
  }

  setupPivot = () => {
    this.props.actions.loading.toggle({
      show: true,
      text: 'Loading...',
    });

    postJSON(
      '/api/targeted-comms/planningtool/count/config',
      { id: this.props.id, filters: this.state.filters },
      (err, result) => {
        this.props.actions.loading.toggle({
          show: false,
        });
        if (!result.error) {
          this.setState({
            data: {
              dataPoints: result.filters,
            },
            activeFilters: this.state.filters,
            fields: result.fields,
            fieldsAvailable: result.fields.sort(),
            redemptionGroup: result.redemption,
            title: result.title,
            total_customers: result.total_customers,
          });
        } else {
          switch (result.error) {
            case 'pivot_not_found': {
              this.props.actions.modal.toggle({
                show: true,
                style: 'error',
                title: 'Data not found',
                body: `No data found for Job ${this.props.id}.`,
                actions: [
                  {
                    text: 'Okay',
                    action: () => {
                      this.props.navigate(modules.planningCounts.path);
                    },
                  },
                ],
              });
              console.error(`Pivot table not found for queue ${this.props.id}`);
              break;
            }
          }
        }
        this.sortData(this.state.data.dataPoints[0]);
      }
    );
  };

  getData = () => {
    if (this.state.row) {
      this.props.actions.loading.toggle({
        show: true,
        text: 'Loading Data...',
      });
      postJSON(
        '/api/targeted-comms/planningtool/count',
        {
          id: this.props.id,
          filters: this.state.filters,
          col: this.state.col,
          row: this.state.row,
        },
        (err, result) => {
          const tableData = reduce(
            result.rowNames,
            (table, rowName) => {
              table.push(
                reduce(
                  result.data,
                  (rowData, row) => {
                    if (row._id.row === rowName.name) {
                      rowData[this.state.row] = rowName.name;
                      rowData[row._id.col] = row.customers;
                      rowData.total = (rowData.total || 0) + row.customers;
                    }
                    return rowData;
                  },
                  {}
                )
              );
              return table;
            },
            []
          );

          this.setState(
            {
              data: result,
              tableData,
              isLoading: false,
              activeRow: this.state.row && this.state.row,
              activeCol: this.state.col && this.state.col,
              activeFilters: this.state.filters,
            },
            () => {
              this.props.actions.loading.toggle({
                show: false,
                fadeDelay: 1,
              });
            }
          );
        }
      );
    } else {
      this.setState(
        {
          data: [],
          tableData: [],
        },
        () => {
          this.setupPivot();
        }
      );
    }
  };

  // Filters with toggles such as AISLE_RULE, LOYALTY_RULE
  handleToggleFilter = (field, value) => {
    const filters = { ...this.state.filters };
    filters[field] = value ? 1 : 0;
    this.setState({ filters });
  };

  handleCheckFilter = (filter, value, checked) => {
    const filters = { ...this.state.filters };
    if (checked) {
      (filters[filter] || (filters[filter] = [])).push(value);
    } else {
      filters[filter] = filters[filter].filter((f) => f !== value);
    }

    if (filters[filter].length === 0) {
      delete filters[filter];
    }

    this.setState({ filters });
  };

  handleChipRemove = (filter, value) => {
    const filters = { ...this.state.filters };
    filters[filter] = filters[filter].filter((f) => f !== value);
    if (filters[filter].length === 0) {
      delete filters[filter];
    }
    this.setState({ filters });
  };

  /*
    Applies the coupon suppression variable to all values in the pivot table
    */
  applySuppression(value) {
    return this.state.suppression ? (value * 0.63).toFixed(2) : value;
  }

  renderTable = () => {
    let colCount = 0;
    const colSum = {};
    let runningTotal = 0;
    const styles = StyleSheet.create({
      table: {
        userSelect: 'auto !important',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      total: {
        fontWeight: 'bold',
      },
      col: {
        whiteSpace: 'normal',
        textOverflow: 'clip !important',
        userSelect: 'auto',
      },

      colx: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      colTitle: {
        textAlign: 'center',
      },
      pivot: {
        position: 'relative',
      },
      rowTitle: {
        textAlign: 'center',
        writingMode: 'vertical-rl',
        transform: 'rotate(180deg)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
      },
    });
    return (
      <div>
        <FormControlLabel
          label={<h3 style={{ margin: 0 }}>View with Coupon Suppression Variable</h3>}
          control={
            <Switch
              style={{ width: 'auto' }}
              onChange={() => {
                this.suppress(this.state.tableData);
              }}
            />
          }
        />

        <div id="PivotTable" className={css(styles.pivot)}>
          <div className={css(styles.rowTitle)}>{this.state.activeRow}</div>
          <div style={{ paddingLeft: 30 }}>
            <div className={css(styles.colTitle)}>{this.state.activeCol}</div>
            <Table className={css(styles.table)}>
              <TableHead>
                <TableRow>
                  <TableCell key="first" style={{ userSelect: 'auto', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                    {this.state.activeRow}
                  </TableCell>
                  {map(this.state.data.columnNames, (column, index) => {
                    colSum[column.name] = 0;
                    colCount++;
                    return (
                      <TableCell key={index} className={css(styles.colx)}>
                        {column.name}
                      </TableCell>
                    );
                  })}
                  <TableCell key="total" className={css(styles.total, styles.col)}>
                    TOTAL
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {map(this.state.data.rowNames, (row, index) => {
                  let rowTotal = this.state.data.columnNames
                    ? 0
                    : reduce(
                        this.state.data.data,
                        (total, data) => {
                          if (data._id.row === row.name) {
                            total += data.customers;
                          }
                          return total;
                        },
                        0
                      );
                  const out = (
                    <TableRow key={index} style={{ width: '100%' }}>
                      <TableCell style={{ userSelect: 'auto' }}>{row.name}</TableCell>
                      {map(this.state.data.columnNames, (col, index) => {
                        const colObj = find(this.state.data.data, (o) => {
                          return o._id.col === col.name && o._id.row === row.name;
                        });
                        const customers = colObj ? colObj.customers : 0;
                        rowTotal += customers;
                        colSum[col.name] += customers;
                        return (
                          <TableCell key={index} style={{ userSelect: 'auto' }}>
                            {customers > 0 ? this.applySuppression(customers) : 0}
                          </TableCell>
                        );
                      })}
                      <TableCell key="total" className={css(styles.total, styles.col)}>
                        {this.applySuppression(rowTotal)}
                      </TableCell>
                    </TableRow>
                  );
                  runningTotal += rowTotal;
                  return out;
                })}
                <TableRow>
                  <TableCell className={css(styles.total, styles.col)}>TOTAL</TableCell>
                  {map(this.state.data.columnNames, (column, index) => {
                    return (
                      <TableCell key={index} className={css(styles.total, styles.col)}>
                        {this.applySuppression(colSum[column.name])}
                      </TableCell>
                    );
                  })}
                  <TableCell className={css(styles.total, styles.col)}>{this.applySuppression(runningTotal)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
        <ContextMenu
          attachTo="PivotTable"
          menuItems={[
            {
              type: 'copy',
            },
          ]}
        />
      </div>
    );
  };

  dragStart = (e, field) => {
    e.dataTransfer.setData('dragging', field);
  };

  drop = (e, o) => {
    e.preventDefault();
    const field = e.dataTransfer.getData('dragging');
    this.setState((prevState) => {
      return {
        [o]: field,
        dragging: null,
      };
    });
  };

  dragOver = (e, o) => {
    e.preventDefault();
    this.setState({ dragging: o });
  };

  clearField = (f) => {
    this.setState((prevState) => {
      return {
        ...(f === 'row' ? { row: null, col: null } : { col: null }),
      };
    });
  };

  handleExport = () => {
    this.setState({ exportProgress: true });
    saveDownload(
      `/api/targeted-comms/planningtool/xls/${this.props.id}`,
      { save: true, path: `PlanningTool_${this.state.title}.csv` },
      (error) => {
        this.setState({ exportProgress: null });
        if (error) {
          console.error(error);
        }
      }
    );
  };

  render() {
    const styles = StyleSheet.create({
      ul: {
        margin: 0,
        padding: 0,
        display: 'flex',
      },
      li: {
        listStyle: 'none',
        display: 'inline-block',
        paddingRight: 20,
        boxSizing: 'border-box',
        width: '50%',
        flex: '1 auto',
      },
      bold: {
        fontWeight: 'bold',
      },
      chipWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        border: `2px solid ${colorNames.lighterGrey}`,
        borderRadius: 10,
      },
      chip: {
        margin: '5px !important',
      },
      rowTitle: {
        textAlign: 'center',
        pointerEvents: 'none',
      },
      colTitle: {
        textAlign: 'center',
      },
      total: {
        textAlign: 'center',
        marginTop: 20,
      },
      largeTotal: {
        fontSize: '30pt',
        userSelect: 'auto',
      },
      placeholder: {
        borderRadius: 10,
        borderStyle: 'dashed',
        borderWidth: 2,
        borderColor: colorNames.lighterGrey,
        color: colorNames.grey,
        padding: 10,
      },
      placeholderHover: {
        borderColor: colorNames.blue,
        color: colorNames.blue,
      },
      fieldFull: {
        borderStyle: 'solid',
      },
      fieldDisabled: {
        opacity: 0.5,
      },
      clearField: {
        float: 'right',
        marginTop: 3,
      },
      split: {
        display: 'flex',
      },
      splitCol: {
        flex: 1,
      },
    });
    return (
      <div>
        <Content title={this.state.title} fullBleed style={{ display: !this.state.fields ? 'none' : 'block' }}>
          <Box
            title="Configuration"
            open={this.state.expandOptions}
            onClick={() => this.setState((prevState) => ({ expandOptions: !prevState.expandOptions }))}
          >
            <ul className={css(styles.ul)}>
              <li className={css(styles.li)}>
                <MdViewColumn size={32} style={{ transform: 'rotate(90deg)' }} /> Row <br />
                <div
                  className={css(
                    styles.placeholder,
                    this.state.dragging === 'row' && styles.placeholderHover,
                    this.state.row && styles.fieldFull
                  )}
                  onDragOver={(e) => this.dragOver(e, 'row')}
                  onDragLeave={() => this.setState({ dragging: null })}
                  onDrop={(e) => this.drop(e, 'row')}
                >
                  {this.state.row || 'Select Row'}
                  {this.state.row && (
                    <MdClear className={css(styles.clearField)} onClick={() => this.clearField('row')} />
                  )}
                </div>
              </li>
              <li className={css(styles.li)}>
                <MdViewColumn size={32} /> Column <br />
                <div
                  className={css(
                    styles.placeholder,
                    this.state.dragging === 'col' && styles.placeholderHover,
                    this.state.col && styles.fieldFull,
                    !this.state.row && styles.fieldDisabled
                  )}
                  onDragOver={(e) => this.state.row && this.dragOver(e, 'col')}
                  onDragLeave={() => this.state.row && this.setState({ dragging: null })}
                  onDrop={(e) => this.state.row && this.drop(e, 'col')}
                >
                  {this.state.col || 'Select Column'}
                  {this.state.col && (
                    <MdClear className={css(styles.clearField)} onClick={() => this.clearField('col')} />
                  )}
                </div>
              </li>
            </ul>
            {map(this.state.fieldsAvailable, (field, index) => {
              const inUse = this.state.col === field || this.state.row === field;
              const color = this.state.fields.includes(field) ? colorNames.blue : colorNames.blueGrey;
              return (
                <Chip
                  key={index}
                  className={css(styles.chip)}
                  style={{
                    border: inUse ? '1px solid transparent' : `1px solid ${color}`,
                    backgroundColor: inUse ? color : 'white',
                    color: inUse ? 'white' : color,
                  }}
                  onDragStart={(e) => this.dragStart(e, field)}
                  draggable={!inUse}
                  label={field.replace(/_/g, ' ')}
                />
              );
            })}
            <div className={css(styles.split)} style={{ marginTop: 10 }}>
              <div className={css(styles.splitCol)} style={{ flex: 3 }}>
                {Object.keys(this.state.filters).length - 2 > 0 ? (
                  <div className={css(styles.chipWrapper)} style={{ border: 'none' }}>
                    {map(this.state.filters, (value, filter) => {
                      const output = [];
                      if (Array.isArray(value)) {
                        output.push(
                          <Chip
                            style={{
                              backgroundColor: 'transparent',
                            }}
                            className={css(styles.chip)}
                            label={filter.replace('_', ' ')}
                          />
                        );
                        value.sort();
                        value.map((v, i) => {
                          output.push(
                            <Chip
                              key={i}
                              onDelete={() => this.handleChipRemove(filter, v)}
                              className={css(styles.chip)}
                              label={v}
                            />
                          );
                        });
                      }
                      return output;
                    })}
                  </div>
                ) : null}
                <Button onClick={() => this.setState({ editFilters: true })}>
                  <span>
                    <MdAdd size={16} /> Add Filter
                  </span>
                </Button>
                <Button onClick={this.handleExport} disabled={this.state.exportProgress !== null}>
                  {this.state.exportProgress ? (
                    <span>Downloading ...</span>
                  ) : (
                    <span>
                      <MdFileDownload size={16} /> Export Raw Data
                    </span>
                  )}
                </Button>
              </div>

              <div style={{ textAlign: 'right' }} className={css(styles.splitCol)}>
                {(this.state.activeCol !== this.state.col ||
                  this.state.activeRow !== this.state.row ||
                  this.state.activeFilters !== this.state.filters) && (
                  <Button
                    style={{ marginRight: 5 }}
                    onClick={() =>
                      this.setState((prevState) => ({
                        col: prevState.activeCol,
                        row: prevState.activeRow,
                        filters: prevState.activeFilters,
                      }))
                    }
                    label={
                      <span>
                        <MdClear /> Cancel
                      </span>
                    }
                  />
                )}
                <Button
                  variant="contained"
                  style={{ color: colorNames.white, backgroundColor: colorNames.blue }}
                  disabled={
                    this.state.activeCol === this.state.col &&
                    this.state.activeRow === this.state.row &&
                    this.state.activeFilters === this.state.filters
                  }
                  onClick={() => this.getData()}
                  startIcon={
                    <span>
                      <MdCheck />
                    </span>
                  }
                >
                  Apply
                </Button>
              </div>
            </div>
          </Box>

          {this.state.data.data || this.state.isLoading ? (
            <Box static>
              {Object.keys(this.state.data).length > 0 && !this.state.isLoading ? this.renderTable() : null}
              {this.state.isLoading ? (
                <div
                  style={{
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  <Loading inline style={{ marginBottom: 10 }} />
                  <br />
                  Loading Pivot table...
                </div>
              ) : null}
            </Box>
          ) : (
            <div className={css(styles.total)}>
              Total Customers:
              <div className={css(styles.largeTotal)}>{this.state.total_customers}</div>
            </div>
          )}

          <Dialog title="Filters" open={this.state.editFilters}>
            <DialogContent>
              <Split layout={2575}>
                <Left>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(event, checked) => this.handleToggleFilter('AISLE_RULE', checked)}
                        checked={this.state.filters.AISLE_RULE === 1}
                      />
                    }
                    label="Aisle Rule"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={(event, checked) => this.handleToggleFilter('BRAND_LOYAL_RULE', checked)}
                        checked={this.state.filters.BRAND_LOYAL_RULE === 1}
                      />
                    }
                    label="Loyalty Rule"
                  />
                  <FormControlLabel control={<Switch checked disabled />} label="Sensitivity Rule" />
                </Left>
                <Right>
                  {Object.keys(this.state.data).length > 0 ? (
                    <div
                      style={{
                        paddingLeft: 10,
                        maxHeight: 500,
                        overflowY: 'auto',
                      }}
                    >
                      {map(this.state.categories, (subcats, category) => {
                        if (subcats && Object.keys(subcats).length != 0) {
                          return (
                            <div key={category}>
                              <h1>{category.replace(/_/g, ' ')}</h1>
                              {map(subcats, (values, point) => {
                                if (values.length > 1) {
                                  values.sort();
                                  return (
                                    <div key={point}>
                                      <ListSubheader
                                        style={{
                                          lineHeight: 'auto',
                                          paddingLeft: 0,
                                          paddingBottom: 5,
                                        }}
                                      >
                                        {point}
                                      </ListSubheader>
                                      <FormControlLabel
                                        labelPlacement="start"
                                        control={<Checkbox color="primary" />}
                                        checked={
                                          (this.state.filters[point] &&
                                            Object.keys(this.state.filters[point]).length === values.length) ||
                                          false
                                        }
                                        onChange={(event, checked) =>
                                          this.setState({
                                            filters: {
                                              ...this.state.filters,
                                              [point]: checked ? values : null,
                                            },
                                          })
                                        }
                                        label="All"
                                      />
                                      {values.map((value) => {
                                        return (
                                          <FormControlLabel
                                            labelPlacement="start"
                                            control={<Checkbox color="primary" />}
                                            key={value}
                                            checked={
                                              (this.state.filters[point] &&
                                                this.state.filters[point].toString().includes(value)) ||
                                              false
                                            }
                                            onChange={(event, checked) => this.handleCheckFilter(point, value, checked)}
                                            label={value}
                                          />
                                        );
                                      })}
                                    </div>
                                  );
                                }
                              })}
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : null}
                </Right>
              </Split>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({ editFilters: false });
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Content>
      </div>
    );
  }
}

const { loading } = Actions;
const mapDispatchToProps = (dispatch) => ({
  actions: {
    loading: {
      toggle: (t) => dispatch(loading.toggle(t)),
    },
  },
});

const Pivot = ({ ...props }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  return <PlanningPivot {...props} id={id} navigate={navigate} />;
};

export default connect(null, mapDispatchToProps)(Pivot);
