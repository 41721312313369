export default {
  SIDEBAR: 'SIDEBAR',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  AMEND_MODAL: 'AMEND_MODAL',
  TOGGLE_LOADING: 'TOGGLE_LOADING',
  SYSTEM_NOTIFICATION: 'SYSTEM_NOTIFICATION',
  UPDATE_PROGRESS: 'UPDATE_LOADING_PROGRESS',
  UPDATE_GLOBAL_SETTINGS: 'UPDATE_GLOBAL_SETTINGS',
  UPDATE_FOOTER_MESSAGE: 'UPDATE_FOOTER_MESSAGE',
  UPDATE_SOCKET: 'UPDATE_SOCKET',
};
