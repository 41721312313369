import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

export default class AnimatedCheck extends React.Component {
  constructor(props) {
    super(props);
    this._drawnTimer = null;
    this.state = {
      drawn: false,
    };
  }

  componentDidMount() {
    if (this.props.delay === 0 && this.props.drawn) {
      this.setState({ drawn: true });
    } else if (this.props.delay) {
      this._drawnTimer = setTimeout(() => this.setState({ drawn: true }), `${this.props.delay}s`);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.delay === 0 || nextProps.drawn) {
      this.setState({ drawn: true });
    } else if (nextProps.delay) {
      this._drawnTimer = setTimeout(() => this.setState({ drawn: true }), `${nextProps.delay}s`);
    }
  }

  componentWillUnmount() {
    clearTimeout(this._drawnTimer);
  }

  render() {
    return (
      <div
        className="false drawn"
        style={{
          width: this.props.size || 32,
          height: this.props.size || 32,
          display: 'inline-block',
          ...this.props.style,
        }}
      >
        <svg
          version="1.1"
          id="tick"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 37 37"
          style={{ enableBackground: 'new 0 0 37 37' }}
        >
          {this.props.outline && (
            <path
              className="circ path"
              style={{
                fill: 'none',
                stroke: this.props.color || '#000',
                strokeWidth: '3',
                strokeLinejoin: 'round',
                strokeMiterlimit: '10',
                transitionDelay: `${this.props.delay || 0}s`,
              }}
              d="
	M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
            />
          )}
          <polyline
            className="tick path"
            style={{
              fill: 'none',
              stroke: this.props.color || '#000',
              strokeWidth: '3',
              strokeLinejoin: 'miter',
              strokeMiterlimit: '10',
              transitionDelay: `${this.props.delay + 0.5 || 0.5}s`,
            }}
            points="
	11.6,20 15.9,24.2 26.4,13.8 "
          />
        </svg>
      </div>
    );
  }
}

AnimatedCheck.propTypes = {
  drawn: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  delay: PropTypes.number,
};

AnimatedCheck.defaultProps = {
  outline: true,
};
