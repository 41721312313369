import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { RadioGroup, Radio, FormControl, FormControlLabel } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment/moment';
import { Split, Left, Right } from '../../../../lib/Split/index';
import Box from '../../../../lib/Box/Box';
import Actions from '../../../../../actions';

class PlanningToolNew4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
    };
    this.stepIndex = this.props.stepIndex;
  }

  /*
    Used to restrict DatePicker to Mondays
    */
  disableDates(date) {
    return (
      date.getDay() === 0 ||
      date.getDay() === 6 ||
      date.getDay() === 2 ||
      date.getDay() === 3 ||
      date.getDay() === 4 ||
      date.getDay() === 5
    );
  }

  handleType(type) {
    new Promise((resolve) =>
      resolve(this.props.actions.planning.updatePeriod(type, this.props.planning.periodDates))
    ).then(() => {
      this.handleComplete();
    });
  }

  handleDateFrom(date) {
    new Promise((resolve) => {
      resolve(
        this.props.actions.planning.updatePeriod(this.props.planning.periodType, {
          from: date,
          to: this.props.planning.periodDates.to,
        })
      );
    }).then(() => {
      this.handleComplete();
    });
  }

  handleDateTo(date) {
    new Promise((resolve) => {
      resolve(
        this.props.actions.planning.updatePeriod(this.props.planning.periodType, {
          from: this.props.planning.periodDates.from,
          to: date,
        })
      );
    }).then(() => {
      this.handleComplete();
    });
  }

  handleComplete() {
    const { periodType, periodDates } = this.props.planning;
    if (periodType === 'aislerule' || (periodType === 'daterange' && periodDates.from && periodDates.to)) {
      this.props.actions.planning.addCompleted(this.stepIndex);
    } else {
      this.props.actions.planning.removeCompleted(this.stepIndex);
    }
  }

  renderDates() {
    const { rules } = this.props.planning;
    const end = moment().day('Saturday').subtract(7, 'days').format('DD/MM/YYYY');
    const start = moment()
      .day('Saturday')
      .subtract(parseInt(rules) + 1, 'weeks')
      .format('DD/MM/YYYY');
    return `Aisle rule of ${rules} weeks` + ` (${start} - ${end})`;
  }

  render() {
    return (
      <div style={{ width: '100%', paddingRight: 3, boxSizing: 'border-box' }}>
        <Box title="Evaluation Period" static>
          <Split>
            <Left>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="period"
                  value={this.props.planning.periodType}
                  onChange={(event) => {
                    const { value } = event.target;
                    this.handleType(value);
                  }}
                >
                  <FormControlLabel control={<Radio />} value="aislerule" label={this.renderDates()} />
                </RadioGroup>
              </FormControl>
            </Left>
            <Right>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="period"
                  value={this.props.planning.periodType}
                  onChange={(event) => {
                    const { value } = event.target;
                    this.handleType(value);
                  }}
                >
                  <FormControlLabel control={<Radio />} value="daterange" label="Specified date range" />
                </RadioGroup>
              </FormControl>
              <Split style={{ display: this.props.planning.periodType === 'daterange' ? 'block' : 'none' }}>
                <Left>
                  <DatePicker
                    label="Start"
                    value={this.props.planning.periodDates.from}
                    shouldDisableDate={this.disableDates}
                    floatinglabeltext="Start"
                    autoOk
                    style={{ width: '100%' }}
                    minDate={moment().day('Saturday').subtract(727, 'days').toDate()}
                    maxDate={moment().day('Saturday').toDate()}
                    onChange={(event) => {
                      this.handleDateFrom(event);
                    }}
                  />
                </Left>
                <Right style={{ paddingLeft: 0, paddingTop: '5px' }}>
                  <DatePicker
                    label="End"
                    value={this.props.planning.periodDates.to}
                    shouldDisableDate={this.disableDates}
                    floatinglabeltext="End"
                    autoOk
                    style={{ width: '100%' }}
                    minDate={this.props.planning.periodDates.from}
                    maxDate={moment().day('Saturday').toDate()}
                    onChange={(event) => {
                      this.handleDateTo(event);
                    }}
                  />
                </Right>
              </Split>
            </Right>
          </Split>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = ({ planningtool }) => ({
  planning: planningtool,
});

const {
  targetedComms: { planning },
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    planning: {
      addCompleted: (index) => dispatch(planning.addCompleted(index)),
      removeCompleted: (index) => dispatch(planning.removeCompleted(index)),
      updatePeriod: (type, data) => dispatch(planning.updatePeriod(type, data)),
    },
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(PlanningToolNew4);
