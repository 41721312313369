import React from 'react';
import PropTypes from 'prop-types';
import TextInput from '../TextInput/TextInput';

class PasteContent extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const { className, ...remaining } = this.props;
    return (
      <div className={className}>
        <TextInput {...remaining} />
      </div>
    );
  }
}

PasteContent.defaultProps = {
  textID: 'pasted--master--id',
  placeholder: 'Sepeate by commas',
  multiline: true,
  fullWidth: true,
  inputstyle: {
    overflow: 'hidden',
  },
};

export default PasteContent;
