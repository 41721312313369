export default {
  existing: {
    code: 'E',
    display: 'Existing',
  },
  existing_a: {
    code: 'EA',
    display: 'Existing A',
  },
  existing_b: {
    code: 'EB',
    display: 'Existing B',
  },
  cont_existing: {
    code: 'XE',
    display: 'Existing Continuity',
  },
  cont_existing_a: {
    code: 'XEA',
    display: 'Existing Continuity A',
  },
  cont_existing_b: {
    code: 'XEB',
    display: 'Existing Continuity B',
  },
  new: {
    code: 'N',
    id: 'new',
    display: 'New',
  },
  new_a: {
    code: 'NA',
    display: 'New A',
  },
  new_b: {
    code: 'NB',
    display: 'New B',
  },
  cont_new: {
    code: 'XN',
    display: 'New Continuity',
  },
  cont_new_a: {
    code: 'XNA',
    display: 'New Continuity A',
  },
  cont_new_b: {
    code: 'XNB',
    display: 'New Continuity B',
  },
  lapsed: {
    code: 'LA',
    display: 'Lapsed',
  },
  lapsed_a: {
    code: 'LAA',
    display: 'Lapsed A',
  },
  lapsed_b: {
    code: 'LAB',
    display: 'Lapsed B',
  },
  cont_lapsed: {
    code: 'XL',
    display: 'Lapsed Continuity',
  },
  cont_lapsed_a: {
    code: 'XLA',
    display: 'Lapsed Continuity A',
  },
  cont_lapsed_b: {
    code: 'XLB',
    display: 'Lapsed Continuity B',
  },
  solus: {
    code: 'ES',
    display: 'Existing Solus',
  },
  solus_a: {
    code: 'ESA',
    display: 'Existing Solus A',
  },
  solus_b: {
    code: 'ESB',
    display: 'Existing Solus B',
  },
  cont_solus: {
    code: 'XS',
    display: 'Existing Solus Continuity',
  },
  cont_solus_a: {
    code: 'XSA',
    display: 'Existing Solus Continuity A',
  },
  cont_solus_b: {
    code: 'XSB',
    display: 'Existing Solus Continuity B',
  },
  repertoire: {
    code: 'ER',
    display: 'Existing Repertoire',
  },
  repertoire_a: {
    code: 'ERA',
    display: 'Existing Repertoire A',
  },
  repertoire_b: {
    code: 'ERB',
    display: 'Existing Repertoire B',
  },
  cont_repertoire: {
    code: 'XR',
    display: 'Existing Repertoire Continuity',
  },
  cont_repertoire_a: {
    code: 'XRA',
    display: 'Existing Repertoire Continuity A',
  },
  cont_repertoire_b: {
    code: 'XRB',
    display: 'Existing Repertoire Continuity B',
  },
  brand: {
    code: 'AB',
    display: 'Brand Cross Sell',
  },
  brand_a: {
    code: 'XBA',
    display: 'Brand Cross Sell A',
  },
  brand_b: {
    code: 'XBB',
    display: 'Brand Cross Sell B',
  },
  cont_brand: {
    code: 'XB',
    display: 'Brand Cross Sell Continuity',
  },
  cont_brand_a: {
    code: 'XBA',
    display: 'Brand Cross Sell Continuity A',
  },
  cont_brand_b: {
    code: 'XBB',
    display: 'Brand Cross Sell Continuity B',
  },
  acquisition: {
    code: 'AC',
    display: 'Acquisition',
  },
  acquisition_a: {
    code: 'ACA',
    display: 'Acquisiton A',
  },
  acquisition_b: {
    code: 'ACB',
    display: 'Acquistion B',
  },
  cont_acquisition: {
    code: 'XC',
    display: 'Acquisition Continuity',
  },
  cont_acquisition_a: {
    code: 'XCA',
    display: 'Acquisition Continuity A',
  },
  cont_acquisition_b: {
    code: 'XCB',
    display: 'Acquisition Continuity B',
  },
  transactional: {
    code: 'TR',
    display: 'Transactional',
  },
};
