import { MdCheck, MdTimer, MdDoneAll, MdHourglassEmpty, MdHourglassFull, MdError, MdThumbUp } from 'react-icons/md';
import Spinner from '../../../../lib/Loading/Spinner';

export default {
  0: 'In Progress',
  1: 'Approval Pending',
  2: 'Awaiting Execution',
  3: 'Preparing',
  4: 'Ready for Execution',
  5: 'Executing',
  6: 'Executed',
  7: 'Base ID Conflict',
};

export const extended = {
  0: 'The campaign is being built.',
  1: 'Campaign is waiting for a member of Planning & Delivery to approve it.',
  2: 'Planning & Delivery has approved the campaign. It is now waiting to be executed.',
  3: 'Campaign is being prepared for execution.',
  4: 'Campaign has been prepared for execution.',
  5: 'Campaign is being executed.',
  6: 'Campaign has been executed successfully and is now ready to go-live.',
  7: 'The Base Asset ID for this campaign belongs to another campaign that has already been committed. Please reject this campaign to change the Base Asset ID.',
};

export const icons = {
  count: MdCheck,
  0: MdTimer,
  1: MdHourglassEmpty,
  2: MdHourglassFull,
  3: Spinner,
  4: MdThumbUp,
  5: Spinner,
  6: MdDoneAll,
  7: MdError,
};
