import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

const PopUp = (props) => {
  return (
    <Snackbar
      open={props.open}
      message={props.message}
      onRequestClose={() => {
        props.handleclose();
      }}
      autoHideDuration={props.duration}
      style={{ bottom: 30 }}
    />
  );
};

PopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  handleclose: PropTypes.func.isRequired,
  message: PropTypes.string,
  duration: PropTypes.number,
};

PopUp.defaultProps = {
  duration: 2000,
};

export default PopUp;
