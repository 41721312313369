import React from 'react';
import { connect } from 'react-redux';
import { Table, TableBody, TableHead, TableCell, TableRow, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { MdAdd, MdRefresh } from 'react-icons/md';
import { useNavigate } from 'react-router';
import Actions from '../../../../actions';

import { Content } from '../../../lib/Content';
import { colorNames } from '../../../../constants/colors';
import { deleteJSON, postJSON, returnJSON } from '../../../../utils/async';
import { queueByIds } from '../../../../reducers/queue';
import ProgressBar from '../../../lib/ProgressBar';
import PTMenu from './views/PTMenu.js';
import { modules } from '../../../../constants/moduleInfo';
import DatePick from '../../../lib/DatePicker/DatePicker';

const NewButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      variant="contained"
      style={{ backgroundColor: colorNames.primaryButton, color: 'white' }}
      startIcon={
        <span>
          <MdAdd size={16} style={{ marginBottom: 3 }} />
        </span>
      }
      onClick={() => navigate(`${modules.planningCounts.path}/new`)}
    >
      New
    </Button>
  );
};

class PlanningTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      delete: false,
      start: moment().subtract(3, 'months').toDate(),
      end: moment(),
    };
  }

  componentDidMount() {
    this.props.actions.queue.sync();
    const start = moment().subtract(3, 'months').toDate();
    const end = moment();
    this.getData(start, end);
  }

  getData = (start, end) => {
    const queryString = `?start=${start}&end=${end}`;
    returnJSON(`/api/targeted-comms/planningtool/list${queryString}`).then((list) => {
      this.setState({ loading: false, data: list });
    });
  };

  handleRefresh = (id) => {
    postJSON('/api/targeted-comms/planningtool/count/refresh', {
      id,
    })
      .then(() => {
        this.props.actions.queue.sync();
        this.getData(this.state.start, this.state.end);
      })
      .catch((err) => {
        console.error(err);
        this.props.action.modal.toggle((modal) => ({
          show: true,
          title: 'Error Refreshing Count',
          style: 'error',
          body: 'There was an error refreshing your count. Please try again.',
          actions: {
            action: modal.close,
            text: 'Ok',
          },
        }));
        console.error(err);
      });
  };

  toggleDelete = () => {
    this.setState((prev) => ({ delete: !prev.delete }));
  };

  handleDelete = (id) => {
    this.setState({ loading: true });
    deleteJSON('/api/targeted-comms/planningtool', { id }).then((result) => {
      this.props.actions.queue.sync();
      this.getData(this.state.start, this.state.end);
    });
  };

  handleStartDateChange = (date) => {
    this.setState({ start: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ end: date });
  };

  handleSearch = () => {
    this.setState({ loading: true });
    this.getData(this.state.start, this.state.end);
  };

  render() {
    return (
      <Content title="Planning Counts">
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <NewButton />
          </div>

          <Paper
            style={{
              display: 'flex',
              padding: '5px 10px',
            }}
          >
            <DatePick
              id="startDate"
              label="Start"
              minDate={moment('2021-01-01')}
              onChange={this.handleStartDateChange}
              value={this.state.start}
              maxDate={moment()}
            />
            <DatePick
              id="endDate"
              label="End"
              minDate={this.state.start}
              value={this.state.end}
              maxDate={moment()}
              onChange={this.handleEndDateChange}
            />
          </Paper>
          <Button
            variant="contained"
            style={{ backgroundColor: colorNames.blueGrey, color: '#FFFFFF' }}
            onClick={() => this.handleSearch()}
          >
            Search
          </Button>
          {this.state.data.length > 0 && (
            <div style={{ flex: 1, textAlign: 'right' }}>
              <Button
                style={{
                  backgroundColor: this.state.delete ? colorNames.red : null,
                  labelColor: this.state.delete ? '#FFFFFF' : null,
                }}
                variant="contained"
                onClick={this.toggleDelete}
              >
                Remove Counts
              </Button>
            </div>
          )}
        </div>
        {this.state.loading ? (
          <div>Loading...</div>
        ) : (
          <Paper style={{ marginTop: 20 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Owner</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.data.length > 0 ? (
                  this.state.data.map((row) => {
                    let status;
                    let action;
                    const queue = this.props.queueById[row.queue_id];
                    const created = moment(row.pt_created).format('YYYY-MM-DD');
                    if (queue) {
                      if (queue.status === 'completed' && !moment().isSame(created, 'week')) {
                        status = 'Expired';
                        action = {
                          icon: (
                            <MdRefresh
                              size={20}
                              onClick={() => this.handleRefresh(row.queue_id)}
                              style={{ cursor: 'pointer' }}
                            />
                          ),
                        };
                      } else if (queue.status === 'completed') {
                        if (row.pt_mongo_exists === true) {
                          status = 'Ready';
                          action = {
                            icon: <PTMenu rowData={row} getData={this.getData} />,
                          };
                        } else {
                          status = 'Data Missing';
                          action = {
                            icon: (
                              <MdRefresh
                                size={20}
                                onClick={() => this.handleRefresh(row.queue_id)}
                                style={{ cursor: 'pointer' }}
                              />
                            ),
                          };
                        }
                      } else if (queue.status === 'error') {
                        status = 'Error';
                      } else if (queue.status === 'running') {
                        status = <ProgressBar queue={row.queue_id} textPosition="right" showText />;
                      } else {
                        status = 'Unknown';
                      }
                      return (
                        <TableRow key={row.pt_id}>
                          <TableCell>{row.pt_name}</TableCell>
                          <TableCell>{moment(row.pt_created).format('DD/MM/YYYY HH:mm')}</TableCell>
                          <TableCell>
                            {row.user.first_name} {row.user.last_name}
                          </TableCell>
                          <TableCell colSpan={this.state.delete || action ? 1 : 2}>{status}</TableCell>
                          {this.state.delete ? (
                            <TableCell style={{ textAlign: 'right' }}>
                              <Button
                                variant="contained"
                                style={{ backgroundColor: colorNames.red, color: '#FFFFFF' }}
                                onClick={() => this.handleDelete(row.queue_id)}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          ) : (
                            action && <TableCell style={{ textAlign: 'right' }}>{action.icon}</TableCell>
                          )}
                        </TableRow>
                      );
                    }
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>No counts</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        )}
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  queueById: queueByIds(state),
});

const { modal, queue } = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
    },
    queue: { sync: () => dispatch(queue.requestQueue()) },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanningTool);
