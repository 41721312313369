import React from 'react';
import { StyleSheet, css } from 'aphrodite/no-important';
import { MdClear, MdArrowDropDown, MdEdit } from 'react-icons/md';
import Switch from '@material-ui/core/Switch';
import { reduce } from 'lodash';

class Box extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openRemove: false,
      hover: false,
      thing: false,
    };
    this.setRef = this.setRef.bind(this);
  }

  calcDiameter = () => {
    const w = parseInt(this.box.clientWidth);
    const h = parseInt(this.box.clientHeight);
    return w > h ? w : h;
  };

  componentDidMount() {
    this.setState({ thing: true });
  }

  setRef(box) {
    this.box = box;
  }

  render() {
    const styles = StyleSheet.create({
      card: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0 0 5px rgba(0,0,0,0.2)',
        width: '100%',
        padding: 15,
        boxSizing: 'border-box',
        color: this.props.static || this.props.onChanger ? 'RGBA(48, 57, 73, 1.00)' : 'RGBA(48, 57, 73, 0.4)',
        userSelect: 'none',
        marginBottom: 10,
        cursor: 'default',
        position: 'relative',
        overflow: 'hidden',
      },
      open: {
        color: 'RGBA(48, 57, 73, 1.00)',
      },
      remove: {
        position: 'absolute',
        borderRadius: '50%',
        backgroundColor: '#F85464',
        color: 'white',
        fontSize: '12px',
        transition: 'all 0.3s ease-in-out, opacity 0.1s linear',
      },
      removeOpen: {
        display: 'flex',
        alignItems: 'center',
      },
      removeText: {
        display: this.state.openRemove ? 'block' : 'none',
        width: '100%',
        textAlign: 'center',
      },
      title: {
        paddingBottom: (this.props.static || this.props.open) && 'children' in this.props ? 10 : 0,
      },
      bodyWrap: {
        height: this.props.static ? 'auto' : this.props.open ? 'auto' : 0,
        transition: 'height 0.2s linear, opacity 0.2s linear, padding 0.2s linear',
        overflow: 'hidden',
        opacity: this.props.static || this.props.open ? 1 : 0,
        fontSize: '10pt',
      },
    });
    return (
      <div
        ref={this.setRef}
        onMouseOver={() => {
          if ('onRemove' in this.props) {
            this.setState({ hover: true });
          }
        }}
        onMouseOut={() => {
          if ('onRemove' in this.props) {
            this.setState({ hover: false });
          }
        }}
        className={css(styles.card, this.props.open ? styles.open : null)}
        style={this.props.style}
      >
        {'onRemove' in this.props && this.state.thing ? (
          <div
            className={css(styles.remove, this.state.openRemove ? styles.removeOpen : null)}
            style={{
              opacity: this.state.hover || this.state.openRemove ? 1 : 0,
              top: this.state.openRemove ? `-${this.calcDiameter()}px` : 10,
              right: this.state.openRemove ? `-${this.calcDiameter()}px` : 10,
              left: this.state.openRemove ? `-${this.calcDiameter()}px` : parseInt(this.box.clientWidth) - 22,
              bottom: this.state.openRemove ? `-${this.calcDiameter()}px` : parseInt(this.box.clientHeight) - 22,
            }}
          >
            <MdEdit
              style={{ display: this.state.openRemove ? 'none' : 'block', cursor: 'pointer' }}
              onClick={() => this.setState({ openRemove: true })}
            />
            <div className={css(styles.removeText)}>
              <div
                style={{ marginTop: 5, cursor: 'pointer' }}
                onClick={() => {
                  this.setState({ openRemove: false });
                  this.props.onRemove();
                }}
              >
                Remove
              </div>
              <div
                style={{ marginTop: 5, cursor: 'pointer' }}
                onClick={() => {
                  this.setState({ openRemove: false });
                  this.props.onEdit();
                }}
              >
                Edit
              </div>
              <div style={{ marginTop: 5, cursor: 'pointer' }} onClick={() => this.setState({ openRemove: false })}>
                Cancel
              </div>
            </div>
          </div>
        ) : null}
        {'title' in this.props ? (
          <div
            style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}
            className={css(styles.title)}
            onClick={this.props.onClick}
            {...reduce(
              this.props,
              (output, value, key) => {
                if (key.indexOf('data') === 0) {
                  output[key] = value;
                }
                return output;
              },
              {}
            )}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignItem: 'center' }}>
              {this.props.title}
              {this.props.subtitle ? (
                <div
                  style={{
                    fontWeight: 'normal',
                    fontSize: '80%',
                    marginTop: 10,
                  }}
                >
                  {this.props.subtitle}
                </div>
              ) : null}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              {this.props.onClick && !this.props.rightIcon ? (
                <MdArrowDropDown size={24} style={{ ...(this.props.open && { transform: 'rotate(180deg)' }) }} />
              ) : (
                this.props.rightIcon
              )}
            </div>
            {'onChanger' in this.props ? (
              <Switch
                style={{ width: 'auto' }}
                onChange={(event, checked) => this.props.onChanger(event, checked)}
                disabled={this.props.disabled}
              />
            ) : null}
          </div>
        ) : null}
        {'children' in this.props && (this.props.open || this.props.static) ? (
          <div className={css(styles.bodyWrap)}>{this.props.children}</div>
        ) : null}
      </div>
    );
  }
}

export default Box;
