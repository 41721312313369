export default {
  ADD: 'QUEUE_ADD',
  UPDATE: 'QUEUE_UPDATE',
  SYNC: 'QUEUE_SYNC',
  FLOATING: 'QUEUE_FLOATING',
  REMOVE: 'QUEUE_REMOVE',
  REQUEST_CHECK: 'REQUEST_QUEUE_CHECK',
  REMOVE_CHECK: 'REMOVE_QUEUE_CHECK',
  CLEAR_REQUESTS: 'CLEAR_QUEUE_REQUESTS',
  UPDATE_MULTIPLE: 'QUEUE_UPDATE_MULTIPLE',
};
