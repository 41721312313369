import jss from 'jss';
import preset from 'jss-preset-default';

export { getDynamicStyles } from 'jss';

jss.combine = (...args) => {
  return args.map((a) => a).join(' ');
};

export default jss.setup(preset());
