// ToDo: Fix incorrect campaign count after filter when channles are filtered on.

import React from 'react';
import values from 'object.values';
import _ from 'lodash';
import Papa from 'papaparse';
import { returnJSON, postJSON, deleteJSON } from '../../../../utils/async';
import { addValues, filter, removeValue } from './lib/filter';
import { formatProper } from '../../../../utils/utils';
import { returnPage } from './Pages';
import { isEmpty, textEmpty } from './lib/utils';
import './style.css';

if (!Object.values) {
  values.shim();
}

class Integrated extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'loading',
      data: null,
      filteredData: null,
      numberCampaigns: 0,
      checked: false,
      names: {
        brand: ['Brand', 'Manufacturer'],
        mastercampaign: ['Master Campaign ID'],
        channel: ['Primary Channel', 'Channel'],
        cat: ['Category', 'Super Category'],
        date: ['Date'],
      },
      selectedValues: {
        brand: [],
        manufacturer: [],
        master_campaign_id: [],
        primary_channel: [],
        channel: [],
        category: [],
        super_category: [],
        date: {
          start: null,
          end: null,
        },
      },
      dateRange: {
        start: null,
        end: null,
      },
      saved: null,
      open: null,
      placeholder: null,
      func: {
        submit: this.submitFilter,
        addValues: addValues.bind(this),
        removeValue: removeValue.bind(this),
        handleChecked: this.handleChecked,
        returnToOptions: this.returnToOptions,
        deleteFilters: this.deleteFilters,
        handleCSV: this.handleCSV,
        handlePaste: this.handlePaste,
        handleMasterOpen: this.handleMasterOpen,
        handleInfoOpen: this.handleInfoOpen,
        handleSaveOpen: this.handleSaveOpen,
        handleOpenOpen: this.handleOpenOpen,
        handlePopUp: this.handlePopOpen,
        handleSaveChange: this.handleSaveChange,
        handleSaveFilters: this.handleSaveFilters,
        handleOpenChange: this.handleOpenChange,
        handleOpenFilters: this.handleOpenFilters,
        handleDeleteFilters: this.handleDeleteFilters,
      },
      modal: {
        view: '',
        submit: false,
        delete: false,
        title: '',
        info: 'brand',
        open: false,
      },
      popup: {
        open: false,
        message: '',
      },
      postData: {},
      results: null,
      errorSaveText: null,
    };
  }

  componentDidMount() {
    returnJSON('/api/integrated/', (error, res) => {
      if (error) {
        this.setState({ page: 'error' });
      }
      const data = _.map(res.data, (obj) => {
        obj.start_date = new Date(obj.start_date);
        return obj;
      });
      const saved = _.map(res.saved, (obj) => {
        obj.selectedValues.date.start = new Date(obj.selectedValues.date.start);
        obj.selectedValues.date.end = new Date(obj.selectedValues.date.end);
        return obj;
      });
      const dates = _.map(data, 'start_date');
      const { selectedValues } = this.state;
      selectedValues.date.start = new Date(Math.min.apply(null, dates));
      selectedValues.date.end = new Date(Math.max.apply(null, dates));
      this.setState({
        page: 'options',
        data,
        saved,
        filteredData: data,
        numberCampaigns: data.length,
        selectedValues,
        dateRange: {
          start: new Date(Math.min.apply(null, dates)),
          end: new Date(Math.max.apply(null, dates)),
        },
      });
    });
  }

  handleOpen = (item) => {
    let placeholder;
    const { modal } = this.state;
    if (item.toLowerCase() !== 'date') {
      placeholder = this.state.selectedValues[item.toLowerCase().replace(/ /g, '_')].slice(0);
    } else {
      placeholder = this.state.selectedValues[item.toLowerCase().replace(/ /g, '_')];
    }
    modal.view = item.toLowerCase().replace(/ /g, '_');
    modal.title = formatProper(item, ['ID']);
    modal.submit = false;
    this.setState({ modal, placeholder }, () => {
      this.modal.handleOpen();
    });
  };

  handleMasterOpen = () => {
    this.masterModal.handleOpen();
  };

  handleInfoOpen = (info) => {
    const { modal } = this.state;
    modal.info = info;
    this.setState({ modal }, () => this.infoModal.handleOpen());
  };

  handleSaveOpen = () => {
    const { modal } = this.state;
    modal.submit = false;
    this.setState({ modal }, () => this.saveModal.handleOpen());
  };

  handleOpenOpen = () => {
    let { modal, open } = this.state;
    open = null;
    modal.submit = false;
    modal.delete = false;
    this.setState({ modal, open }, () => this.openModal.handleOpen());
  };

  handlePopOpen = (message) => {
    const { popup } = this.state;
    popup.open = true;
    popup.message = message;
    this.setState({ popup });
  };

  handlePopUpClose = () => {
    const { popup } = this.state;
    popup.open = false;
    this.setState({ popup });
  };

  handleCSV = (file) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results) => {
        const { data } = results;
        const lowerData = _.map(data, (item) => {
          return _.transform(item, (result, value, key) => (result[key.toLowerCase().replace(/ /g, '_')] = value));
        });
        const { view } = this.state.modal;
        const fields = results.meta.fields.toLocaleString().toLowerCase().replace(/ /g, '_').split(',');
        if (fields.indexOf(view) !== -1) {
          for (let i = 0; i < lowerData.length; i++) {
            addValues.bind(this)(lowerData[i][view]);
          }
        }
      },
    });
  };

  handlePaste = () => {
    let string = document.getElementById('pasted--master--id').value;
    string = string.replace(/ /g, '').split(',');
    if (string[0] !== '') {
      addValues.bind(this)(string);
    }
  };

  submitFilter = () => {
    const { selectedValues, modal, placeholder } = this.state;
    selectedValues[modal.view.toLowerCase().replace(/ /g, '_')] = placeholder;
    if (modal.view.toLowerCase() === 'date') {
      selectedValues.date.start = placeholder.start;
      selectedValues.date.end = placeholder.end;
    }
    this.setState({ selectedValues, modal }, () => {
      filter.bind(this)();
      this.handlePopOpen(`${modal.title} successful filtered`);
    });
  };

  handleChecked = (event) => {
    this.setState({
      checked: event.target.checked,
    });
  };

  handleSubmitData = () => {
    const { filteredData, selectedValues } = this.state;
    let channels;
    if (selectedValues.channel.length > 0) {
      channels = selectedValues.channel.sort();
    } else {
      channels = [];
    }
    const postData = {
      master_campaign_id: _(filteredData).map('master_campaign_id').value(),
      channel: channels,
      primary: selectedValues.primary_channel,
    };
    if (!_.isEqual(postData, this.state.postData)) {
      this.setState({
        page: 'loading',
        postData,
      });
      postJSON('/api/integrated', postData, (status, res) => {
        if (status) {
          this.setState({ page: 'error' });
          return;
        }
        res = res.map((i) => {
          return {
            ...i,
            offer_exp: parseInt(i.offer_exp),
            offer_ctrl: parseInt(i.offer_ctrl),
            aisle_exp: parseInt(i.aisle_exp),
            aisle_ctrl: parseInt(i.aisle_ctrl),
          };
        });
        this.setState(
          {
            results: res,
          },
          () => {
            this.setState({
              page: 'results',
            });
          }
        );
      });
    } else {
      this.setState({
        page: 'results',
      });
    }
  };

  deleteFilters = () => {
    const { data } = this.state;
    const dates = _.map(data, 'start_date');
    this.setState({
      selectedValues: {
        brand: [],
        manufacturer: [],
        master_campaign_id: [],
        primary_channel: [],
        channel: [],
        category: [],
        super_category: [],
        date: {
          start: new Date(Math.min.apply(null, dates)),
          end: new Date(Math.max.apply(null, dates)),
        },
      },
      filteredData: data,
      numberCampaigns: data.length,
    });
  };

  handleSaveChange = (event) => {
    const { modal, data, selectedValues, saved } = this.state;
    try {
      if (_.map(saved, (obj) => obj.title).indexOf(event.target.value) !== -1) {
        modal.submit = false;
        this.setState({
          modal,
          errorSaveText: 'name already exists',
        });
        return true;
      }
      modal.submit = !textEmpty(event) && !isEmpty(selectedValues, data);
      this.setState({
        modal,
        errorSaveText: null,
      });
      return false;
    } catch (err) {}
  };

  handleSaveFilters = () => {
    const { selectedValues, saved, modal } = this.state;
    const name = document.getElementById('save--filters').value;
    postJSON(
      '/api/integrated/save',
      {
        title: name,
        selectedValues,
      },
      (status, result) => {
        if (status) {
          this.setState({ page: 'error' });
          return;
        }
        saved.push({
          title: name,
          selectedValues,
          id: result.saved,
        });
        this.setState({ modal }, () => this.handlePopOpen(`successfully saved ${name}`));
        this.saveModal.handleClose();
      }
    );
  };

  handleOpenChange = (event) => {
    let { modal, open } = this.state;
    open = event.target.value;
    modal.submit = true;
    modal.delete = true;
    this.setState({ open, modal });
  };

  handleOpenFilters = () => {
    let { open, selectedValues, saved, modal } = this.state;
    selectedValues = saved[open].selectedValues;
    this.setState({ selectedValues }, () => {
      filter.bind(this)();
      this.handlePopOpen(`${modal.title} successful filtered`);
    });
  };

  handleDeleteFilters = () => {
    let { saved, open, modal } = this.state;
    const deletedFilter = saved[open].title;
    deleteJSON(
      '/api/integrated/save',
      {
        id: saved[open].id,
      },
      (status) => {
        if (status) {
          this.setState({ page: 'error' });
          return;
        }
        modal.submit = false;
        modal.delete = false;
        saved = _.reject(saved, (filter) => filter.title === deletedFilter);
        open = null;
        this.setState({ modal, saved, open }, () => {
          this.handlePopOpen(`successfully deleted ${deletedFilter}`);
        });
      }
    );
  };

  returnToOptions = () => {
    this.setState({ page: 'options' });
  };

  render() {
    return returnPage.bind(this)(this.state.page);
  }
}

export default Integrated;
