import React from 'react';
import PropTypes from 'prop-types';
import { MdCloudUpload } from 'react-icons/md';

const FileUpload = (props) => {
  const handleDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    this.props.changeEventHandler(file);
  };

  const handleDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  };

  const handleFileSelect = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    this.props.changeEventHandler(file);
  };

  return (
    <label className={props.labelClass} onDragOver={handleDragOver} onDrop={handleDrop}>
      <MdCloudUpload height="25" width="25" />
      <span>Drag and drop a file or click to import</span>
      <input style={{ display: 'none' }} type="file" onChange={handleFileSelect} accept={props.fileTypes} />
    </label>
  );
};

FileUpload.propTypes = {
  labelClass: PropTypes.string,
  changeEventHandler: PropTypes.func.isRequired,
  fileTypes: PropTypes.string,
};

export default FileUpload;
