import React from 'react';
import { connect } from 'react-redux';
import { RadioGroup, Radio, FormControl, FormControlLabel } from '@material-ui/core';
import { map } from 'lodash';
import Actions from '../../../../../actions';
import Box from '../../../../lib/Box/Box';
import { Split, Left, Right } from '../../../../lib/Split/index';
import Check from '../../../../lib/Checkbox/Checkbox';

class PlanningToolNew2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aislesSelected: [],
      weeks: null,
      disablei2c: false,
      i2cSelected: false,
      error: null,
    };
    this.stepIndex = this.props.stepIndex;
  }

  /**
   *  Checks the current selection to see if we can enable/disable things
   */
  handleSelectionUpdate() {
    const selected = this.state.aislesSelected;
    let i2cCount = 0;
    let regular = 0;
    selected.map((group) => {
      if (group.indexOf('I2C') > -1) {
        i2cCount++;
      } else {
        regular++;
      }
    });
    this.setState({ i2cSelected: i2cCount > 0, disablei2c: regular > 0 }, () => this.handleComplete());
  }

  /**
   * Called when selecting an aisle
   * @param {string} aisle - the name of the aisle to check
   * @param {('redemption'|'targeted')} type - redemption or targeted
   * @param {bool} checked - whether or not the action has made it checked or not
   */
  handleAisleSelect(aisle, type, checked) {
    let { aislesSelected } = this.state;

    const isI2C = aisle.indexOf('I2C') > -1;

    if (checked) {
      aislesSelected.push(aisle);
    } else {
      aislesSelected = aislesSelected.filter((a) => a !== aisle);
    }

    if (isI2C) {
      this.setState({
        i2cSelected: checked,
      });
    } else {
      this.setState({
        disablei2c: checked,
      });
    }

    this.setState({ aislesSelected }, this.handleSelectionUpdate);
  }

  /**
   * Check if we can continue to the next step
   */
  handleComplete(value) {
    // if this is a refresh of an earlier count, this makes sure the next step is enabled without pressing anything
    let weeks;
    if (this.state.weeks == null) {
      weeks = this.props.planning.rules;
    } else {
      weeks = this.state.weeks;
    }

    if (this.state.aislesSelected.length > 0 && weeks !== null) {
      this.props.actions.planning.addCompleted(this.stepIndex);
      if (this.props.planning.rerun) {
        this.props.actions.planning.addCompleted(this.stepIndex + 1);
        this.props.actions.planning.addCompleted(this.stepIndex + 2);
        this.props.actions.planning.addCompleted(this.stepIndex + 3);
        this.props.actions.planning.addCompleted(this.stepIndex + 4);
        this.props.actions.planning.addCompleted(this.stepIndex + 5);
        this.props.actions.planning.addCompleted(this.stepIndex + 6);
      }
      this.props.actions.planning.updateAisles(this.state.aislesSelected, weeks);
    } else {
      this.props.actions.planning.removeCompleted(this.stepIndex);
    }
  }

  render() {
    const { aisles: aislesAvailable } = this.props.planning;
    return (
      <Split>
        <Left>
          <Box title="Select your aisles" static>
            {aislesAvailable.redemption.length === 0
              ? 'No aisles found, please check your SKUs.'
              : map(aislesAvailable, (aisles, type) => {
                  if (aisles.length > 0) {
                    return (
                      <div key={type} style={{ marginBottom: 10 }}>
                        {type.toCapitalized()} SKUs
                        <br />
                        {aisles.map((aisle, i) => {
                          let disabled = false;
                          // i2C Aisle is selected and this aisle is not the selected one
                          if (!this.state.aislesSelected.includes(aisle) && this.state.i2cSelected) {
                            disabled = true;
                            // This is an i2C aisle and we are disabling those
                          } else if (aisle.indexOf('I2C') > -1 && this.state.disablei2c) {
                            disabled = true;
                          }
                          return (
                            <Check
                              key={`${type}_${i}`}
                              label={aisle}
                              handleChange={(event, checked) => this.handleAisleSelect(aisle, type, checked)}
                              checked={this.state.aislesSelected.includes(aisle)}
                            />
                          );
                        })}
                      </div>
                    );
                  }
                })}
          </Box>
        </Left>
        <Right>
          <Box title="Aisle Rules" static>
            <div>
              <FormControl component="fieldset">
                <RadioGroup
                  name="aislerules"
                  onChange={(event) => {
                    const { value } = event.target;
                    this.setState({ weeks: value }, () => this.handleComplete(value));
                  }}
                  value={this.props.planning.rules}
                >
                  <FormControlLabel control={<Radio />} value="26" label="26 Weeks" />
                  <FormControlLabel control={<Radio />} value="52" label="52 Weeks" />
                </RadioGroup>
              </FormControl>
            </div>
          </Box>
        </Right>
      </Split>
    );
  }
}

const mapStateToProps = ({ planningtool }) => ({
  planning: planningtool,
});

const {
  targetedComms: { planning },
} = Actions;
const mapDispatchToProps = (dispatch) => ({
  actions: {
    planning: {
      addCompleted: (index) => dispatch(planning.addCompleted(index)),
      removeCompleted: (index) => dispatch(planning.removeCompleted(index)),
      updateAisles: (a, b) => dispatch(planning.updateAisles(a, b)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanningToolNew2);
