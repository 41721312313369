import React from 'react';
import PropTypes from 'prop-types';
import { MdSend, MdDelete, MdSave, MdInfoOutline, MdFolderOpen } from 'react-icons/md';
import Details from './Details';
import style from '../../style/style';
import Tile from '../../../../../lib/Tiles/tile';
import client from '../../../../../../../images/Benchmark/client.png';
import id from '../../../../../../../images/Benchmark/id.png';
import channel from '../../../../../../../images/Benchmark/channel.png';
import category from '../../../../../../../images/Benchmark/category.png';
import date from '../../../../../../../images/Benchmark/date.png';

const SelectCategory = (props) => {
  const createOptions = (items, i) => {
    return (
      <div key={i} style={style.content}>
        <div style={style.heading}>
          <span>Select:</span>
        </div>
        <div style={style.box}>
          {items.map((item, i) => {
            return (
              <span
                key={i}
                className="btn--select--cat"
                style={style.item}
                onClick={() => {
                  props.openModal(item);
                }}
              >
                {item}
              </span>
            );
          })}
        </div>
      </div>
    );
  };
  const images = {
    0: client,
    1: id,
    2: channel,
    3: category,
    4: date,
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Tile
        style={{
          tile: {
            height: 800,
            width: 300,
          },
        }}
        contentTitle={
          <div className="title--selection">
            <div>Options</div>
            <MdInfoOutline height="20" width="20" onClick={() => props.openInfoModal('options')} />
          </div>
        }
        content={
          <div style={style.outer}>
            {props.names.map((items, i) => {
              return createOptions(items, i);
            })}
            <div style={style.run}>
              <div className="btn--select--cat opts" onClick={() => props.openSaveModal()}>
                <MdSave height="25px" width="25px" />
                <span>Save</span>
              </div>
              <div
                className="btn--select--cat opts"
                style={{ cursor: 'pointer' }}
                onClick={() => props.openOpenModal()}
              >
                <MdFolderOpen height="25px" width="25px" />
                <span>Open</span>
              </div>

              <div
                className="btn--select--cat opts"
                style={{ cursor: 'pointer' }}
                onClick={() => props.deleteFilters()}
              >
                <MdDelete height="25px" width="25px" />
                <span>Delete all filters</span>
              </div>
            </div>
          </div>
        }
      />
      <Tile
        style={{
          tile: {
            height: 800,
            width: 800,
          },
        }}
        contentTitle={
          <div className="title--selection">
            <div>Details</div>
            <MdInfoOutline height="20" width="20" onClick={() => props.openInfoModal('details')} />
          </div>
        }
        content={
          <div style={style.outer}>
            {props.names.map((items, i) => {
              return <Details key={i} imageSource={images[i]} names={items} values={props.values} />;
            })}
            <div style={style.run}>
              <div className="btn--select--cat" style={{ cursor: 'pointer' }} onClick={() => props.campaignFunc()}>
                <span>Run {props.numberCampaigns} campaigns</span>
                <MdSend height="25px" width="25px" style={{ marginLeft: 15 }} />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

SelectCategory.propTypes = {
  names: PropTypes.array.isRequired,
  values: PropTypes.object.isRequired,
  numberCampaigns: PropTypes.number.isRequired,
  openModal: PropTypes.func.isRequired,
  campaignFunc: PropTypes.func.isRequired,
  deleteFilters: PropTypes.func.isRequired,
  openInfoModal: PropTypes.func.isRequired,
  openSaveModal: PropTypes.func.isRequired,
  openOpenModal: PropTypes.func.isRequired,
};

export default SelectCategory;
