import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ children }) => {
  const container = useRef(document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(container.current);
    return () => document.body.removeChild(container.current);
  }, []);

  return ReactDOM.createPortal(children, container.current);
};

export default Portal;
