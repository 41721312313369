import { createSelectorCreator } from 'reselect';
import { reduce, memoize } from 'lodash';
import { getVisible, getPrintVolumes, getRedemptionRates, cellTemplates, getCellOffers } from './getCells';

const createDeepEqualSelector = createSelectorCreator(memoize, (...args) =>
  args.reduce((acc, val) => `${acc}-${JSON.stringify(val)}`, '')
);

export default createDeepEqualSelector(
  [getVisible, getPrintVolumes, getRedemptionRates, cellTemplates, getCellOffers],
  (cells, prints, redemption, templates, offers) => {
    const returnObj = reduce(
      cells,
      (output, code) => {
        switch (templates[code]) {
          case 1:
            output.print += 0.07 * prints[code].value;
            break;
          case 2:
          case 3:
            output.print += 0.14 * prints[code].value;
            break;
          case 4:
            output.print += 0.15 * prints[code].value;
            break;
        }
        const estRed = prints[code].value * ((redemption[code].adjusted || redemption[code].original) / 100);
        if (offers[code].type === 'money') {
          output.redemption += estRed * parseInt(offers[code].value);
        } else {
          output.redemption += parseFloat(offers[code].value) * 0.005 * estRed;
        }

        return output;
      },
      { print: 0, redemption: 0 }
    );
    if (returnObj.print < 10000) {
      returnObj.print = 10000;
    }
    returnObj.total = returnObj.print + returnObj.redemption;
    return returnObj;
  }
);
