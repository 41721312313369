/*
This goes in the top level component, and responds to every update of that by checking whether a queue check needs to take place.
Certain events in the app, like moving to a certain page, opening the footer, or refreshing counts, add a 'queue check request' to the store
saying a queue check needs to happen. Moving away from the page or closing the footer removes the request.

Once started, the queue checking will continue until either:
    -there are no current queue check requests
    -the last queue check showed that all running counts have finished
*/

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import Actions from '../../../actions/index';
import { postJSON } from '../../../utils/async';
import { findModuleByPath, modules } from '../../../constants/moduleInfo';

class QueueCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queueCheckInterval: null, // interval object representing periodic requests to check on queue job progress
      runningJobs: [],
      finishedJobs: [],
    };
  }

  isQueueCheckPage = (pathname) =>
    pathname.includes(modules.planningCounts.path) || pathname.includes(modules.smartCounts.path);

  queueCheckCodeName = (pathname) => {
    const { path } = findModuleByPath(pathname);

    const codeNames = {
      [modules.planningCounts.path]: 'planningtool',
      [modules.smartCounts.path]: 'smartcounts',
    };

    return codeNames[path];
  };

  componentDidMount() {
    if (this.isQueueCheckPage(this.props.pathname)) {
      // detected move from non-qc page to qc, so informing store that qc needs to run by virtue of newPage
      console.log('requestQueueCheck', this.queueCheckCodeName(this.props.pathname));
      this.props.actions.queue.requestQueueCheck(this.queueCheckCodeName(this.props.pathname));
    }
  }

  componentDidUpdate(prevProps) {
    /*
        Page check

        Whenever page updates, check whether it has moved to or from a page requiring queue check ('qc' in comments below), and if so update the
        queue store, adding/removing a queue check request
        */
    const oldPage = prevProps.pathname;
    const newPage = this.props.pathname;

    if (this.isQueueCheckPage(oldPage)) {
      if (!this.isQueueCheckPage(newPage)) {
        // detected move from qc page to non-qc page, so informing store that qc no longer needs to run by virtue of page
        this.props.actions.queue.removeQueueCheckRequest(this.queueCheckCodeName(prevProps.pathname));
      }
    } else if (this.isQueueCheckPage(newPage)) {
      // detected move from non-qc page to qc, so informing store that qc needs to run by virtue of newPage
      this.props.actions.queue.requestQueueCheck(this.queueCheckCodeName(this.props.pathname));
    }

    /*
        Checking for queue requests

        Look at queue store to decide whether to start a queue check
        */
    if (this.props.queue.checkQueue.length > 0) {
      // there is at least one request for the queue to run
      if (!this.state.queueCheckInterval) {
        // no existing queueCheckInterval has been set, so set one
        this.checkQueue();
      }
      // else there is an interval already running, so do nothing.
    } else {
      // there are no current requests to check the queue
      if (this.state.queueCheckInterval) {
        // there was a queueCheckInterval set, so remove it
        this.clearCurrentInterval();
      }
    }
  }

  clearCurrentInterval = () => {
    clearInterval(this.state.queueCheckInterval);
    this.setState({ queueCheckInterval: null });
  };

  checkQueue = () => {
    const interval = setInterval(() => {
      // request the data for any jobs of the necessary type that are running, or that were marked as finished in the previous cycle
      postJSON(
        '/api/queue/running',
        {
          // ids of jobs marked as finished in the previous cycle. They will all be fetched regardless of status.
          completedIds: this.state.finishedJobs.map((j) => j.id),

          /*
                    based on the current queue check requests, request only the types of jobs user needs to see progress for. For these, the request
                    will return only jobs with a status of 'running'
                    */
          jobTypes: this.props.queue.checkQueue.includes('footer') ? 'all' : this.props.queue.checkQueue[0],
        },
        (err, res) => {
          if (err) console.log(`error with checkQueue request: ${err}`);

          /*
                    This action will check the status of all the returned jobs and update them in the store. Running ones will be marked so, and
                    finished ones will be marked either error or done
                    */
          this.props.actions.queue.syncRunning(res);

          /*
                    Preparing for the next cycle:
                    */

          /*
                    extract the running jobs from res
                    */
          const runningJobs = this.state.runningJobs.map((j) => j);
          const jobsToAdd = [];
          const newRunning = res.filter((j) => j.status == 'running');

          // if any are new, add them to the state's list of running jobs
          res.forEach((j) => {
            if (j.status == 'running' && !runningJobs.includes(j)) jobsToAdd.push(j);
          });

          /*
                    if any of the state's list of running jobs have stopped running in this cycle, add them to a list of jobs whose final
                    status needs to be checked in the next cycle
                    */
          const finishedJobs = runningJobs.filter((j) => !res.map((j2) => j2.id).includes(j.id));

          const remainingJobs = runningJobs.filter((j) => res.map((j2) => j2.id).includes(j.id));
          this.setState({
            runningJobs: remainingJobs.concat(jobsToAdd),
            finishedJobs,
          });
          if (res.filter((j) => j.status == 'running').length == 0 && this.state.finishedJobs.length == 0) {
            this.props.actions.queue.clearQueueRequests();
            this.clearCurrentInterval();
          }
        }
      );
    }, 10000); // currently set to check every 10s (more often than this and it seems to slow other requests)
    this.setState({ queueCheckInterval: interval });
  };

  render() {
    return <div />;
  }
}

const { queue } = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    queue: {
      syncRunning: (data) => dispatch(queue.updateMultiple(data)),
      removeQueueCheckRequest: (codeName) => dispatch(queue.removeQueueCheckRequest(codeName)),
      requestQueueCheck: (codeName) => dispatch(queue.requestQueueCheck(codeName)),
      clearQueueRequests: () => dispatch(queue.clearQueueRequests()),
    },
  },
});

const QueueCheckWrapper = ({ ...props }) => {
  const { pathname } = useLocation();
  return <QueueCheck {...props} pathname={pathname} />;
};

export default connect((state) => state, mapDispatchToProps)(QueueCheckWrapper);
