import sc_newcount from './TargetedComms/Smartcounts/newcount';
import sc_list from './TargetedComms/Smartcounts/list';
import * as SmartcountsCampaign from './TargetedComms/Smartcounts/campaign/index';
import planningTool from './TargetedComms/planning';
import error from './errorInfo';
import * as loading from './loading';
import modal from './modal';
import * as queue from './queue';

export default {
  loading,
  modal,
  queue,
  error,
  targetedComms: {
    planning: planningTool,
    smartcounts: {
      newcount: sc_newcount,
      campaign: SmartcountsCampaign,
      list: sc_list,
    },
  },
};
