import { createSelectorCreator } from 'reselect';
import { memoize } from 'lodash';
import { getBuiltCells } from './getCells';
import getCampaignObject from './getCampaignObject';
import { getCoupons } from './getCoupons';

const createDeepEqualSelector = createSelectorCreator(memoize, (...args) =>
  args.reduce((acc, val) => `${acc}-${JSON.stringify(val)}`, '')
);

export default createDeepEqualSelector([getCampaignObject, getBuiltCells, getCoupons], (campaign, cells, coupons) => {
  return {
    completed: campaign.completed,
    id: campaign.id,
    campaign_id: campaign.campaign_id,
    base_id: campaign.base_id,
    count_id: campaign.count.id,
    name: campaign.name,
    fullName: `${campaign.base_id}_${campaign.time_periods.cycle}_${campaign.count.payload.manufacturer}_${campaign.count.payload.brand}_${campaign.name}_BAU`,
    time_periods: campaign.time_periods,
    clashes: campaign.clashes,
    cells,
    rsp: campaign.rsp.used,
    pcr: campaign.cells.pcr,
    coupons,
    coupon_artwork: campaign.coupons.artwork,
    coupon_text: campaign.coupons.text,
    packShot: campaign.coupons.artwork_path,
    supplier: campaign.supplier,
    temp: {
      grouped: campaign.cells.groupedExisting,
      transactional: campaign.cells.transactional,
      cycles: campaign.cycles,
      redemption_rates: campaign.redemption_rates,
      coupon_template: campaign.coupons.template,
      rsp: campaign.rsp.original,
      ab: campaign.cells.ab,
      catable_stores: campaign.catable_stores.length,
    },
    execution: campaign.execution,
  };
});
