import React from 'react';
import { connect } from 'react-redux';
import { Paper, Button, Table, TableBody, TableHead, TableCell, TableRow } from '@material-ui/core';
import { map, reduce, isEqual } from 'lodash';
import { MdFileDownload } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Actions from '../../../../../actions';
import ContentTabbed from '../../../../lib/Content/ContentTabbed';
import { modules } from '../../../../../constants/moduleInfo';
import getProductsInCount, {
  getMissingSkus,
  getProductTableTabs,
  getSkusInCount,
} from './selectors/getProductsInCount';
import getCampaignObject from './selectors/getCampaignObject';
import { saveDownload } from '../../../../../utils/async';

class CB_Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTab: 'redemption',
      missingSkus: [],
      exportProgress: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      nextProps.cc.products !== this.props.cc.products ||
      nextProps.cc.stepIndex !== this.props.cc.stepIndex ||
      nextProps.cc.missingSkus !== this.props.cc.missingSkus ||
      nextProps.cc.tabs !== this.props.cc.tabs ||
      nextProps.cc.queue_id !== this.props.cc.queue_id ||
      nextState !== this.state ||
      nextContext !== this.context
    );
  }

  componentDidMount() {
    if (!this.props.cc.completed.includes(this.props.stepIndex)) {
      this.props.actions.campaign.addCompleted(this.props.stepIndex);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.cc.missingSkus, nextProps.cc.missingSkus)) {
      const {
        cc: { missingSkus },
      } = nextProps;
      if (missingSkus.length > 0) {
        const rows = missingSkus.length < 15 ? missingSkus.length : 15;
        this.props.actions.modal.toggle((modal) => ({
          show: true,
          title: 'Campaign Error',
          style: 'error',
          body: (
            <div>
              <div style={{ marginBottom: 10 }}>
                This campaign has {missingSkus.length} SKUs that no longer exist. The campaign needs to be deleted and
                the count refreshed.
              </div>
              Missing SKUs: <br />
              <textarea rows={rows} style={{ width: '100%', fontFamily: 'monospace' }} value={missingSkus.join('\n')} />
              <br />
              This will be your only opportunity to make a note of these SKUs as they will be removed from the count
              data now.
            </div>
          ),
          actions: [
            {
              text: 'Ok',
              action: () => this.props.navigate(modules.smartCounts.path),
            },
          ],
        }));
      }
    }
  }

  handleDownloadSkuList = () => {
    this.setState({ exportProgress: true });
    saveDownload(
      `/api/targeted-comms/smartcounts/count/skus/${this.props.cc.queue_id}`,
      {
        save: true,
        mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        path: `SKUS_${this.props.cc.queue_id}.xlsx`,
      },
      (err) => {
        this.setState({ exportProgress: null });
        if (err) {
          this.props.actions.modal.toggle((modal) => ({
            show: true,
            title: 'Export Error',
            style: 'error',
            body: 'There was a problem exporting the SKUs.',
            actions: [{ text: 'Ok', action: modal.close }],
          }));
        }
      }
    );
  };

  render() {
    const {
      cc: { tabs },
    } = this.props;

    if (this.props.cc.missingSkus.length === 0) {
      return (
        <div>
          {tabs.length > 0 && [
            <ContentTabbed
              key="tabs"
              tabLabels={tabs.map((t) => t.name)}
              tintColor={modules.smartCounts.color}
              tabBadge={tabs.map((t) => t.count)}
            >
              {tabs.map((t) => {
                return (
                  <Paper key={t.tab}>
                    <Table style={{ userSelect: 'none', cursor: 'default' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SKU</TableCell>
                          <TableCell>SKU Name</TableCell>
                          <TableCell>Aisle</TableCell>
                          <TableCell>Brand</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {map(this.props.cc.products[t.tab], (product) => {
                          return (
                            <TableRow key={product.sku_no}>
                              <TableCell>{product.sku_no}</TableCell>
                              <TableCell>{product.sku_name}</TableCell>
                              <TableCell>{product.aisle || product.i2c_aisle}</TableCell>
                              <TableCell>
                                {product.brand}
                                <br />
                                <small>{product.manufacturer}</small>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Paper>
                );
              })}
            </ContentTabbed>,
            <Button
              variant="contained"
              key="sku_download"
              startIcon={<MdFileDownload />}
              onClick={this.handleDownloadSkuList}
              style={{ marginTop: 10 }}
            >
              {this.state.exportProgress ? (
                <span>
                  Downloading {this.state.exportProgress * 100}
                  %...
                </span>
              ) : (
                <span>Download SKU List</span>
              )}
            </Button>,
          ]}
        </div>
      );
    }
    return <div>Campaign Error.</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    cc: {
      queue_id: getCampaignObject(state).count.queue_id,
      products: getProductsInCount(state),
      skus: getSkusInCount(state),
      missingSkus: getMissingSkus(state),
      tabs: getProductTableTabs(state),
      completed: getCampaignObject(state).completed,
    },
  };
};

const {
  targetedComms: {
    smartcounts: { campaignCreator },
  },
  modal,
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    campaign: {
      addCompleted: (index) => dispatch(campaignCreator.addCompleted(index)),
      removeCompleted: (index) => dispatch(campaignCreator.removeCompleted(index)),
    },
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
    },
  },
});

const ProductsWrapper = ({ ...props }) => {
  const navigate = useNavigate();
  return <CB_Products {...props} navigate={navigate} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsWrapper);
