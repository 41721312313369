import React from 'react';
import { isEqual } from 'lodash';

class Transition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      children: props.children,
      showChild: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.children, this.state.children)) {
      this.setState({ showChild: false }, () => {
        setTimeout(() => {
          this.setState({ children: nextProps.children, showChild: true });
        }, this.props.duration);
      });
    }
  }

  render() {
    const { children: ChildrenFromState, showChild } = this.state;
    const { style } = this.props;
    return (
      <div
        style={{
          opacity: showChild ? 1 : 0,
          transition: `all ${this.props.duration}ms ${this.props.transition}`,
          ...style,
        }}
      >
        {ChildrenFromState}
      </div>
    );
  }
}

Transition.defaultProps = {
  duration: 200,
  transition: 'ease-in-out',
};

export default Transition;
