export default {
  ADD_COMPLETED: 'PLANNING_ADD_COMPLETED',
  REMOVE_COMPLETED: 'PLANNING_REMOVE_COMPLETED',
  RESET: 'RESET_PLANNING',
  CHANGE_NAME: 'CHANGE_PLANNING_NAME',
  CHANGE_GROUPS: 'CHANGE_PLANNING_GROUPS',
  CHANGE_BANDING_VISIBILITY: 'CHANGE_PLANNING_BANDING_VISIBILITY',
  CHANGE_AISLES: 'CHANGE_PLANNING_AISLES',
  CHANGE_STORES: 'CHANGE_PLANNING_STORE_LIST',
  CHANGE_PERIOD: 'CHANGE_PLANNING_PERIOD',
  CHANGE_COUPONS: 'CHANGE_PLANNING_COUPONS',
  CHANGE_PROMOS: 'CHANGE_PLANNING_PROMOS',
  CHANGE_VARS: 'CHANGE_PLANNING_VARS',
  CHANGE_SEGMENTATIONS: 'CHANGE_PLANNING_SEGMENTATIONS',
  RECEIVED_AISLES: 'RECEIVED_PLANNING_AISLES',
  RESTORE_COUNT: 'RESTORE_COUNT',
};
