import React from 'react';
import PropTypes from 'prop-types';
import { Select, FormControl } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { formatProper } from '../../../utils/utils';

class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.selectedValue || 0,
    };
  }

  handleChange = (event, index) => {
    const { value } = event.target;

    if (this.props.handleChange) {
      this.props.handleChange(this.props.labels[value], value);
    }
    this.setState({ value });
  };

  render() {
    const value = this.props.selectedValue;
    const {
      autoWidth,
      style,
      labelstyle,
      underlinestyle,
      labels,
      ignoreFormat,
      selectedValue,
      handleChange,
      ...remaining
    } = this.props;
    return (
      <FormControl style={{ minWidth: 120 }}>
        <Select
          labelId="regexThing"
          value={value}
          onChange={this.handleChange}
          selectedmenuitemstyle={{ color: '#51586A' }}
          autoWidth={autoWidth}
          style={style}
          labelstyle={labelstyle}
          underlinestyle={underlinestyle}
          {...remaining}
        >
          {labels.map((value, key) => {
            return (
              <MenuItem key={key} value={key} label={formatProper(value, ignoreFormat)}>
                {formatProper(value, ignoreFormat)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
}

DropDown.propTypes = {
  handleChange: PropTypes.func,
  labels: PropTypes.array.isRequired,
  ignoreFormat: PropTypes.array,
  underlinestyle: PropTypes.object,
  labelstyle: PropTypes.object,
  style: PropTypes.object,
  autoWidth: PropTypes.bool,
  selectedValue: PropTypes.number,
};

DropDown.defaultProps = {
  autoWidth: false,
  selectedValue: 0,
};

export default DropDown;
