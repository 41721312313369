import { useEffect, useState } from 'react';
import { useMsalAuthentication } from '@azure/msal-react';
import { InteractionType, InteractionRequiredAuthError } from '@azure/msal-browser';
import env from '../utils/env';
import { returnJSON } from '../utils/async';
import { handleLoginApi } from '../utils/authProvider';
import pkg from '../../../package.json';
import config from '../../config';

const { authenticationParameters, appInfo } = config;

export const useAuth = (skipAD = false) => {
  const [apiLoginData, setApiLoginData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [authenticationError, setAuthenticationError] = useState(false);
  const [apiError, setApiError] = useState(false);

  if (!skipAD) {
    const { login, result, error } = useMsalAuthentication(InteractionType.Silent, authenticationParameters);

    useEffect(() => {
      if (error instanceof InteractionRequiredAuthError) {
        login(InteractionType.Redirect, authenticationParameters);
      } else if (error) {
        setAuthenticationError(authenticationError);
        setIsLoading(false);
      }
    }, [error]);

    useEffect(() => {
      if (result) setApiLoginData({ access_token: result.accessToken });
    }, [result]);
  } else if (!apiLoginData) {
    setApiLoginData({
      user: {
        id: '00000000-0000-0000-0000-000000000000',
        userPrincipalName: 'dev.dev@sainsburys.co.uk',
        givenName: 'Dev',
        surname: 'Dev',
        displayName: 'Dev Dev',
      },
      groups: ['Developers'],
    });
  }

  useEffect(() => {
    if (apiLoginData) {
      handleLoginApi({
        ...apiLoginData,
        app_version: pkg.version + env.name === 'staging' ? '-stg' : '',
        app_build: appInfo.build,
      })
        .then(() => returnJSON('/ping'))
        .catch((err) => {
          console.error(err);
          setApiError(err);
        })
        .finally(() => setIsLoading(false));
    }
  }, [apiLoginData]);

  return { isLoading, authenticationError, apiError };
};
