import React from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import Actions from '../../../../../actions';
import BatchUpload from '../../../../lib/BatchUpload/BatchUpload.js';

class PlanningToolNew1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      maxGroups: false,
      name: null,
      skus: null,
      type: null,
      error: false,
      nameError: null,
      show: {
        sku: true,
        promo: false,
      },
      groupCount: 0,
      skuCount: 0,
      groupError: {
        type: null,
        message: null,
        field: null,
      },
      batchArray: [],
      badBatchName: false,
    };
    this.stepIndex = this.props.stepIndex;
    this.bandingsBoilerPlate = {
      show: [],
      qty: [{ low: 0, high: 0 }],
      rev: [{ low: 0, high: 0 }],
      sow: [{ low: 0, high: 0 }],
      lp: [{ low: 0, high: 0 }],
      qty_t: [{ low: 0, high: 0, banding: [{ low: 0, high: 0 }] }],
    };
    this.colNames = ['GROUP_NAME', 'SKU_NO', 'TYPE'];
  }

  handleBatchAdd = (batchArray) => {
    const newArray = [];
    batchArray.forEach(function (i) {
      newArray.push({ name: i.name, skus: i.list, bandings: this.bandingsBoilerPlate, type: i.type });
    }, this);
    const complete = this.checkStepComplete(newArray);
    if (batchArray.length === 0 || !complete) {
      this.props.actions.planning.updateGroups(newArray);
      this.props.actions.planning.removeCompleted(this.stepIndex);
    } else {
      this.props.actions.planning.updateGroups(newArray);
      this.props.actions.planning.addCompleted(this.stepIndex);
    }
  };

  checkStepComplete = (batchArray) => {
    let redemption = 0;
    let targeted = 0;

    map(batchArray, (group) => {
      if (group.type === 'Redemption') {
        redemption++;
      } else {
        targeted++;
      }
    });
    if (redemption > 0) {
      return true;
    }
    return false;
  };

  render() {
    const data = this.props.planning.rerun
      ? this.props.planning.productGroups.map(function (i) {
          return { name: i.name, list: i.skus, type: i.type };
        })
      : null;

    return (
      <div>
        <BatchUpload
          maxSize={8}
          onChange={this.handleBatchAdd}
          title="Product Groups"
          colNames={this.colNames}
          groups={data}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ planningtool }) => ({
  planning: planningtool,
});

const {
  targetedComms: { planning },
  modal,
} = Actions;

const mapDispatchToProps = (dispatch) => ({
  actions: {
    planning: {
      addCompleted: (index) => dispatch(planning.addCompleted(index)),
      removeCompleted: (index) => dispatch(planning.removeCompleted(index)),
      updateGroups: (groups) => dispatch(planning.updateGroups(groups)),
    },
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
      amendAction: (index, opts) => dispatch(modal.amendAction(index, opts)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanningToolNew1);
