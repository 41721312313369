import React from 'react';
import { connect } from 'react-redux';
import { map, isEqual } from 'lodash';
import { Paper, Button, TextField } from '@material-ui/core';
import { MdWarning } from 'react-icons/md';
import moment from 'moment';
import Actions from '../../../../../actions';
import jss from '../../../../../utils/jss';
import ContentHeader from '../../../../lib/ContentHeader';
import { colorNames } from '../../../../../constants/colors';
import Badge from '../../../../lib/Badge';
import { getCouponArtwork, getCoupons, getCouponText } from './selectors/getCoupons';
import getCampaignObject from './selectors/getCampaignObject';
import CouponDesign from '../Landing/lib/CouponDesign';
import { returnJSON } from '../../../../../utils/async';

const {
  modal,
  targetedComms: {
    smartcounts: { campaign },
  },
} = Actions;

const styles = {
  group_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  group_flex: {
    margin: 10,
  },
  coupon_wrapper: {
    margin: '0 auto',
    marginTop: 10,
    position: 'relative',
    userSelect: 'none',
    cursor: 'default',
    textAlign: 'left',
    display: 'inline-block',
  },
  coupon_text: {
    position: 'absolute',
    fontFamily: 'Mary-Ann',
    fontWeight: 'bold',
    top: 70,
    left: 30,
    right: 170,
    color: colorNames.black,
  },
  coupon_artwork: {
    position: 'absolute',
    width: '15%',
    left: '49%',
    top: '17%',
    zIndex: 10,
    border: `1px dashed ${colorNames.blue}`,
    cursor: 'move',
  },
  offer_value: {
    fontSize: '30pt',
    fontWeight: 'bold',
    lineHeight: '28pt',
    color: colorNames.orange,
  },
  offer_text: {
    fontSize: '16pt',
    fontWeight: 300,
    color: colorNames.orange,
  },
  custom_text: {
    fontSize: '11pt',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  img: {
    width: '100%',
  },
  uploading: { position: 'absolute', top: '50%', right: '50%', marginTop: '-33px', marginRight: '-33px' },
};

class CustomTextModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Paper style={{ padding: 15 }}>
        <TextField
          label="Line 1"
          value={this.props.text.line1}
          onChange={(e) => {
            const v = e.target.value;
            if (v !== '') this.props.campaignCreator.addCompleted(this.props.stepIndex);
            else this.props.campaignCreator.removeCompleted(this.props.stepIndex);
            this.props.campaignCreator.updateCouponText({ line1: v });
          }}
        />
        <br />
        <TextField
          label="Line 2"
          value={this.props.text.line2}
          onChange={(e) => {
            const v = e.target.value;
            this.props.campaignCreator.updateCouponText({ line2: v });
          }}
        />
        <br />
        <TextField
          label="Extended text"
          value={this.props.text.extended}
          onChange={(e) => {
            const v = e.target.value;
            this.props.campaignCreator.updateCouponText({ extended: v });
          }}
        />
      </Paper>
    );
  }
}
const CustomText = connect(
  (state) => ({ text: getCouponText(state) }),
  (dispatch) => ({
    campaignCreator: {
      updateCouponText: (line) => dispatch(campaign.updateCouponText(line)),
      addCompleted: (index) => dispatch(campaign.addCompleted(index)),
      removeCompleted: (index) => dispatch(campaign.removeCompleted(index)),
    },
  })
)(CustomTextModal);

class CB_Coupon extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'campaignCreator4',
      classNamePrefix: 'CampaignCreator-Step4-',
    });
    this.state = {
      artwork: null,
      line1: props.text.line1,
      line2: props.text.line2,
      extended: props.text.extended,
      imageTime: moment().valueOf(),
      uploading: false,
      image: undefined,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isEqual(nextProps.text, {
        line1: this.state.line1,
        line2: this.state.line2,
        extended: this.state.extended,
      })
    ) {
      this.setState({
        line1: nextProps.text.line1,
        line2: nextProps.text.line2,
        extended: nextProps.text.extended,
      });
      if (nextProps.text.line1 !== '') {
        this.props.actions.campaign.addCompleted(3);
      } else {
        this.props.actions.campaign.removeCompleted(3);
      }
    }
    // Removed the request to get an image from the api since no one uses it
    // if (nextProps.campaign_id && nextProps.artwork.image) this.getImage(nextProps.campaign_id);
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  addArtwork = () => {
    const file = document.querySelector('input[type=file]').files[0];
    if (file) {
      this.setState({ uploading: true });
      this.props.actions.campaign
        .uploadArtwork(file)
        .then(() => {
          this.setState({ imageTime: moment().valueOf() });
          this.setState({ uploading: false });
        })
        .catch((err) => {
          this.setState({ uploading: false });
          if (err === 'invalid_type') {
            this.props.actions.modal.toggle({
              show: true,
              title: 'Invalid Image',
              style: 'warning',
              body: 'The image must be of file type jpeg, jpg or png.',
              actions: [
                {
                  text: 'ok',
                  action: () => this.props.actions.modal.toggle({ show: false }),
                },
              ],
            });
          } else {
            this.props.actions.modal.toggle({
              show: true,
              title: 'Artwork Error',
              style: 'error',
              body: 'There was a problem uploading the artwork. Please try again later.',
              actions: [{ text: 'OK', action: () => this.props.actions.modal.toggle({ show: false }) }],
            });
          }
        });
    }
  };

  removeArtwork = () => {
    this.props.actions.campaign.removeArtwork();
    this.setState({ image: undefined });
  };

  increaseArtwork = () => {
    this.props.actions.campaign.updateArtworkPosition({
      s: this.props.artwork.position.s + 1,
    });
  };

  decreaseArtwork = () => {
    this.props.actions.campaign.updateArtworkPosition({
      s: this.props.artwork.position.s - 1,
    });
  };

  invokeTextModal = () => {
    this.props.actions.modal.toggle({
      show: true,
      title: 'Customise Coupon Text',
      body: <CustomText stepIndex={this.props.stepIndex} />,
      actions: [{ text: 'Ok', action: () => this.props.actions.modal.toggle({ show: false }) }],
    });
  };

  getImage = async (campaign_id) => {
    const packshot = await returnJSON(`/api/targeted-comms/smartcounts/campaign/proof/pack-shot/${campaign_id}`);
    this.setState({ image: packshot });
  };

  render() {
    const { classes } = this.stylesheet;

    return (
      <div>
        {/* Removed the button for uploading coupon artwork and scaling features since no one uses it.
                {this.props.stage === 0 && (
                    <div>
                        {!this.props.artwork.image ? (
                            <Button variant="contained" component="label">
                                Add Artwork
                                <input type="file" onChange={this.addArtwork} hidden />
                            </Button>
                        ) : (
                            <span>
                                <Button variant="contained" onClick={this.removeArtwork}>
                                    Remove Artwork
                                </Button>
                                <Button
                                    style={{
                                        minWidth: 'auto',
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        marginLeft: 10,
                                    }}
                                    variant="contained"
                                    onClick={this.increaseArtwork}
                                >
                                    +
                                </Button>
                                <Button
                                    style={{ minWidth: 'auto', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                    variant="contained"
                                    onClick={this.decreaseArtwork}
                                >
                                    -
                                </Button>
                            </span>
                        )} */}
        <Button variant="contained" onClick={() => this.invokeTextModal()} style={{ marginLeft: 10 }}>
          <span>
            {this.props.text.line1 === '' && <MdWarning color={colorNames.orange} />}
            Coupon Text
          </span>
        </Button>
        {/* </div>
                )} */}
        <div>
          {map(this.props.coupons, (coupon, i) => {
            return (
              <div className={classes.group_flex} key={i} style={{ textAlign: 'center' }}>
                <ContentHeader header={`Variation ${i + 1}`} />
                {map(coupon.cells, (cell, i) => {
                  return (
                    <Badge color={colorNames.graphite} key={i}>
                      {cell.replace(/_/g, ' ').toSentenceCase()}
                    </Badge>
                  );
                })}
                <br />
                <div className={classes.coupon_wrapper}>
                  <CouponDesign
                    type={coupon.type}
                    offer={coupon.value}
                    template={this.props.coupons.template}
                    layout={coupon.template === 2 ? 'extended' : 'standard'}
                    text={this.props.text}
                    artwork={{
                      ...this.props.artwork,
                      image: this.state.image,
                      movable: this.props.stage === 0,
                      onPositionChange: this.props.actions.campaign.updateArtworkPosition,
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stage: getCampaignObject(state).stage,
    campaign_id: getCampaignObject(state).campaign_id,
    coupons: getCoupons(state),
    text: getCouponText(state),
    artwork: getCouponArtwork(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    campaign: {
      addCompleted: (index) => dispatch(campaign.addCompleted(index)),
      removeCompleted: (index) => dispatch(campaign.removeCompleted(index)),
      uploadArtwork: (path) => dispatch(campaign.uploadCouponArtwork(path)),
      updateArtworkPosition: (coords) => dispatch(campaign.updateCouponArtworkPosition(coords)),
      removeArtwork: () => dispatch(campaign.removeArtwork()),
      updateCouponText: (text) => dispatch(campaign.updateCouponText(text)),
    },
    modal: {
      toggle: (opts) => dispatch(modal.toggle(opts)),
      update: (opts) => dispatch(modal.update(opts)),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CB_Coupon);
