import { combineReducers } from 'redux';
import savetargetedcomms from './TargetedComms/save_targeted_comms';
import { default as planningtool } from './TargetedComms/PlanningTool';
import errorInfo from './errorInfo';
import loading from './loading';
import modal from './modal';
import queue from './queue';

import TargetedComms from './TargetedComms';

export default combineReducers({
  savetargetedcomms,
  planningtool,
  errorInfo,
  loading,
  modal,
  queue,
  TargetedComms,
});
