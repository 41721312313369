import Papa from 'papaparse';

const filterData = (str, arr, dataValue, cb) => {
  const items = arr.filter((el) => {
    return el[dataValue].toLowerCase().indexOf(str.toLowerCase()) !== -1;
  });
  return cb(items);
};

const formatProper = (str, except = null) => {
  const exceptLower = except !== null ? except.map((txt) => txt.toLowerCase()) : [];
  str = str.toString().replace(/_/g, ' ').replace(/ +/g, ' ');
  return str.replace(/\w\S*/g, (txt) => {
    const pos = exceptLower.indexOf(txt.toLowerCase());
    if (pos === -1) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
    return except[pos];
  });
};

const downloadCSV = (json, filename) => {
  const csv = Papa.unparse(json);
  const uri = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
  const link = document.createElement('a');
  if (csv === '') {
    return;
  }
  link.href = uri;
  link.style = 'visibility:hidden';
  link.download = filename.replace(/ /g, '');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const copyToClipboard = (element, sep = ', ') => {
  let text;
  if (Array.isArray(element)) {
    text = element.join(sep);
  } else if (typeof element === 'string') {
    text = element;
  } else {
    throw TypeError;
  }
  const input = document.createElement('input');
  document.body.appendChild(input);
  input.value = text;
  input.select();
  document.execCommand('copy');
  input.remove();
};

export { filterData, formatProper, downloadCSV, copyToClipboard };
