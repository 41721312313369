import { forEach, map, reduce } from 'lodash';
import ActionTypes from '../../../../constants/ActionTypes';
import * as loading from '../../../loading';
import { postJSON } from '../../../../utils/async';
import buildPayload from '../../../../components/views/TargetedComms/Smartcounts/CampaignBuilder/selectors/buildPayload';
import getCampaignObject from '../../../../components/views/TargetedComms/Smartcounts/CampaignBuilder/selectors/getCampaignObject';

export * from './timings';
export * from './targeting';
export * from './coupons';
export * from './barcodes';
export * from './clashes';

const {
  targetedComms,
  targetedComms: {
    smartcounts: { campaign },
  },
} = ActionTypes;

let debounceSync;

export const syncToServerDebounced =
  (update, delay = 1000) =>
  (dispatch) => {
    clearTimeout(debounceSync);
    return Promise.resolve(dispatch(update)).then(() => {
      debounceSync = setTimeout(() => {
        dispatch(syncToServer);
      }, delay);
    });
  };

export const syncToServer = (dispatch, getState) => {
  const payload = buildPayload(getState());
  postJSON(
    '/api/targeted-comms/smartcounts/campaign/update',
    {
      route: '/smartcounts/campaign/creator',
      payload,
    },
    (err, res) => {
      dispatch({ type: targetedComms.SAVED_TARGETED_COMMS, lastSave: res.lastSave });
    }
  );
};

export const addCompleted = (index) => (dispatch, getState) => {
  const { completed } = getCampaignObject(getState());
  if (!completed.includes(index)) {
    return Promise.resolve(dispatch(syncToServerDebounced({ type: campaign.ADD_COMPLETED, index })));
  }
};

export const updateProvisionalBase = (base) => (dispatch) =>
  Promise.resolve(dispatch({ type: campaign.PROVISIONAL_BASE, base }));

export const removeCompleted = (index) => (dispatch) =>
  Promise.resolve(dispatch(syncToServerDebounced({ type: campaign.REMOVE_COMPLETED, index })));

export const resetCampaign = () => (dispatch) => Promise.resolve(dispatch({ type: campaign.RESET }));

export const resetFromStep = (index) => (dispatch) => Promise.resolve(dispatch({ type: campaign.RESET, index }));

export const start = (count_id) => (dispatch) =>
  Promise.resolve(dispatch(resetCampaign())).then(
    () =>
      new Promise((resolve, reject) => {
        dispatch(loading.toggle({ show: true, text: 'Loading Campaign...' }));
        postJSON('/api/targeted-comms/smartcounts/campaign/start', { count_id }, (err, result) => {
          dispatch(loading.toggle({ show: false }));
          if (err) {
            console.error(err);
            return reject(err);
          }

          const { payload, rejected, stage, catable_stores, restored } = result;

          // let cells = {};
          const cells = reduce(
            payload.cells,
            (output, cell, code) => {
              (output.visible || (output.visible = [])).push(code);
              output.redemptionRates = { ...output.redemptionRates, [code]: cell.redemptionRate };
              output.offers = {
                ...output.offers,
                [code]: { type: cell.offer_type, value: cell.offer_value },
              };
              output.templates = { ...output.templates, [code]: cell.template };
              output.barcodes = { ...output.barcodes, [code]: cell.barcode };

              if (cell.required) {
                (output.required || (output.required = [])).push(code);
              }
              if (cell.continuity) {
                (output.continuity || (output.continuity = [])).push(code);
              }
              if (cell.ab) {
                (output.ab || (output.ab = [])).push(code);
              }
              if (code === 'transactional') {
                output.transactional = true;
              }
              if (code === 'existing') {
                output.groupedExisting = true;
              }
              if (cell.printVolume > 0) {
                output.prints = {
                  ...output.prints,
                  [code]: { value: cell.printVolume, method: cell.printVolumeType },
                };
              }
              return output;
            },
            {}
          );

          const unpacked = {
            ...(payload.completed && { completed: payload.completed }),
            id: result.id,
            campaign_id: result.campaign_id,
            base_id: result.base_id,
            ...(payload.name && { name: payload.name }),
            ...(payload.fullName && { full_name: payload.fullName }),
            restored: result.restored,
            stage,
            rejected: rejected || false,
            ...(payload.time_periods && { time_periods: payload.time_periods }),
            ...(payload.clashes && { clashes: payload.clashes }),
            ...(payload.coupon_text && {
              coupons: {
                artwork: payload.coupon_artwork,
                text: payload.coupon_text,
                template: payload.temp.coupon_template,
                artwork_path: payload.packShot,
              },
            }),
            rsp: {
              original: payload.temp.rsp,
              used: payload.rsp || payload.temp.rsp,
            },
            cells,
            ...(payload.supplier && { supplier: payload.supplier }),
            ...(payload.temp && {
              count: {
                id: payload.count_id,
                payload: payload.temp.count_data.details,
                results: payload.temp.count_data.results,
                queue_id: payload.temp.count_data.count.queue_id,
              },
              sku_details: payload.temp.sku_details,
              skus_missing: payload.temp.count_data.details.skus_missing || [],
              cycles: payload.temp.cycles,
              redemption_rates: payload.temp.redemption_rates,
            }),
            catable_stores,
            execution: payload.execution,
          };

          resolve(dispatch({ type: campaign.START_CAMPAIGN, data: unpacked }));
        });
      })
  );

export const updateRejectionComment = (comment) => (dispatch) =>
  Promise.resolve(
    dispatch([
      {
        type: campaign.UPDATE_REJECTION_COMMENT,
        comment,
      },
    ])
  );

export const updateStage = (stage) => {
  return (dispatch, getState) => {
    const { campaign_id, rejectionComment: comment } = getCampaignObject(getState());
    return new Promise((resolve, reject) =>
      postJSON(
        '/api/targeted-comms/smartcounts/campaign/update-stage',
        {
          nextStage: stage,
          campaign: campaign_id,
          comment,
        },
        (err, res) => {
          resolve(dispatch({ type: campaign.UPDATE_STAGE, stage }));
        }
      )
    );
  };
};

export const updateSupplier = (update) => syncToServerDebounced({ type: campaign.UPDATE_SUPPLIER, update });

export const updateCampaignName = (name) => syncToServerDebounced({ type: campaign.UPDATE_NAME, name });

export const updateBaseID = (id) => (dispatch) =>
  Promise.resolve(dispatch([{ type: campaign.UPDATE_BASE_ID, id }, syncToServer]));
