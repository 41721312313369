import React from 'react';
import { connect } from 'react-redux';
import jss from '../../../../../../utils/jss';
import { colorNames } from '../../../../../../constants/colors';
import Card from '../../../../../lib/Card/index';
import { getStageCount } from '../selector';
import SmartcountsShared from '../../Constants/SmartcountsShared';

const styles = {
  wrap: {
    display: 'flex',
    marginTop: 20,
  },
  box: {
    flex: 2,
    cursor: 'pointer',
  },
  between: {
    flex: 1,
    position: 'relative',
    '&:after': {
      content: "' '",
      position: 'absolute',
      top: 'calc(50% - 1.5px)',
      left: 0,
      right: 0,
      borderBottom: `3px solid ${colorNames.green}`,
    },
  },
  number: {
    fontSize: '32pt',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  arrow: {
    borderStyle: 'solid',
    borderColor: colorNames.green,
    borderWidth: '0 3px 3px 0',
    display: 'inline-block',
    padding: '6px',
    transform: 'rotate(-45deg)',
    position: 'absolute',
    top: '49%',
    marginTop: '-7px',
    left: '50%',
    marginLeft: '-10px',
  },
};

class Stages extends React.Component {
  constructor(props) {
    super(props);
    this.stylesheet = jss.createStyleSheet(styles, {
      meta: 'SmartcountStages',
      classNamePrefix: 'SmartcountStages-',
    });
  }

  componentDidMount() {
    this.stylesheet.attach();
  }

  componentWillUnmount() {
    this.stylesheet.detach();
  }

  render() {
    const { classes } = this.stylesheet;
    const { currentView, onViewChange } = this.props;
    const { typeCounts, SCAccess } = this.props;
    const { execute: canExecute } = SCAccess;
    return (
      <div className={classes.wrap}>
        <Card
          highlight={currentView === 'counts'}
          onClick={() => onViewChange('counts')}
          className={classes.box}
          header="Counts"
        >
          <div className={classes.number}>{typeCounts.counts}</div>
        </Card>
        <div className={classes.between}>
          <div className={classes.arrow} />
        </div>
        <Card
          highlight={currentView === 'inprogress'}
          onClick={() => onViewChange('inprogress')}
          className={classes.box}
          header="In Progress"
        >
          <div className={classes.number}>{typeCounts.inprogress}</div>
        </Card>
        <div className={classes.between}>
          <div className={classes.arrow} />
        </div>
        <Card
          highlight={currentView === 'approvals'}
          onClick={() => onViewChange('approvals')}
          className={classes.box}
          header="To Approve"
        >
          <div className={classes.number}>{typeCounts.approvals}</div>
        </Card>
        <div className={classes.between}>
          <div className={classes.arrow} />
        </div>
        <Card
          highlight={currentView === 'approved'}
          onClick={() => onViewChange('approved')}
          className={classes.box}
          header="Approved"
        >
          <div className={classes.number}>{typeCounts.approved}</div>
        </Card>
        <div className={classes.between}>
          <div className={classes.arrow} />
        </div>
        {canExecute && [
          <Card
            key="card"
            highlight={currentView === 'execution'}
            onClick={() => onViewChange('execution')}
            className={classes.box}
            header="To Execute"
          >
            <div className={classes.number}>{typeCounts.executions}</div>
          </Card>,
          <div key="between" className={classes.between}>
            <div className={classes.arrow} />
          </div>,
        ]}
        <Card
          highlight={currentView === 'executed'}
          onClick={() => onViewChange('executed')}
          className={classes.box}
          header="Executed"
        >
          <div className={classes.number}>{typeCounts.executed}</div>
        </Card>
      </div>
    );
  }
}

export default connect((state) => ({ typeCounts: getStageCount(state) }))(SmartcountsShared(Stages));
