import { find, reduce } from 'lodash';
import ActionTypes from '../../constants/ActionTypes/index';
import { postJSON } from '../../utils/async';

const restoreCount = (jobid) => {
  return (dispatch, getState) => {
    // dispatch(loading.toggle({ show: true, text: 'Loading count data...' }));
    // pulling a job out of queue data. Is this where the data comes from?!
    const job = find(getState().queue.data, (o) => {
      return o.id === jobid;
    });
    const { payload } = job;
    // this might be getting prerequisites for redoing the count.
    if (payload) {
      dispatch([
        {
          type: ActionTypes.targetedComms.planning.RESTORE_COUNT,
          payload,
        },
      ]);
    }
  };
};

const addCompleted = (index) => {
  return { type: ActionTypes.targetedComms.planning.ADD_COMPLETED, index };
};

const removeCompleted = (index) => {
  return { type: ActionTypes.targetedComms.planning.REMOVE_COMPLETED, index };
};

const reset = () => {
  return { type: ActionTypes.targetedComms.planning.RESET };
};

const receivedAisles = (aisles) => {
  return { type: ActionTypes.targetedComms.planning.RECEIVED_AISLES, aisles };
};

const getAisles = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const { productGroups } = getState().planningtool;

      dispatch({ type: ActionTypes.general.TOGGLE_LOADING, text: 'Loading Aisles...', show: true });

      const skus = reduce(
        productGroups,
        (groups, group) => {
          (groups[group.type.toLowerCase()] || (groups[group.type.toLowerCase()] = [])).push(
            ...group.skus.split(',').eachToInt()
          );
          return groups;
        },
        {}
      );

      postJSON('/api/targeted-comms/planningtool/aisles', skus, (err, result) => {
        dispatch({ type: ActionTypes.general.TOGGLE_LOADING, show: false });
        if (!err) {
          dispatch(receivedAisles(result));
          resolve(true);
        } else {
          dispatch({ type: ActionTypes.error.INFO, code: 500, data: err });
          reject(err);
        }
      });
    });
  };
};

const updateName = (data) => {
  return { type: ActionTypes.targetedComms.planning.CHANGE_NAME, data };
};

const updateGroups = (data) => {
  return { type: ActionTypes.targetedComms.planning.CHANGE_GROUPS, productGroups: data };
};

const toggleBandingVisibility = (index, field) => {
  return { type: ActionTypes.targetedComms.planning.CHANGE_BANDING_VISIBILITY, index, field };
};

const updateAisles = (definitions, rules) => {
  return { type: ActionTypes.targetedComms.planning.CHANGE_AISLES, definitions, rules };
};

const updateStoreList = (stores) => {
  return { type: ActionTypes.targetedComms.planning.CHANGE_STORES, stores };
};

const updatePeriod = (type, dates) => {
  return { type: ActionTypes.targetedComms.planning.CHANGE_PERIOD, periodDates: dates, periodType: type };
};

const updateCoupons = (coupons) => {
  return { type: ActionTypes.targetedComms.planning.CHANGE_COUPONS, coupons };
};

const updatePromos = (promos) => {
  return { type: ActionTypes.targetedComms.planning.CHANGE_PROMOS, promos };
};

const updateVariables = (variables) => {
  return { type: ActionTypes.targetedComms.planning.CHANGE_VARS, variables };
};

const updateSegmentations = (segmentations) => {
  return { type: ActionTypes.targetedComms.planning.CHANGE_SEGMENTATIONS, segmentations };
};

export default {
  addCompleted,
  removeCompleted,
  reset,
  receivedAisles,
  getAisles,
  updateName,
  updateAisles,
  updateCoupons,
  updatePromos,
  updateGroups,
  toggleBandingVisibility,
  updatePeriod,
  updateSegmentations,
  updateStoreList,
  updateVariables,
  restoreCount,
};
